import * as React from "react";

function SvgBokumanots(props) {
  return (
    <svg width="19.877" height="17.753" viewBox="0 0 19.877 17.753" {...props}>
      <symbol id="Bokumanots_svg__g" viewBox="-13.026 -12.415 26.052 24.83">
        <linearGradient
          id="Bokumanots_svg__a"
          gradientUnits="userSpaceOnUse"
          x1={4.15}
          y1={-10.07}
          x2={4.15}
          y2={7.683}
        >
          <stop offset={0.206} stopColor="#ba6c29" />
          <stop offset={0.807} stopColor="#e98624" />
        </linearGradient>
        <path
          d="M13.026-1.193a8.877 8.877 0 10-17.754 0 8.877 8.877 0 0017.754 0z"
          fill="url(#Bokumanots_svg__a)"
        />
        <linearGradient
          id="Bokumanots_svg__b"
          gradientUnits="userSpaceOnUse"
          x1={2.025}
          y1={-10.07}
          x2={2.025}
          y2={7.683}
        >
          <stop offset={0} stopColor="#f7992d" />
          <stop offset={0.464} stopColor="#fac24e" />
          <stop offset={1} stopColor="#fef277" />
        </linearGradient>
        <path
          d="M10.901-1.193a8.877 8.877 0 10-17.754 0 8.877 8.877 0 0017.754 0z"
          fill="url(#Bokumanots_svg__b)"
        />
        <defs>
          <path
            id="Bokumanots_svg__c"
            d="M10.901-1.193a8.877 8.877 0 10-17.754 0 8.877 8.877 0 0017.754 0z"
          />
        </defs>
        <clipPath id="Bokumanots_svg__d">
          <use xlinkHref="#Bokumanots_svg__c" overflow="visible" />
        </clipPath>
        <linearGradient
          id="Bokumanots_svg__e"
          gradientUnits="userSpaceOnUse"
          x1={-526.331}
          y1={-1626.725}
          x2={-518.165}
          y2={-1629.724}
          gradientTransform="matrix(.9768 -.2145 .2144 .9768 856.642 1478.39)"
        >
          <stop offset={0} stopColor="#f7ec28" />
          <stop offset={0.231} stopColor="#f9f075" stopOpacity={0.744} />
          <stop offset={0.763} stopColor="#fefdf1" stopOpacity={0.152} />
          <stop offset={0.81} stopColor="#fff" stopOpacity={0.1} />
        </linearGradient>
        <path
          opacity={0.6}
          clipPath="url(#Bokumanots_svg__d)"
          fill="url(#Bokumanots_svg__e)"
          d="M-5.688-12.415l-7.338 4.894L.413 12.415 7.75 7.521z"
        />
        <linearGradient
          id="Bokumanots_svg__f"
          gradientUnits="userSpaceOnUse"
          x1={-7348.473}
          y1={754.019}
          x2={-7340.307}
          y2={751.02}
          gradientTransform="matrix(.5696 -.0172 .269 .7463 3982.422 -689.142)"
        >
          <stop offset={0} stopColor="#f7ec28" />
          <stop offset={0.285} stopColor="#f9f075" stopOpacity={0.715} />
          <stop offset={0.942} stopColor="#fefdf1" stopOpacity={0.058} />
          <stop offset={1} stopColor="#fff" stopOpacity={0} />
        </linearGradient>
        <path
          opacity={0.6}
          clipPath="url(#Bokumanots_svg__d)"
          fill="url(#Bokumanots_svg__f)"
          d="M-2.163-10.727L-5.98-8.193 5.074 8.339l3.818-2.535z"
        />
        <path
          d="M2.025 7.683A8.839 8.839 0 01-4.4 4.919 8.836 8.836 0 001.713 7.37a8.878 8.878 0 008.876-8.876 8.838 8.838 0 00-2.451-6.113 8.843 8.843 0 012.764 6.426c-.001 4.9-3.975 8.876-8.877 8.876z"
          opacity={0.4}
          fill="#fdf7b8"
        />
      </symbol>
      <use
        xlinkHref="#Bokumanots_svg__g"
        width={26.052}
        height={24.83}
        x={-13.026}
        y={-12.415}
        transform="matrix(1 0 0 -1 6.85 7.683)"
        overflow="visible"
      />
    </svg>
  );
}

export default SvgBokumanots;
