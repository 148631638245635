import * as React from "react";

function SvgRoket(props) {
  var screen = {
    mixBlendMode: "screen",
  };
  return (
    <svg
      width="110.098"
      height="297.69"
      viewBox="0 0 110.098 297.69"
      {...props}
    >
      <linearGradient
        id="Roket_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={55.118}
        y1={125.122}
        x2={36.929}
        y2={297.589}
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={0.128} stopColor="#f0eef5" stopOpacity={0.863} />
        <stop offset={0.418} stopColor="#dcd6e7" stopOpacity={0.552} />
        <stop offset={0.692} stopColor="#d1c8e0" stopOpacity={0.258} />
        <stop offset={0.933} stopColor="#cec4dd" stopOpacity={0} />
      </linearGradient>
      <path
        opacity={0.85}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__a)"
        style={{ mixBlendMode: "soft-light" }}
        d="M36.415 124.267L20.496 297.69h23.125l29.897-168.834z"
      />
      <linearGradient
        id="Roket_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={364.841}
        y1={149.757}
        x2={364.841}
        y2={94.413}
        gradientTransform="scale(-1 1) rotate(-7.198 185.978 3784.09)"
      >
        <stop offset={0.002} stopColor="#e03548" />
        <stop offset={1} stopColor="#f36f2e" />
      </linearGradient>
      <path
        d="M97.078 100.85s10.525 10.761 13.02 22.124c0 0-12.049 23.93-31.889 31.275 0 0 8.158-25.115 18.869-53.399z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__b)"
      />
      <linearGradient
        id="Roket_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={372.865}
        y1={149.757}
        x2={372.865}
        y2={94.413}
        gradientTransform="scale(-1 1) rotate(-7.198 185.978 3784.09)"
      >
        <stop offset={0} stopColor="#c74028" />
        <stop offset={0.998} stopColor="#a41d2e" />
      </linearGradient>
      <path
        d="M97.078 100.85l-2.504-.314s-8.693 25.961-16.717 53.616l.352.098c0-.001 11.207-28.737 18.869-53.4z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__c)"
      />
      <linearGradient
        id="Roket_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={376.715}
        y1={121.049}
        x2={376.715}
        y2={98.197}
        gradientTransform="scale(-1 1) rotate(-7.198 185.978 3784.09)"
      >
        <stop offset={0} stopColor="#5c5aa4" />
        <stop offset={0.397} stopColor="#5f5aa3" />
        <stop offset={0.768} stopColor="#695da3" />
        <stop offset={0.994} stopColor="#725fa2" />
      </linearGradient>
      <path
        d="M78.234 121.869l5.703 2.866 3.473 1.739 3.848-11.681s-3.316-1.973-4.195-3.495c-.328-.565-.449-1.417-.459-2.355-.031-2.312.629-5.15.709-5.513-.28.633-4.79 10.896-9.079 18.439z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__d)"
      />
      <linearGradient
        id="Roket_svg__e"
        gradientUnits="userSpaceOnUse"
        x1={372.24}
        y1={149.757}
        x2={372.24}
        y2={94.413}
        gradientTransform="scale(-1 1) rotate(-7.198 185.978 3784.09)"
      >
        <stop offset={0} stopColor="#9b6aa5" />
        <stop offset={0.465} stopColor="#c5abcc" />
        <stop offset={0.828} stopColor="#ebe3ee" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M92.322 145.612s-5.379-2.736-3.879-19.004c0 0 5.916-16.164 8.635-25.759l-5.822 13.943-4.988 11.112-8.059 28.344c0 .001 7.473-2.024 14.113-8.636z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__e)"
      />
      <path
        d="M110.098 122.974s-6.059-14.641-13.02-22.124c0 0 10.735 10.258 13.02 22.124z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#f8aba0"
      />
      <linearGradient
        id="Roket_svg__f"
        gradientUnits="userSpaceOnUse"
        x1={379.385}
        y1={119.758}
        x2={379.385}
        y2={98.197}
        gradientTransform="scale(-1 1) rotate(-7.198 185.978 3784.09)"
      >
        <stop offset={0} stopColor="#b4a7d2" />
        <stop offset={0.206} stopColor="#ac94c2" />
        <stop offset={0.54} stopColor="#a37db1" />
        <stop offset={0.817} stopColor="#9d6fa8" />
        <stop offset={1} stopColor="#9b6aa5" />
      </linearGradient>
      <path
        d="M78.234 121.869l5.703 2.866 2.666-15.792c-.031-2.312.629-5.15.709-5.513-.279.633-4.789 10.896-9.078 18.439z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__f)"
      />
      <path
        d="M109.443 120.968c-.064 1.567-1.715-.892-2.463-3.538-.748-2.645-.574-3.489-.574-3.489s3.135 4.638 3.037 7.027z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
      <linearGradient
        id="Roket_svg__g"
        gradientUnits="userSpaceOnUse"
        x1={39.116}
        y1={149.108}
        x2={39.116}
        y2={93.767}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0.002} stopColor="#e03548" />
        <stop offset={1} stopColor="#f36f2e" />
      </linearGradient>
      <path
        d="M18.111 90.88S5.24 98.687 0 109.074c0 0 5.724 26.173 23.116 38.22 0 0-1.661-26.356-5.005-56.414z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__g)"
      />
      <linearGradient
        id="Roket_svg__h"
        gradientUnits="userSpaceOnUse"
        x1={47.141}
        y1={149.108}
        x2={47.141}
        y2={93.765}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#c74028" />
        <stop offset={0.998} stopColor="#a41d2e" />
      </linearGradient>
      <path
        d="M18.111 90.88l2.503.315s1.968 27.31 2.866 56.091l-.365.008c.001 0-3.714-30.621-5.004-56.414z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__h)"
      />
      <linearGradient
        id="Roket_svg__i"
        gradientUnits="userSpaceOnUse"
        x1={50.988}
        y1={120.398}
        x2={50.988}
        y2={97.549}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#5c5aa4" />
        <stop offset={0.397} stopColor="#5f5aa3" />
        <stop offset={0.768} stopColor="#695da3" />
        <stop offset={0.994} stopColor="#725fa2" />
      </linearGradient>
      <path
        d="M31.138 115.923l-6.236 1.358-3.797.821-.822-12.27s3.702-1.087 4.932-2.344c.459-.465.788-1.26 1.031-2.165.603-2.232.671-5.147.682-5.518.114.684 1.931 11.745 4.21 20.118z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__i)"
      />
      <linearGradient
        id="Roket_svg__j"
        gradientUnits="userSpaceOnUse"
        x1={46.516}
        y1={149.108}
        x2={46.516}
        y2={93.767}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#9b6aa5" />
        <stop offset={0.465} stopColor="#c5abcc" />
        <stop offset={0.828} stopColor="#ebe3ee" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M11.592 135.419s5.889-1.313 8.48-17.442c0 0-1.712-17.128-1.961-27.097l2.172 14.951 2.07 12.004.762 29.459c.001 0-6.735-3.82-11.523-11.875z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__j)"
      />
      <path
        d="M0 109.074S9.507 96.397 18.111 90.88c0 0-12.948 7.267-18.111 18.194z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#f8aba0"
      />
      <linearGradient
        id="Roket_svg__k"
        gradientUnits="userSpaceOnUse"
        x1={53.656}
        y1={119.108}
        x2={53.656}
        y2={97.549}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#b4a7d2" />
        <stop offset={0.206} stopColor="#ac94c2" />
        <stop offset={0.54} stopColor="#a37db1" />
        <stop offset={0.817} stopColor="#9d6fa8" />
        <stop offset={1} stopColor="#9b6aa5" />
      </linearGradient>
      <path
        d="M31.138 115.923l-6.236 1.358 1.343-15.957c.603-2.232.671-5.147.682-5.518.115.683 1.932 11.744 4.211 20.117z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__k)"
      />
      <path
        d="M1.133 107.293c-.327 1.534 1.883-.438 3.266-2.814 1.381-2.378 1.423-3.236 1.423-3.236s-4.191 3.711-4.689 6.05z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
      <radialGradient
        id="Roket_svg__l"
        cx={71.795}
        cy={33.34}
        r={66.616}
        gradientTransform="matrix(.9921 .1253 -.1482 1.1735 -12.531 6.973)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#dcccd9" />
        <stop offset={0.108} stopColor="#d5c6d6" />
        <stop offset={0.264} stopColor="#c3b6d0" />
        <stop offset={0.449} stopColor="#a79dc6" />
        <stop offset={0.655} stopColor="#8480ba" />
        <stop offset={0.754} stopColor="#7373b5" />
        <stop offset={0.975} stopColor="#787fbd" />
      </radialGradient>
      <path
        d="M80.395 123.446l-48.905-6.174s-2.863-6.576-5.048-17.276a119.857 119.857 0 01-2.385-20.801l.001-.009c-.196-7.204.266-15.085 1.803-23.35 4.097-21.957 13.611-29.549 24.277-31.615l.016.002c5.82-1.13 11.988-.602 17.807.133 10.807 1.365 19.715 4.345 25.295 11.756l.008.01c4.639 6.17 6.986 15.398 6.209 29.314-1.19 21.48-19.078 58.01-19.078 58.01z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__l)"
      />
      <linearGradient
        id="Roket_svg__m"
        gradientUnits="userSpaceOnUse"
        x1={82.891}
        y1={118.276}
        x2={82.891}
        y2={23.62}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#633a64" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M67.848 26.46l-12.01 93.889.193.024.484.062s9.012-52.23 11.508-93.942l-.175-.033z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__m)"
      />
      <linearGradient
        id="Roket_svg__n"
        gradientUnits="userSpaceOnUse"
        x1={57.603}
        y1={101.149}
        x2={102.247}
        y2={46.417}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#5c5aa4" />
        <stop offset={0.397} stopColor="#5f5aa3" />
        <stop offset={0.768} stopColor="#695da3" />
        <stop offset={0.994} stopColor="#725fa2" />
      </linearGradient>
      <path
        d="M55.838 120.349s5.977-54.147 12.01-93.889l.176.032s-6.775 65.765-11.992 93.881l-.194-.024z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__n)"
      />
      <radialGradient
        id="Roket_svg__o"
        cx={71.242}
        cy={20.578}
        r={22.232}
        gradientTransform="rotate(7.207 52.437 -89.18)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#b2b6be" />
        <stop offset={0.112} stopColor="#adb0bd" />
        <stop offset={0.273} stopColor="#9fa0b9" />
        <stop offset={0.464} stopColor="#8b88b3" />
        <stop offset={0.678} stopColor="#726bac" />
        <stop offset={0.754} stopColor="#6860ab" />
        <stop offset={0.975} stopColor="#7774b6" />
      </radialGradient>
      <path
        d="M50.641 23.836l1.482-2.11s12.27-1.287 30.994 3.605l.992 2.984-33.468-4.479z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__o)"
      />
      <radialGradient
        id="Roket_svg__p"
        cx={75.434}
        cy={17.63}
        r={13.482}
        gradientTransform="rotate(7.207 52.437 -89.18)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#b2b6be" />
        <stop offset={0.112} stopColor="#adb0bd" />
        <stop offset={0.273} stopColor="#9fa0b9" />
        <stop offset={0.464} stopColor="#8b88b3" />
        <stop offset={0.678} stopColor="#726bac" />
        <stop offset={0.754} stopColor="#6860ab" />
        <stop offset={0.975} stopColor="#7774b6" />
      </radialGradient>
      <path
        d="M57.719 21.587l1.707-2.455s11.303-.369 18.027 2.817l.48 2.142-20.214-2.504z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__p)"
      />
      <radialGradient
        id="Roket_svg__q"
        cx={69.166}
        cy={45.774}
        r={35.985}
        gradientTransform="rotate(7.207 52.437 -89.18)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.025} stopColor="#6c65ad" />
        <stop offset={0.939} stopColor="#5b56a5" />
      </radialGradient>
      <path
        d="M54.545 38.912c-13.228 5.511-16.655 29.573-1.361 37.205 15.293 7.63 29.918-4.536 30.236-17.41.314-12.72-13.25-26.302-28.875-19.795z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__q)"
      />
      <radialGradient
        id="Roket_svg__r"
        cx={69.172}
        cy={44.571}
        r={35.985}
        gradientTransform="rotate(7.207 52.437 -89.18)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#b2b6be" />
        <stop offset={0.112} stopColor="#adb0bd" />
        <stop offset={0.273} stopColor="#9fa0b9" />
        <stop offset={0.464} stopColor="#8b88b3" />
        <stop offset={0.678} stopColor="#726bac" />
        <stop offset={0.754} stopColor="#6860ab" />
        <stop offset={0.975} stopColor="#7774b6" />
      </radialGradient>
      <path
        d="M54.703 37.72c-13.229 5.51-16.656 29.573-1.361 37.203 15.293 7.631 29.918-4.535 30.236-17.408.315-12.72-13.252-26.302-28.875-19.795z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__r)"
      />
      <radialGradient
        id="Roket_svg__s"
        cx={82.143}
        cy={66.819}
        r={25.14}
        gradientTransform="rotate(7.207 52.437 -89.18)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.025} stopColor="#1d96b9" />
        <stop offset={0.939} stopColor="#5b56a5" />
      </radialGradient>
      <path
        d="M74.199 65.561c-2.104 2.698-5.27 4.729-9.49 5.356-9.35 1.393-15.834-7.496-15.561-15.498.071-2.18.652-4.302 1.809-6.124 6.383-10.063 19.205-8.082 24.16-.62 3.235 4.871 2.916 11.983-.918 16.886z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__s)"
      />
      <radialGradient
        id="Roket_svg__t"
        cx={81.096}
        cy={59.382}
        r={14.382}
        gradientTransform="rotate(7.207 52.437 -89.18)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#70cff2" />
        <stop offset={0.304} stopColor="#69c5ed" />
        <stop offset={0.804} stopColor="#52b0e2" />
        <stop offset={1} stopColor="#47a7de" />
      </radialGradient>
      <path
        d="M52.992 50.478c-4.518 7.123 1.549 19.567 11.5 18.083 9.949-1.484 12.893-12.288 8.701-18.603-4.142-6.238-14.865-7.893-20.201.52z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__t)"
      />
      <radialGradient
        id="Roket_svg__u"
        cx={67.401}
        cy={90.525}
        r={31.607}
        gradientTransform="matrix(.9921 .1253 -.1482 1.1735 -12.531 6.973)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#b2b6be" />
        <stop offset={0.112} stopColor="#adb0bd" />
        <stop offset={0.273} stopColor="#9fa0b9" />
        <stop offset={0.464} stopColor="#8b88b3" />
        <stop offset={0.678} stopColor="#726bac" />
        <stop offset={0.754} stopColor="#6860ab" />
        <stop offset={0.975} stopColor="#7774b6" />
      </radialGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__u)"
        d="M35.439 117.772l.976 6.495 37.103 4.589 1.906-6.035z"
      />
      <radialGradient
        id="Roket_svg__v"
        cx={78.493}
        cy={49.615}
        r={19.447}
        gradientTransform="matrix(.6806 .4686 -.7481 1.0866 25.634 -46.851)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#f48648" />
        <stop offset={0.212} stopColor="#9c5f3e" />
        <stop offset={0.416} stopColor="#614131" />
        <stop offset={0.603} stopColor="#3b2b23" />
        <stop offset={0.768} stopColor="#201915" />
        <stop offset={0.906} stopColor="#0b0907" />
        <stop offset={1} stopColor="#010101" />
      </radialGradient>
      <path
        d="M55.176 52.956c-8.035 11.67-20.474 17.051-27.784 12.018S20.669 46.4 28.704 34.73c8.035-11.669 20.474-17.05 27.785-12.017 7.308 5.033 6.722 18.573-1.313 30.243z"
        fillRule="evenodd"
        style={screen}
        clipRule="evenodd"
        fill="url(#Roket_svg__v)"
      />
      <radialGradient
        id="Roket_svg__w"
        cx={56.791}
        cy={41.272}
        r={25.311}
        gradientTransform="matrix(.9921 .1253 -.1482 1.1735 -12.531 6.973)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#633a64" />
      </radialGradient>
      <path
        d="M28.651 53.861c-6.188 25.903-1.852 46.467-1.706 47.172l-.504-1.037c-2.276-11.168-3.812-26.823-.58-44.159 4.097-21.957 13.611-29.549 24.277-31.615l.016.002 5.137.807c0-.001-20.348 2.476-26.64 28.83z"
        opacity={0.91}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__w)"
      />
      <linearGradient
        id="Roket_svg__x"
        gradientUnits="userSpaceOnUse"
        x1={81.986}
        y1={118.274}
        x2={81.986}
        y2={30.021}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#9b6aa5" />
        <stop offset={0.005} stopColor="#9c6ca6" />
        <stop offset={0.072} stopColor="#ae88b6" />
        <stop offset={0.143} stopColor="#c0a3c7" />
        <stop offset={0.223} stopColor="#d1bdd7" />
        <stop offset={0.31} stopColor="#dfd3e4" />
        <stop offset={0.409} stopColor="#ece4ef" />
        <stop offset={0.526} stopColor="#f5f1f7" />
        <stop offset={0.679} stopColor="#fcfbfd" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M80.395 123.446l-48.905-6.174s-2.863-6.576-5.048-17.276a119.857 119.857 0 01-2.385-20.801c.105.598 1.961 31.565 30.197 35.454 30.668 4.224 39.447-35.918 43.012-47.618 3.555-11.702-1.209-26.142-4.002-30.909 4.639 6.17 6.986 15.398 6.209 29.314-1.19 21.48-19.078 58.01-19.078 58.01z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__x)"
      />
      <linearGradient
        id="Roket_svg__y"
        gradientUnits="userSpaceOnUse"
        x1={81.655}
        y1={121.943}
        x2={81.655}
        y2={118.274}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={0.005} stopColor="#fdfcfd" />
        <stop offset={0.266} stopColor="#d3c0d9" />
        <stop offset={0.504} stopColor="#b997c0" />
        <stop offset={0.714} stopColor="#a87eb0" />
        <stop offset={0.886} stopColor="#9e6fa8" />
        <stop offset={1} stopColor="#9b6aa5" />
      </linearGradient>
      <path
        d="M36.005 121.54s7.177-2.196 20.675-.141c13.5 2.056 17.621 4.977 17.621 4.977l1.123-3.555-39.985-5.049.566 3.768z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__y)"
      />
      <path
        d="M43.282 32.091c-2.746-1.556-8.127 6.317-6.326 9.307 1.802 2.99 8.194-8.247 6.326-9.307z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
      <radialGradient
        id="Roket_svg__z"
        cx={73.732}
        cy={21.663}
        r={3.627}
        gradientTransform="rotate(7.207 52.437 -89.18)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#633a64" />
      </radialGradient>
      <path
        d="M55.727 21.576l-1.08 2.89 8.994 1.11 1.064-3.124s-6.148-.848-8.978-.876z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__z)"
      />
      <radialGradient
        id="Roket_svg__A"
        cx={77.529}
        cy={18.387}
        r={2.932}
        gradientTransform="rotate(7.207 52.437 -89.18)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#633a64" />
      </radialGradient>
      <path
        d="M59.881 21.837l1.092-2.717s5.086.012 6.852.415l-.891 3.216-7.053-.914z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__A)"
      />
      <radialGradient
        id="Roket_svg__B"
        cx={71.829}
        cy={90.412}
        r={3.91}
        gradientTransform="matrix(.9921 .1253 -.1482 1.1735 -12.531 6.973)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#633a64" />
      </radialGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__B)"
        d="M40.848 118.455l.338 6.144 8.628 1.089.758-6.006z"
      />
      <radialGradient
        id="Roket_svg__C"
        cx={101.159}
        cy={65.924}
        r={20.166}
        gradientTransform="matrix(.9921 .1253 -.1482 1.1735 -12.531 6.973)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#633a64" />
      </radialGradient>
      <path
        d="M71.889 121.675s9.799-7.779 20.105-32.137c0 0-7.234 23.564-12.207 33.135l-7.898-.998z"
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__C)"
      />
      <linearGradient
        id="Roket_svg__D"
        gradientUnits="userSpaceOnUse"
        x1={81.359}
        y1={61.617}
        x2={81.359}
        y2={39.926}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#e7e3f1" />
        <stop offset={0.191} stopColor="#cfbfd9" />
        <stop offset={0.452} stopColor="#b797c0" />
        <stop offset={0.684} stopColor="#a77eb0" />
        <stop offset={0.874} stopColor="#9e6fa8" />
        <stop offset={1} stopColor="#9b6aa5" />
      </linearGradient>
      <path
        d="M74.199 65.561c-.631-.339-.861-1.184-.426-2.775 2.219-8.126-3.451-15.765-12.289-14.834-8.828.931-10.211 8.903-10.211 8.903l-2.125-1.436c.071-2.18.652-4.302 1.809-6.124 6.383-10.063 19.205-8.082 24.16-.62 3.235 4.871 2.916 11.983-.918 16.886z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__D)"
      />
      <path
        d="M59.021 66.335c-.074 1.18 5.783 2.075 6.387.242.602-1.834-6.312-1.422-6.387-.242z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#92d6e3"
      />
      <radialGradient
        id="Roket_svg__E"
        cx={69.139}
        cy={43.169}
        r={7.123}
        gradientTransform="rotate(7.207 52.437 -89.18)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#633a64" />
      </radialGradient>
      <path
        d="M45.031 50.008s2.128-9.2 12.221-12.845l2.135 4.663s-7.133 1.584-10.328 9.628l-4.028-1.446z"
        opacity={0.91}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__E)"
      />
      <path
        d="M69.967 75.517l-1.924-4.773s5.367-1.322 9.615-9.667l4.08 1.812c0-.001-.951 8.126-11.771 12.628z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#decde0"
      />
      <radialGradient
        id="Roket_svg__F"
        cx={107.919}
        cy={33.056}
        r={7.612}
        gradientTransform="rotate(7.207 52.437 -89.18)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#633a64" />
      </radialGradient>
      <path
        d="M85.85 30.324s10.094 3.603 12.631 17.436c-.001 0-3.913-9.995-12.631-17.436z"
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__F)"
      />
      <linearGradient
        id="Roket_svg__G"
        gradientUnits="userSpaceOnUse"
        x1={82.086}
        y1={17.526}
        x2={82.086}
        y2={6.072}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0.006} stopColor="#f15e65" />
        <stop offset={0.316} stopColor="#ec5b64" />
        <stop offset={0.556} stopColor="#e05062" />
        <stop offset={0.773} stopColor="#d13e5f" />
        <stop offset={0.976} stopColor="#bd225a" />
        <stop offset={1} stopColor="#ba1e5a" />
      </linearGradient>
      <path
        d="M60.973 19.12S64.34 8.492 69.916 9.036c5.576.547 6.117 12.315 6.117 12.315l-15.06-2.231z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__G)"
      />
      <linearGradient
        id="Roket_svg__H"
        gradientUnits="userSpaceOnUse"
        x1={78.593}
        y1={102.577}
        x2={85.785}
        y2={93.76}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#5c5aa4" />
        <stop offset={0.397} stopColor="#5f5aa3" />
        <stop offset={0.768} stopColor="#695da3" />
        <stop offset={0.994} stopColor="#725fa2" />
      </linearGradient>
      <path
        d="M60.029 93.746l-.338-1.502s-2 1.906-2.818 5.843c-1.107 5.326 1.246 9.689 1.246 9.689l1.91-14.03z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__H)"
      />
      <path
        d="M68.473 10.621c-.924-1.637-4.313 2.903-3.75 4.08.562 1.178 4.15-3.37 3.75-4.08z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fff"
      />
      <linearGradient
        id="Roket_svg__I"
        gradientUnits="userSpaceOnUse"
        x1={225.419}
        y1={103.387}
        x2={232.792}
        y2={94.348}
        gradientTransform="scale(-1 1) rotate(-7.198 98.432 2391.319)"
      >
        <stop offset={0} stopColor="#5c5aa4" />
        <stop offset={0.397} stopColor="#5f5aa3" />
        <stop offset={0.768} stopColor="#695da3" />
        <stop offset={0.994} stopColor="#725fa2" />
      </linearGradient>
      <path
        d="M59.613 93.693l.063-1.318s2.102 2.292 1.916 6.308c-.252 5.436-3.613 9.076-3.613 9.076l1.634-14.066z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__I)"
      />
      <radialGradient
        id="Roket_svg__J"
        cx={-92.577}
        cy={42.748}
        r={10.009}
        gradientTransform="matrix(1.6537 .2088 -.2202 1.7443 232.294 -37.65)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ee3724" />
        <stop offset={0.105} stopColor="#c23a26" />
        <stop offset={0.35} stopColor="#773425" />
        <stop offset={0.57} stopColor="#46271e" />
        <stop offset={0.759} stopColor="#261915" />
        <stop offset={0.908} stopColor="#0e0908" />
        <stop offset={1} stopColor="#010101" />
      </radialGradient>
      <path
        d="M86.34 19.675c-1.217 9.642-9.615 16.522-18.756 15.368-9.143-1.153-15.566-9.907-14.35-19.549C54.453 5.853 62.85-1.028 71.992.126s15.567 9.907 14.348 19.549z"
        fillRule="evenodd"
        clipRule="evenodd"
        style={screen}
        fill="url(#Roket_svg__J)"
      />
      <radialGradient
        id="Roket_svg__K"
        cx={-75.109}
        cy={53.07}
        r={5.775}
        gradientTransform="matrix(1.6537 .2088 -.2202 1.7443 232.294 -37.65)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ee3724" />
        <stop offset={0.105} stopColor="#c23a26" />
        <stop offset={0.35} stopColor="#773425" />
        <stop offset={0.57} stopColor="#46271e" />
        <stop offset={0.759} stopColor="#261915" />
        <stop offset={0.908} stopColor="#0e0908" />
        <stop offset={1} stopColor="#010101" />
      </radialGradient>
      <path
        d="M105.951 40.443c-.703 5.562-5.547 9.531-10.822 8.866-5.273-.666-8.979-5.716-8.277-11.278.703-5.563 5.547-9.532 10.822-8.866 5.273.666 8.978 5.715 8.277 11.278z"
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        style={screen}
        fill="url(#Roket_svg__K)"
      />
      <linearGradient
        id="Roket_svg__L"
        gradientUnits="userSpaceOnUse"
        x1={111.037}
        y1={38.179}
        x2={111.037}
        y2={27.259}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0.006} stopColor="#f15e65" />
        <stop offset={0.316} stopColor="#ec5b64" />
        <stop offset={0.556} stopColor="#e05062" />
        <stop offset={0.773} stopColor="#d13e5f" />
        <stop offset={0.976} stopColor="#bd225a" />
        <stop offset={1} stopColor="#ba1e5a" />
      </linearGradient>
      <path
        d="M91.777 33.149s8.568 1.416 7.045 11.896l-7.045-11.896z"
        fillRule="evenodd"
        clipRule="evenodd"
        style={screen}
        fill="url(#Roket_svg__L)"
      />
      <linearGradient
        id="Roket_svg__M"
        gradientUnits="userSpaceOnUse"
        x1={82.57}
        y1={103.445}
        x2={82.57}
        y2={91.702}
        gradientTransform="rotate(7.207 52.437 -89.18)"
      >
        <stop offset={0} stopColor="#faa71a" />
        <stop offset={1} stopColor="#ffd32a" />
      </linearGradient>
      <path
        d="M59.549 94.033c-1.08-.144-2.105 3.503-2.391 5.757-.283 2.253.348 5.821 1.059 5.911.713.09 2.092-2.913 2.473-5.605.378-2.695-.294-5.951-1.141-6.063z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Roket_svg__M)"
      />
      <radialGradient
        id="Roket_svg__N"
        cx={-92.398}
        cy={74.519}
        r={7.061}
        gradientTransform="matrix(1.6537 .2088 -.2605 2.0632 231.603 -34.525)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ee3724" />
        <stop offset={0.105} stopColor="#c23a26" />
        <stop offset={0.35} stopColor="#773425" />
        <stop offset={0.57} stopColor="#46271e" />
        <stop offset={0.759} stopColor="#261915" />
        <stop offset={0.908} stopColor="#0e0908" />
        <stop offset={1} stopColor="#010101" />
      </radialGradient>
      <path
        d="M71.068 101.405c-1.016 8.046-7.068 13.908-13.518 13.094-6.448-.814-10.854-7.996-9.837-16.043 1.016-8.045 7.067-13.908 13.517-13.094s10.854 7.997 9.838 16.043z"
        fillRule="evenodd"
        clipRule="evenodd"
        style={screen}
        fill="url(#Roket_svg__N)"
      />
    </svg>
  );
}

export default SvgRoket;
