import * as React from "react";

function SvgIn(props) {
  return (
    <svg width={19.262} height={19.271} {...props}>
      <g fill="#FFF">
        <path d="M.382 6.424h3.977v12.848H.382z" />
        <ellipse cx={2.352} cy={2.371} rx={2.352} ry={2.371} />
        <path d="M10.716 12.528c0-1.807.831-2.883 2.423-2.883 1.463 0 2.165 1.032 2.165 2.883v6.743h3.958v-8.135c0-3.442-1.95-5.105-4.674-5.105-2.726 0-3.872 2.123-3.872 2.123v-1.73H6.901v12.848h3.815v-6.744z" />
      </g>
    </svg>
  );
}

export default SvgIn;
