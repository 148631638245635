import React from "react";
import useWindowSize from "../../../../hooks/useWindowSize";

function SvgS1(props) {
  const size = useWindowSize();

  const [svgHeight, setSvgHeight] = React.useState(487.357);
  const [tubeWidth, setTubeWidth] = React.useState(
    `M7.5 479.857v-87.646c0-16.5 13.5-30 30-30l111.032-.104c16.5 1 30-12.5 30-29V37.5c0-16.5-13.5-30-30-30H76.866`
  );

  React.useEffect(() => {
    if (size.height < 768) return;

    setSvgHeight(487.357 + (size.height - 768) / 2);

    setTubeWidth(
      `M7.5 ${
        479.857 + (size.height - 768) / 2
      }v-87.646c0-16.5 13.5-30 30-30l111.032-.104c16.5 1 30-12.5 30-29V37.5c0-16.5-13.5-30-30-30H76.866`
    );
  }, [size.height]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="186.032"
      height={svgHeight}
      viewBox={`0 0 186.032 ${svgHeight}`}
      {...props}
    >
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="15"
        d={tubeWidth}
        opacity="0.3"
      ></path>
      <path
        className="tubefill s1"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="15"
        d={tubeWidth}
      ></path>
    </svg>
  );
}

export default SvgS1;
