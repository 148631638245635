import {useState, useEffect} from "react";
import mobileCheck from "./mobileCheck";

export default function useWindowSize() {
    function getSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        function handleResize() {
            if (mobileCheck()) {
                if (document.activeElement.tagName.toLowerCase() === "input") return;
            }
            setWindowSize(getSize());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}


