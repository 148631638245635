import * as React from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import {MobileCheck} from "../../../../helpers/responsive";

function SvgMiddtube(props) {
    const size = useWindowSize();
    const [height, setHeight] = React.useState(782.7752);
    const [tube, setTube] = React.useState("M7.501 7.5L7.5 775.252");

    React.useEffect(() => {
        let heightWant;
        let heightWantForTube;
        let totalIconsHeight = 80
        const spaceBetweenIcons = 70
        let heig = size.height > 768 ? size.height : 768
        let cats = document.querySelectorAll(".service-path");
        if (size.width < 480) {
            // heightWant = 1460
            // heightWantForTube = 1455
            // cats.forEach((cat, key) => {
            //     let iconHeight = cat.querySelector(".icon").offsetHeight
            //     cat.style.top = totalIconsHeight + "px"
            //     cat.style.height = iconHeight + 30 + "px"
            //     totalIconsHeight += (iconHeight + 30 + spaceBetweenIcons)
            // });
            heightWant = heig - 768 + 782.7752
            heightWantForTube = 775.252 + heig - 768
            let c = [
                0.1041666666666667,
                0.3791666666666667,
                0.65,
                0.6777777777777778,
                0.4069444444444444,
                0.1041666666666667,
            ];
            cats.forEach((cat, key) => {
                cat.style.top = parseFloat(c[key]) * heig + "px";
            });
        } else if (size.width >= 480 && size.width < 768) {
            heightWant = heig - 768 + 782.7752
            heightWantForTube = 775.252 + heig - 768
            let c = [
                0.1041666666666667,
                0.3791666666666667,
                0.65,
                0.6777777777777778,
                0.4069444444444444,
                0.1041666666666667,
            ];
            cats.forEach((cat, key) => {
                cat.style.top = parseFloat(c[key]) * heig + "px";
            });
        } else {
            heightWant = heig - 768 + 782.7752
            heightWantForTube = 775.252 + heig - 768
            let c = [
                0.13671875,
                0.32552083333333333333333333333333,
                0.47265625,
                0.54296875,
                0.37109375,
                0.26041666666666666666666666666667,
            ];
            cats.forEach((cat, key) => {
                cat.style.top = parseFloat(c[key]) * heig + "px";
            });
        }
        setHeight(heightWant);
        setTube(`M7.501 7.5L7.5 ${heightWantForTube}`);


    }, [size.height]);

    return (
        <svg
            width="15.001"
            height={height}
            viewBox={"0 0 15.001 " + height}
            {...props}
        >
            <path
                opacity={0.3}
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d={tube}
            />
            <path
                className="tubefill s8"
                fill="none"
                stroke="white"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d={tube}
            />
        </svg>
    );
}

export default SvgMiddtube;
