import * as React from "react";

function SvgLess1024S1(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={116.666}
            height={457.968}
            {...props}
        >
            <path
                opacity={0.3}
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M8.134 450.468v-78.256c0-16.5 13.5-39 30-39l41.032-.102c16.5 1 30-12.5 30-29V37.5c0-16.5-13.5-30-30-30H7.5"
            />
            <path
                className="tubefill s1"
                fill="none"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M8.134 450.468v-78.256c0-16.5 13.5-39 30-39l41.032-.102c16.5 1 30-12.5 30-29V37.5c0-16.5-13.5-30-30-30H7.5"
            />
        </svg>
    );
}

export default SvgLess1024S1;
