import * as React from "react";

function SvgStars(props) {
  return (
    <svg
      width="713.284"
      height="746.861"
      viewBox="0 0 713.284 746.861"
      {...props}
    >
      <g opacity={0.3}>
        <path
          fill="#f0525f"
          d="M571.443 477.471l.619 3.492 2.395-2.614 3.509.497-1.752-3.065 1.567-3.185-3.47.699-2.549-2.457-.403 3.509-3.127 1.669zm-207.929 183l.908 5.252 3.636-3.928 5.284.748-2.622-4.645 2.349-4.812-5.239 1.06-3.85-3.728-.615 5.316-4.719 2.498z"
        />
        <path
          d="M541.132 504.995c1.9-1.478 2.262-4.226.787-6.13a4.388 4.388 0 00-6.135-.799c-1.904 1.473-2.262 4.222-.783 6.124 1.463 1.919 4.215 2.269 6.131.805z"
          fill="#d7d532"
        />
        <path
          fill="#f0525f"
          d="M458.412 253.038l-4.651-1.182 1.91 4.391-2.55 4.044 4.768-.437 3.03 3.666 1.055-4.674 4.428-1.776-4.096-2.431-.324-4.748z"
        />
        <path
          fill="#5d3f60"
          d="M263.818 7.463l-10.951-2.759 4.467 10.384-6.013 9.543 11.26-1.051 7.224 8.68 2.479-11.024 10.489-4.2-9.725-5.766L272.312 0z"
        />
        <path
          d="M98.903 229.228c1.173 4.837 6.032 7.815 10.875 6.636 4.824-1.164 7.796-6.019 6.645-10.861-1.17-4.848-6.042-7.822-10.864-6.643-4.851 1.167-7.835 6.03-6.656 10.868z"
          fill="#d7d532"
        />
        <path
          d="M156.511 215.49c-1.438 1.246-1.619 3.396-.396 4.83a3.427 3.427 0 004.835.389 3.415 3.415 0 00.378-4.83 3.41 3.41 0 00-4.817-.389zm230.418-71.51a3.423 3.423 0 00-.391 4.823 3.42 3.42 0 004.832.39c1.434-1.221 1.615-3.4.381-4.827-1.216-1.447-3.376-1.616-4.822-.386z"
          fill="#e6b422"
        />
        <path
          d="M394.957 131.91c1.177 4.818 6.012 7.771 10.819 6.601 4.816-1.155 7.771-5.991 6.612-10.804-1.155-4.817-6-7.78-10.805-6.615-4.806 1.16-7.783 6.002-6.626 10.818z"
          fill="#d7d532"
        />
        <path
          d="M347.863 67.166c.096.377.041.771-.206 1.063l-2.618 3.392c-.589.759-.07 1.862.887 1.872l4.303.02c.375.01.725.197.936.495l2.414 3.563c.54.795 1.74.636 2.045-.264l1.359-4.08c.125-.363.404-.643.77-.748l4.131-1.204c.556-.144.836-.638.826-1.116a1.093 1.093 0 00-.453-.897l-3.475-2.562a1.18 1.18 0 01-.451-.967l.139-4.289a1.143 1.143 0 00-1.823-.965l-3.499 2.496a1.21 1.21 0 01-1.058.147l-2.329-.849-1.717-.618c-.885-.335-1.778.517-1.484 1.425l1.303 4.086z"
          fill="#f9c02d"
        />
        <path
          d="M347.863 67.166c.096.377.041.771-.206 1.063l-2.618 3.392c-.589.759-.07 1.862.887 1.872l4.303.02c.375.01.725.197.936.495l2.414 3.563c.54.795 1.74.636 2.045-.264l1.359-4.08c.125-.363.404-.643.77-.748l4.131-1.204c.556-.144.836-.638.826-1.116-.211.018-.452.084-.743.178-1.923.664-3.433 1.551-5.575 1.493-2.502-.046-5.059-1.237-6.418-3.366-1.188-1.828-1.289-4.341-.213-6.189l-1.717-.618c-.885-.335-1.778.517-1.484 1.425l1.303 4.084z"
          fill="#e5a524"
        />
        <path
          d="M352.677 64.15c-.73-.004-1.551-.135-2.078.367-.516.514-.408 1.386-.056 2.021.991 1.793 3.585 2.482 5.31 1.41 1.115-.686 2.58-3.09 1.494-4.334-1.18-1.301-3.263.556-4.67.536z"
          fill="#fff"
          opacity={0.27}
        />
        <path
          d="M248.72 135.006a1.642 1.642 0 01-.247 1.475l-3.697 4.738a1.597 1.597 0 001.259 2.582l5.994.061c.523.014 1.026.259 1.311.698l3.367 4.968c.745 1.09 2.424.863 2.865-.387l1.896-5.709a1.58 1.58 0 011.08-1.029l5.759-1.652a1.614 1.614 0 001.154-1.582c.002-.469-.222-.935-.641-1.264l-4.84-3.551a1.616 1.616 0 01-.649-1.355l.201-5.988a1.613 1.613 0 00-2.562-1.355l-4.855 3.483a1.621 1.621 0 01-1.491.206l-3.264-1.19-2.371-.837c-1.245-.469-2.477.72-2.067 1.982l1.798 5.706z"
          fill="#f9c02d"
        />
        <path
          d="M248.72 135.006a1.642 1.642 0 01-.247 1.475l-3.697 4.738a1.597 1.597 0 001.259 2.582l5.994.061c.523.014 1.026.259 1.311.698l3.367 4.968c.745 1.09 2.424.863 2.865-.387l1.896-5.709a1.58 1.58 0 011.08-1.029l5.759-1.652a1.614 1.614 0 001.154-1.582 6.02 6.02 0 00-1.043.254c-2.697.908-4.785 2.155-7.775 2.104-3.509-.092-7.06-1.766-8.978-4.729-1.64-2.552-1.801-6.05-.304-8.644l-2.371-.837c-1.245-.469-2.477.72-2.067 1.982l1.797 5.707z"
          fill="#e5a524"
        />
        <path
          d="M255.45 130.786c-1.019.014-2.169-.183-2.908.511-.711.711-.533 1.925-.045 2.832 1.382 2.488 4.973 3.455 7.413 1.965 1.543-.953 3.592-4.344 2.061-6.042-1.649-1.848-4.542.746-6.521.734z"
          fill="#fff"
          opacity={0.27}
        />
        <path
          d="M109.308 102.975a5.954 5.954 0 00-8.371-.918c-2.561 2.043-2.97 5.783-.926 8.374 2.044 2.556 5.798 2.982 8.374.918 2.555-2.044 2.959-5.79.923-8.374z"
          fill="#e6b422"
        />
        <path
          fill="#f8981d"
          d="M502.929 100.572l9.064-1.754-6.91-6.15 1.139-9.182-7.972 4.677-8.381-3.923 1.983 9.039-6.329 6.752 9.227.925 4.448 8.085z"
        />
        <path
          d="M595.02 114.07c-1.575-1.927-4.409-2.26-6.363-.698-1.952 1.572-2.272 4.419-.711 6.36 1.563 1.953 4.405 2.279 6.351.709a4.545 4.545 0 00.723-6.371z"
          fill="#e6b422"
        />
        <path
          fill="#f8981d"
          d="M282.888 668.217l-8.979-2.287 3.659 8.5-4.92 7.841 9.202-.862 5.952 7.107 2.02-9.026 8.592-3.433-7.957-4.726-.608-9.238z"
        />
        <path
          d="M286.392 738.999a3.463 3.463 0 00-.39 4.837 3.44 3.44 0 004.834.373 3.426 3.426 0 00.392-4.83 3.44 3.44 0 00-4.836-.38z"
          fill="#e6b422"
        />
        <path
          d="M470.662 497.156c-4.852 1.045-7.941 5.834-6.902 10.688 1.039 4.87 5.836 7.975 10.713 6.926 4.857-1.045 7.95-5.852 6.914-10.713a9.024 9.024 0 00-10.725-6.901z"
          fill="#d7d532"
        />
        <path
          fill="#f8981d"
          d="M483.082 447.104l5.221-1.028-4.014-3.516.633-5.302-4.581 2.742-4.836-2.227 1.174 5.202-3.605 3.906 5.295.478 2.613 4.649z"
        />
        <path
          d="M610.818 387.107c-2.428.533-4.002 2.935-3.457 5.388.512 2.44 2.932 4.002 5.377 3.468 2.426-.525 3.973-2.936 3.462-5.373a4.536 4.536 0 00-5.382-3.483z"
          fill="#e6b422"
        />
        <path
          fill="#f8981d"
          d="M63.02 149.875l.388-12.914-10.49 7.537-12.153-4.342 3.934 12.301-7.898 10.239 12.921.052 7.287 10.666 4.033-12.275 12.402-3.629z"
        />
        <path
          fill="#5d3f60"
          d="M588.082 289.42l.248-8.032-6.532 4.688-7.548-2.694 2.449 7.647-4.914 6.349 8.027.04 4.527 6.625 2.527-7.617 7.69-2.26z"
        />
        <path
          d="M635.642 240.142c-4.49 2.177-6.328 7.567-4.15 12.041 2.188 4.474 7.576 6.315 12.048 4.144 4.472-2.188 6.318-7.589 4.146-12.057-2.174-4.471-7.587-6.316-12.044-4.128z"
          fill="#d7d532"
        />
        <path
          fill="#f8981d"
          d="M670.313 340.666l4.825-2.256-4.734-2.46-.653-5.279-3.793 3.742-5.236-1.002 2.402 4.759-2.582 4.676 5.276-.805 3.653 3.887z"
        />
        <path
          d="M711.925 328.363a3.43 3.43 0 00-4.797.673c-1.136 1.519-.84 3.661.664 4.788a3.403 3.403 0 004.799-.666c1.141-1.485.841-3.65-.666-4.795z"
          fill="#e6b422"
        />
        <path
          d="M664.587 369.985a2.352 2.352 0 01-2.219.099l-8.077-3.826c-1.778-.852-3.769.712-3.356 2.657l1.861 8.733a2.38 2.38 0 01-.59 2.133l-6.143 6.479c-1.36 1.446-.479 3.824 1.486 4.035l8.889.934a2.38 2.38 0 011.855 1.229l4.25 7.829c.58 1.025 1.685 1.433 2.679 1.177a2.305 2.305 0 001.61-1.341l3.633-8.175c.31-.707.984-1.23 1.739-1.373l8.788-1.639c1.949-.361 2.63-2.795 1.143-4.117l-6.631-5.973a2.422 2.422 0 01-.769-2.098l.655-5.117.484-3.73c.258-1.973-1.848-3.373-3.563-2.368l-7.724 4.452z"
          fill="#f9c02d"
        />
        <path
          d="M664.587 369.985a2.352 2.352 0 01-2.219.099l-8.077-3.826c-1.778-.852-3.769.712-3.356 2.657l1.861 8.733a2.38 2.38 0 01-.59 2.133l-6.143 6.479c-1.36 1.446-.479 3.824 1.486 4.035l8.889.934a2.38 2.38 0 011.855 1.229l4.25 7.829c.58 1.025 1.685 1.433 2.679 1.177-.153-.427-.396-.893-.7-1.427-2.195-3.618-4.662-6.264-5.541-10.612-1.032-5.136.242-10.865 3.957-14.58 3.189-3.189 8.232-4.57 12.453-3.213l.484-3.73c.258-1.973-1.848-3.373-3.563-2.368l-7.725 4.451z"
          fill="#e5a524"
        />
        <path
          d="M672.871 378.396c-.3-1.449-.398-3.201-1.656-4.035-1.252-.819-2.988-.18-4.106.813-3.216 2.832-3.438 8.365-.478 11.433 1.854 1.91 7.447 3.81 9.455 1.038 2.118-2.979-2.594-6.359-3.215-9.249z"
          fill="#fff"
          opacity={0.27}
        />
        <path
          d="M17.147 252.594a5.947 5.947 0 00-4.578 7.057c.696 3.204 3.864 5.254 7.069 4.576 3.227-.701 5.259-3.867 4.56-7.071a5.934 5.934 0 00-7.051-4.562z"
          fill="#e6b422"
        />
        <path
          fill="#f0525f"
          d="M100.446 465.497l1.931-6.952-6.706 2.681-6.004-3.987.493 7.193-5.657 4.494 6.991 1.767 2.515 6.746 3.842-6.11 7.208-.304z"
        />
        <path
          fill="#5d3f60"
          d="M129.68 539.27l3.038-10.874-10.464 4.202-9.408-6.235.76 11.263-8.854 7.005 10.953 2.752 3.956 10.596 5.982-9.578 11.291-.455z"
        />
        <path
          d="M178.941 621.504a5.965 5.965 0 00-4.57 7.076c.685 3.212 3.86 5.256 7.076 4.568a5.927 5.927 0 004.545-7.073c-.688-3.21-3.862-5.254-7.051-4.571zM6.082 463.802c-1.191-1.46-3.353-1.675-4.819-.491-1.465 1.189-1.687 3.338-.5 4.827 1.199 1.453 3.35 1.688 4.813.494a3.44 3.44 0 00.506-4.83zM90.726 394.977a4.562 4.562 0 00-3.48 5.387 4.531 4.531 0 005.397 3.466 4.504 4.504 0 003.441-5.368 4.521 4.521 0 00-5.358-3.485z"
          fill="#e6b422"
        />
      </g>
      <path
        d="M240.769 108.161a2.844 2.844 0 01-.342-3.616c.889-1.32 2.641-1.661 3.981-.768l1.205.847c1.303.884 1.607 2.671.72 3.964a2.861 2.861 0 01-3.996.719l-1.138-.774a2.526 2.526 0 01-.43-.372z"
        fill="#f8981d"
        opacity={0.8}
      />
      <path
        d="M354.662 695.748a2.864 2.864 0 01-.6-3.168 2.86 2.86 0 013.77-1.5l1.301.578a2.861 2.861 0 011.513 3.756c-.638 1.461-2.319 2.133-3.771 1.499l-1.336-.589a2.589 2.589 0 01-.877-.576z"
        opacity={0.4}
        fill="#f8981d"
      />
      <g
        fill="none"
        stroke="#f8981d"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity={0.3}
      >
        <path d="M246.333 108.588s-.807-.356-2.308-.95" />
        <path
          d="M232.643 103.655c-29.531-9.191-99.363-22.868-134.286 38.231-30.229 52.888-24.628 172.586 143.137 270.254s257.753 66.16 299.143 25.119 28.774-101.02-24.714-128.189c-53.486-27.17-130.549 28.639-160.465 82.307-28.247 50.677-103.026 185.349 14.338 347.116"
          strokeDasharray="5.0259,12.0621"
        />
        <path d="M373.379 743.359c.498.667.999 1.334 1.505 2.002" />
      </g>
    </svg>
  );
}

export default SvgStars;
