import * as React from "react";

function SvgS5N(props) {
  return (
    <svg
      width={350.114}
      height={985.677}
      viewBox="0 0 350.114 985.667"
      {...props}
    >
      <path
        opacity={0.3}
        fill="none"
        stroke="#010101"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M7.502 988.177L7.5 863.763c0-16.5 13.5-30 30-30l275.479-.002c16.5 0 30-13.5 30-30L342.614 7.5"
      />
      <path
        fill="none"
        className="tubefill s5"
        stroke="#fff"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M7.502 988.177L7.5 863.763c0-16.5 13.5-30 30-30l275.479-.002c16.5 0 30-13.5 30-30L342.614 7.5"
      />
    </svg>
  );
}

export default SvgS5N;
