import * as React from "react";

function SvgPrinting(props) {
  return (
    <svg width="137.693" height="113.074" viewBox="0 0 137.693 113.074" {...props}>
      <linearGradient
        id="printing_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={10.926}
        y1={113.074}
        x2={10.926}
        y2={10.618}
      >
        <stop offset={0.017} stopColor="#bdafd1" />
        <stop offset={1} stopColor="#ebd7e3" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#printing_svg__a)"
        d="M1.679 10.618L.001 105.489c-.047 2.661 1.813 4.853 4.125 4.859 0 1.507 1.064 2.726 2.377 2.726h3.24l12.11-.165L5.906 12.592l-4.227-1.974z"
      />
      <linearGradient
        id="printing_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={-147.458}
        y1={32.268}
        x2={-9.996}
        y2={62.834}
        gradientTransform="scale(-1 1) rotate(7.424 -126.155 97.99)"
      >
        <stop offset={0.024} stopColor="#af98c8" />
        <stop offset={0.271} stopColor="#d5c7e2" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#printing_svg__b)"
        d="M137.671 91.823l-6.797-83.539L4.125 12.686l12.809 93.853c.536 3.87 3.691 6.55 7.117 6.051l108.083-14.009a6.258 6.258 0 005.537-6.758z"
      />
      <radialGradient
        id="printing_svg__c"
        cx={16.73}
        cy={-230.139}
        r={40.108}
        gradientTransform="matrix(-1.1295 .1472 .1472 1.1295 119.05 266.93)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ebd7e3" />
        <stop offset={0.983} stopColor="#bdafd1" />
      </radialGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#printing_svg__c)"
        d="M130.875 8.284l-4.542-1.859L1.679 10.618l2.446 2.068z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#A695C8"
        d="M119.558 14.247c.209 1.66 1.682 2.969 3.289 2.923s2.739-1.419 2.531-3.069c-.211-1.652-1.683-2.963-3.289-2.928-1.607.035-2.74 1.411-2.531 3.074zm-13.426.375c.209 1.684 1.682 3.006 3.288 2.953 1.606-.051 2.739-1.448 2.53-3.123-.209-1.674-1.682-3-3.289-2.959-1.605.042-2.738 1.443-2.529 3.129zm-13.427.426c.209 1.707 1.682 3.043 3.289 2.985s2.74-1.479 2.529-3.177c-.209-1.699-1.682-3.038-3.287-2.99-1.608.049-2.74 1.473-2.531 3.182zm-13.427.478c.21 1.732 1.682 3.082 3.288 3.018 1.607-.063 2.74-1.51 2.531-3.23-.209-1.722-1.683-3.074-3.289-3.021-1.605.052-2.739 1.501-2.53 3.233zm-13.427.531c.21 1.754 1.682 3.119 3.289 3.048 1.606-.07 2.739-1.54 2.529-3.284-.209-1.745-1.682-3.112-3.288-3.053-1.606.061-2.739 1.534-2.53 3.289zm-13.427.583c.209 1.777 1.681 3.155 3.288 3.079s2.74-1.57 2.53-3.338c-.209-1.77-1.682-3.15-3.289-3.084-1.605.067-2.738 1.564-2.529 3.343zm-13.426.634c.209 1.801 1.682 3.192 3.289 3.109 1.606-.082 2.739-1.6 2.53-3.391-.209-1.793-1.682-3.188-3.289-3.115-1.607.073-2.74 1.594-2.53 3.397zm-13.427.684c.209 1.825 1.681 3.231 3.289 3.142 1.606-.088 2.739-1.63 2.529-3.444-.209-1.815-1.681-3.225-3.288-3.146-1.607.078-2.74 1.624-2.53 3.448zm-13.427.74c.209 1.848 1.681 3.268 3.289 3.172 1.606-.095 2.739-1.66 2.529-3.498-.209-1.839-1.681-3.263-3.288-3.178-1.607.086-2.74 1.654-2.53 3.504z"
      />
      <path
        d="M116.236 53.256l11.103-.748-1.175-9.218-11.104.638zm-14.174 1.006l11.104-.793-1.178-9.357-11.103.682zm-14.174 1.063l11.103-.837-1.177-9.497-11.103.728zm-14.175 1.122l11.103-.883-1.176-9.637-11.103.775zm-14.175 1.178l11.104-.928-1.176-9.775-11.104.821zm-14.174 1.237l11.103-.973-1.176-9.914-11.103.864zm-14.175 1.295l11.104-1.02-1.176-10.052-11.103.911zm-14.174 1.351l11.103-1.063-1.176-10.192-11.104.955zm97.084-21.488l11.104-.595-1.176-9.257-11.104.486zm-14.174.811l11.104-.64-1.178-9.397-11.103.532zm18.095 26.524l11.103-.912-1.176-9.185-11.104.804zm-14.175 1.216l11.104-.957-1.176-9.322-11.104.847zM89.67 69.844l11.104-1.002-1.176-9.462-11.103.894zm-14.173 1.332l11.102-1.048-1.176-9.6-11.103.939zm-14.175 1.389l11.103-1.093-1.175-9.739-11.104.985zm-14.175 1.447l11.104-1.137-1.176-9.878-11.104 1.029zm-14.174 1.506l11.103-1.184-1.177-10.017-11.103 1.074zm-14.174 1.563l11.103-1.23-1.177-10.155-11.103 1.12zm100.993 4.234l11.104-1.077-1.177-9.149-11.102.967zm-14.175 1.424l11.104-1.121-1.177-9.288-11.103 1.013zm-14.174 1.483l11.103-1.165-1.176-9.428-11.103 1.058zm-14.174 1.54l11.104-1.211-1.177-9.564-11.103 1.101zm-14.174 1.6l11.102-1.258-1.176-9.704-11.103 1.149zM48.92 89.017l11.103-1.302-1.176-9.842-11.104 1.194zm44.214 8.808l11.103-1.323-1.177-9.392-11.102 1.214zm-14.176 1.738l11.104-1.366-1.176-9.532-11.103 1.259zm-14.174 1.799l11.103-1.412-1.177-9.672-11.102 1.304zm-14.174 1.853l11.103-1.457-1.176-9.809-11.103 1.349zm-14.175 1.914l11.104-1.504-1.177-9.948-11.103 1.395zm-14.175 1.97l11.104-1.548-1.176-10.088-11.104 1.44zm12.485-16.368l11.103-1.347-1.175-9.982-11.104 1.239zm-14.174 1.772l11.103-1.392-1.176-10.12-11.103 1.283z"
        opacity={0.2}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#A695C8"
      />
      <linearGradient
        id="printing_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={-65.165}
        y1={16.645}
        x2={-22.097}
        y2={16.645}
        gradientTransform="scale(-1 1) rotate(7.424 -126.155 97.99)"
      >
        <stop offset={0} stopColor="#f36f2e" />
        <stop offset={0.998} stopColor="#e03548" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#printing_svg__d)"
        d="M54.993 23.876l.676 5.724-42.523 2.709-1.258-5.924z"
      />
      <path
        fill="#F0534F"
        d="M38.236 82.546c-5.793 0-10.896-4.908-11.656-11.478-.421-3.646.571-7.254 2.723-9.899 1.842-2.267 4.391-3.653 7.175-3.907 6.26-.555 12.003 4.594 12.814 11.48.414 3.5-.541 7.009-2.619 9.629-1.847 2.33-4.427 3.788-7.266 4.108-.392.045-.783.067-1.171.067zm-10.58-11.602c.742 6.414 5.96 11.106 11.628 10.459 2.551-.287 4.874-1.602 6.54-3.704 1.9-2.396 2.772-5.613 2.393-8.829-.745-6.312-5.966-11.038-11.642-10.528-2.491.227-4.775 1.474-6.431 3.511-1.969 2.421-2.876 5.734-2.488 9.091z"
      />
      <path
        fill="#13152E"
        d="M119.83 14.715l-.543-.938c.203-.118 4.922-2.969 1.479-9.088-1.879-3.364-5.729-4.086-8.516-3.34-2.788.752-4.427 2.776-4.383 5.418l-1.082.018c-.053-3.122 1.934-5.606 5.184-6.48 3.18-.856 7.581-.015 9.742 3.854 3.976 7.07-1.823 10.522-1.881 10.556z"
      />
      <path
        fill="#13152E"
        d="M106.787 15.077l-.551-.934c.203-.119 4.932-3.031 1.482-9.236-1.859-3.369-5.678-4.111-8.443-3.374-2.188.582-4.506 2.343-4.453 5.535l-1.084.019c-.053-3.193 1.963-5.723 5.258-6.602 3.158-.842 7.531.02 9.67 3.896 3.97 7.145-1.82 10.661-1.879 10.696z"
      />
      <path
        fill="#13152E"
        d="M92.951 15.514l-.56-.928c.204-.124 4.938-3.102 1.483-9.396-1.838-3.375-5.627-4.135-8.365-3.411-2.225.586-4.582 2.38-4.529 5.662l-1.084.018c-.053-3.269 1.993-5.847 5.338-6.728 3.136-.826 7.476.054 9.592 3.938 3.965 7.225-1.817 10.809-1.875 10.845z"
      />
      <path
        fill="#13152E"
        d="M80.086 15.969l-.566-.923c.204-.126 4.946-3.164 1.486-9.544-1.821-3.381-5.581-4.156-8.296-3.445-2.259.588-4.652 2.415-4.599 5.779l-1.083.018c-.053-3.34 2.019-5.963 5.408-6.846 3.115-.813 7.426.085 9.521 3.979 3.961 7.301-1.811 10.946-1.871 10.982z"
      />
      <path
        fill="#13152E"
        d="M66.2 16.514l-.575-.918c.204-.129 4.955-3.232 1.49-9.701-1.802-3.387-5.527-4.184-8.224-3.484-2.294.589-4.724 2.453-4.67 5.908l-1.083.016c-.052-3.363 2.099-6.102 5.482-6.974 3.095-.798 7.374.12 9.451 4.023 3.952 7.38-1.812 11.093-1.871 11.13z"
      />
      <path
        fill="#13152E"
        d="M52.967 17.084l-.583-.912c.204-.132 4.964-3.298 1.492-9.854-1.794-3.412-5.391-4.229-8.155-3.52-2.326.594-4.792 2.488-4.736 6.027l-1.083.018c-.054-3.437 2.125-6.221 5.551-7.095 3.078-.786 7.325.151 9.382 4.063 3.948 7.459-1.81 11.236-1.868 11.273z"
      />
      <path
        fill="#13152E"
        d="M39.709 17.707l-.59-.907c.205-.135 4.971-3.363 1.495-10.005-1.778-3.42-5.346-4.25-8.089-3.556-2.357.595-4.857 2.523-4.801 6.148l-1.084.018c-.053-3.508 2.152-6.341 5.62-7.217 3.162-.796 7.266.162 9.314 4.105 3.944 7.535-1.805 11.376-1.865 11.414z"
      />
      <path
        fill="#13152E"
        d="M26.766 18.364l-.597-.903c.204-.136 4.978-3.427 1.497-10.152-1.762-3.427-5.302-4.271-8.026-3.591-2.388.598-4.92 2.558-4.863 6.268l-1.083.018c-.054-3.58 2.178-6.459 5.684-7.336 3.143-.782 7.22.192 9.251 4.145 3.938 7.607-1.805 11.512-1.863 11.551z"
      />
      <path
        fill="#13152E"
        d="M14.516 18.612c-.639-4.412-1.724-11.19-2.384-13.905-.189 1.316-.349 3.509-.416 5.454l-1.083-.038c.249-7.204.898-7.371 1.285-7.471.116-.028.508-.089.794.388.806 1.346 2.537 13.077 2.875 15.416l-1.071.156z"
      />
      <linearGradient
        id="printing_svg__e"
        gradientUnits="userSpaceOnUse"
        x1={2.731}
        y1={110.348}
        x2={2.731}
        y2={11.866}
      >
        <stop offset={0} stopColor="#542266" />
        <stop offset={1} stopColor="#90949b" />
      </linearGradient>
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#printing_svg__e)"
        d="M4.125 110.348L2.731 11.866l-1.357 92.91s-.562 3.619 2.751 5.572z"
      />
      <path
        fill="#F0534F"
        d="M38.026 72.224l-4.625-4.479.755-.78 3.7 3.586 6.136-9.009.896.61z"
      />
    </svg>
  );
}

export default SvgPrinting;
