import * as React from "react";
import useWindowSize from "../../../../../hooks/useWindowSize";
import {qs} from "../../../../../helpers/helpers";
// import {gsap} from "gsap";
// import {ScrollTrigger} from "gsap/ScrollTrigger";
// import DrawSVGPlugin from "gsap/DrawSVGPlugin";

function SvgLess1024S2(props) {
    const size = useWindowSize();
    // React.useEffect(() => {
    //     gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);
    //     // let s2Tube = document.querySelector(".tubefill.s2");
    //     // let s2Total = Math.ceil(s2Tube.getTotalLength().toFixed(3));
    //     // s2Tube.style.strokeDasharray = s2Total + "px";
    //     // s2Tube.style.strokeDashoffset = s2Total + "px";
    //
    //     gsap
    //         .timeline({
    //             defaults: {duration: 1},
    //             scrollTrigger: {
    //                 trigger: ".sectionAbout",
    //                 onUpdate: (e) => {
    //                     console.log(e.progress)
    //                     window.abb = e
    //                 },
    //                 scrub: "20",
    //                 triggerHook: 0,
    //                 markers: true,
    //                 start: "top-=20 top",
    //                 end: "bottom-=50 top",
    //                 ease: "none",
    //             },
    //         })
    //         .fromTo(".tubefill.s2", {drawSVG: "0%"}, {drawSVG: "100%"}, 0)
    //         .add(window.tl, 0);
    // }, []);

    React.useEffect(() => {
        if (size.width < 768) return;
        qs(".sectionAbout .tube svg").style.left = `-${1023 - size.width + 5}px`
        qs(".sectionAbout .lampshades").style.left = `-${1023 - size.width + 585}px`;
        if (size.width < 885) {
            qs(".sectionAbout .robot").style.left = "190px"
        }
        if (size.width < 830) {
            qs(".sectionAbout .robot").style.left = "140px"
        }
    }, [size.width]);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={867.363}
            height={783.353}
            {...props}
        >
            <path
                opacity={0.3}
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 859.863,7.5 L 859.863,546.472 C 859.863,562.973 846.363,576.472 829.864,576.472 L 726.832,576.475 C 710.332,576.475 696.832,589.974 696.832,601.475 L 696.832,745.852 C 696.832,762.353 683.332,775.852 666.832,775.852 L 7.5,775.853"
            />
            <path
                fill="none"
                stroke="#fff"
                className="tubefill s2"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 859.863,7.5 L 859.863,546.472 C 859.863,562.973 846.363,576.472 829.864,576.472 L 726.832,576.475 C 710.332,576.475 696.832,589.974 696.832,601.475 L 696.832,745.852 C 696.832,762.353 683.332,775.852 666.832,775.852 L 7.5,775.853"
            />
        </svg>
    );
}

export default SvgLess1024S2;
