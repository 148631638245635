import * as React from "react";

function SvgGhost3(props) {
  return (
    <svg viewBox="0 0 107 97.215" {...props}>
      <linearGradient
        id="Ghost3_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={8.375}
        y1={105.59}
        x2={98.626}
        y2={15.339}
      >
        <stop offset={0} stopColor="#f69482" />
        <stop offset={0.424} stopColor="#f49687" />
        <stop offset={1} stopColor="#f19c94" />
      </linearGradient>
      <path
        d="M95.141 24.305V12.152H83.287V0h-24.01v12.152H47.721V0H23.713v12.152H11.857v12.152H0v72.908h12.15V85.063h11.563v12.151h12.152V85.063h11.557v12.151h12.152V85.063h11.559v12.151h12.154V85.063h11.559v12.151H107V24.305H95.141zm-.295 24.304H82.99v12.15H71.43V36.457h23.416v12.152zM12.15 36.457h23.419v12.152H23.713v12.15H12.15V36.457z"
        fill="url(#Ghost3_svg__a)"
      />
      <defs>
        <path
          id="Ghost3_svg__b"
          d="M95.141 24.305V12.152H83.287V0h-24.01v12.152H47.721V0H23.713v12.152H11.857v12.152H0v72.908h12.15V85.063h11.563v12.151h12.152V85.063h11.557v12.151h12.152V85.063h11.559v12.151h12.154V85.063h11.559v12.151H107V24.305H95.141zm-.295 24.304H82.99v12.15H71.43V36.457h23.416v12.152zM12.15 36.457h23.419v12.152H23.713v12.15H12.15V36.457z"
        />
      </defs>
      <clipPath id="Ghost3_svg__c">
        <use xlinkHref="#Ghost3_svg__b" overflow="visible" />
      </clipPath>
      <linearGradient
        id="Ghost3_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={-13.235}
        y1={78.362}
        x2={46.766}
        y2={41.362}
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0.1} />
        <stop offset={1} stopColor="#c7d1d7" stopOpacity={0.5} />
      </linearGradient>
      <path
        opacity={0.3}
        clipPath="url(#Ghost3_svg__c)"
        fill="url(#Ghost3_svg__d)"
        d="M85.271 101.061l-62.52 38.496-74.45-120.92 62.52-38.494z"
      />
    </svg>
  );
}

export default SvgGhost3;
