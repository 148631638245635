import * as React from "react";

function SvgSocial(props) {
  return (
    <svg width="145.062" height="138.336" viewBox="0 0 145.062 138.336" {...props}>
      <linearGradient
        id="social_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={4.852}
        y1={68.296}
        x2={25.34}
        y2={34.458}
        gradientTransform="rotate(7.18 122.834 131.982)"
      >
        <stop offset={0.007} stopColor="#f04c4c" />
        <stop offset={1} stopColor="#f7912e" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#social_svg__a)"
        d="M40.307 40.346c-1.479 11.73-9.075 20.434-16.967 19.438-7.892-.994-13.091-11.31-11.612-23.041 1.479-11.73 9.074-20.434 16.967-19.439 7.891.995 13.091 11.31 11.612 23.042z"
      />
      <linearGradient
        id="social_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={0.198}
        y1={68.672}
        x2={21.809}
        y2={32.98}
        gradientTransform="rotate(7.18 122.834 131.982)"
      >
        <stop offset={0.007} stopColor="#f04c4c" />
        <stop offset={1} stopColor="#f7912e" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#social_svg__b)"
        d="M15.296 58.771s5.854 1.395 9.9 1.065 9.957-25.673 9.957-25.673l-4.38-16.361s-8.257-3.231-13.371-1.377c-4.527 1.64-2.106 42.346-2.106 42.346z"
      />
      <linearGradient
        id="social_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={6.989}
        y1={72.784}
        x2={6.989}
        y2={29.97}
        gradientTransform="rotate(7.18 122.834 131.982)"
      >
        <stop offset={0} stopColor="#faa71a" />
        <stop offset={1} stopColor="#ffd32a" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#social_svg__c)"
        d="M32.263 39.331c-1.479 11.731-9.075 20.435-16.967 19.439-7.891-.994-13.09-11.31-11.612-23.041 1.478-11.73 9.074-20.434 16.966-19.439 7.892.995 13.092 11.311 11.613 23.041z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#842250"
        d="M28.224 43.122s-.719 2.373-2.247 5.025c-2.077 3.615-5.657 7.746-10.94 7.064-3.809-.487-5.999-2.89-7.262-5.619l-.006-.008c-1.918-4.143-1.686-9.023-1.686-9.023l22.141 2.561z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#55224F"
        d="M20.227 36.513s4.986-2.313 9.285-.087l.232-1.846s-5.829-2.274-9.421 1.166l-.096.767zm-6.636-.836s-3.071-3.328-6.632-2.094l.233-1.845s4.667-.952 6.495 3.171l-.096.768z"
      />
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FBF9FB"
        d="M30.189 24.245s5.144-.109 8.046 1.014c0 0-1.656-3.039-3.464-4.938 0 0-4.567-1.675-9.317-2.082 0-.001 3.37 2.289 4.735 6.006z"
      />
      <linearGradient
        id="social_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={15.698}
        y1={73.692}
        x2={20.582}
        y2={65.626}
        gradientTransform="rotate(7.18 122.834 131.982)"
      >
        <stop offset={0} stopColor="#dbccd2" />
        <stop offset={0.998} stopColor="#ac3c96" />
      </linearGradient>
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#social_svg__d)"
        d="M20.433 58.075s2.841 1.103 8.044 1.014c0 0 2.184-.824 4.593-3.036 0 0-3.359-.716-7.25-1.825 0 0-2.507 2.694-5.387 3.847z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        d="M7.795 40.772s-.585 13.227 7.241 14.439c9.347 1.449 13.188-12.09 13.188-12.09L7.795 40.772z"
      />
      <radialGradient
        id="social_svg__e"
        cx={-154.878}
        cy={-302.256}
        r={33.807}
        gradientTransform="translate(287.174 426.173) scale(1.2304)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#b4829d" />
        <stop offset={1} stopColor="#795da6" />
      </radialGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#social_svg__e)"
        d="M145.062 20.915v9.814l-22.441 56.91-70.079-19.196-4.379-5.372z"
      />
      <linearGradient
        id="social_svg__f"
        gradientUnits="userSpaceOnUse"
        x1={90.908}
        y1={77.332}
        x2={102.54}
        y2={5.274}
      >
        <stop offset={0.017} stopColor="#d4cbe1" />
        <stop offset={1} stopColor="#f2e6ee" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#social_svg__f)"
        d="M48.163 63.071L69.867 0l75.195 20.915-25.199 61.091z"
      />
      <path
        opacity={0.45}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#7A1C37"
        d="M122.621 87.639l-2.758-5.633-71.7-18.935 4.379 5.372z"
      />
      <path
        opacity={0.3}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#5D1748"
        d="M117.814 41.715l1.564 5.243-5.388 18.823-42.413-12.613v-8.097l11.118-24.64 2.092.679v5.124h3.461l3.675-3.666 11.172 4.392 4.674 9.359z"
      />
      <g fill="#B12B44">
        <path d="M82.349 50.521c-3.574-1.176-6.483-2.001-10.124-2.82.173-.505-2.264-2.365-2.092-2.87 2.883-8.495 8.097-15.682 10.183-24.939.49.2 1.404-1.602 2.379-1.65.4-.021 1.561 2.758 2.092 2.869-2.852 7.289-5.029 15.677-7.658 24.092 1.727.457 2.961-1.809 4.299-1.574 0 0 1.478 2.785 2.092 2.869l-1.171 4.023z" />
        <path d="M94.359 32.294c-2.42 5.838-5.37 16.789-5.568 18.995-1.299-.379-3.482-.891-5.061-1.14.188-.472-2.27-2.405-2.092-2.869 3.169-8.27 7.484-13.617 7.918-16.573.646.278 1.586-1.644 2.711-1.284 0 0 1.768 2.822 2.092 2.871zm-1.146-3.385c-2.034-.438-3.772-3.242-4.278-4.371-1.111-2.495 1.158-3.965 2.988-4.097 5.53-.396 6.434 9.578 1.29 8.468z" />
        <path d="M107.769 36.319c-1.442 1.856-6.314 8.075-7.973 9.734.482 3.301 1.29 7.1 1.941 9.985l-4.713-1.037-2.092-2.869 1.229-4.86c-.338 1.161-1.728 5.997-1.887 7.201-1.949-.567-2.633-.773-3.932-1.062.129-.347-2.215-2.529-2.092-2.869 3.363-9.233 7.496-14.015 9.899-25.105.403.14 1.852-1.669 2.853-1.347 0 0 1.65 2.803 2.092 2.869-2.404 7.039-4.512 13.574-5.131 15.698 2.828-3.883 4.24-6.091 5.552-8.308l2.16-.9 2.094 2.87z" />
        <path d="M114.171 59.177c-4.49.221-7.131-.618-8.498-2.393-1.009-1.312-3.36-3.679-3.215-5.961.248-3.981 4.189-9.014 6.887-11.955 2.303-2.519 3.281-5.345 5.361-3.646 3.239 2.646 4.313 9.042 1.439 16.235l-7.081-1.078c-.702 3.936 1.853 2.124 3.548 2.155.456.009 1.57 2.801 2.092 2.869l-.533 3.774zm-4.269-12.105l3.373.334c.375-1.298 1.443-4.876-.08-5.621-1.851-.342-2.762 3.461-3.293 5.287z" />
      </g>
      <linearGradient
        id="social_svg__g"
        gradientUnits="userSpaceOnUse"
        x1={75.054}
        y1={43.171}
        x2={86.896}
        y2={12.512}
      >
        <stop offset={0.002} stopColor="#e03548" />
        <stop offset={1} stopColor="#f36f2e" />
      </linearGradient>
      <path
        fill="url(#social_svg__g)"
        d="M80.256 47.651c-.712-.234-1.403-.455-2.073-.665a97.213 97.213 0 00-8.051-2.154c.809-2.354 1.59-4.692 2.343-7.016a247.489 247.489 0 002.17-7.065 271.275 271.275 0 001.895-6.833c.6-2.271 1.159-4.568 1.684-6.896.125.051.271.1.438.149.139.054.309.11.514.17l.694.203c.411.119.86.239 1.346.359.487.12.979.232 1.479.338a111.202 111.202 0 00-2.054 5.73c-.665 2.02-1.31 4.072-1.937 6.152-.627 2.081-1.244 4.156-1.854 6.229a392.524 392.524 0 01-1.814 5.979c1.061.281 2.1.526 3.115.739 1.02.213 2.109.397 3.275.557l-1.17 4.024z"
      />
      <linearGradient
        id="social_svg__h"
        gradientUnits="userSpaceOnUse"
        x1={83.483}
        y1={46.426}
        x2={95.325}
        y2={15.767}
      >
        <stop offset={0.002} stopColor="#e03548" />
        <stop offset={1} stopColor="#f36f2e" />
      </linearGradient>
      <path
        fill="url(#social_svg__h)"
        d="M92.267 29.423a52.982 52.982 0 00-.855 2.217c-.285.793-.575 1.618-.865 2.474a96.832 96.832 0 00-.846 2.616 216.4 216.4 0 00-.783 2.623 148.03 148.03 0 00-.816 2.907c-.248.936-.473 1.8-.67 2.594a73.03 73.03 0 00-.49 2.112 12.863 12.863 0 00-.242 1.452 39.734 39.734 0 00-1.611-.425c-.483-.113-1.025-.235-1.627-.37a30.632 30.632 0 00-1.822-.343 124.095 124.095 0 002.291-6.215 137.133 137.133 0 001.619-5.138 78.3 78.3 0 00.933-3.457c.241-.996.435-1.84.581-2.535.181-.812.314-1.511.402-2.099.693.3 1.333.549 1.924.748.588.2 1.102.362 1.539.488.203.061.391.115.559.162.168.051.31.086.43.106.138.043.255.07.349.083zm2.785-5.269a3.245 3.245 0 01-.514 1.052 2.506 2.506 0 01-.835.717 2.777 2.777 0 01-1.143.307c-.435.026-.915-.037-1.439-.189-.577-.168-1.043-.38-1.396-.635a2.75 2.75 0 01-.791-.867 2.312 2.312 0 01-.281-1.043 3.811 3.811 0 01.16-1.155c.113-.386.275-.731.489-1.038s.484-.552.81-.736a2.704 2.704 0 011.149-.323c.44-.031.938.033 1.489.193.553.159 1.002.374 1.352.634.347.262.611.557.787.887s.272.684.297 1.059c.023.372-.02.752-.134 1.137z"
      />
      <linearGradient
        id="social_svg__i"
        gradientUnits="userSpaceOnUse"
        x1={93.109}
        y1={50.145}
        x2={104.951}
        y2={19.485}
      >
        <stop offset={0.002} stopColor="#e03548" />
        <stop offset={1} stopColor="#f36f2e" />
      </linearGradient>
      <path
        fill="url(#social_svg__i)"
        d="M105.676 33.45a350.142 350.142 0 01-3.183 4.046 225.502 225.502 0 01-2.295 2.821c-.633.763-1.146 1.372-1.543 1.827-.398.456-.715.802-.952 1.038.122.83.332 2.068.634 3.716.299 1.647.736 3.737 1.309 6.27l-4.713-1.035-.863-7.73a250.037 250.037 0 00-1.135 4.046 71.187 71.187 0 00-.49 1.896c-.137.57-.227.99-.262 1.259l-1.098-.319a76.023 76.023 0 00-1.226-.346 31.734 31.734 0 00-1.608-.396 178.282 178.282 0 002.689-7.802c.407-1.261.777-2.457 1.106-3.587.353-1.261.742-2.721 1.17-4.38.359-1.427.781-3.163 1.266-5.208a386.967 386.967 0 001.576-6.998c1.557.537 2.766.93 3.626 1.179.206.061.39.113.55.16s.3.081.418.104c.141.04.26.066.352.08a652.345 652.345 0 00-3.461 10.398 368.064 368.064 0 00-1.034 3.229c-.274.868-.486 1.559-.635 2.071a164.3 164.3 0 002.771-3.924 77.448 77.448 0 002.779-4.383l4.252 1.968z"
      />
      <linearGradient
        id="social_svg__j"
        gradientUnits="userSpaceOnUse"
        x1={105.706}
        y1={55.01}
        x2={117.548}
        y2={24.351}
      >
        <stop offset={0.002} stopColor="#e03548" />
        <stop offset={1} stopColor="#f36f2e" />
      </linearGradient>
      <path
        fill="url(#social_svg__j)"
        d="M112.08 56.307c-1.314.065-2.48.017-3.5-.141s-1.842-.328-2.475-.511c-.781-.229-1.424-.58-1.924-1.061-.502-.48-.891-1.041-1.172-1.68s-.465-1.335-.551-2.092a12.988 12.988 0 01-.055-2.323 17.88 17.88 0 01.303-2.367c.15-.784.325-1.518.521-2.198.496-1.693 1.086-3.217 1.771-4.57s1.438-2.476 2.254-3.365c.814-.891 1.688-1.518 2.615-1.882a4.274 4.274 0 012.834-.125 4.268 4.268 0 012.004 1.23c.539.589.92 1.361 1.143 2.316.224.956.276 2.104.158 3.44-.117 1.337-.428 2.873-.932 4.605-.143.489-.297.979-.462 1.476a27.864 27.864 0 01-.56 1.528l-7.081-1.079c-.088.49-.138.964-.151 1.418-.015.456.022.875.113 1.256.092.382.237.706.439.974.201.268.488.455.859.563.693.202 1.398.363 2.117.481.717.118 1.471.23 2.262.334l-.53 3.773zm-4.271-12.105l3.373.335.213-.733c.317-1.091.48-1.959.486-2.604.007-.646-.046-1.137-.156-1.476-.134-.401-.343-.671-.622-.808a1.235 1.235 0 00-1.037.262c-.308.244-.586.592-.835 1.041-.249.45-.475.972-.677 1.567a50.93 50.93 0 00-.572 1.82l-.173.596z"
      />
      <g>
        <radialGradient
          id="social_svg__k"
          cx={733.135}
          cy={-29.289}
          r={29.509}
          gradientTransform="matrix(-1.0942 -.5627 -.5627 1.0942 826.29 531.773)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#5a72b7" />
          <stop offset={0.994} stopColor="#723792" />
        </radialGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#social_svg__k)"
          d="M44.736 132.381l24.743-44.099-48.857-20.929L0 109.376z"
        />
        <linearGradient
          id="social_svg__l"
          gradientUnits="userSpaceOnUse"
          x1={329.627}
          y1={225.127}
          x2={322.467}
          y2={171.088}
          gradientTransform="scale(-1 1) rotate(-27.213 -41.477 947.29)"
        >
          <stop offset={0.006} stopColor="#532367" />
          <stop offset={1} stopColor="#613996" />
        </linearGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#social_svg__l)"
          d="M69.479 88.282l.343 4.556-24.824 44.807-.262-5.264z"
        />
        <linearGradient
          id="social_svg__m"
          gradientUnits="userSpaceOnUse"
          x1={353.426}
          y1={209.24}
          x2={362.468}
          y2={182.724}
          gradientTransform="scale(-1 1) rotate(-27.213 -41.477 947.29)"
        >
          <stop offset={0.006} stopColor="#532367" />
          <stop offset={1} stopColor="#613996" />
        </linearGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#social_svg__m)"
          d="M38.935 94.289s-.29-6.953-6.244-10.409c-.947-.549-2.477-1.365-2.663-1.425-7.133-2.275-9.426 7.328-7.235 14.482 2.247 7.341 6.561 10.636 7.684 13.798l8.458-16.446z"
        />
        <linearGradient
          id="social_svg__n"
          gradientUnits="userSpaceOnUse"
          x1={28.35}
          y1={210.229}
          x2={37.461}
          y2={183.51}
          gradientTransform="rotate(27.215 230.428 167.561)"
        >
          <stop offset={0.006} stopColor="#532367" />
          <stop offset={1} stopColor="#613996" />
        </linearGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#social_svg__n)"
          d="M38.935 94.289s2.597-5.708 7.892-3.316c1.56.704 3.742 2.037 4.207 2.339 6.711 4.376 2.416 13.205-4.863 15.646-7.278 2.443-12.468.852-15.693 1.777l-3.064-1.576 11.521-14.87z"
        />
        <linearGradient
          id="social_svg__o"
          gradientUnits="userSpaceOnUse"
          x1={356.631}
          y1={209.156}
          x2={365.384}
          y2={183.489}
          gradientTransform="scale(-1 1) rotate(-27.213 -41.477 947.29)"
        >
          <stop offset={0.017} stopColor="#d4cbe1" />
          <stop offset={1} stopColor="#f2e6ee" />
        </linearGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#social_svg__o)"
          d="M35.87 92.714s.168-7.906-6.243-10.41c-7.463-2.912-12.145 5.718-9.898 13.058 2.247 7.341 6.561 10.636 7.685 13.798l8.456-16.446z"
        />
        <linearGradient
          id="social_svg__p"
          gradientUnits="userSpaceOnUse"
          x1={25.495}
          y1={209.255}
          x2={34.247}
          y2={183.589}
          gradientTransform="rotate(27.215 230.428 167.561)"
        >
          <stop offset={0.017} stopColor="#d4cbe1" />
          <stop offset={1} stopColor="#f2e6ee" />
        </linearGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#social_svg__p)"
          d="M35.87 92.714s6.333-4.737 12.098-.978c6.711 4.375 2.415 13.204-4.862 15.646-7.278 2.442-12.468.851-15.693 1.776l8.457-16.444z"
        />
        <linearGradient
          id="social_svg__q"
          gradientUnits="userSpaceOnUse"
          x1={323.25}
          y1={258.488}
          x2={376.079}
          y2={258.488}
          gradientTransform="scale(-1 1) rotate(-38.36 -31.476 726.305)"
        >
          <stop offset={0.006} stopColor="#532367" />
          <stop offset={1} stopColor="#613996" />
        </linearGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#social_svg__q)"
          d="M0 109.376l2.657 5.3 42.341 22.969-.262-5.264z"
        />
      </g>
      <g>
        <linearGradient
          id="social_svg__r"
          gradientUnits="userSpaceOnUse"
          x1={23.242}
          y1={100.751}
          x2={36.588}
          y2={61.612}
          gradientTransform="matrix(.8998 -.1196 .1318 .9913 63.274 38.562)"
        >
          <stop offset={0.002} stopColor="#90182f" />
          <stop offset={1} stopColor="#c62f27" />
        </linearGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#social_svg__r)"
          d="M113.126 97.512c-8.647-.151-11.66 11.3-11.66 11.3s-5.607-10.5-13.997-8.47c-9.763 2.363-8.241 17.548-.575 25.305 7.666 7.756 14.487 8.504 17.949 11.717 2.621-3.885 9.093-6.098 14.751-15.324 5.657-9.227 3.596-24.352-6.468-24.528z"
        />
        <linearGradient
          id="social_svg__s"
          gradientUnits="userSpaceOnUse"
          x1={18.342}
          y1={101.854}
          x2={32.549}
          y2={60.192}
          gradientTransform="matrix(.8998 -.1196 .1318 .9913 63.274 38.562)"
        >
          <stop offset={0.002} stopColor="#90182f" />
          <stop offset={1} stopColor="#c62f27" />
        </linearGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#social_svg__s)"
          d="M77.434 102.479s5.019-3.396 13.322-2.317l10.253 5.391 2.841-6.887s6.666-3.888 14.114.4l.811 15.303-13.932 22.996-7.316.973-10.766-18.262-9.327-17.597z"
        />
        <radialGradient
          id="social_svg__t"
          cx={7.649}
          cy={71.924}
          r={31.547}
          gradientTransform="matrix(.8998 -.1196 .1318 .9913 63.274 38.562)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#f36f2e" />
          <stop offset={0.998} stopColor="#e03548" />
        </radialGradient>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#social_svg__t)"
          d="M105.81 98.484c-8.646-.151-11.659 11.3-11.659 11.3s-5.608-10.5-13.997-8.471c-9.763 2.363-8.241 17.548-.575 25.306 7.666 7.756 14.487 8.502 17.948 11.717 2.622-3.885 9.094-6.098 14.752-15.324 5.657-9.226 3.595-24.352-6.469-24.528z"
        />
        <path
          opacity={0.5}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#FBF9FB"
          d="M111.001 100.317s6.689-.673 8.45.11c0 0 2.938 3.245 3.063 7.449l-7.494-.541c.001.001-1.232-5.549-4.019-7.018z"
        />
      </g>
    </svg>
  );
}

export default SvgSocial;
