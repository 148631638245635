import * as React from "react";

function SvgSocialTube1024(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={135.566}
            height={78.348}
            {...props}
        >
            <path
                opacity={0.3}
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 7.5000000000000036,28.98 L 7.5000000000000036,40.849000000000004 C 7.5000000000000036,57.349000000000004 20.519000000000002,70.849 36.432,70.849 L 52.49600000000001,70.849 C 68.99600000000001,70.849 82.49600000000001,57.829 82.49600000000001,41.917 L 82.492,37.5 C 82.492,21.0 95.511,7.5 111.423,7.5 L 128.066,7.502"
            />
            <path
                className="tubefill servicepath"
                fill="none"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 7.5000000000000036,28.98 L 7.5000000000000036,40.849000000000004 C 7.5000000000000036,57.349000000000004 20.519000000000002,70.849 36.432,70.849 L 52.49600000000001,70.849 C 68.99600000000001,70.849 82.49600000000001,57.829 82.49600000000001,41.917 L 82.492,37.5 C 82.492,21.0 95.511,7.5 111.423,7.5 L 128.066,7.502"
            />
        </svg>
    );
}

export default SvgSocialTube1024;
