import * as React from "react";

function SvgMedia(props) {
  return (
    <svg width="146.09" height="102.605" viewBox="0 0 146.09 102.605" {...props}>
      <linearGradient
        id="media_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={74.448}
        y1={6.724}
        x2={69.873}
        y2={121.036}
      >
        <stop offset={0} stopColor="#f16577" />
        <stop offset={1} stopColor="#8a181b" />
      </linearGradient>
      <path
        fill="url(#media_svg__a)"
        d="M141.801 82.16c.073-.104.142-.209.212-.314a11.6 11.6 0 00.371-.594 11.31 11.31 0 00.528-.999c.05-.104.101-.205.147-.31.148-.332.285-.668.409-1.018l1.626-4.318c-.123.35-.262.687-.408 1.018-.046.104-.1.206-.148.311a12.74 12.74 0 01-.527.998c-.118.202-.242.4-.371.595-.07.105-.139.211-.213.313-.148.211-.306.415-.468.616-.061.076-.117.154-.181.229a12.68 12.68 0 01-.719.788c-.051.051-.105.098-.157.148-.206.201-.419.398-.642.59-.096.082-.196.161-.295.241a13.675 13.675 0 01-.926.697c-.208.145-.424.285-.645.423-.123.078-.246.158-.375.234-.09.053-.182.104-.273.156-.197.111-.396.221-.602.328-.096.049-.191.1-.288.148-.241.121-.488.238-.74.352-.06.027-.117.056-.178.083-.313.138-.635.271-.964.401-.091.033-.184.067-.274.102-.248.094-.499.186-.757.273a34.75 34.75 0 01-1.123.362l-.338.103c-.37.106-.748.211-1.136.311-13.137 3.334-26.647 5.367-40.126 7.536-.919.147-1.839.285-2.76.417-1.66.238-3.322.461-4.98.699-14.819 1.914-29.587 4.156-44.625 5.052-2.705.157-5.448.233-8.175.044-8.116-.563-13.945-5.219-17.335-13.764C7.69 67.618 3.486 50.463 1.776 32.954c-.349-3.582-.09-6.625.818-9.189L.967 28.083C.06 30.646-.2 33.689.149 37.272c1.71 17.509 5.914 34.663 12.57 51.457 3.391 8.545 9.22 13.202 17.336 13.765 2.727.188 5.47.112 8.175-.045 15.038-.895 29.805-3.137 44.624-5.052 1.658-.237 3.322-.46 4.98-.698.922-.133 1.842-.27 2.76-.418 13.479-2.168 26.99-4.203 40.127-7.537a31.115 31.115 0 001.475-.413c.26-.079.515-.158.766-.241.119-.04.238-.079.355-.12.258-.088.51-.181.758-.274.092-.034.184-.067.273-.102.328-.13.65-.263.963-.401.063-.027.121-.056.182-.083a20.104 20.104 0 001.625-.827c.093-.052.186-.104.275-.158.051-.028.104-.057.154-.087.076-.047.146-.099.222-.146a17.057 17.057 0 00.982-.664c.203-.148.398-.302.588-.457.1-.08.199-.158.295-.24.223-.191.438-.391.645-.592.051-.05.105-.096.154-.146.252-.254.492-.518.721-.788.062-.074.117-.153.179-.228.163-.203.32-.407.468-.617z"
      />
      <linearGradient
        id="media_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={24.912}
        y1={-0.244}
        x2={126.672}
        y2={102.296}
      >
        <stop offset={0.013} stopColor="#fcd2af" />
        <stop offset={0.424} stopColor="#f37888" />
        <stop offset={0.988} stopColor="#ef3742" />
      </linearGradient>
      <path
        fill="url(#media_svg__b)"
        d="M132.937 12.774c5.715 13.557 9.193 27.524 11.643 41.662.766 4.419 1.451 8.846 1.508 13.29.122 9.188-4.19 14.273-13.739 16.7-13.137 3.335-26.647 5.368-40.126 7.537-.919.147-1.839.285-2.76.417-1.66.238-3.322.461-4.98.699-14.819 1.914-29.587 4.156-44.625 5.052-2.705.157-5.448.233-8.175.044-8.116-.563-13.945-5.219-17.335-13.764C7.69 67.618 3.486 50.463 1.776 32.954.715 22.065 5.275 16.162 16.572 13.521 27.87 10.875 39.38 8.99 50.866 6.982c4.194-.73 8.401-1.393 12.621-2 13.759-1.976 27.644-3.36 41.552-4.633 3.316-.307 6.702-.428 10.063-.298 8.42.314 14.37 4.507 17.835 12.723z"
      />
      <linearGradient
        id="media_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={22.949}
        y1={3.565}
        x2={129.005}
        y2={98.36}
      >
        <stop offset={0.005} stopColor="#fab392" />
        <stop offset={0.422} stopColor="#f16374" />
        <stop offset={0.988} stopColor="#ee252e" />
      </linearGradient>
      <path
        fill="url(#media_svg__c)"
        d="M35.093 97.218c-1.189 0-2.281-.036-3.336-.108-7.657-.531-13.18-4.936-16.417-13.092C8.734 67.352 4.528 50.136 2.84 32.85 1.837 22.557 6.017 17.087 16.816 14.563c9.726-2.277 19.793-4.022 29.53-5.709 1.568-.271 3.136-.544 4.704-.818a429.79 429.79 0 0112.588-1.994c13.988-2.009 28.21-3.411 41.498-4.628a79.48 79.48 0 017.26-.345c.896 0 1.792.018 2.664.051 8.07.301 13.595 4.25 16.893 12.07 5.093 12.084 8.771 25.248 11.573 41.43.724 4.178 1.435 8.632 1.491 13.121.115 8.638-3.752 13.317-12.933 15.65-11.868 3.012-24.274 4.996-36.272 6.915l-3.76.603c-.912.146-1.826.283-2.743.414-.825.119-1.652.233-2.479.348-.834.115-1.668.232-2.5.352-2.874.371-5.76.756-8.646 1.142-11.711 1.565-23.821 3.183-35.89 3.901a82.067 82.067 0 01-4.701.152z"
      />
      <linearGradient
        id="media_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={102.451}
        y1={100.625}
        x2={56.212}
        y2={11.422}
      >
        <stop offset={0} stopColor="#f16577" />
        <stop offset={0.796} stopColor="#8a181b" />
      </linearGradient>
      <path
        fill="url(#media_svg__d)"
        style={{mixBlendMode: "multiply"}}
        d="M65.063 69.949c10.216-7.997 20.224-15.835 30.57-23.932L55.855 32.199l9.208 37.75"
      />
    </svg>
  );
}

export default SvgMedia;
