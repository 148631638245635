import * as React from "react";

function SvgArrow(props) {
  return (
    <svg
      width="98.402px"
      height="43.686px"
      viewBox="0 0 98.402 45.686"
      {...props}
    >
      <g fill="#FFF">
        <path d="M29.66 12.913h52.019v21.675H29.66zm-11.178 0h7.467v21.675h-7.467zm-15.469 0h7.466v21.675H3.013zm9.799-.002h3.703v21.678h-3.703z" />
        <path d="M80.493 45.243l20.922-21.844-21.18-21.143z" />
      </g>
    </svg>
  );
}

export default SvgArrow;
