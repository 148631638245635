import React, {useEffect} from "react";
import SvgLogoLoadingN from "../assets/svg/SvgJs/LogoLoading_N";
import LoadingBarInIntro from "../hooks/LoadingBarInIntro";

function Loading({quote}) {

    return (
        <div className="loading" id="loading">
            <canvas id="noise"/>
            <div className="loadingBar">
                <LoadingBarInIntro/>
            </div>
            <div className="cont" id="cont">
                <div className="logo_loading">
                    <SvgLogoLoadingN/>
                </div>
                <div className="quote hide">
                    <blockquote>{quote.text}</blockquote>
                    {quote.author != null ? <cite>{quote.author}</cite> : null}
                </div>
            </div>
        </div>
    );
}

export default Loading;
