import * as React from "react";

function SvgWebtube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={164.599}
      height={99.674}
      viewBox="0 18.183 164.599 99.674"
      {...props}
    >
      <path
        opacity={0.3}
        fill="none"
        stroke="#010101"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M157.098 25.683c0 16.5-13.02 30-28.933 30l-90.665.195c-16.5 0-30 13.019-30 28.933l.023 25.547"
      />
      <path
        className="tubefill servicepath"
        fill="none"
        stroke="#fff"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M157.098 25.683c0 16.5-13.02 30-28.933 30l-90.665.195c-16.5 0-30 13.019-30 28.933l.023 25.547"
      />
    </svg>
  );
}

export default SvgWebtube;
