import * as React from "react";

function SvgSpotFooterN(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1342.6704443953553145919572796513"
      height="768"
      viewBox="0 99.4 910.14 520.595"
    >
      <linearGradient
        id="aaa"
        x1="43.965"
        x2="1608.303"
        y1="610.38"
        y2="610.38"
        gradientTransform="matrix(.5711 0 0 -.5711 -8.3 721.417)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="silver"></stop>
        <stop offset="0.591" stopColor="#a9a9a9" stopOpacity="0.273"></stop>
        <stop offset="1" stopColor="#a7a7a7" stopOpacity="0"></stop>
      </linearGradient>
      <path
        fill="url(#aaa)"
        className="lightFooter"
        style={{ mixBlendMode: "soft-light" }}
        d="M139.669 125.71L910.14 372.853v247.143H16.807S58.274 180.62 58.872 165.664c.599-14.956 80.797-39.954 80.797-39.954z"
        opacity="0.6"
      ></path>
      <radialGradient
        id="bbb"
        cx="-1234.257"
        cy="2766.76"
        r="33.374"
        gradientTransform="rotate(180 -579.474 1483.833) scale(1.0197)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#0f4a7d"></stop>
        <stop offset="0.351" stopColor="#10497c"></stop>
        <stop offset="0.527" stopColor="#134577"></stop>
        <stop offset="0.664" stopColor="#173e6e"></stop>
        <stop offset="0.781" stopColor="#193563"></stop>
        <stop offset="0.885" stopColor="#182954"></stop>
        <stop offset="0.979" stopColor="#151943"></stop>
        <stop offset="1" stopColor="#14143e"></stop>
      </radialGradient>
      <path
        fill="url(#bbb)"
        d="M102.763 152.813c-23.765 11.207-44.416 17.372-46.117 13.759-1.403-2.964 10.452-11.62 27.938-20.836a306.542 306.542 0 0121.02-10.058c19.774-8.567 35.629-12.838 37.111-9.689 1.702 3.602-16.187 15.616-39.952 26.824z"
      ></path>
      <path
        fill="#321550"
        d="M0 619.995h63.108l-2.562-2.353-31.063-2.353-1.348.138-22.429 2.215L0 619.995z"
      ></path>
      <path
        fill="#321550"
        d="M29.588 616.445l-5.723-486.831 45.501-23.819 10.021 14.222 3.12-2.223-12.621-15.175-48.213 26.234 6.577 487.593h1.338z"
      ></path>
      <radialGradient
        id="ccc"
        cx="2493.085"
        cy="2779.767"
        r="39.341"
        gradientTransform="matrix(1.0197 0 0 -1.0197 -2445.297 2967.667)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#0f4a7d"></stop>
        <stop offset="0.351" stopColor="#10497c"></stop>
        <stop offset="0.527" stopColor="#134577"></stop>
        <stop offset="0.664" stopColor="#173e6e"></stop>
        <stop offset="0.781" stopColor="#193563"></stop>
        <stop offset="0.885" stopColor="#182954"></stop>
        <stop offset="0.979" stopColor="#151943"></stop>
        <stop offset="1" stopColor="#14143e"></stop>
      </radialGradient>
      <path
        fill="url(#ccc)"
        d="M142.716 125.987L95.51 148.252l-38.971 18.38-5.033-39.566c-1.131-2.28 2.701-6.674 9.419-11.427 3.806-2.692 8.551-5.507 13.838-8.129 14.346-7.086 27.403-10.058 29.543-6.778l38.41 25.255z"
      ></path>
      <path
        fill="#7D2986"
        d="M125.318 128.126L95.51 148.252l-38.971 18.38-5.033-39.566c-1.131-2.28 2.701-6.674 9.419-11.427 0 0 8.646 27.308 9.856 28.518 1.211 1.21 54.537-16.031 54.537-16.031z"
        opacity="0.14"
      ></path>
      <linearGradient
        id="ddd"
        x1="3195.333"
        x2="3337.002"
        y1="1008.148"
        y2="1008.148"
        gradientTransform="matrix(.5711 0 0 -.5711 -1765.86 721.417)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff0d1"></stop>
        <stop offset="1" stopColor="#fee2c9"></stop>
      </linearGradient>
      <path
        fill="url(#ddd)"
        d="M102.213 151.827c-22.279 10.507-41.642 16.288-43.235 12.9-1.316-2.779 9.799-10.893 26.192-19.534a289.273 289.273 0 0111.264-5.616 276.76 276.76 0 018.442-3.813c18.54-8.032 33.404-12.037 34.793-9.084 1.596 3.378-15.177 14.642-37.456 25.147z"
      ></path>
      <path
        fill="#FFF"
        d="M101.094 138.053a7.27 7.27 0 01-7.266 7.266 7.215 7.215 0 01-4.967-1.966 181.645 181.645 0 016.979-3.481 173.73 173.73 0 015.233-2.364c.017.178.021.362.021.545z"
      ></path>
    </svg>
  );
}

export default SvgSpotFooterN;
