import * as React from "react";

function SvgGhost2(props) {
  return (
    <svg viewBox="0 0 106.999 97.219" {...props}>
      <path
        d="M95.144 24.305V12.15H83.287V0H59.279v12.15H47.721V0h-24.01v12.15H11.855v12.154H0V60.76h11.855v24.304h.197v12.152h23.614v-12.15H24.007V72.913h58.986V84.77H71.035v12.154h24.008V85.066h.1V60.762h11.855V24.305H95.144zm-.296 24.304H71.432V36.457h23.417v12.152zm-82.697 0V36.457h23.417v12.152H12.151z"
        fill="#fff"
      />
      <defs>
        <path
          id="Ghost2_svg__a"
          d="M95.144 24.305V12.15H83.287V0H59.279v12.15H47.721V0h-24.01v12.15H11.855v12.154H0V60.76h11.855v24.304h.197v12.152h23.614v-12.15H24.007V72.913h58.986V84.77H71.035v12.154h24.008V85.066h.1V60.762h11.855V24.305H95.144zm-.296 24.304H71.432V36.457h23.417v12.152zm-82.697 0V36.457h23.417v12.152H12.151z"
        />
      </defs>
      <clipPath id="Ghost2_svg__b">
        <use xlinkHref="#Ghost2_svg__a" overflow="visible" />
      </clipPath>
      <linearGradient
        id="Ghost2_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={-7.148}
        y1={78.366}
        x2={52.852}
        y2={41.366}
      >
        <stop offset={0} stopColor="#9e9e9e" stopOpacity={0.1} />
        <stop offset={1} stopColor="#bfbfbf" stopOpacity={0.5} />
      </linearGradient>
      <path
        opacity={0.3}
        clipPath="url(#Ghost2_svg__b)"
        fill="url(#Ghost2_svg__c)"
        d="M91.357 101.064l-62.52 38.495-74.45-120.918 62.52-38.495z"
      />
    </svg>
  );
}

export default SvgGhost2;
