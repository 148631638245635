import React from "react";

function SvgProjectTube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.002"
      height="3213.319"
      viewBox="0 0 15.002 3213.319"
      {...props}
    >
      <path
        className="product_tube_path"
        fill="none"
        stroke="#010101"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="15"
        d="M7.502 7.5L7.5 3205.819"
        opacity="0.3"
      ></path>
      <path
        className="product_tube_path tubefillproject"
        fill="none"
        stroke="#3f3f3f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="15"
        d="M7.502 7.5L7.5 3205.819"
      ></path>
    </svg>
  );
}

export default SvgProjectTube;
