import * as React from "react";

function SvgLess768S3(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={787.683}
            height={1349.577}
            {...props}
        >
            <path
                opacity={0.3}
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M7.5 7.501L369.124 7.5c16.5 0 30 13.5 30 30l-.562 448.57-.002 34.489c0 16.5 13.5 30 30 30l321.623-.178c16.5 0 30 13.5 30 30l-.002 761.696"
            />
            <path
                fill="none"
                stroke="#3f3f3f"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="tubefill s3"
                strokeMiterlimit={10}
                d="M7.5 7.501L369.124 7.5c16.5 0 30 13.5 30 30l-.562 448.57-.002 34.489c0 16.5 13.5 30 30 30l321.623-.178c16.5 0 30 13.5 30 30l-.002 761.696"
            />
        </svg>
    );
}

export default SvgLess768S3;
