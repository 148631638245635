import React from "react";

function SvgLess768S6() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="319.401"
            height="820.6"
            viewBox="0 0 319.401 820.6"
        >
            <path
                fill="none"
                stroke="#010101"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="15"
                d="M7.5 7.5l.002 43.13.5 181.525L8 260.698c0 16.5 13.5 30 30 30l69.07-.001c16.5 0 30-13.5 30-30l-.017-32.391c0-16.5 13.5-30 30-30h114.271c16.5 0 30 13.5 30 30l.574 440.9.002 39.402c0 16.5-13.5 30-30 30l-107.479.002c-16.5 0-30 13.5-30 30l.002 44.489"
                opacity="0.3"
            />
            <path
                fill="none"
                stroke="#fff"
                className="tubefill s6"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M7.5 7.5l.002 43.13.5 181.525L8 260.698c0 16.5 13.5 30 30 30l69.07-.001c16.5 0 30-13.5 30-30l-.017-32.391c0-16.5 13.5-30 30-30h114.271c16.5 0 30 13.5 30 30l.574 440.9.002 39.402c0 16.5-13.5 30-30 30l-107.479.002c-16.5 0-30 13.5-30 30l.002 44.489"
            />
        </svg>
    );
}

export default SvgLess768S6;
