import * as React from "react";
import useWindowSize from "../../../../hooks/useWindowSize";

function SvgS6N(props) {
  const size = useWindowSize();
  const [tubeWidth, setTubeWidth] = React.useState(
    "M7.5 7.5l2.89 256.198c0 16.5 13.5 30 30 30l116.681-.001c16.5 0 30-13.5 30-30l-.017-32.391c0-16.5 13.5-30 30-30h224.271c16.5 0 30 13.5 30 30l.574 440.9.002 39.402c0 16.5-13.5 30-30 30l-147.479.002c-16.5 0-30 13.5-30 30l.002 42.489"
  );

  React.useEffect(() => {
    // if (size.width > 1024) return;
    // let w = (size.width - 1024) / 2;
    // setTubeWidth(
    //   `M7.5 7.5l2.89 256.198c0 16.5 13.5 30 30 30l116.681-.001c16.5 0 30-13.5 30-30l-.017-32.391c0-16.5 13.5-30 30-30h224.271c16.5 0 30 13.5 30 30l.574 440.9.002 39.402c0 16.5-13.5 30-30 30l ${w} 0.002c-16.5 0-30 13.5-30 30l.002 42.489`
    // );
      if (size.width > 1024) return;
      let x = -24.7
      let w = x + ((size.width - 1024) / 2);
      setTubeWidth(
          `M7.5 7.5l2.89 256.198c0 16.5 13.5 30 30 30l116.681-.001c16.5 0 30-13.5 30-30l-.017-32.391c0-16.5 13.5-30 30-30h204.271c16.5 0 30 13.5 30 30l.574 440.9.002 39.402c0 16.5-13.5 30-30 30l ${w} 0.002c-16.5 0-30 13.5-30 30l.002 42.489`
      );
  }, [size.width]);
  return (
    <svg width={479.4} height={821.6} {...props}>
      <path
        opacity={0.3}
        fill="none"
        stroke="#010101"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d={tubeWidth}
      />
      <path
        fill="none"
        stroke="#fff"
        className="tubefill s6"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d={tubeWidth}
      />
    </svg>
  );
}

export default SvgS6N;
