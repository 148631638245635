import * as React from "react";
import useWindowSize from "../../../../../hooks/useWindowSize";
import {qs} from "../../../../../helpers/helpers";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

function SvgLess768S2(props) {

    const size = useWindowSize();

    let [change, setChange] = React.useState(0)

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let s2Tube = qs(".tubefill.s2");
        let s2Total = s2Tube.getTotalLength().toFixed(0);
        s2Tube.style.strokeDasharray = s2Total + "px";
        s2Tube.style.strokeDashoffset = s2Total + "px";
        gsap
            .timeline({
                defaults: {duration: 1},
                scrollTrigger: {
                    trigger: ".sectionAbout",
                    scrub: "20",
                    triggerHook: 0,
                    start: "top-=20 top",
                    end: "bottom-=50 top",
                    ease: "none",
                },
            })
            .fromTo(
                ".tubefill.s2",
                {
                    strokeDasharray: s2Total + "px",
                    strokeDashoffset: s2Total + "px",
                },
                {
                    strokeDashoffset: s2Total * 2 + "px",
                },
                0
            )
            .add(window.tl, 0);
    }, [change]);
    React.useEffect(() => {
        if (size.width < 480) return;
        qs(".sectionAbout .tube svg").style.left = `-${767 - size.width + 5}px`
        qs(".sectionAbout .lampshades").style.left = `-${767 - size.width + 695}px`;

        qs(".sectionAbout .robot").style.left = "190px"
        qs(".sectionAbout .robot").style.left = "90px"
        setChange(1)
    }, [size.width]);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={735.363}
            height={783.353}
            {...props}
        >
            <path
                opacity={0.3}
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M7.5 775.853l568.832-.001c16.5 0 30-13.499 30-30V601.476c0-11.501 13.5-25 30-25l59.032-.003c16.499 0 29.999-13.499 29.999-30L725.282 7.5"
            />
            <path
                fill="none"
                stroke="#fff"
                className="tubefill s2"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M7.5 775.853l568.832-.001c16.5 0 30-13.499 30-30V601.476c0-11.501 13.5-25 30-25l59.032-.003c16.499 0 29.999-13.499 29.999-30L725.282 7.5"
            />
        </svg>
    );
}

export default SvgLess768S2;
