import * as React from "react";

function SvgIg(props) {
  return (
    <svg width={21.072} height={21.07} {...props}>
      <g fill="#FFF">
        <path d="M10.536 1.898c2.813 0 3.146.011 4.256.063 1.027.047 1.586.218 1.957.361.492.192.843.421 1.211.789.369.369.598.72.789 1.211.144.371.314.93.362 1.957.05 1.11.062 1.443.062 4.257 0 2.813-.012 3.146-.062 4.257-.048 1.027-.219 1.585-.362 1.957-.191.491-.42.842-.789 1.211a3.247 3.247 0 01-1.211.787c-.371.146-.93.316-1.957.363-1.11.051-1.443.062-4.256.062s-3.146-.011-4.257-.062c-1.028-.047-1.585-.218-1.957-.363a3.247 3.247 0 01-1.211-.787 3.278 3.278 0 01-.788-1.211c-.144-.372-.316-.93-.363-1.957-.051-1.111-.062-1.444-.062-4.257 0-2.813.011-3.146.062-4.257.047-1.027.219-1.586.363-1.957.191-.491.42-.842.788-1.211.369-.368.72-.597 1.211-.789.371-.144.929-.314 1.957-.361 1.111-.052 1.444-.063 4.257-.063m0-1.898c-2.861 0-3.22.012-4.344.064-1.122.052-1.887.229-2.557.49A5.126 5.126 0 001.769 1.77 5.137 5.137 0 00.554 3.635c-.261.67-.438 1.437-.49 2.558C.013 7.316 0 7.674 0 10.536c0 2.86.013 3.22.064 4.344.051 1.121.229 1.888.49 2.558.269.692.629 1.28 1.215 1.865a5.173 5.173 0 001.866 1.216c.67.261 1.436.438 2.558.489 1.124.051 1.482.063 4.344.063 2.86 0 3.219-.012 4.343-.063 1.122-.051 1.888-.229 2.558-.489.693-.27 1.28-.63 1.866-1.216a5.167 5.167 0 001.215-1.865c.261-.67.438-1.437.489-2.558.052-1.124.064-1.483.064-4.344 0-2.862-.013-3.22-.064-4.344-.051-1.121-.229-1.888-.489-2.558a5.167 5.167 0 00-1.215-1.865A5.147 5.147 0 0017.438.553c-.67-.261-1.436-.438-2.558-.489C13.755.012 13.396 0 10.536 0" />
        <path d="M10.536 5.125a5.41 5.41 0 10.001 10.821 5.41 5.41 0 00-.001-10.821m0 8.922a3.511 3.511 0 110-7.024 3.512 3.512 0 110 7.024m6.888-9.135a1.266 1.266 0 11-2.532-.002 1.266 1.266 0 012.532.002" />
      </g>
    </svg>
  );
}

export default SvgIg;
