import * as React from "react";

function SvgRightService480(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={48.15} height={45} viewBox="0 0 48.15 45" {...props}>
            <path
                d="M7.5 7.5c0 16.5 13.02 30 28.93 30h4.22"
                fill="none"
                stroke="#020203"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                opacity={0.3}
            />
            <path
                d="M7.5 7.5c0 16.5 13.02 30 28.93 30h4.22"
                className="tubefill servicepath"
                fill="none"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
            />
        </svg>
    );
}

export default SvgRightService480;
