import React from "react";

function SvgS10(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.001"
      height="53.501"
      viewBox="0 0 15.001 53.501"
      {...props}
    >
      <path
        opacity=".3"
        fill="none"
        stroke="#010101"
        strokeWidth="15"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M7.501 7.5L7.5 46.001"
      />
      <path
        className="tubefill s10"
        fill="none"
        stroke="#fff"
        strokeWidth="15"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M7.501 7.5L7.5 46.001"
      />
    </svg>
  );
}

export default SvgS10;
