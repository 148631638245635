import * as React from "react";

function SvgGhost4(props) {
  return (
    <svg viewBox="0 0 107 97.219" {...props}>
      <path
        d="M95.146 24.306V12.152H71.433V0H59.28v12.152H47.72V0H35.567v12.152H11.856v12.153H0v48.606h11.856v12.154h11.856v12.151h12.15V85.067h11.562v12.151h12.151V85.067h11.561v12.151h12.152V85.067h11.856V72.913H107V24.306H95.146zM12.152 36.457h23.416v24.306H12.152V36.457zm82.697 24.307H71.433V36.457H94.85v24.307z"
        fill="#8a539a"
      />
      <defs>
        <path
          id="Ghost4_svg__a"
          d="M95.146 24.306V12.152H71.433V0H59.28v12.152H47.72V0H35.567v12.152H11.856v12.153H0v48.606h11.856v12.154h11.856v12.151h12.15V85.067h11.562v12.151h12.151V85.067h11.561v12.151h12.152V85.067h11.856V72.913H107V24.306H95.146zM12.152 36.457h23.416v24.306H12.152V36.457zm82.697 24.307H71.433V36.457H94.85v24.307z"
        />
      </defs>
      <clipPath id="Ghost4_svg__b">
        <use xlinkHref="#Ghost4_svg__a" overflow="visible" />
      </clipPath>
      <linearGradient
        id="Ghost4_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={-2.431}
        y1={90.363}
        x2={57.57}
        y2={53.363}
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0.1} />
        <stop offset={1} stopColor="#c7d1d7" stopOpacity={0.5} />
      </linearGradient>
      <path
        opacity={0.3}
        clipPath="url(#Ghost4_svg__b)"
        fill="url(#Ghost4_svg__c)"
        d="M96.075 113.063l-62.52 38.494-74.451-120.918L21.625-7.855z"
      />
    </svg>
  );
}

export default SvgGhost4;
