import * as React from "react";

function SvgSpotLightN(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="164.799"
      height="462.907"
      viewBox="0 0 164.799 462.907"
      {...props}
    >
      <radialGradient
        id="a"
        cx="-1568.896"
        cy="-3083.52"
        r="39.818"
        gradientTransform="translate(3682.664 7209.671) scale(2.2962)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.022" stopColor="#402843"></stop>
        <stop offset="0.994" stopColor="#2a1331"></stop>
      </radialGradient>
      <path
        fill="url(#a)"
        d="M80.219 258.585l-1.61-.03L80.119 0l1.606.028z"
      ></path>
      <path
        fill="#745379"
        fillRule="evenodd"
        d="M101.857 258.557l-.433-5.527-33.338-4.689-12.965 9.01v6.808z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#724B6B"
        fillRule="evenodd"
        d="M47.459 267.911l2.213 63.619 16.172-1.708-.762-74.482z"
        clipRule="evenodd"
      ></path>
      <radialGradient
        id="b"
        cx="-2346.907"
        cy="-2995.933"
        r="14.797"
        gradientTransform="translate(5479.078 7209.671) scale(2.2962)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.022" stopColor="#402843"></stop>
        <stop offset="0.994" stopColor="#2a1331"></stop>
      </radialGradient>
      <path
        fill="url(#b)"
        fillRule="evenodd"
        d="M65.844 329.822l38.642 3.216 4.032-74.481-43.436-3.217z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#745379"
        fillRule="evenodd"
        d="M49.672 331.53l34.558.956 20.256.552-38.642-3.216z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M80.02 333.038h24.466l-38.642-3.216-16.172 1.708z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#511E4A"
        fillRule="evenodd"
        d="M68.086 248.341v6.999l33.771 3.217-.433-5.527z"
        clipRule="evenodd"
        opacity="0.5"
      ></path>
      <linearGradient
        id="c"
        x1="82.4"
        x2="82.4"
        y1="462.907"
        y2="331.53"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" stopOpacity="0"></stop>
        <stop offset="1" stopColor="#fff"></stop>
      </linearGradient>
      <path
        fill="url(#c)" className="lightOurStudio"
        style={{mixBlendMode: "soft-light"}}
        d="M101.857 332.483l62.942 130.424H0L49.672 331.53z"
        opacity="0.7"
      ></path>
    </svg>
  );
}

export default SvgSpotLightN;
