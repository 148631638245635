import * as React from "react";

function SvgRobot(props) {
  return (
    <svg width="223.46" height="196.608" viewBox="0 0 223.46 196.608" {...props}>
      <path
        d="M47.489 154.827h-5.37c.433 14.405 2.011 29.604 7.597 41.781h2.955l-5.182-41.781zm40.331 2.681l-5.009-1.101c-4.9-9.022-8.361-17.255-11.19-26.938h9.272s3.342 8.156 4.795 14.355c1.453 6.199 2.132 13.684 2.132 13.684z"
        fill="#943f98"
      />
      <path
        d="M123.991 146.379l1.479 9.136 6.711.122-5.416-12.727a6.982 6.982 0 00-2.774 3.469zm-17.997-7.526l15.918 1.38c-.504 1.904-1.595 3.621-3.034 5.225l-14.335 2.109c-.48-3.167-.161-6.117 1.451-8.714z"
        fill="#673090"
      />
      <linearGradient
        id="Robot_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={127.551}
        y1={137.529}
        x2={137.494}
        y2={150.265}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#cc7878" />
        <stop offset={0.762} stopColor="#662b84" />
        <stop offset={0.812} stopColor="#682c83" />
        <stop offset={0.851} stopColor="#6f3183" />
        <stop offset={0.887} stopColor="#7a3a82" />
        <stop offset={0.921} stopColor="#894681" />
        <stop offset={0.953} stopColor="#9e577f" />
        <stop offset={0.984} stopColor="#ba6b7b" />
        <stop offset={1} stopColor="#cc7878" />
      </linearGradient>
      <path
        d="M124.551 135.896l5.252 3.601c-1.853 5.087-5.038 8.911-9.448 11.572l-3.156-2.233c1.047-4.147 3.4-9.644 7.352-12.94z"
        fill="url(#Robot_svg__a)"
      />
      <path
        d="M124.551 135.896s-.637 3.105-2.889 6.838c-2.253 3.734-4.463 6.102-4.463 6.102.905-5.451 3.03-10.004 7.352-12.94z"
        fill="#673090"
      />
      <path
        d="M129.369 139.892c-.895 2.215-1.941 4.006-3.142 5.356l-4.316-2.338c1.396-2.101 2.538-4.146 2.976-6.041l4.482 3.023zm-1.847 6.516l3.133 7.638h-1.956l-2.066-6.745z"
        opacity={0.4}
        fill="#fff7cd"
      />
      <path
        d="M133.744 90.451l12.104 20.516 5.357-1.938-7.48-21.851c-3.838.307-7.236 1.291-9.981 3.273z"
        fill="#673090"
      />
      <linearGradient
        id="Robot_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={294.013}
        y1={-89.458}
        x2={340.937}
        y2={-89.458}
        gradientTransform="scale(-1 1) rotate(84.313 -39.523 -254.794)"
      >
        <stop offset={0} stopColor="#ffd586" />
        <stop offset={1} stopColor="#d15f5e" />
      </linearGradient>
      <path
        d="M133.937 112.194c16.394-3.197 33.386-9.387 50.732-17.347l17.359 40.46c-22.32 5.334-43.803 7.11-63.023-.717l-5.068-22.396z"
        fill="url(#Robot_svg__b)"
      />
      <linearGradient
        id="Robot_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={298.045}
        y1={-37.663}
        x2={318.692}
        y2={-74.408}
        gradientTransform="scale(-1 1) rotate(84.313 -39.523 -254.794)"
      >
        <stop offset={0} stopColor="#f5875d" />
        <stop offset={1} stopColor="#ab1e37" />
      </linearGradient>
      <path
        d="M184.668 94.848l18.002-8.844.976 10.228-13.702 10.911-5.276-12.295zm17.359 40.46c7.071-1.979 13.421-4.268 18.555-7.077l-5.722-6.799-17.453 3.107 4.62 10.769zm1.619-39.077l11.946 2.621 2.749-5.62-15.671-7.229.976 10.228zm16.937 31.999l2.878-17.059-5.378-.115-3.222 10.375 5.722 6.799z"
        fill="url(#Robot_svg__c)"
      />
      <path
        opacity={0.4}
        fill="#fff7cd"
        d="M138.938 90.295l4.536-.593 6.21 17.407-2.185.683z"
      />
      <path
        opacity={0.4}
        fill="#7e58a4"
        d="M150.968 138.179l1.277-12.81 19.439-4.832-4.154-18.345-.653.149 4.008 17.703-19.26 4.788-1.323 13.28z"
      />
      <path
        opacity={0.6}
        fill="#fff7cd"
        d="M150.272 138l1.398-13.184 19.268-4.729-4.12-17.62-.653.152 3.968 16.975-19.079 4.682-1.448 13.652z"
      />
      <path
        opacity={0.4}
        fill="#7e58a4"
        d="M179.596 139.338l1.763-21.92 10.835-5.056-.282-.607-11.189 5.22-1.795 22.31z"
      />
      <path
        opacity={0.6}
        fill="#fff7cd"
        d="M178.756 139.42l1.864-22.476 11.222-5.412-.29-.603-11.569 5.578-1.894 22.858z"
      />
      <path
        d="M133.937 112.194c7.488-1.46 15.103-3.552 22.817-6.144l4.673 18.602c10.996-1.092 22.152-3.229 33.42-6.082l7.181 16.737c-22.32 5.334-43.803 7.11-63.023-.717l-2.125-9.392-2.943-13.004z"
        opacity={0.3}
        fill="#b96154"
      />
      <path
        d="M66.809 10.604c-8.925 3.37-18.675 7.562-27.051 12.478C43.918 63.206 52.23 99.27 69.267 129.47h21.896L66.809 10.604z"
        fill="#943f98"
      />
      <path
        d="M43.46 40.766h12.707L73.5 111.097l10.171 2.071 3.98 12.909H70.573C56.821 101.51 47.095 73.051 43.46 40.766z"
        opacity={0.3}
        fill="#b96154"
      />
      <linearGradient
        id="Robot_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={123.32}
        y1={2.959}
        x2={180.966}
        y2={123.167}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#ffd586" />
        <stop offset={1} stopColor="#d15f5e" />
      </linearGradient>
      <path
        d="M149.031 11.719c-28.596-3.94-55.205-4.077-82.223-1.115 2.257 36.492 9.985 76.292 24.354 118.865h41.781c13.472-39.495 21.017-78.785 16.088-117.75z"
        fill="url(#Robot_svg__d)"
      />
      <linearGradient
        id="Robot_svg__e"
        gradientUnits="userSpaceOnUse"
        x1={137.74}
        y1={107.002}
        x2={151.61}
        y2={135.925}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#f5875d" />
        <stop offset={1} stopColor="#ab1e37" />
      </linearGradient>
      <path
        d="M137.141 116.509a436.243 436.243 0 01-4.197 12.96H91.163a549.85 549.85 0 01-4.194-12.96h50.172z"
        fill="url(#Robot_svg__e)"
      />
      <radialGradient
        id="Robot_svg__f"
        cx={508.831}
        cy={-2612.74}
        r={53.873}
        gradientTransform="matrix(-1.6527 0 0 1.6527 993.44 4363.532)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#61c19a" />
        <stop offset={0.227} stopColor="#60c09c" />
        <stop offset={0.399} stopColor="#5ebda0" />
        <stop offset={0.552} stopColor="#59b9a9" />
        <stop offset={0.694} stopColor="#52b3b5" />
        <stop offset={0.829} stopColor="#47acc3" />
        <stop offset={0.957} stopColor="#38a3d2" />
        <stop offset={1} stopColor="#2f9fd9" />
      </radialGradient>
      <path
        d="M150.544 35.849l-17.595 1.007-2.234 14.523h-15.864L109.8 36.855l-40.745-2.097c1.726 14.604 4.607 30.159 8.302 46.385h21.402l11.041 36.259h16.665l9.384-34.631 9.75.653c3.218-15.955 4.95-31.819 4.945-47.575z"
        fill="url(#Robot_svg__f)"
      />
      <path
        d="M78.427 79.803c-3.66-16.194-6.232-30.493-7.85-43.623l10.526.541c2.005 15.776 7.666 30.299 16.001 43.082H78.427zm48.431 31.019l-1.419 5.239h-14.646l-9.028-29.652c7.053 9.271 15.561 17.483 25.093 24.413z"
        opacity={0.15}
        fill="#d15f5e"
      />
      <linearGradient
        id="Robot_svg__g"
        gradientUnits="userSpaceOnUse"
        x1={111.064}
        y1={12.234}
        x2={115.086}
        y2={30.22}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#f5875d" />
        <stop offset={0.762} stopColor="#ab1e37" />
        <stop offset={0.818} stopColor="#ad2238" />
        <stop offset={0.863} stopColor="#b32d3b" />
        <stop offset={0.903} stopColor="#bc3e40" />
        <stop offset={0.941} stopColor="#cb5447" />
        <stop offset={0.977} stopColor="#df7052" />
        <stop offset={1} stopColor="#f5875d" />
      </linearGradient>
      <path
        d="M148.074 8.673s-10.902.717-11.824 10.541c-.922 9.823 10.576 11.206 10.576 11.206l1.248-21.747z"
        fill="url(#Robot_svg__g)"
      />
      <linearGradient
        id="Robot_svg__h"
        gradientUnits="userSpaceOnUse"
        x1={105.245}
        y1={5.951}
        x2={106.473}
        y2={29.745}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#ffd586" />
        <stop offset={1} stopColor="#d15f5e" />
      </linearGradient>
      <path
        d="M141.004 18.118c.531-6.015 3.896-9.481 7.744-9.481h2.773c3.849 0 6.938 4.396 6.408 10.411-.531 6.016-4.481 11.372-8.329 11.372h-2.773c-3.849 0-6.354-6.285-5.823-12.302z"
        fill="url(#Robot_svg__h)"
      />
      <path
        d="M144.077 18.179c-.508 5.761 1.89 11.781 5.575 11.781s7.469-5.131 7.978-10.892c.508-5.761-2.451-9.972-6.137-9.972s-6.908 3.322-7.416 9.083z"
        fill="#943f98"
      />
      <path
        d="M144.077 18.179c.393-4.452 2.408-7.438 5.003-8.569 3.351.495 5.914 4.527 5.438 9.92-.405 4.598-2.899 8.785-5.762 10.292-3.168-.885-5.145-6.366-4.679-11.643z"
        fill="#fff7cd"
      />
      <defs>
        <filter
          id="Robot_svg__i"
          filterUnits="userSpaceOnUse"
          x={147.153}
          y={11.564}
          width={6.962}
          height={15.297}
        >
          <feFlood result="back" floodColor="#fff" floodOpacity={1} />
          <feBlend in="SourceGraphic" in2="back" />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x={147.153}
        y={11.564}
        width={6.962}
        height={15.297}
        id="Robot_svg__j"
      >
        <g filter="url(#Robot_svg__i)">
          <linearGradient
            id="Robot_svg__k"
            gradientUnits="userSpaceOnUse"
            x1={99.173}
            y1={9.476}
            x2={111.629}
            y2={30.031}
            gradientTransform="matrix(-1 0 0 1 255.363 0)"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset={1} />
          </linearGradient>
          <path
            d="M150.053 26.861c2.069-1.384 3.724-4.589 4.016-7.88.358-4.062-1.437-6.869-3.469-7.417-1.865.999-3.124 3.369-3.394 6.433-.34 3.86.986 7.859 2.847 8.864z"
            mask="url(#Robot_svg__j)"
            fill="url(#Robot_svg__k)"
          />
        </g>
      </mask>
      <linearGradient
        id="Robot_svg__l"
        gradientUnits="userSpaceOnUse"
        x1={99.173}
        y1={9.476}
        x2={111.629}
        y2={30.031}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#fff7cd" />
        <stop offset={1} stopColor="#fff7cd" />
      </linearGradient>
      <path
        d="M150.053 26.861c2.069-1.384 3.724-4.589 4.016-7.88.358-4.062-1.437-6.869-3.469-7.417-1.865.999-3.124 3.369-3.394 6.433-.34 3.86.986 7.859 2.847 8.864z"
        fill="url(#Robot_svg__l)"
      />
      <path
        d="M147.813 18.09c.931-.529 1.677-1.756 1.808-3.015.161-1.554-.646-2.627-1.562-2.837-.839.383-1.406 1.288-1.527 2.46-.153 1.478.443 3.007 1.281 3.392z"
        opacity={0.75}
        fill="#fff7cd"
      />
      <linearGradient
        id="Robot_svg__m"
        gradientUnits="userSpaceOnUse"
        x1={176.684}
        y1={12.148}
        x2={180.706}
        y2={30.136}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#f5875d" />
        <stop offset={0.762} stopColor="#ab1e37" />
        <stop offset={0.818} stopColor="#ad2238" />
        <stop offset={0.863} stopColor="#b32d3b" />
        <stop offset={0.903} stopColor="#bc3e40" />
        <stop offset={0.941} stopColor="#cb5447" />
        <stop offset={0.977} stopColor="#df7052" />
        <stop offset={1} stopColor="#f5875d" />
      </linearGradient>
      <path
        d="M82.455 8.588s-10.902.717-11.824 10.54 10.576 11.208 10.576 11.208l1.248-21.748z"
        fill="url(#Robot_svg__m)"
      />
      <linearGradient
        id="Robot_svg__n"
        gradientUnits="userSpaceOnUse"
        x1={170.865}
        y1={5.867}
        x2={172.094}
        y2={29.662}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#ffd586" />
        <stop offset={1} stopColor="#d15f5e" />
      </linearGradient>
      <path
        d="M75.385 18.035c.531-6.016 3.895-9.483 7.744-9.483h2.773c3.849 0 6.938 4.396 6.407 10.411-.531 6.017-4.481 11.373-8.33 11.373h-2.773c-3.848 0-6.352-6.287-5.821-12.301z"
        fill="url(#Robot_svg__n)"
      />
      <path
        d="M78.457 18.094c-.509 5.762 1.89 11.781 5.575 11.781 3.685 0 7.468-5.13 7.977-10.891.508-5.762-2.451-9.972-6.136-9.972s-6.908 3.32-7.416 9.082z"
        fill="#943f98"
      />
      <path
        d="M78.457 18.094c.393-4.451 2.408-7.438 5.002-8.569 3.352.495 5.915 4.527 5.438 9.919-.406 4.599-2.898 8.787-5.762 10.293-3.167-.885-5.144-6.366-4.678-11.643z"
        fill="#fff7cd"
      />
      <defs>
        <filter
          id="Robot_svg__o"
          filterUnits="userSpaceOnUse"
          x={81.533}
          y={11.479}
          width={6.961}
          height={15.297}
        >
          <feFlood result="back" floodColor="#fff" floodOpacity={1} />
          <feBlend in="SourceGraphic" in2="back" />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x={81.533}
        y={11.479}
        width={6.961}
        height={15.297}
        id="Robot_svg__p"
      >
        <g filter="url(#Robot_svg__o)">
          <linearGradient
            id="Robot_svg__q"
            gradientUnits="userSpaceOnUse"
            x1={164.794}
            y1={9.392}
            x2={177.249}
            y2={29.945}
            gradientTransform="matrix(-1 0 0 1 255.363 0)"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset={1} />
          </linearGradient>
          <path
            d="M84.433 26.776c2.07-1.384 3.725-4.589 4.016-7.88.358-4.062-1.436-6.868-3.469-7.417-1.864.998-3.124 3.368-3.393 6.432-.342 3.861.985 7.861 2.846 8.865z"
            mask="url(#Robot_svg__p)"
            fill="url(#Robot_svg__q)"
          />
        </g>
      </mask>
      <linearGradient
        id="Robot_svg__r"
        gradientUnits="userSpaceOnUse"
        x1={164.794}
        y1={9.392}
        x2={177.249}
        y2={29.945}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#fff7cd" />
        <stop offset={1} stopColor="#fff7cd" />
      </linearGradient>
      <path
        d="M84.433 26.776c2.07-1.384 3.725-4.589 4.016-7.88.358-4.062-1.436-6.868-3.469-7.417-1.864.998-3.124 3.368-3.393 6.432-.342 3.861.985 7.861 2.846 8.865z"
        fill="url(#Robot_svg__r)"
      />
      <path
        d="M82.193 18.006c.931-.53 1.676-1.756 1.807-3.016.162-1.553-.646-2.626-1.561-2.836-.839.383-1.406 1.288-1.528 2.459-.153 1.479.444 3.008 1.282 3.393z"
        opacity={0.75}
        fill="#fff7cd"
      />
      <linearGradient
        id="Robot_svg__s"
        gradientUnits="userSpaceOnUse"
        x1={108.023}
        y1={85.787}
        x2={181.863}
        y2={85.787}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#61c19a" />
        <stop offset={0.227} stopColor="#60c09c" />
        <stop offset={0.399} stopColor="#5ebda0" />
        <stop offset={0.552} stopColor="#59b9a9" />
        <stop offset={0.694} stopColor="#52b3b5" />
        <stop offset={0.829} stopColor="#47acc3" />
        <stop offset={0.957} stopColor="#38a3d2" />
        <stop offset={1} stopColor="#2f9fd9" />
      </linearGradient>
      <path
        d="M73.5 55.513h16.657l1.899 18.433 5.049-.67v-5.027l9.304-.096 1.176 21.321h3.39V76.081h8.564l.447 7.806 8.156-.558 4.021-18.768h15.178a256.521 256.521 0 01-2.827 17.446l-9.671-.648-9.403 34.702h-14.646l-11.04-36.259H78.427c-1.943-8.595-3.576-16.654-4.927-24.289z"
        opacity={0.3}
        fill="url(#Robot_svg__s)"
      />
      <path
        d="M132.551 89.816c-9.001-17.666-18.946-34.94-29.417-51.961l5.697.292 5.068 14.571h17.967l2.203-14.323c3.753 14.214 6.382 28.81 8.494 43.482l-7.72-.519-2.292 8.458zM87.652 37.058c14.084 22.777 28.075 46.179 40.115 70.415l3.371-12.438c-9.674-19.445-20.501-38.237-31.034-57.337l-12.452-.64z"
        opacity={0.4}
        fill="#fff7cd"
      />
      <linearGradient
        id="Robot_svg__t"
        gradientUnits="userSpaceOnUse"
        x1={120.888}
        y1={109.689}
        x2={121.757}
        y2={126.515}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#ffd586" />
        <stop offset={1} stopColor="#d15f5e" />
      </linearGradient>
      <path
        d="M128.029 118.293c.376-4.254 2.755-6.705 5.477-6.705h1.961c2.722 0 4.906 3.108 4.531 7.362-.375 4.255-3.169 8.042-5.89 8.042h-1.961c-2.721 0-4.493-4.444-4.118-8.699z"
        fill="url(#Robot_svg__t)"
      />
      <path
        d="M130.203 118.336c-.36 4.073 1.336 8.332 3.942 8.332s5.282-3.629 5.641-7.703c.36-4.074-1.732-7.051-4.339-7.051-2.606 0-4.885 2.348-5.244 6.422z"
        fill="#943f98"
      />
      <path
        d="M130.203 118.336c.278-3.148 1.703-5.259 3.538-6.06 2.37.35 4.182 3.201 3.845 7.015-.287 3.251-2.049 6.213-4.074 7.278-2.241-.626-3.638-4.501-3.309-8.233z"
        fill="#fff7cd"
      />
      <defs>
        <filter
          id="Robot_svg__u"
          filterUnits="userSpaceOnUse"
          x={132.377}
          y={113.658}
          width={4.923}
          height={10.818}
        >
          <feFlood result="back" floodColor="#fff" floodOpacity={1} />
          <feBlend in="SourceGraphic" in2="back" />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x={132.377}
        y={113.658}
        width={4.923}
        height={10.818}
        id="Robot_svg__v"
      >
        <g filter="url(#Robot_svg__u)">
          <linearGradient
            id="Robot_svg__w"
            gradientUnits="userSpaceOnUse"
            x1={116.594}
            y1={112.181}
            x2={125.403}
            y2={126.718}
            gradientTransform="matrix(-1 0 0 1 255.363 0)"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset={1} />
          </linearGradient>
          <path
            d="M134.428 124.477c1.464-.979 2.635-3.246 2.84-5.572.253-2.873-1.015-4.858-2.453-5.246-1.318.706-2.208 2.383-2.4 4.549-.241 2.73.698 5.558 2.013 6.269z"
            mask="url(#Robot_svg__v)"
            fill="url(#Robot_svg__w)"
          />
        </g>
      </mask>
      <linearGradient
        id="Robot_svg__x"
        gradientUnits="userSpaceOnUse"
        x1={116.594}
        y1={112.181}
        x2={125.403}
        y2={126.718}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#fff7cd" />
        <stop offset={1} stopColor="#fff7cd" />
      </linearGradient>
      <path
        d="M134.428 124.477c1.464-.979 2.635-3.246 2.84-5.572.253-2.873-1.015-4.858-2.453-5.246-1.318.706-2.208 2.383-2.4 4.549-.241 2.73.698 5.558 2.013 6.269z"
        fill="url(#Robot_svg__x)"
      />
      <path
        d="M132.845 118.273c.659-.375 1.186-1.241 1.277-2.132.115-1.099-.457-1.856-1.103-2.007-.594.271-.994.912-1.081 1.74-.107 1.046.314 2.128.907 2.399z"
        opacity={0.75}
        fill="#fff7cd"
      />
      <linearGradient
        id="Robot_svg__y"
        gradientUnits="userSpaceOnUse"
        x1={157.018}
        y1={108.978}
        x2={157.887}
        y2={125.803}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#ffd586" />
        <stop offset={1} stopColor="#d15f5e" />
      </linearGradient>
      <path
        d="M91.9 117.582c.375-4.253 2.754-6.705 5.476-6.705h1.961c2.721 0 4.906 3.108 4.53 7.362-.375 4.254-3.169 8.042-5.89 8.042h-1.961c-2.72 0-4.491-4.443-4.116-8.699z"
        fill="url(#Robot_svg__y)"
      />
      <path
        d="M94.073 117.625c-.359 4.073 1.336 8.331 3.943 8.331 2.605 0 5.281-3.627 5.641-7.702.359-4.074-1.733-7.051-4.339-7.051-2.608 0-4.886 2.348-5.245 6.422z"
        fill="#943f98"
      />
      <path
        d="M94.073 117.625c.278-3.148 1.703-5.258 3.538-6.059 2.37.349 4.182 3.201 3.845 7.014-.287 3.251-2.05 6.213-4.075 7.278-2.239-.627-3.637-4.502-3.308-8.233z"
        fill="#fff7cd"
      />
      <defs>
        <filter
          id="Robot_svg__z"
          filterUnits="userSpaceOnUse"
          x={96.248}
          y={112.947}
          width={4.923}
          height={10.817}
        >
          <feFlood result="back" floodColor="#fff" floodOpacity={1} />
          <feBlend in="SourceGraphic" in2="back" />
        </filter>
      </defs>
      <mask
        maskUnits="userSpaceOnUse"
        x={96.248}
        y={112.947}
        width={4.923}
        height={10.817}
        id="Robot_svg__A"
      >
        <g filter="url(#Robot_svg__z)">
          <linearGradient
            id="Robot_svg__B"
            gradientUnits="userSpaceOnUse"
            x1={152.724}
            y1={111.47}
            x2={161.533}
            y2={126.006}
            gradientTransform="matrix(-1 0 0 1 255.363 0)"
          >
            <stop offset={0} stopColor="#fff" />
            <stop offset={1} />
          </linearGradient>
          <path
            d="M98.299 123.765c1.463-.979 2.634-3.245 2.839-5.572.254-2.872-1.015-4.857-2.453-5.245-1.318.706-2.208 2.382-2.4 4.55-.24 2.729.698 5.557 2.014 6.267z"
            mask="url(#Robot_svg__A)"
            fill="url(#Robot_svg__B)"
          />
        </g>
      </mask>
      <linearGradient
        id="Robot_svg__C"
        gradientUnits="userSpaceOnUse"
        x1={152.724}
        y1={111.47}
        x2={161.533}
        y2={126.006}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#fff7cd" />
        <stop offset={1} stopColor="#fff7cd" />
      </linearGradient>
      <path
        d="M98.299 123.765c1.463-.979 2.634-3.245 2.839-5.572.254-2.872-1.015-4.857-2.453-5.245-1.318.706-2.208 2.382-2.4 4.55-.24 2.729.698 5.557 2.014 6.267z"
        fill="url(#Robot_svg__C)"
      />
      <path
        d="M96.715 117.563c.659-.376 1.186-1.242 1.278-2.133.114-1.099-.458-1.858-1.104-2.006-.593.27-.994.911-1.081 1.739-.107 1.046.315 2.127.907 2.4z"
        opacity={0.75}
        fill="#fff7cd"
      />
      <path
        opacity={0.6}
        fill="#fff7cd"
        d="M133.27 30.95l.424-21.024-.671-.013-.411 20.365-25.674-.069-10.305-21.72-.606.287 10.487 22.102z"
      />
      <path
        opacity={0.4}
        fill="#7e58a4"
        d="M107.038 30.21h26.911l.362-20.228-.671-.011-.349 19.569H107.46L97.407 8.407l-.605.289zm-22.149 79.317l12.613-5.135-3.845-14.911H79.458v.671h13.679l3.569 13.84-12.07 4.915zm54.737-1.765l.342-.578-5.339-3.156 2.78-12.421h6.511v-.67h-7.047l-3.005 13.419z"
      />
      <path
        opacity={0.6}
        fill="#fff7cd"
        d="M139.385 108.498l.356-.568-5.914-3.699 2.763-11.93h7.172v-.671h-7.704l-2.991 12.916zm-54.272 1.788l12.048-4.933-3.938-15.044H79.667v.67h13.039l3.658 13.976-11.505 4.711z"
      />
      <linearGradient
        id="Robot_svg__D"
        gradientUnits="userSpaceOnUse"
        x1={155.477}
        y1={160.078}
        x2={155.477}
        y2={128.596}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#f5875d" />
        <stop offset={1} stopColor="#ab1e37" />
      </linearGradient>
      <path
        d="M118.877 129.469a126.274 126.274 0 01-11.618 28.039c-6.788.805-13.335.984-19.439 0-1.266-9.346-3.575-18.692-6.926-28.039h37.983z"
        fill="url(#Robot_svg__D)"
      />
      <linearGradient
        id="Robot_svg__E"
        gradientUnits="userSpaceOnUse"
        x1={205.088}
        y1={67.975}
        x2={194.363}
        y2={107.858}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#f5875d" />
        <stop offset={0.762} stopColor="#ab1e37" />
        <stop offset={0.818} stopColor="#ad2238" />
        <stop offset={0.863} stopColor="#b32d3b" />
        <stop offset={0.903} stopColor="#bc3e40" />
        <stop offset={0.941} stopColor="#cb5447" />
        <stop offset={0.977} stopColor="#df7052" />
        <stop offset={1} stopColor="#f5875d" />
      </linearGradient>
      <path
        d="M50.778 65.901c14.325 6.425 19.656 20.058 17.26 39.884l-11.874 3.434c-1.99-14.178-8.737-28.57-16.406-34.771l11.02-8.547z"
        fill="url(#Robot_svg__E)"
      />
      <path
        d="M39.757 74.448c2.298 14.328 7.768 25.919 16.406 34.771 0 0 .304-9.021-5.051-20.359-4.272-9.05-11.355-14.412-11.355-14.412z"
        fill="#ab1e37"
      />
      <path
        d="M81.778 138.853l-19.327 1.675 1.118 5.586 19.662 1.453c.477-3.167.16-6.117-1.453-8.714z"
        fill="#673090"
      />
      <linearGradient
        id="Robot_svg__F"
        gradientUnits="userSpaceOnUse"
        x1={195.012}
        y1={136.37}
        x2={186.13}
        y2={151.117}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#cc7878" />
        <stop offset={0.762} stopColor="#662b84" />
        <stop offset={0.812} stopColor="#682c83" />
        <stop offset={0.851} stopColor="#6f3183" />
        <stop offset={0.887} stopColor="#7a3a82" />
        <stop offset={0.921} stopColor="#894681" />
        <stop offset={0.953} stopColor="#9e577f" />
        <stop offset={0.984} stopColor="#ba6b7b" />
        <stop offset={1} stopColor="#cc7878" />
      </linearGradient>
      <path
        d="M67.417 151.069l3.156-2.233c-1.046-4.146-3.399-9.644-7.352-12.939l-5.253 3.601c3.659 3.663 7.571 8.152 9.449 11.571z"
        fill="url(#Robot_svg__F)"
      />
      <path
        d="M57.969 139.497c1.854 5.087 5.038 8.911 9.449 11.572-1.982-4.983-5.132-8.842-9.449-11.572z"
        fill="#673090"
      />
      <path
        d="M63.782 146.379l-1.479 9.136-6.711.122 5.415-12.727a6.979 6.979 0 012.775 3.469z"
        fill="#673090"
      />
      <linearGradient
        id="Robot_svg__G"
        gradientUnits="userSpaceOnUse"
        x1={183.311}
        y1={175.718}
        x2={208.043}
        y2={175.718}
        gradientTransform="matrix(-1 0 0 1 255.363 0)"
      >
        <stop offset={0} stopColor="#ffd586" />
        <stop offset={1} stopColor="#d15f5e" />
      </linearGradient>
      <path
        d="M71.722 155.246l-24.232-.419c-.718 14.805.822 28.799 5.183 41.781h16.68c2.621-13.478 3.149-27.31 2.369-41.362z"
        fill="url(#Robot_svg__G)"
      />
      <path
        d="M65.46 85.786l-11.287 4.03c-3.152-6.158-7.29-11.348-12.613-15.368l9.831-7.093c7.2 4.359 11.717 10.624 14.069 18.431z"
        opacity={0.3}
        fill="#fff7cd"
      />
      <g>
        <path
          d="M46.252 86.681l-19.214 14.076 2.457 5.14 21.003-9.607c-.685-3.79-1.999-7.073-4.246-9.609z"
          fill="#673090"
        />
        <linearGradient
          id="Robot_svg__H"
          gradientUnits="userSpaceOnUse"
          x1={208.44}
          y1={124.05}
          x2={255.363}
          y2={124.05}
          gradientTransform="matrix(-1 0 0 1 255.363 0)"
        >
          <stop offset={0} stopColor="#ffd586" />
          <stop offset={1} stopColor="#d15f5e" />
        </linearGradient>
        <path
          d="M24.636 89.026c4.806 15.996 12.648 32.292 22.288 48.765L8.382 159.073c-7.518-21.684-11.414-42.884-5.53-62.784l21.784-7.263z"
          fill="url(#Robot_svg__H)"
        />
        <linearGradient
          id="Robot_svg__I"
          gradientUnits="userSpaceOnUse"
          x1={212.474}
          y1={175.846}
          x2={233.121}
          y2={139.101}
          gradientTransform="matrix(-1 0 0 1 255.363 0)"
        >
          <stop offset={0} stopColor="#f5875d" />
          <stop offset={1} stopColor="#ab1e37" />
        </linearGradient>
        <path
          d="M46.924 137.791l10.583 17.036-10.081 1.984-12.216-12.553 11.714-6.467zM8.382 159.073c2.67 6.84 5.577 12.931 8.882 17.763l6.198-6.367-4.823-17.06-10.257 5.664zm39.044-2.261l-1.425 12.148 5.866 2.177 5.64-16.31-10.081 1.985zm-30.162 20.024l17.26 1.173-.419-5.363-10.643-2.177-6.198 6.367z"
          fill="url(#Robot_svg__I)"
        />
        <path
          opacity={0.4}
          fill="#fff7cd"
          d="M46.924 91.834l1.038 4.455-16.707 7.903-.895-2.106z"
        />
        <path
          opacity={0.4}
          fill="#7e58a4"
          d="M20.074 127.415l17.842-5.95-.212-.636-17.219 5.743-6.672-18.693H.467v.672h12.874z"
        />
        <path
          opacity={0.6}
          fill="#fff7cd"
          d="M20.448 126.627l17.126-5.844-.217-.636-16.499 5.633-6.549-18.523-13.728-.089-.004.671 13.256.085z"
        />
        <path
          opacity={0.4}
          fill="#7e58a4"
          d="M30.241 147.015l.575-.342-6.302-10.618-22.377.424.013.671 21.987-.416z"
        />
        <path
          opacity={0.6}
          fill="#fff7cd"
          d="M31.032 146.582l.572-.348-6.698-10.96-22.933.379.011.67 22.551-.372z"
        />
        <path
          d="M24.325 150.269l-15.943 8.805C.864 137.39-3.032 116.19 2.852 96.29l9.134-3.046c.684 18.822 5.405 37.863 12.339 57.025z"
          opacity={0.3}
          fill="#b96154"
        />
      </g>
      <path
        opacity={0.3}
        fill="#b96154"
        d="M118.877 129.469H80.894l3.607 11.541 31.354-2.157z"
      />
      <path
        opacity={0.6}
        fill="#fff7cd"
        d="M95.959 153.794h5.453l3.574-10.014 9.804-1.643-.111-.661-10.192 1.71-3.548 9.938h-4.351l-.669-10.519-11.172-1.131-.068.666 10.609 1.077z"
      />
      <path
        opacity={0.4}
        fill="#7e58a4"
        d="M96.583 154.38h5.419l4.045-11.712 9.046-1.326-.096-.663-9.45 1.385-4.022 11.647h-4.309l-.65-11.646-12.025-1.388-.077.667 11.465 1.322z"
      />
      <path
        opacity={0.4}
        fill="#fff7cd"
        d="M81.611 141.619v4.789l-10.473-.658-1.257-3.383zm-17.829-4.414c1.783 1.697 3.277 3.884 4.549 6.451l-3.142 1.626-5.027-5.313 3.62-2.764z"
      />
      <path
        opacity={0.6}
        fill="#fff7cd"
        d="M69.615 194.348l.407-.534-9.609-7.332-.027-16.304 11.79-4.676-.247-.624-12.215 4.846.029 17.091z"
      />
      <path
        opacity={0.4}
        fill="#7e58a4"
        d="M69.679 193.441l.404-.535-9-6.771v-16.947l11.08-4.444-.25-.622-11.5 4.61v17.739z"
      />
      <path
        d="M124.556 155.254h-7.723c-1.03 14.258-.995 29.303 3.354 41.354h2.956l1.413-41.354z"
        fill="#943f98"
      />
      <linearGradient
        id="Robot_svg__J"
        gradientUnits="userSpaceOnUse"
        x1={82.456}
        y1={175.718}
        x2={115.404}
        y2={175.718}
        gradientTransform="matrix(-1 0 -.1026 1 250.41 0)"
      >
        <stop offset={0} stopColor="#ffd586" />
        <stop offset={1} stopColor="#d15f5e" />
      </linearGradient>
      <path
        d="M123.479 155.246l24.275-.419c-.8 14.805-3.776 28.799-9.469 41.781h-16.679c-1.238-13.478-.349-27.31 1.873-41.362z"
        fill="url(#Robot_svg__J)"
      />
      <path
        opacity={0.6}
        fill="#fff7cd"
        d="M121.594 194.355l10.626-7.521 1.783-17.098-11.746-4.857-.257.619 11.284 4.667-1.699 16.298-10.378 7.345z"
      />
      <path
        opacity={0.4}
        fill="#7e58a4"
        d="M121.622 193.448l9.963-6.958 1.821-17.744-11.055-4.622-.259.618 10.596 4.431-1.738 16.944-9.712 6.783z"
      />
      <g fill="#d15f5e">
        <path d="M119.148 13.599h-9.257v4.49l-.034.001.034.664v.672h.034l.221 4.317h8.957v-1.352h-7.051l-.153-2.982-.034-1.351V14.95h7.283zm4.329 1.354l5.989.302.145-1.348-8.145-.41.077 1.376z" />
        <path d="M126.296 23.793h-1.944l.292-9.981h2.083z" />
      </g>
    </svg>
  );
}

export default SvgRobot;
