import * as React from "react";

function SvgLampshades2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1039.694"
      height="587.971"
      viewBox="0 161.05 1039.694 587.971"
    >
      <linearGradient
        id="aaaa"
        x1="-609.283"
        x2="-609.283"
        y1="471.398"
        y2="549.206"
        gradientTransform="scale(.645 -.645) rotate(73.192 777.091 151.583)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9f3040"></stop>
        <stop offset="1" stopColor="#e05b2b"></stop>
      </linearGradient>
      <path
        fill="url(#aaaa)"
        d="M.261 676.059l37.765 33.303s2.677 1.284 3.642-.042c1.66-2.281-.376-4.271-.376-4.271L3.305 672.942s-1.785-.973-2.858.451c-.882 1.172-.186 2.665-.186 2.666z"
      ></path>
      <linearGradient
        id="bbbb"
        x1="-610.459"
        x2="-610.459"
        y1="471.796"
        y2="548.998"
        gradientTransform="scale(.645 -.645) rotate(73.192 777.091 151.583)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f4774d"></stop>
        <stop offset="1" stopColor="#f8983a"></stop>
      </linearGradient>
      <path
        fill="url(#bbbb)"
        d="M.583 676.593l37.778 33.063s1.832 1.088 2.994-.069-.524-3.013-.524-3.013L2.286 673.997s-1.247-.726-2.009.261c-.763.987.306 2.335.306 2.335z"
      ></path>
      <linearGradient
        id="cccc"
        x1="-1309.25"
        x2="-1309.25"
        y1="131.329"
        y2="145.145"
        gradientTransform="scale(.645 -.645) rotate(31.637 1351.401 1967.323)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f4774d"></stop>
        <stop offset="1" stopColor="#f8983a"></stop>
      </linearGradient>
      <path
        fill="url(#cccc)"
        d="M39.316 629.591l-20.838 7.481s-1.813 1.078.663 4.064l21.398-7.189-1.223-4.356z"
      ></path>
      <linearGradient
        id="dddd"
        x1="-1307.022"
        x2="-1307.022"
        y1="138.075"
        y2="145.147"
        gradientTransform="scale(.645 -.645) rotate(31.637 1351.401 1967.323)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9f3040"></stop>
        <stop offset="1" stopColor="#e05b2b"></stop>
      </linearGradient>
      <path
        fill="url(#dddd)"
        d="M18.478 637.072l2.365-1.458 19.145-7.255-1.132 1.515-20.378 7.198z"
      ></path>
      <linearGradient
        id="eeee"
        x1="-2821.924"
        x2="-2775.321"
        y1="1624.77"
        y2="1624.77"
        gradientTransform="matrix(.2246 -.6046 -.6046 -.2248 1660.757 -703.808)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#63203c"></stop>
        <stop offset="1" stopColor="#7d3c34"></stop>
      </linearGradient>
      <path
        fill="url(#eeee)"
        d="M62.638 638.297s-8.434 5.131-23.269-3.917l-2.098-1.709s-7.536-17.169 2.401-29.369l22.966 34.995z"
      ></path>
      <linearGradient
        id="ffff"
        x1="-2818.305"
        x2="-2769.466"
        y1="1623.439"
        y2="1623.439"
        gradientTransform="matrix(.2246 -.6046 -.6046 -.2248 1660.757 -703.808)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f6d8bf"></stop>
        <stop offset="1" stopColor="#f1bfbd"></stop>
      </linearGradient>
      <path
        fill="url(#ffff)"
        d="M55.566 607.24c2.089 1.949 4.082 4.363 5.818 7.147 6.122 9.828 6.681 20.536 1.254 23.908-1.825 1.137-4.098 1.298-6.544.625-2.467-.656-5.114-2.173-7.653-4.367 0 0-.02-.015-.057-.053-.308-.26-.605-.53-.906-.812-2.069-1.935-4.026-4.327-5.742-7.077-1.501-2.418-2.671-4.887-3.493-7.289-.988-2.867-1.487-5.638-1.485-8.097-.004-3.861 1.221-6.972 3.724-8.523 3.89-2.421 9.802-.417 15.084 4.538z"
      ></path>
      <path
        fill="#FFF"
        d="M46.764 617.854c4.453 1.656 6.728 6.607 5.072 11.062a8.598 8.598 0 01-4.358 4.772c-2.069-1.935-4.026-4.327-5.742-7.077-1.501-2.417-2.671-4.887-3.493-7.289a8.604 8.604 0 018.521-1.468z"
      ></path>
      <linearGradient
        id="gggg"
        x1="-2807.493"
        x2="-2807.493"
        y1="1597.854"
        y2="1618.49"
        gradientTransform="matrix(.2246 -.6046 -.6046 -.2248 1660.757 -703.808)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#010101"></stop>
        <stop offset="1" stopColor="#b39d75"></stop>
      </linearGradient>
      <path
        fill="url(#gggg)"
        style={{ mixBlendMode: "screen" }}
        d="M49.75 634.535l11.415 3.527s3.567-2.077 3.862-8.544l-12.954 1.467s-1.467 3.143-2.323 3.55z"
        opacity="0.8"
      ></path>
      <linearGradient
        id="hhhh"
        x1="-2799.424"
        x2="-2799.424"
        y1="1598.82"
        y2="1617.645"
        gradientTransform="matrix(.2246 -.6046 -.6046 -.2248 1660.757 -703.808)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#010101"></stop>
        <stop offset="1" stopColor="#b39d75"></stop>
      </linearGradient>
      <path
        fill="url(#hhhh)"
        style={{ mixBlendMode: "screen" }}
        d="M52.441 630.186s.776-1.803 1.008-4.04l11.184-2.529.876 4.937-13.068 1.632z"
        opacity="0.8"
      ></path>
      <linearGradient
        id="iiii"
        x1="-2815.954"
        x2="-2769.466"
        y1="1635.947"
        y2="1635.947"
        gradientTransform="matrix(.2246 -.6046 -.6046 -.2248 1660.757 -703.808)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f7aab3"></stop>
        <stop offset="1" stopColor="#cb8190"></stop>
      </linearGradient>
      <path
        fill="url(#iiii)"
        d="M55.566 607.24s-10.051-2.031-13.115 8.122c-2.922 9.687 5.165 18.345 5.934 19.139-.308-.26-.605-.53-.906-.812-2.069-1.935-4.026-4.327-5.742-7.077-1.501-2.418-2.671-4.887-3.493-7.289-.988-2.867-1.487-5.638-1.485-8.097-.004-3.861 1.221-6.972 3.724-8.523 3.889-2.422 9.801-.418 15.083 4.537z"
        opacity="0.4"
      ></path>
      <linearGradient
        id="jjjj"
        x1="799.477"
        x2="799.477"
        y1="89.012"
        y2="166.819"
        gradientTransform="matrix(.645 0 0 -.645 -501.355 740.408)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9f3040"></stop>
        <stop offset="1" stopColor="#e05b2b"></stop>
      </linearGradient>
      <path
        fill="url(#jjjj)"
        d="M22.857 633.537L1.895 679.318s-.456 2.933 1.092 3.474c2.665.928 3.979-1.594 3.979-1.594l19.752-45.648s.416-1.99-1.257-2.604c-1.377-.508-2.604.591-2.604.591z"
      ></path>
      <linearGradient
        id="kkkk"
        x1="798.304"
        x2="798.304"
        y1="89.409"
        y2="166.613"
        gradientTransform="matrix(.645 0 0 -.645 -501.355 740.408)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f4774d"></stop>
        <stop offset="1" stopColor="#f8983a"></stop>
      </linearGradient>
      <path
        fill="url(#kkkk)"
        d="M22.438 634L1.71 679.725s-.512 2.068.931 2.847c1.444.777 2.733-1.373 2.733-1.373l20.043-46.319s.334-1.405-.832-1.848c-1.166-.445-2.147.968-2.147.968z"
      ></path>
      <linearGradient
        id="llll"
        x1="829.261"
        x2="829.261"
        y1="46.052"
        y2="56.524"
        gradientTransform="matrix(.645 0 0 -.645 -501.355 740.408)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#f4774d"></stop>
        <stop offset="1" stopColor="#f8983a"></stop>
      </linearGradient>
      <path
        fill="url(#llll)"
        d="M20.306 710.705l.854-6.754h24.317l1.233 6.754H20.306z"
      ></path>
      <linearGradient
        id="mmmm"
        x1="856.997"
        x2="856.997"
        y1="46.052"
        y2="56.524"
        gradientTransform="matrix(.645 0 0 -.645 -501.355 740.408)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#9f3040"></stop>
        <stop offset="1" stopColor="#e05b2b"></stop>
      </linearGradient>
      <path
        fill="url(#mmmm)"
        d="M45.478 703.951h10.993l.848 6.754H46.711l-1.233-6.754z"
      ></path>
      <linearGradient xmlns="http://www.w3.org/2000/svg" id="nnnn" gradientUnits="userSpaceOnUse" x1="218.533" y1="185.9766" x2="-1278.8345" y2="184.9068" gradientTransform="matrix(-.2493 .2725 -.2725 -.2493 292.7634 670.9067)">
        <stop offset="0" stopColor="#fff" stopOpacity=".8" />
        <stop offset=".4273" stopColor="#fff" stopOpacity=".1" />
        <stop offset=".8065" stopColor="#fff" stopOpacity="0" />
      </linearGradient>
      <path
        fill="url(#nnnn)" className="lightOurTeam"
        style={{ mixBlendMode: "soft-light" }}
        d="M40.564 616.489c-.522 9.176 7.677 18.136 7.677 18.136l991.453 114.396V161.05H478.722S43.698 613.055 40.564 616.489z"
        opacity="0.6"
      ></path>
    </svg>
  );
}

export default SvgLampshades2;
