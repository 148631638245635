import * as React from "react";

function SvgOfficeFooter(props) {
  return (
    <svg
      width="1040.613"
      height="290.261"
      viewBox="0 0 1040.613 290.261"
      {...props}
    >
      <path
        fill="#3b140f"
        d="M430.967 229.729l69.169 1.36-18.261-88.883h-2.018l14.72 85.287h-61.593z"
      />
      <linearGradient
        id="OfficeFooter_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={457.353}
        y1={149.794}
        x2={457.353}
        y2={51.034}
      >
        <stop offset={0} stopColor="#c9eaf3" />
        <stop offset={1} stopColor="#79d2f3" />
      </linearGradient>
      <path
        d="M505.146 119.073l-.162.297s-.013.014-.026.055c-.27.482-.565 1.021-.875 1.586-22.431 40.369-78.504 36.332-91.058 0-3.055-8.84-4.778-17.506-5.356-25.566-1.817-25.016 7.333-44.109 21.503-44.404 21.449-.443 32.241 22.404 48.429 39.52a85.833 85.833 0 005.732 5.559c21.813 19.133 27.639 12.256 21.813 22.953z"
        fill="url(#OfficeFooter_svg__a)"
      />
      <path
        d="M505.146 119.073l-.162.297s-.013.014-.026.055c-.202.349-1.454 2.502-3.97 5.193-6.189 6.674-20.117 16.727-45.064 11.357-17.911-3.836-29.927-13.887-37.503-23.012-7.657-9.23-10.751-17.52-10.751-17.52s8.046-8.41 41.014-9.418c10.2-.311 20.104 1.654 28.918 4.533a85.833 85.833 0 005.732 5.559c21.812 19.136 27.638 12.259 21.812 22.956z"
        opacity={0.73}
        fill="#74b1e1"
      />
      <path
        d="M500.988 124.618c-6.189 6.674-20.117 16.727-45.064 11.357-17.911-3.836-29.927-13.887-37.503-23.012 0 0 20.844-8.1 53.368 1.992 14.235 4.415 23.399 7.55 29.199 9.663z"
        fill="#74b1e1"
      />
      <path
        fill="#782b2d"
        d="M440.946 142.206l.561 96.324-12.224-8.617 4.503-85.353 2.263 1.078-3.065 81.855 6.456 6.342-1.746-91.629z"
      />
      <path
        d="M1040.613 216.128v13.496c-78.568 9.465-147.462 22.699-147.462 22.699l-202.875 13.926-275.956 24.012h-69.343L0 246.105l488.313-24.179 124.468-.744 194.898 5.193 114.268-11.158c.001.001 37.051-.075 118.666.911z"
        opacity={0.2}
        fill="#3b140f"
      />
      <path
        d="M671.15 39.005l2.842-18.775-3.518 8.813.885-14.648-2.025 10.016.318-8.078-4.84 12.584 1.004-9.188-4.76 11.85-.085-18.211-2.806 12.352-1.578-11.135-1.965 15.801-2.695-20.145-.963 16.559-5.306-14.518 2.613 23.104-6.232-13.742 3.055 16.895s6.1 1.139 12.984 1.287c6.884.15 13.072-.821 13.072-.821z"
        fill="#678f3d"
      />
      <path
        fill="#9aaf3b"
        d="M647.031 38.636l-5.519-28.035 8.548 28.849zm5.011.869l3.125-24.609-.9 24.908zm-5.825-.678l-5.388-14.828 4.046 14.695zm10.712 1.061l8.83-24.397-5.282 24.573zm7.464-.178l9.166-23.445-4.491 23.097zm5.448-.504l3.782-15.59-2.133 15.563z"
      />
      <path fill="#9aaf3b" d="M661.432 39.853l6.978-24.842-4.036 24.82z" />
      <path
        d="M673.722 38.571l-7.025 29.885s-7.318 1.037-14.554.262l-.092-.018a33.726 33.726 0 01-3.71-.586l-.01-.002a28.148 28.148 0 01-2.375-.63l-3.199-29.364 24.041.352h.012l6.912.101z"
        fill="#fdf7be"
      />
      <path
        d="M666.798 38.47c-.339.154-7.548 3.432-11.42 10.23-3.973 6.957-3.235 20.018-3.235 20.018l-.092-.018-3.71-.586-.01-.002a28.148 28.148 0 01-2.375-.63l-3.199-29.364 24.041.352z"
        fill="#ffe1ba"
      />
      <linearGradient
        id="OfficeFooter_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={88.269}
        y1={36.316}
        x2={206.924}
        y2={36.316}
        gradientTransform="matrix(-1 0 0 1 710.847 0)"
      >
        <stop offset={0} stopColor="#94bbd4" />
        <stop offset={1} stopColor="#d9f0f8" />
      </linearGradient>
      <path
        d="M509.137 71.265l86.842-.121c2.2 0 4.165-1.318 4.904-3.295l21.392-57.416c1.179-3.159-1.226-6.516-4.747-6.618l-10.123-.302-74.048-2.141c-2.217-.076-4.243 1.213-5.029 3.176l-20.591 51.336-3.474 8.654c-1.288 3.252 1.227 6.74 4.874 6.727z"
        fill="url(#OfficeFooter_svg__b)"
      />
      <path
        d="M574.585 37.206c-.465.127-.938.182-1.408.092-1.799-.346-2.996-2.285-3.152-4.731-1.874.507-3.591 2.614-4.164 5.409-.714 3.49.622 6.688 2.984 7.141 2.363.453 4.857-2.009 5.571-5.5a9.053 9.053 0 00.169-2.411z"
        fill="#fff"
      />
      <path
        d="M505.699 69.47c-.686-.576-1.349-1.854-1.172-2.473l24.92-62.137c.642-1.598 2.264-2.646 4.061-2.621l84.634 1.633L534.223.007c-3.355-.137-6.428 1.783-7.633 4.771l-25.33 62.793-30.094 1.623v2.225h126.831l.093-1.15-89.61.236c-1.004.016-2.039-.41-2.781-1.035z"
        fill="#481421"
      />
      <path
        d="M509.137 71.265l86.842-.121c2.2 0 4.165-1.318 4.904-3.295l21.392-57.416c1.179-3.159-1.226-6.516-4.747-6.618l-10.123-.302-.723.136v22.284L562.64 60.841l-54.699-5.301-.204.344-3.474 8.654c-1.288 3.252 1.227 6.74 4.874 6.727z"
        opacity={0.29}
        fill="#7585c2"
      />
      <path
        fill="#fec258"
        d="M605.577 69.649l4.613-.943 9.896-.121 4.434 1.402z"
      />
      <path
        d="M639.307 52.177c-1.826-2.571-5.973-2.01-8.581-1.336-.002-1.156-.074-1.869-.074-1.869l-31.534.386s-1.519 20.138 16.037 19.923c5.527-.068 9.107-2.105 11.423-4.867 1.774-.254 4.426-.73 7.071-1.582 4.581-1.477 9.306-5.516 5.658-10.655zm-5.977 9.17c-2.071.668-4.153 1.02-5.427 1.189 1.931-3.238 2.575-6.992 2.758-9.73 1.796-.532 5.311-1.244 6.771.814 2.646 3.729-.78 6.655-4.102 7.727z"
        fill="#fec258"
      />
      <path
        d="M624.866 49.042l5.953-.072s2.06 20.097-15.496 20.309c-4.938.061-8.366-1.49-10.745-3.748 0 0 7.235-.232 14.325-5.291 7.09-5.059 5.963-11.198 5.963-11.198z"
        fill="#f9a148"
      />
      <g>
        <path
          fill="#6e82c0"
          d="M750.305 74.814l-14.451 154.048 18.18.867 10.06-151.755z"
        />
        <linearGradient
          id="OfficeFooter_svg__c"
          gradientUnits="userSpaceOnUse"
          x1={884.293}
          y1={246.403}
          x2={884.293}
          y2={77.974}
        >
          <stop offset={0} stopColor="#94bbd4" />
          <stop offset={1} stopColor="#d9f0f8" />
        </linearGradient>
        <path
          fill="url(#OfficeFooter_svg__c)"
          d="M877.742 77.974l21.537 168.052-15.432.377-14.54-167.568z"
        />
        <path
          d="M869.307 78.835l-119.002-4.021-14.451 154.048 147.993 17.541-14.54-167.568zm-115.577-2.34l112.312 6.535 14.069 157.223-140.067-14.551L753.73 76.495z"
          fill="#3d1312"
        />
        <path
          fill="#6e82c0"
          d="M754.55 221.925l125.022 12.295.539 6.033-140.068-14.551zM411.426 73.048l-15.239 171.385 12.558.115 13.545-171.5z"
        />
        <path
          fill="#6e82c0"
          d="M409.143 239.509l131.831 13.023-3.516 5.276-141.271-13.375z"
        />
        <linearGradient
          id="OfficeFooter_svg__d"
          gradientUnits="userSpaceOnUse"
          x1={539.459}
          y1={259.956}
          x2={539.459}
          y2={81.03}
        >
          <stop offset={0} stopColor="#94bbd4" />
          <stop offset={1} stopColor="#d9f0f8" />
        </linearGradient>
        <path
          fill="url(#OfficeFooter_svg__d)"
          d="M525.452 81.03l14.809 178.926 13.205-2.107L535.507 81.03z"
        />
        <path
          d="M525.452 81.03H404.296l-8.109 163.402 144.074 15.523L525.452 81.03zm-114.913 2h113.333l13.585 174.777-136.292-14.032 9.374-160.745z"
          fill="#782b2d"
        />
        <path
          fill="#5a1e0d"
          d="M384.67 72.247l6.101 10.684 505.824-4.375v-4.332z"
        />
        <path fill="#af643c" d="M899.279 74.196v-8.097l-519.63 3.115v3.033z" />
      </g>
      <g>
        <path
          fill="#fec258"
          d="M848.75 66.999l-4.684-27.285h24.291l-4.155 27.475z"
        />
        <path
          d="M848.412 65.025s5.428-3.72 9.979-9.911c4.55-6.193 5.812-15.4 5.812-15.4h4.155l-4.155 27.476-15.416.017-.375-2.182z"
          fill="#f9a148"
        />
        <path
          fill="#d41f26"
          d="M845.829 49.985l21.01-.232-.975 6.441h-18.969z"
        />
        <path
          fill="#049678"
          d="M862.514 39.714l2.14-11.947h2.185l-2.637 11.947z"
        />
        <path
          fill="#d41f26"
          d="M848.412 39.714l-.704-4.699h1.494l.951 4.699zm15.79 0l3.339-17.123h1.766l-3.192 17.123z"
        />
        <path
          fill="#f7931e"
          d="M845.829 39.714l-3.792-15.344h2.852l4.041 15.344z"
        />
      </g>
      <path
        fill="#3b140f"
        d="M441.507 238.53l2.519-3.375-2.519-92.949h-.561z"
      />
      <linearGradient
        id="OfficeFooter_svg__e"
        gradientUnits="userSpaceOnUse"
        x1={808.647}
        y1={66.798}
        x2={808.647}
        y2={13.892}
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        opacity={0.7}
        fill="url(#OfficeFooter_svg__e)"
        d="M824.25 15.753l45.062 50.525-121.33.52L788.1 13.892z"
      />
    </svg>
  );
}

export default SvgOfficeFooter;
