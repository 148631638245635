import * as React from "react";

function SvgPrintingtube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={150.601}
      height={94.688}
      viewBox="0.001 28.002 150.601 94.688"
      {...props}
    >
      <path
        opacity={0.3}
        fill="none"
        stroke="#010101"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M7.501 35.502c0 16.5 13.02 30 28.933 30l76.668-.035c16.5 0 30 13.02 30 28.933l-.025 20.791"
      />
       <path
        className="tubefill servicepath"
        fill="none"
        stroke="#fff"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M7.501 35.502c0 16.5 13.02 30 28.933 30l76.668-.035c16.5 0 30 13.02 30 28.933l-.025 20.791"
      />
    </svg>
  );
}

export default SvgPrintingtube;
