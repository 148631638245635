import * as React from "react";

function SvgTelescope(props) {
  return (
    <svg width="143.707" height="241.36" viewBox="0 0 143.707 241.36" {...props}>
      <linearGradient
        id="Telescope_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={89.232}
        y1={239.529}
        x2={89.232}
        y2={120.809}
      >
        <stop offset={0} stopColor="#25545f" />
        <stop offset={0.397} stopColor="#2e5862" />
        <stop offset={0.994} stopColor="#325a63" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__a)"
        d="M77.465 121.986l.387 2.315 18.405 110.361.807 4.867 3.936-.674-15.961-113.477-.514-3.663-.127-.906z"
      />
      <path
        d="M77.852 124.301l18.405 110.361c.05-.014.1-.037.149-.07.439-.281.449-.938.404-1.473-.186-2.313-.305-4.576-.5-6.887-2.553-30.348-9.576-59.873-13.869-90.008-.342-2.389-.65-4.883.076-7.178.622-1.965.854-3.164 2.521-3.669l-.514-3.663c-.494.039-.99.141-1.473.258-1.838.452-3.674 1.186-5.199 2.329z"
        fill="#39454e"
      />
      <linearGradient
        id="Telescope_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={79.332}
        y1={126.252}
        x2={79.332}
        y2={95.712}
      >
        <stop offset={0.006} stopColor="#ee6535" />
        <stop offset={0.923} stopColor="#6a1e49" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__b)"
        d="M72.594 97.24l4.458 29.012 1.671-.285 7.347-1.246-4.107-26.726-.35-2.283z"
      />
      <linearGradient
        id="Telescope_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={98.565}
        y1={241.36}
        x2={98.565}
        y2={230.959}
      >
        <stop offset={0.006} stopColor="#de5538" />
        <stop offset={0.041} stopColor="#d9533a" />
        <stop offset={0.497} stopColor="#a93651" />
        <stop offset={0.829} stopColor="#8f2457" />
        <stop offset={1} stopColor="#851c5a" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__c)"
        d="M96.902 241.36l-1.589-9.542 5.041-.859 1.464 10.401z"
      />
      <linearGradient
        id="Telescope_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={77.278}
        y1={126.252}
        x2={77.278}
        y2={95.712}
      >
        <stop offset={0} stopColor="#c3414c" />
        <stop offset={0.247} stopColor="#bf404c" />
        <stop offset={0.438} stopColor="#b33b4d" />
        <stop offset={0.611} stopColor="#9f334c" />
        <stop offset={0.773} stopColor="#86284b" />
        <stop offset={0.927} stopColor="#681947" />
        <stop offset={1} stopColor="#590f44" />
      </linearGradient>
      <path
        d="M72.594 97.24l3.221 20.973 1.237 8.039 1.671-.285c.191-.704.074-1.461-.045-2.182-.959-6.156-.99-12.43-.691-18.664.087-1.719.215-3.512 1.055-4.988.682-1.197 1.709-1.709 2.912-2.133.006 0 .006 0 .01-.005l-.35-2.283-1.104.185-7.916 1.343z"
        fill="url(#Telescope_svg__d)"
      />
      <path
        d="M72.594 97.24l3.221 20.973c.057.105.109.205.165.305a249.47 249.47 0 01-.646-15.871c-.009-1.023-.006-2.094.449-2.998.986-1.973 2.813-1.283 4.172-2.383.613-.492.727-.957.555-1.369l-7.916 1.343z"
        fill="#95323a"
      />
      <path
        d="M83.543 118.955c-.026.684-.072 1.39-.355 2.008-.283.621-.859 1.139-1.512 1.135-1.16-.016-1.727-1.475-1.951-2.674a51.054 51.054 0 01-.471-15.703c.15-1.195.941-2.648 2.02-1.289.906 1.146 1.117 3.862 1.396 5.296a48.664 48.664 0 01.873 11.227z"
        opacity={0.3}
        fill="#fff"
      />
      <linearGradient
        id="Telescope_svg__e"
        gradientUnits="userSpaceOnUse"
        x1={48.475}
        y1={239.344}
        x2={48.475}
        y2={120.622}
      >
        <stop offset={0} stopColor="#25545f" />
        <stop offset={0.397} stopColor="#2e5862" />
        <stop offset={0.994} stopColor="#325a63" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__e)"
        d="M60.242 121.803l-.387 2.31-18.404 110.366-.81 4.865-3.934-.676 15.961-113.479.514-3.661.127-.907z"
      />
      <path
        d="M59.855 124.113L41.451 234.479a.56.56 0 01-.15-.073c-.441-.282-.451-.937-.404-1.472.186-2.314.305-4.578.5-6.888 2.553-30.349 9.576-59.874 13.87-90.009.341-2.389.649-4.883-.077-7.176-.623-1.965-.854-3.165-2.521-3.672l.514-3.661c.496.038.99.14 1.473.259 1.838.449 3.673 1.184 5.199 2.326z"
        fill="#39454e"
      />
      <linearGradient
        id="Telescope_svg__f"
        gradientUnits="userSpaceOnUse"
        x1={58.375}
        y1={126.064}
        x2={58.375}
        y2={95.523}
      >
        <stop offset={0.006} stopColor="#ee6535" />
        <stop offset={0.923} stopColor="#6a1e49" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__f)"
        d="M65.112 97.053l-4.456 29.011-1.673-.281-7.346-1.248 4.107-26.724.35-2.288z"
      />
      <linearGradient
        id="Telescope_svg__g"
        gradientUnits="userSpaceOnUse"
        x1={39.143}
        y1={241.172}
        x2={39.143}
        y2={230.773}
      >
        <stop offset={0.006} stopColor="#de5538" />
        <stop offset={0.041} stopColor="#d9533a" />
        <stop offset={0.497} stopColor="#a93651" />
        <stop offset={0.829} stopColor="#8f2457" />
        <stop offset={1} stopColor="#851c5a" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__g)"
        d="M40.805 241.172l1.59-9.541-5.043-.858-1.462 10.399z"
      />
      <linearGradient
        id="Telescope_svg__h"
        gradientUnits="userSpaceOnUse"
        x1={60.428}
        y1={126.064}
        x2={60.428}
        y2={95.523}
      >
        <stop offset={0} stopColor="#c3414c" />
        <stop offset={0.247} stopColor="#bf404c" />
        <stop offset={0.438} stopColor="#b33b4d" />
        <stop offset={0.611} stopColor="#9f334c" />
        <stop offset={0.773} stopColor="#86284b" />
        <stop offset={0.927} stopColor="#681947" />
        <stop offset={1} stopColor="#590f44" />
      </linearGradient>
      <path
        d="M65.112 97.053l-3.222 20.973-1.234 8.039-1.673-.281c-.19-.709-.071-1.463.046-2.184.959-6.155.99-12.432.689-18.666-.086-1.719-.213-3.512-1.053-4.988-.683-1.193-1.709-1.706-2.914-2.129-.003 0-.003 0-.008-.006l.35-2.287 1.104.188 7.915 1.341z"
        fill="url(#Telescope_svg__h)"
      />
      <path
        d="M65.112 97.053l-3.222 20.973c-.053.105-.109.207-.162.308.381-5.282.598-10.577.645-15.874.01-1.023.004-2.092-.449-2.996-.986-1.975-2.813-1.285-4.172-2.385-.613-.492-.727-.957-.554-1.366l7.914 1.34z"
        fill="#95323a"
      />
      <path
        d="M54.164 118.768c.027.686.072 1.393.355 2.011.282.62.859 1.14 1.512 1.132 1.16-.012 1.728-1.475 1.951-2.671a51.084 51.084 0 00.471-15.702c-.148-1.195-.941-2.65-2.02-1.289-.908 1.143-1.117 3.857-1.398 5.291a48.972 48.972 0 00-.871 11.228z"
        opacity={0.3}
        fill="#fff"
      />
      <linearGradient
        id="Telescope_svg__i"
        gradientUnits="userSpaceOnUse"
        x1={69.076}
        y1={239.246}
        x2={69.076}
        y2={119.924}
      >
        <stop offset={0} stopColor="#25545f" />
        <stop offset={0.397} stopColor="#2e5862" />
        <stop offset={0.994} stopColor="#325a63" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__i)"
        d="M72.587 119.924l-.909 21.73-.604 97.592h-3.99l-.104-8.139-.099-7.828-.959-75.439-.356-27.916z"
      />
      <path
        d="M71.678 141.654c.037-19.35.077 11.299.117-8.053 0-1.816-.361-4.14-2.049-4.521-1.066-.238-2.152.555-2.662 1.576-.508 1.02-.576 2.209-.631 3.355a872.772 872.772 0 00-.531 13.828l-.355-27.916h7.021l-.91 21.731z"
        fill="#39454e"
      />
      <linearGradient
        id="Telescope_svg__j"
        gradientUnits="userSpaceOnUse"
        x1={69.077}
        y1={124.066}
        x2={69.077}
        y2={94.68}
      >
        <stop offset={0.006} stopColor="#ee6535" />
        <stop offset={0.923} stopColor="#6a1e49" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__j)"
        d="M64.508 94.68h9.139v29.387h-9.139z"
      />
      <path
        d="M65.723 118.924c.125 1.117.427 2.405 1.402 2.871.9.433 2.01-.094 2.59-.938.582-.842.748-1.918.85-2.953.18-1.82.314-14.387-2.691-13.154-1.773.728-1.926 4.781-2.119 6.398a33.65 33.65 0 00-.032 7.776z"
        opacity={0.3}
        fill="#fff"
      />
      <linearGradient
        id="Telescope_svg__k"
        gradientUnits="userSpaceOnUse"
        x1={69.077}
        y1={124.066}
        x2={69.077}
        y2={94.68}
      >
        <stop offset={0} stopColor="#c3414c" />
        <stop offset={0.247} stopColor="#bf404c" />
        <stop offset={0.438} stopColor="#b33b4d" />
        <stop offset={0.611} stopColor="#9f334c" />
        <stop offset={0.773} stopColor="#86284b" />
        <stop offset={0.927} stopColor="#681947" />
        <stop offset={1} stopColor="#590f44" />
      </linearGradient>
      <path
        d="M73.646 94.902v29.164h-.334c-.363-.32-.498-1.066-.48-1.643a79.984 79.984 0 00-1.607-18.664c-.287-1.365-.873-2.992-2.191-3.174-.809-.111-1.594.43-2.049 1.143-.455.711-.636 1.572-.795 2.408a84.567 84.567 0 00-1.322 10.385c-.041.65.164 3.801-.359 5.654V94.68h8.965c.057.072.116.148.172.222z"
        fill="url(#Telescope_svg__k)"
      />
      <linearGradient
        id="Telescope_svg__l"
        gradientUnits="userSpaceOnUse"
        x1={69.077}
        y1={241.172}
        x2={69.077}
        y2={231.342}
      >
        <stop offset={0.006} stopColor="#de5538" />
        <stop offset={0.041} stopColor="#d9533a" />
        <stop offset={0.497} stopColor="#a93651" />
        <stop offset={0.829} stopColor="#8f2457" />
        <stop offset={1} stopColor="#851c5a" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__l)"
        d="M71.506 241.172l.125-9.83h-5.108l.125 9.83z"
      />
      <linearGradient
        id="Telescope_svg__m"
        gradientUnits="userSpaceOnUse"
        x1={68.391}
        y1={92.012}
        x2={68.391}
        y2={74.473}
      >
        <stop offset={0.006} stopColor="#de5538" />
        <stop offset={0.146} stopColor="#d8523b" />
        <stop offset={0.351} stopColor="#c84944" />
        <stop offset={0.594} stopColor="#b13b4e" />
        <stop offset={0.865} stopColor="#942856" />
        <stop offset={1} stopColor="#851c5a" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__m)"
        d="M61.482 74.473h13.816v17.539H61.482z"
      />
      <path
        fill="#ae3a44"
        d="M61.482 80.781h13.817v1.217l-13.817-.978zM75.299 86.076v1.419H61.482v-1.038z"
      />
      <linearGradient
        id="Telescope_svg__n"
        gradientUnits="userSpaceOnUse"
        x1={88.04}
        y1={76.605}
        x2={143.707}
        y2={76.605}
      >
        <stop offset={0} stopColor="#851c5a" />
        <stop offset={0.024} stopColor="#8a2059" />
        <stop offset={0.156} stopColor="#a13053" />
        <stop offset={0.296} stopColor="#b53d4c" />
        <stop offset={0.442} stopColor="#c64845" />
        <stop offset={0.599} stopColor="#d34f3e" />
        <stop offset={0.772} stopColor="#db5439" />
        <stop offset={0.994} stopColor="#de5538" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__n)"
        d="M143.707 86.158l-.521.942-5.117 9.239-9.962-5.434-4.037-2.202-.873-.471L88.04 69.073l6.757-12.202 35.305 21.137 3.365 2.014z"
      />
      <linearGradient
        id="Telescope_svg__o"
        gradientUnits="userSpaceOnUse"
        x1={56.984}
        y1={66.494}
        x2={83.766}
        y2={66.494}
      >
        <stop offset={0} stopColor="#851c5a" />
        <stop offset={0.171} stopColor="#8f2457" />
        <stop offset={0.503} stopColor="#a93651" />
        <stop offset={0.96} stopColor="#d9533a" />
        <stop offset={0.994} stopColor="#de5538" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__o)"
        d="M83.766 60.311L75.875 80.18l-18.891-7.502 7.891-19.87z"
      />
      <linearGradient
        id="Telescope_svg__p"
        gradientUnits="userSpaceOnUse"
        x1={71.297}
        y1={74.498}
        x2={71.297}
        y2={55.782}
      >
        <stop offset={0} stopColor="#c3414c" />
        <stop offset={0.649} stopColor="#671348" />
        <stop offset={0.83} stopColor="#641348" />
        <stop offset={0.971} stopColor="#5b1045" />
        <stop offset={1} stopColor="#590f44" />
      </linearGradient>
      <path
        d="M82.04 64.654l-3.909 9.844c-.711-2.921-1.879-5.729-3.741-7.996-2.081-2.529-5.112-4.324-8.374-4.385-1.638-.033-3.215.441-4.678 1.244-.281.156-.547.27-.785.33l2.65-6.673c1.018-.671 2.123-1.11 3.168-1.209 1.458-.142 2.895.282 4.247.803a29.24 29.24 0 0110.007 6.452c.503.493.992 1.022 1.415 1.59z"
        fill="url(#Telescope_svg__p)"
      />
      <path
        d="M143.186 87.1l-5.116 9.239-14.872-8.106a5.09 5.09 0 01.209-.577c3.213-7.585 9.269-.96 13.201.424 2.162.76 4.814.584 6.578-.98z"
        fill="#ae3a44"
      />
      <path
        fill="#95323a"
        d="M133.467 80.022l-5.36 10.883-4.037-2.202 6.032-10.695z"
      />
      <linearGradient
        id="Telescope_svg__q"
        gradientUnits="userSpaceOnUse"
        x1={53.904}
        y1={63.853}
        x2={133.266}
        y2={63.853}
      >
        <stop offset={0} stopColor="#851c5a" />
        <stop offset={0.024} stopColor="#8a2059" />
        <stop offset={0.156} stopColor="#a13053" />
        <stop offset={0.296} stopColor="#b53d4c" />
        <stop offset={0.442} stopColor="#c64845" />
        <stop offset={0.599} stopColor="#d34f3e" />
        <stop offset={0.772} stopColor="#db5439" />
        <stop offset={0.994} stopColor="#de5538" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__q)"
        d="M133.266 77.476l-3.996 7.216-.118.215-3.923 7.08-61.678-33.616-4.693-2.553-.747-.408-.931-.51-3.276-1.783 9.637-17.397 6.425 3.85 1.934 1.157z"
      />
      <path
        d="M129.152 84.907l-3.924 7.08L58.111 55.41c-.72-2.703.014-5.674 3.192-6.623 4.79-1.434 10.462 3.916 13.996 6.218a478.102 478.102 0 0035.876 21.2 473.576 473.576 0 0010.572 5.469c.704.359 4.671 1.928 7.405 3.233z"
        fill="#c3414c"
        opacity={0.2}
      />
      <linearGradient
        id="Telescope_svg__r"
        gradientUnits="userSpaceOnUse"
        x1={58.858}
        y1={48.971}
        x2={71.9}
        y2={48.971}
      >
        <stop offset={0} stopColor="#590f44" />
        <stop offset={0.083} stopColor="#5b1045" />
        <stop offset={0.481} stopColor="#641348" />
        <stop offset={0.994} stopColor="#671348" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__r)"
        d="M71.9 40.727l-8.349 17.644-4.693-2.553L69.966 39.57z"
      />
      <linearGradient
        id="Telescope_svg__s"
        gradientUnits="userSpaceOnUse"
        x1={7.457}
        y1={32.664}
        x2={70.624}
        y2={32.664}
      >
        <stop offset={0} stopColor="#851c5a" />
        <stop offset={0.024} stopColor="#8a2059" />
        <stop offset={0.156} stopColor="#a13053" />
        <stop offset={0.296} stopColor="#b53d4c" />
        <stop offset={0.442} stopColor="#c64845" />
        <stop offset={0.599} stopColor="#d34f3e" />
        <stop offset={0.772} stopColor="#db5439" />
        <stop offset={0.994} stopColor="#de5538" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__s)"
        d="M70.624 37.911l-5.467 9.862-.53.953-5.205 9.405-45.297-24.192-3.687-1.967-.713-.382-.592-.318-1.676-.889 1.416-2.553 3.817-6.894 7.607-13.739 1.98 1.207 4.28 2.617z"
      />
      <path
        d="M64.627 48.726l-3.738 6.761-1.467 2.645L9.725 31.59l-.592-.317a9.094 9.094 0 01-.088-1.551c.145-6.064 6.15-10.403 13.688-5.354 11.967 8.014 23.956 15.518 37.082 21.46 1.699.772 3.5 1.61 4.812 2.898z"
        fill="#c3414c"
        opacity={0.2}
      />
      <g opacity={0.2}>
        <linearGradient
          id="Telescope_svg__t"
          gradientUnits="userSpaceOnUse"
          x1={25.165}
          y1={25.984}
          x2={52.711}
          y2={25.984}
        >
          <stop offset={0} stopColor="#633a64" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <path
          d="M30.051 25.495c5.807 3.683 11.846 7.015 18.201 9.608 1.369.561 3.24.945 4.119-.336.937-1.363-.24-3.117-1.385-4.217-5.372-5.174-11.639-10.063-18.375-13.227-2.121-.995-6.014-2.02-7.223 1.186-1.182 3.13 2.609 5.68 4.663 6.986z"
          fill="url(#Telescope_svg__t)"
        />
      </g>
      <g opacity={0.2}>
        <linearGradient
          id="Telescope_svg__u"
          gradientUnits="userSpaceOnUse"
          x1={73.374}
          y1={56.13}
          x2={106.192}
          y2={56.13}
        >
          <stop offset={0} stopColor="#633a64" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <path
          d="M78.475 53.992a248.1 248.1 0 0024.868 12.59c.767.334 1.728.641 2.393.08 1.079-.909.042-2.619-.997-3.504-6.385-5.42-19.055-8.828-26.353-12.859-2.079-1.152-1.563-6.644-3.923-4.533-3.356 2.991 1.921 7.013 4.012 8.226z"
          fill="url(#Telescope_svg__u)"
        />
      </g>
      <path
        d="M134.6 85.278c1.111.5 2.25.944 3.408 1.329.463.156 1.072.253 1.367-.164.303-.432-.006-1.008-.33-1.399-1.122-1.353-2.879-3.192-4.795-3.138-.681.022-1.199.285-1.352 1.022-.252 1.213.799 1.945 1.702 2.35z"
        fill="#fff"
        opacity={0.29}
      />
      <path
        d="M60.889 55.486l-1.467 2.645L9.725 31.59l-.592-.317a9.094 9.094 0 01-.088-1.551c-.001-.013.001-.017.004-.025.811-2.778 4.629-6.349 7.221-4.037 1.167 1.038 1.719 3.377 2.649 4.702 1.093 1.56 2.409 2.945 3.847 4.166 3.18 2.699 6.913 4.612 10.615 6.504 8.467 4.324 16.936 8.647 25.403 12.979.757.384 1.566.836 2.105 1.475z"
        fill="#ae3a44"
      />
      <linearGradient
        id="Telescope_svg__v"
        gradientUnits="userSpaceOnUse"
        x1={10.438}
        y1={21.172}
        x2={26.557}
        y2={21.172}
      >
        <stop offset={0} stopColor="#c489bc" />
        <stop offset={0.256} stopColor="#b677b2" />
        <stop offset={0.744} stopColor="#a059a3" />
        <stop offset={1} stopColor="#994d9e" />
      </linearGradient>
      <path
        opacity={0.71}
        fill="url(#Telescope_svg__v)"
        d="M26.557 11.021L14.125 33.939l-3.687-1.967L22.277 8.404z"
      />
      <linearGradient
        id="Telescope_svg__w"
        gradientUnits="userSpaceOnUse"
        x1={13.878}
        y1={37.574}
        x2={13.878}
        y2={0}
      >
        <stop offset={0.006} stopColor="#de5538" />
        <stop offset={0.228} stopColor="#db5439" />
        <stop offset={0.402} stopColor="#d34f3e" />
        <stop offset={0.558} stopColor="#c64845" />
        <stop offset={0.704} stopColor="#b53d4c" />
        <stop offset={0.844} stopColor="#a13053" />
        <stop offset={0.976} stopColor="#8a2059" />
        <stop offset={1} stopColor="#851c5a" />
      </linearGradient>
      <path
        fill="url(#Telescope_svg__w)"
        d="M27.756 5.809L10.164 37.574 0 31.766l1.118-2.014L17.592 0l4.638 2.648z"
      />
      <g>
        <path
          d="M27.756 5.809L10.164 37.574 0 31.766l1.118-2.014c1.257-.482 2.665-.584 3.992-.857 5.659-1.178 10.274-5.871 12.976-11.216 1.433-2.837 2.41-5.871 3.174-8.942.437-1.764.525-4.106.971-6.088l5.525 3.16z"
          fill="#ae3a44"
        />
      </g>
      <g>
        <ellipse cx={68.481} cy={73.021} rx={4.567} ry={4.805} fill="#f0525f" />
      </g>
      <g>
        <path
          d="M70.879 74.014c-.52 1.392-2.014 2.078-3.34 1.531-1.324-.547-1.976-2.121-1.455-3.514.52-1.395 2.014-2.078 3.34-1.533 1.324.548 1.975 2.119 1.455 3.516z"
          fill="#942d6a"
        />
      </g>
      <g>
        <linearGradient
          id="Telescope_svg__x"
          gradientUnits="userSpaceOnUse"
          x1={53.781}
          y1={92.631}
          x2={83.464}
          y2={92.631}
        >
          <stop offset={0} stopColor="#851c5a" />
          <stop offset={0.994} stopColor="#de5538" />
        </linearGradient>
        <path
          fill="url(#Telescope_svg__x)"
          d="M53.781 87.495h29.683v10.272H53.781z"
        />
      </g>
      <g>
        <path
          d="M83.464 88.318v9.449H53.781v-8.811c.541.844 1.107 2.268 1.658 2.826a10.611 10.611 0 003.775 2.447c3.268 1.236 6.842 1.076 10.309.898 4.762-.248 10.256-.914 12.854-5.115.336-.539.687-1.167 1.087-1.694z"
          fill="#ae3a44"
        />
      </g>
      <g opacity={0.3}>
        <path
          d="M61.277 88.084c-1.234-.068-2.775.055-3.26 1.252-.309.758-.004 1.67.545 2.256.551.586 1.301.906 2.041 1.174a22.062 22.062 0 0011.119 1c1.119-.188 2.375-.587 2.842-1.672 2.227-5.186-11.322-3.908-13.287-4.01z"
          fill="#fff"
        />
      </g>
      <g opacity={0.22}>
        <path
          d="M37.057 236.563c-.148.699-.295 1.424-.154 2.125.143.699.66 1.369 1.338 1.424.619.051 1.188-.422 1.475-1.004.285-.579.344-1.248.399-1.896.083-.996.349-4.592-1.284-4.658-1.38-.059-1.583 3.094-1.774 4.009z"
          fill="#fff"
        />
      </g>
      <g opacity={0.22}>
        <path
          d="M98.837 239.152c.269.416.651.842 1.126.811.441-.029.781-.469.869-.926.086-.455-.018-.926-.121-1.379a821.27 821.27 0 01-.646-2.809c-.111-.482-.227-.975-.482-1.393-.258-.414-.689-.745-1.162-.736-2.316.055-.179 5.518.416 6.432z"
          fill="#fff"
        />
      </g>
      <g opacity={0.22}>
        <path
          d="M68.051 238.378c.108.31.242.624.482.833.242.211.621.278.865.069.179-.15.24-.405.291-.644.225-1.072.402-2.156.535-3.246.15-1.246-.445-4.787-2.225-3.061-1.226 1.191-.417 4.724.052 6.049z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgTelescope;
