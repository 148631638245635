import React, { useState } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { normalize } from "styled-normalize";
import CustomCursor from "./customCursor";
import {
  useGlobalStateContext,
  useGlobalDispatchContext,
} from "../context/globalContext";

const GlobalStyle = createGlobalStyle`
${normalize}
html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
  
  
}
body {
  font-size: 16px;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  overscroll-behavior: none;
  overflow-x: hidden;
}
`;

const Layout = ({ children }) => {
  const dispatch = useGlobalDispatchContext();
  const cursorStyles = ["pointer", "hovered", "locked", "white"];

  const lightTheme = {
    background: "#fff",
    text: "#000",
  };

  const onCursor = (cursorType) => {
    cursorType = (cursorStyles.includes(cursorType) && cursorType) || false;
    dispatch({ type: "CURSOR_TYPE", cursorType: cursorType });
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <main>{children}</main>
    </ThemeProvider>
  );
};

export default Layout;
