import React from "react";
import Lottie from "react-lottie";
import * as loading from "../assets/json/loadingcircle";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const LoadingBarLoading = () => {
  return (
    <div>
      <Lottie options={defaultOptions} height={80} width={80} />
    </div>
  );
};

export default LoadingBarLoading;
