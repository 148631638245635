import * as React from "react";

function SvgGraphicTube1024(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={151.786}
            height={126.5}
            {...props}
        >
            <path
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 144.286,7.500000000000007 L 144.285,25.686000000000007 C 144.285,42.18600000000001 131.266,55.68600000000001 115.354,55.68600000000001 L 108.688,55.879000000000005 C 92.188,55.879000000000005 78.688,68.899 78.688,84.811 L 78.709,89.0 C 78.709,105.5 65.691,119.0 49.778,119.0 L 7.5,118.999"
                opacity={0.3}
            />
            <path
                className="tubefill servicepath"
                fill="none"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 144.286,7.500000000000007 L 144.285,25.686000000000007 C 144.285,42.18600000000001 131.266,55.68600000000001 115.354,55.68600000000001 L 108.688,55.879000000000005 C 92.188,55.879000000000005 78.688,68.899 78.688,84.811 L 78.709,89.0 C 78.709,105.5 65.691,119.0 49.778,119.0 L 7.5,118.999"
            />
        </svg>
    );
}

export default SvgGraphicTube1024;
