import * as React from "react";

function SvgLeftService(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.15 45" height={45} width={65.15} {...props}>
      <path
        d="M57.65 7.5c0 16.5-13.02 30-28.93 30H7.5"
        opacity={0.3}
        fill="none"
        stroke="#020203"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
        <path
            d="M57.65 7.5c0 16.5-13.02 30-28.93 30H7.5"
            className="tubefill servicepath"
            fill="none"
            stroke="#fff"
            strokeWidth={15}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
        />
    </svg>
  );
}

export default SvgLeftService;
