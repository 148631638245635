import * as React from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";


function SvgS2N(props) {
    const size = useWindowSize();
    const [widthSvg, setWidthSvg] = React.useState(840.201);
    const [tubeWidth, setTubeWidth] = React.useState(
        `M7.5 764.109l598.17-.001c16.5 0 30-13.499 30-30V489.731c0-11.501 13.5-25 30-25l133.032-.003c16.499 0 29.999-13.499 29.999-30L828.62 7.5`
    );

    let [change, setChange] = React.useState(0)

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let s2Tube = document.querySelector(".tubefill.s2");
        let s2Total = Math.ceil(s2Tube.getTotalLength().toFixed(3));
        s2Tube.style.strokeDasharray = s2Total + "px";
        s2Tube.style.strokeDashoffset = s2Total + "px";
        gsap
            .timeline({
                defaults: {duration: 1},
                scrollTrigger: {
                    trigger: ".sectionAbout",
                    scrub: "20",
                    triggerHook: 0,
                    start: "top-=20 top",
                    end: "bottom-=50 top",
                    ease: "none",
                },
            })
            .fromTo(
                ".tubefill.s2",
                {
                    strokeDasharray: s2Total + "px",
                    strokeDashoffset: s2Total + "px",
                },
                {
                    strokeDashoffset: s2Total * 2 + "px",
                },
                0
            )
    }, [tubeWidth, change]);

    React.useEffect(() => {
        if (size.width < 1300) return;
        // let w = 598.17 + size.width * 0.204166;
        // let h = 828.62 + size.width * 0.204166;
        let w = 598.17 + (size.width - 1300) * 0.6;
        let h = 828.62 + (size.width - 1300) * 0.6;
        let leftLogo = size.width * 0.6;
        let LogoSectionTube = document.querySelector(".SectionLogo .tube");
        // LogoSectionTube.style.left = `${leftLogo}px`;
        LogoSectionTube.style.left = `${816 + (size.width - 1300) * 0.6}px`;

        let lampshadow = document.querySelector(".sectionAbout .lampshades");
        lampshadow.style.left = `${w - 598.17 - 640}px`;

        let content = document.querySelector(".sectionAbout .content");
        content.style.paddingLeft = `${size.width * 0.2}px`;

        let robot = document.querySelector(".sectionAbout .robot");
        robot.style.left = `${size.width * 0.2}px`;

        setWidthSvg(840.201 + (size.width - 1300));
        setTubeWidth(
            `M7.5 764.109l${w}-.001c16.5 0 30-13.499 30-30V489.731c0-11.501 13.5-25 30-25l133.032-.003c16.499 0 29.999-13.499 29.999-30L${h} 7.5`
        );
        setChange(!change)
    }, [size.width]);

    return (
        <svg
            width={widthSvg}
            height={771.609}
            viewBox={"0 0 " + widthSvg + " 771.609"}
            {...props}
        >
            <path
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d={tubeWidth}
                opacity={0.3}
            />
            <path
                fill="none"
                stroke="#fff"
                className="tubefill s2"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d={tubeWidth}
            />
        </svg>
    );
}

export default SvgS2N;
