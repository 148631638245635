import * as React from "react";

function SvgGhost5(props) {
  return (
    <svg viewBox="0 0 107 97.215" {...props}>
      <path
        d="M95.141 24.305V12.152H83.287V0h-24.01v12.152H47.721V0H23.713v12.152H11.857v12.152H0v72.908h12.15V85.063h11.563v12.151h12.152V85.063h11.557v12.151h12.152V85.063h11.561v12.151h12.152V85.063h11.559v12.151H107V24.305H95.141zm-.295 24.304H82.99v12.15H71.43V36.457h23.416v12.152zM12.15 36.457h23.419v12.152H23.713v12.15H12.15V36.457z"
        fill="#a23654"
      />
      <defs>
        <path
          id="Ghost5_svg__a"
          d="M95.141 24.305V12.152H83.287V0h-24.01v12.152H47.721V0H23.713v12.152H11.857v12.152H0v72.908h12.15V85.063h11.563v12.151h12.152V85.063h11.557v12.151h12.152V85.063h11.561v12.151h12.152V85.063h11.559v12.151H107V24.305H95.141zm-.295 24.304H82.99v12.15H71.43V36.457h23.416v12.152zM12.15 36.457h23.419v12.152H23.713v12.15H12.15V36.457z"
        />
      </defs>
      <clipPath id="Ghost5_svg__b">
        <use xlinkHref="#Ghost5_svg__a" overflow="visible" />
      </clipPath>
      <linearGradient
        id="Ghost5_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={-11.256}
        y1={78.361}
        x2={48.745}
        y2={41.361}
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0.1} />
        <stop offset={1} stopColor="#c7d1d7" stopOpacity={0.5} />
      </linearGradient>
      <path
        opacity={0.3}
        clipPath="url(#Ghost5_svg__b)"
        fill="url(#Ghost5_svg__c)"
        d="M87.25 101.061l-62.52 38.496-74.45-120.92 62.521-38.494z"
      />
    </svg>
  );
}

export default SvgGhost5;
