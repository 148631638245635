import * as React from "react";

function SvgPrintingTube1024(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={130.603}
            height={122.69}
            {...props}
        >
            <path
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 7.5,7.5 L 7.502000000000006,35.501999999999995 C 7.502000000000006,52.001999999999995 20.520000000000003,65.502 36.434000000000005,65.502 L 93.102,65.467 C 109.602,65.467 123.102,78.485 123.102,94.399 L 123.077,115.19"
                opacity={0.3}
            />
            <path
                className="tubefill servicepath"
                fill="none"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 7.5,7.5 L 7.502000000000006,35.501999999999995 C 7.502000000000006,52.001999999999995 20.520000000000003,65.502 36.434000000000005,65.502 L 93.102,65.467 C 109.602,65.467 123.102,78.485 123.102,94.399 L 123.077,115.19"
            />
        </svg>
    );
}

export default SvgPrintingTube1024;
