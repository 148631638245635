import * as React from "react";

function SvgOffice(props) {
  return (
    <svg
      viewBox="0 0 1134.503 304.452"
      wudth="1134.503"
      height="304.452"
      {...props}
    >
      <path
        d="M659.745 253.533l-.034-.75c38.185-1.76 94.736-4.835 102.272-6.455l-.106-.016c-2.991-.408-11.033-.287-21.217-.131-37.219.572-106.498 1.633-109.633-18.997-4.52-29.729 19.009-95.159 19.247-95.816l.705.255c-.237.656-23.701 65.907-19.212 95.449 3.036 19.98 71.89 18.93 108.882 18.359 10.614-.16 18.283-.279 21.329.139.835.113 1.472.24 1.449.734-.03.661-.075 1.659-52.053 4.584-25.525 1.438-51.372 2.632-51.629 2.645z"
        fill="#362a5c"
      />
      <path
        d="M659.71 252.783l.034.75c-1.273 2.242 11.169 2.279 19.724 1.672 27.935-1.981 56.819-4.029 75.396-19.135 23.172-18.846 34.75-80.141 34.864-80.758l.738.137c-.115.619-11.744 62.185-35.13 81.203-18.759 15.254-47.765 17.313-75.815 19.301-8.545.606-26.716.572-19.811-3.17z"
        fill="#362a5c"
      />
      <path
        fill="#6e82c0"
        d="M894.128 158.326l-11.113 118.633 13.981.459 7.738-116.82z"
      />
      <linearGradient
        id="Office_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={997.169}
        y1={315.468}
        x2={997.169}
        y2={185.94}
        gradientTransform="matrix(1 -.0149 0 1 -.001 -11.861)"
      >
        <stop offset={0} stopColor="#94bbd4" />
        <stop offset={1} stopColor="#d9f0f8" />
      </linearGradient>
      <path
        fill="url(#Office_svg__a)"
        d="M992.13 159.296l16.563 128.99-11.867.467-11.182-128.697z"
      />
      <path
        d="M985.644 160.056l-91.516-1.729-11.113 118.633 113.811 11.794-11.182-128.698zm-88.881-.476l86.369 3.738 10.82 120.747-107.715-9.585 10.526-114.9z"
        fill="#3d1312"
      />
      <path
        fill="#6e82c0"
        d="M897.394 271.408l96.143 8.024.416 4.633-107.715-9.585zM543.523 162.191l-11.719 131.973 9.658-.055 10.416-132.043z"
      />
      <path
        fill="#6e82c0"
        d="M541.768 290.23l101.381 8.504-2.704 4.097-108.641-8.667z"
      />
      <linearGradient
        id="Office_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={641.985}
        y1={325.889}
        x2={641.985}
        y2={188.291}
        gradientTransform="matrix(1 -.0149 0 1 -.001 -11.861)"
      >
        <stop offset={0} stopColor="#94bbd4" />
        <stop offset={1} stopColor="#d9f0f8" />
      </linearGradient>
      <path
        fill="url(#Office_svg__b)"
        d="M631.212 167.024l11.389 137.428 10.154-1.771-13.81-135.772z"
      />
      <path
        d="M631.212 167.024l-93.172 1.388-6.236 125.752 110.797 10.288-11.389-137.428zm-88.371 2.853l87.156-1.298 10.447 134.252-104.812-9.229 7.209-123.725z"
        fill="#782b2d"
      />
      <path
        fill="#5a1e0d"
        d="M522.947 161.883l4.692 8.145 478.989-10.501v-3.331z"
      />
      <path fill="#af643c" d="M1008.693 156.144v-6.226l-489.607 9.689v2.332z" />
      <path
        fill="#481421"
        d="M653.164 157.707l-25.678-5.656-3.389.057-27.343 6.534z"
      />
      <path
        fill="#481421"
        d="M624.097 152.108l-7.181-46.491 34.591-29.267 7.371-1.344 5.175 6.015-8.703 1.368-5.445-1.129-28.505 25.502 5.814 45.294z"
      />
      <path
        fill="#741a29"
        d="M664.053 81.021l21.724 24.81-39.395 3.562 8.968-27.004z"
      />
      <path
        d="M654.074 86.232s6.882 1.555 14.247 5.855c7.366 4.301 13.794 14.074 13.794 14.074l3.662-.331-21.724-24.81-8.703 1.367-1.276 3.845z"
        fill="#481421"
      />
      <path
        fill="#b61f24"
        d="M654.802 159.279l-3.56-21.599 27.658 10.64.142 10.551z"
      />
      <path
        fill="#ef4134"
        d="M616.789 145.285l34.453-7.605 3.56 21.599-36.539.616.232-2.911 33.563-1.063-2.987-15.996-31.81 7.594zm38.222-4.05l2.891 11.799 2.89.305-2.275-10.787z"
      />
      <path
        fill="#ddf0eb"
        d="M652.058 155.921l-2.987-15.996-32.282 7.714 1.706 9.345z"
      />
      <linearGradient
        id="Office_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={657.875}
        y1={135.463}
        x2={657.875}
        y2={117.891}
        gradientTransform="matrix(1 -.0168 0 1 0 -17.84)"
      >
        <stop offset={0} stopColor="#010101" />
        <stop offset={1} stopColor="#7195c2" />
      </linearGradient>
      <path
        opacity={0.38}
        fill="url(#Office_svg__c)"
        d="M648.771 106.724l7.072-17.692 4.949 1.165 6.185 15.245z"
      />
      <linearGradient
        id="Office_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={570.916}
        y1={182.768}
        x2={570.916}
        y2={111.371}
        gradientTransform="matrix(1 -.0168 0 1 0 -17.84)"
      >
        <stop offset={0} stopColor="#c9eaf3" />
        <stop offset={1} stopColor="#79d2f3" />
      </linearGradient>
      <path
        d="M622.669 145.883l-9.684-59.045a4.213 4.213 0 00-4.289-3.533l-85.336 2.353c-2.901.08-4.939 2.952-4.042 5.696l20.127 61.543a4.212 4.212 0 004.294 2.892l74.893-4.85c2.565-.167 4.452-2.528 4.037-5.056z"
        fill="url(#Office_svg__d)"
      />
      <linearGradient
        id="Office_svg__e"
        gradientUnits="userSpaceOnUse"
        x1={574.582}
        y1={178.942}
        x2={574.582}
        y2={115.207}
        gradientTransform="matrix(1 -.0168 0 1 0 -17.84)"
      >
        <stop offset={0} stopColor="#180f24" />
        <stop offset={1} stopColor="#620934" />
      </linearGradient>
      <path
        fill="url(#Office_svg__e)"
        d="M543.902 151.965l-14.711-63.488 80.25.645 10.531 56.975z"
      />
      <path
        fill="#481421"
        d="M519.086 90.643l.018 3.834 17.652 64.906 118.286-1.991v-5.499l-31.595-1.266-82.987 5.374z"
      />
      <path
        fill="#027b8f"
        d="M544.524 149.464l6.827-20.715.051 2.681 3.756-8.423.098 5.132 2.09-6.493.897 2.356 3.841-12.871.223 11.619 2.598-6.651.146 7.659 2.677-2.556 2.41 4.968 1.643-9.68 1.254 6.981 2.3-5.246 5.109 8.214-.132-6.877 3.341 6.122 1.924-6.312 3.513 5.598 3.218-9.857 2.383 5.641 2.771-3.324-.205 4.642 3.063-4.581.086 4.47 2.272-5.291.135 7.065 1.681-3.785 1.747 4.09.395 20.7z"
      />
      <path
        fill="#026278"
        d="M544.524 149.464l6.91-16.367.04 2.112 3.79-6.663.077 4.04 2.116-5.129.888 1.849 3.893-10.167.175 9.15 2.625-5.259.115 6.031 2.686-2.033 2.39 3.893 1.683-7.635 1.224 5.487 2.322-4.148 5.074 6.432-.103-5.416 3.316 4.795 1.948-4.986 3.491 4.381 3.256-7.787 2.36 4.425 2.784-2.641-.223 3.66 3.081-3.633.067 3.521 2.295-4.185.106 5.565 1.695-2.994 1.73 3.207.313 16.302z"
      />
      <path
        fill="#034254"
        d="M548.512 149.519l7.032-15.235 1.095 3.807 1.357-7.123.103 5.366 3.372-12.769 1.201 9.338 3.009-2.93.077 4.021 2.433-6.366 2.799 7.463 1.65-9.366.902 8.693 3.3-8.662 3.731 8.084.899-6.473 3.165 5.216 1.426-5.599 4.948 4.902 1.561-9.745 1.454 7.095 1.42-4.635 2.427 5.368.98-4.565 2.409 5.673 1.261-3.093 1.898 6.217 2.563-6.914.429 18.668-3.505-.685z"
      />
      <linearGradient
        id="Office_svg__f"
        gradientUnits="userSpaceOnUse"
        x1={575.437}
        y1={182.921}
        x2={575.437}
        y2={111.383}
        gradientTransform="matrix(1 -.0168 0 1 0 -17.84)"
      >
        <stop offset={0} stopColor="#010101" />
        <stop offset={1} stopColor="#f7a39e" />
      </linearGradient>
      <path
        d="M582.268 84.034l-47.375 54.939L540.461 156l28.747-1.862 26.332-39.792-12.85 38.764 22.003-1.269 11.286-46.74-2.819-17.194a5.45 5.45 0 00-5.55-4.572l-25.342.699z"
        opacity={0.57}
        fill="url(#Office_svg__f)"
      />
      <g>
        <path
          fill="#fec258"
          d="M710.278 156.663l-4.684-27.204 24.29-.409-4.154 27.544z"
        />
        <path
          d="M709.939 154.695s5.428-3.81 9.979-10.079 5.813-15.496 5.813-15.496l4.154-.07-4.154 27.544-15.416.277-.376-2.176z"
          fill="#f9a148"
        />
        <path
          fill="#d41f26"
          d="M707.357 139.7l21.01-.586-.975 6.457-18.969.32z"
        />
        <path
          fill="#049678"
          d="M724.042 129.148l2.14-11.983 2.185-.036-2.637 11.991z"
        />
        <path
          fill="#d41f26"
          d="M709.939 129.386l-.703-4.689 1.494-.024.951 4.683zm15.791-.266l3.339-17.181 1.766-.029-3.193 17.178z"
        />
        <path
          fill="#f7931e"
          d="M707.357 129.43l-3.793-15.282 2.853-.047 4.041 15.276z"
        />
      </g>
      <linearGradient
        id="Office_svg__g"
        gradientUnits="userSpaceOnUse"
        x1={670.175}
        y1={186.238}
        x2={670.175}
        y2={133.332}
        gradientTransform="matrix(1 -.0168 0 1 0 -17.84)"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        opacity={0.7}
        fill="url(#Office_svg__g)"
        d="M685.777 105.831l45.063 49.766-121.331 2.561 40.119-53.58z"
      />
      <g>
        <ellipse
          cx={567.252}
          cy={294.082}
          rx={567.252}
          ry={6.719}
          // style={{MixBlendMode:"multiply"}}
          style={{ mixBlendMode: "multiply" }}
          fill="#efecf6"
        />
        <path
          d="M770.611 9.972s-13.545 2.563-19.906 10.374c-5.895 7.241-1.642 21.641-1.642 21.641s11.871-22.309 21.548-32.015z"
          fill="#362a5c"
        />
        <path
          d="M764.541 143.052c-.199-.497-19.76-50.287-16.505-105.81l2.493.147c-3.223 54.948 16.135 104.246 16.33 104.737l-2.318.926z"
          fill="#362a5c"
        />
        <linearGradient
          id="Office_svg__h"
          gradientUnits="userSpaceOnUse"
          x1={748.293}
          y1={24.722}
          x2={806.037}
          y2={24.722}
        >
          <stop offset={0} stopColor="#b8acd4" />
          <stop offset={1} stopColor="#e0d1e7" />
        </linearGradient>
        <path
          d="M748.293 37.316S763.899-2.075 806.036.202c0 0-32.943 18.732-55.332 49.135.001 0-2.254-4.661-2.411-12.021z"
          fill="url(#Office_svg__h)"
        />
        <path
          d="M764.013 45.654s-8.555 4.647-13.309 3.683c0 0 3.967-12.222 20.17-28.402C787.735 4.099 803.538-1.147 806.036.203c2.497 1.351-16.386 30.651-42.023 45.451z"
          fill="#4963ae"
        />
        <path
          d="M754.705 41.219s34.424-18.429 40.406-38.098c0 0-22.524 9.878-40.406 38.098z"
          fill="#8fd3cf"
        />
        <path
          fill="#9262a9"
          d="M825.628 149.691v-7.101l-72.73.121 1.425 5.966z"
        />
        <path
          fill="#443972"
          d="M825.628 142.59l156.559-4.253-1.178 7.001-155.381 4.353z"
        />
        <path
          fill="#714d9f"
          d="M765.699 148.838l4.801 7.225h59.303l1.261-6.621z"
        />
        <path
          fill="#362a5c"
          d="M973.087 144.016l-2.415 8.718-140.869 3.329 1.261-6.621zm20.73-13.243l-2.828 7.564-163.928 4.776 2.081-8.34z"
        />
        <path
          fill="#744e9f"
          d="M754.65 55.408l152.777 1.771 86.39 73.594-164.675 4z"
        />
        <path
          d="M761.558 57.55c-1.309.292 63.898 70.537 65.503 71.318 1.603.78 148.401-1.883 150.69-2.507s-69.107-65.926-70.668-67.174-143.131-2.171-145.525-1.637z"
          fill="#8fd3cf"
        />
        <linearGradient
          id="Office_svg__i"
          gradientUnits="userSpaceOnUse"
          x1={754.65}
          y1={95.091}
          x2={880.164}
          y2={95.091}
        >
          <stop offset={0} stopColor="#010101" />
          <stop offset={1} stopColor="#7594cc" />
        </linearGradient>
        <path
          opacity={0.82}
          fill="url(#Office_svg__i)"
          d="M829.142 134.773l51.022-77.91-125.514-1.455z"
        />
        <path
          fill="#8963aa"
          d="M754.65 55.408l-2.496 3.434 74.907 84.271 2.081-8.34z"
        />
        <path
          fill="#744e9f"
          d="M796.633 108.883l-13.225 33.707h3.121l13.738-29.62z"
        />
        <path
          d="M778.725 141.03h-18.884s.118 4.581 1.699 11.421h17.185l2.653-3.613h-9.363l.308-6.158 9.056-.09-2.654-1.56z"
          fill="#362a5c"
        />
        <linearGradient
          id="Office_svg__j"
          gradientUnits="userSpaceOnUse"
          x1={768.831}
          y1={94.989}
          x2={806.037}
          y2={94.989}
        >
          <stop offset={0} stopColor="#e5b0ae" />
          <stop offset={1} stopColor="#683f3f" />
        </linearGradient>
        <path
          opacity={0.2}
          fill="url(#Office_svg__j)"
          d="M768.831 70.518l1.78 9.089 35.426 39.854v-9.305z"
        />
        <linearGradient
          id="Office_svg__k"
          gradientUnits="userSpaceOnUse"
          x1={784.967}
          y1={145.899}
          x2={816.388}
          y2={145.899}
        >
          <stop offset={0} stopColor="#e5b0ae" />
          <stop offset={1} stopColor="#683f3f" />
        </linearGradient>
        <path
          opacity={0.2}
          fill="url(#Office_svg__k)"
          d="M784.967 142.59l6.869 6.619h24.552l-3.746-6.598z"
        />
        <linearGradient
          id="Office_svg__l"
          gradientUnits="userSpaceOnUse"
          x1={764.914}
          y1={152.451}
          x2={764.914}
          y2={141.03}
        >
          <stop offset={0} stopColor="#683f3f" />
          <stop offset={1} stopColor="#e5b0ae" />
        </linearGradient>
        <path
          d="M759.841 141.03h8.989l1.155 11.421h-8.445c.001 0-1.308-4.164-1.699-11.421z"
          opacity={0.2}
          fill="url(#Office_svg__l)"
        />
      </g>
      <g>
        <linearGradient
          id="Office_svg__m"
          gradientUnits="userSpaceOnUse"
          x1={450.564}
          y1={292.443}
          x2={450.564}
          y2={251.094}
        >
          <stop offset={0} stopColor="#b8acd4" />
          <stop offset={1} stopColor="#e0d1e7" />
        </linearGradient>
        <path
          d="M481.516 252.568c-1.868 25.4-11.86 39.875-11.86 39.875h-39.645c-6.824-13.352-9.411-31.223-10.155-37.545l-.081-.732a12.083 12.083 0 01-.082-.799l-.067-.717c-.013-.055-.013-.082-.013-.082 37.045-3.318 61.903 0 61.903 0z"
          fill="url(#Office_svg__m)"
        />
        <path
          d="M419.614 252.572s34.28 3.861 61.902 0c0 0-33.238-4.273-61.902 0z"
          fill="#362a5c"
        />
        <path
          d="M444.998 252.481s4.266.856 6.763 0c0 0-3.225-47.05 0-60.784 0 0 4.266-3.953 5.097-6.449 0 0 .417-48.823 2.289-60.826l-1.456-1.509s-2.498 57.291-3.329 60.854c0 0-6.035 6.475-7.283 9.803l-2.081 58.911z"
          fill="#f59084"
        />
        <linearGradient
          id="Office_svg__n"
          gradientUnits="userSpaceOnUse"
          x1={481.053}
          y1={235.602}
          x2={481.053}
          y2={211.363}
        >
          <stop offset={0} stopColor="#2f62a0" />
          <stop offset={1} stopColor="#2b7cc1" />
        </linearGradient>
        <path
          d="M450.564 235.603s-1.508-2.407 0-4.637c0 0 10.248-7.433 30.951-14.3 20.704-6.865 30.899-5.617 30.692-4.16-.209 1.457-14.982 15.281-28.715 18.46-13.733 3.18-26.01-1.815-26.01-1.815s-5.096 2.705-6.918 6.452z"
          fill="url(#Office_svg__n)"
        />
        <linearGradient
          id="Office_svg__o"
          gradientUnits="userSpaceOnUse"
          x1={442.537}
          y1={237.616}
          x2={442.537}
          y2={192.161}
        >
          <stop offset={0} stopColor="#2f62a0" />
          <stop offset={1} stopColor="#2b7cc1" />
        </linearGradient>
        <path
          d="M462.477 192.321c4.091 1.292-16.23 42.448-28.089 45.153-11.861 2.706-13.318-34.128-11.861-37.664 1.456-3.535 17.27-2.286 17.27-2.286s18.726-6.451 22.68-5.203z"
          fill="url(#Office_svg__o)"
        />
        <linearGradient
          id="Office_svg__p"
          gradientUnits="userSpaceOnUse"
          x1={482.323}
          y1={185.248}
          x2={482.323}
          y2={133.101}
        >
          <stop offset={0} stopColor="#1b53a0" />
          <stop offset={1} stopColor="#2b7cc1" />
        </linearGradient>
        <path
          d="M456.858 185.248s10.82 0 24.657-5.202 28.82-40.958 25.905-45.969c-2.913-5.011-32.838 10.19-41.615 24.329-8.777 14.138-5.409 24.353-5.409 24.353l-3.524 1.009-.014 1.48z"
          fill="url(#Office_svg__p)"
        />
        <linearGradient
          id="Office_svg__q"
          gradientUnits="userSpaceOnUse"
          x1={436.073}
          y1={192.161}
          x2={436.073}
          y2={115.652}
        >
          <stop offset={0} stopColor="#343c98" />
          <stop offset={1} stopColor="#0a66a6" />
        </linearGradient>
        <path
          d="M449.895 192.161s-.53-4.804-3.439-8.394c-5.996-7.404-17.365-18.232-21.849-31.316-6.657-19.432-4.882-34.324-2.545-36.554 2.338-2.229 19.038 11.072 26.475 27.216 7.437 16.143 1.543 34.58-.416 39.646 0 0 1.439 4.282 3.953 5.128l-.312 3.811-1.867.463z"
          fill="url(#Office_svg__q)"
        />
        <linearGradient
          id="Office_svg__r"
          gradientUnits="userSpaceOnUse"
          x1={467.233}
          y1={127.176}
          x2={467.233}
          y2={100.384}
        >
          <stop offset={0} stopColor="#343c98" />
          <stop offset={1} stopColor="#0a66a6" />
        </linearGradient>
        <path
          d="M458.805 127.176s-1.113-10.144 2.007-16.196c3.122-6.05 13.733-11.668 14.982-10.421 1.248 1.25-16.989 26.617-16.989 26.617z"
          fill="url(#Office_svg__r)"
        />
        <linearGradient
          id="Office_svg__s"
          gradientUnits="userSpaceOnUse"
          x1={449.084}
          y1={134.077}
          x2={449.084}
          y2={73.602}
        >
          <stop offset={0} stopColor="#343c98" />
          <stop offset={1} stopColor="#0a66a6" />
        </linearGradient>
        <path
          d="M457.189 134.077s-10.526-10.838-17.601-25.194c-7.075-14.357-5.158-34.541-3.516-35.27 1.642-.728 23.938 32.723 26.939 44.088s-5.822 16.376-5.822 16.376z"
          fill="url(#Office_svg__s)"
        />
        <linearGradient
          id="Office_svg__t"
          gradientUnits="userSpaceOnUse"
          x1={435.118}
          y1={211.769}
          x2={462.477}
          y2={211.769}
        >
          <stop offset={0} stopColor="#313131" />
          <stop offset={1} stopColor="#375f8c" />
        </linearGradient>
        <path
          d="M439.796 197.524s-7.218 29.938-3.723 33.441c3.494 3.504 24.895-30.921 26.403-38.645.001.001-10.791.307-22.68 5.204z"
          opacity={0.82}
          fill="url(#Office_svg__t)"
        />
        <linearGradient
          id="Office_svg__u"
          gradientUnits="userSpaceOnUse"
          x1={484.259}
          y1={182.759}
          x2={484.259}
          y2={134.077}
        >
          <stop offset={0} stopColor="#313131" />
          <stop offset={1} stopColor="#375f8c" />
        </linearGradient>
        <path
          d="M462.477 182.759s25.005-37.289 43.565-48.682c0 0-4.644 48.267-43.565 48.682z"
          opacity={0.82}
          fill="url(#Office_svg__u)"
        />
        <linearGradient
          id="Office_svg__v"
          gradientUnits="userSpaceOnUse"
          x1={434.28}
          y1={178.475}
          x2={434.28}
          y2={115.897}
        >
          <stop offset={0} stopColor="#313131" />
          <stop offset={1} stopColor="#375f8c" />
        </linearGradient>
        <path
          d="M422.061 115.897s19.864 25.348 22.937 38.407c3.074 13.061 2.158 24.17 2.158 24.17s-31.171-18.495-25.095-62.577z"
          opacity={0.82}
          fill="url(#Office_svg__v)"
        />
        <linearGradient
          id="Office_svg__w"
          gradientUnits="userSpaceOnUse"
          x1={447.394}
          y1={127.176}
          x2={447.394}
          y2={73.613}
        >
          <stop offset={0} stopColor="#313131" />
          <stop offset={1} stopColor="#375f8c" />
        </linearGradient>
        <path
          d="M436.073 73.613s23.611 40.13 22.731 53.563c0 0-13.11-10.388-18.523-23.336-5.413-12.949-4.208-30.227-4.208-30.227z"
          opacity={0.82}
          fill="url(#Office_svg__w)"
        />
        <linearGradient
          id="Office_svg__x"
          gradientUnits="userSpaceOnUse"
          x1={462.477}
          y1={220.585}
          x2={509.938}
          y2={220.585}
        >
          <stop offset={0} stopColor="#313131" />
          <stop offset={1} stopColor="#375f8c" />
        </linearGradient>
        <path
          d="M462.477 226.391l47.461-14.622s-10.166 12.061-23.73 16.144-23.731-1.522-23.731-1.522z"
          opacity={0.82}
          fill="url(#Office_svg__x)"
        />
        <linearGradient
          id="Office_svg__y"
          gradientUnits="userSpaceOnUse"
          x1={450.57}
          y1={292.443}
          x2={450.57}
          y2={252.568}
        >
          <stop offset={0} stopColor="#976aad" />
          <stop offset={1} stopColor="#cdecfc" />
        </linearGradient>
        <path
          d="M481.516 252.568c-1.868 25.4-11.86 39.875-11.86 39.875h-39.645c-6.824-13.352-9.411-31.223-10.155-37.545l-.081-.732-.082-.799-.067-.717c.271 1.57 2.326 28.598 12.314 34.715 8.293 5.078 31.603 4.147 35.88-1.512 4.293-5.66 13.696-33.285 13.696-33.285z"
          opacity={0.5}
          fill="url(#Office_svg__y)"
        />
        <linearGradient
          id="Office_svg__z"
          gradientUnits="userSpaceOnUse"
          x1={437.693}
          y1={285.853}
          x2={437.693}
          y2={253.019}
        >
          <stop offset={0} stopColor="#4d1e1e" />
          <stop offset={1} stopColor="#d98c8b" />
        </linearGradient>
        <path
          d="M421.276 253.019s5.498 23.005 17.434 31.754l15.401 1.081V254.69c0-.001-30.143-.545-32.835-1.671z"
          opacity={0.2}
          fill="url(#Office_svg__z)"
        />
      </g>
      <g>
        <linearGradient
          id="Office_svg__A"
          gradientUnits="userSpaceOnUse"
          x1={911.002}
          y1={215.981}
          x2={910.479}
          y2={199.165}
        >
          <stop offset={0} stopColor="#907ebb" />
          <stop offset={1} stopColor="#926eb0" />
        </linearGradient>
        <path
          d="M877.822 215.314h54.607s4.742-9.239 11.299-13.359l-53.955-2.145c0 .001-10.234 7.389-11.951 15.504z"
          fill="url(#Office_svg__A)"
        />
        <linearGradient
          id="Office_svg__B"
          gradientUnits="userSpaceOnUse"
          x1={1007.643}
          y1={197.394}
          x2={1004.971}
          y2={111.615}
        >
          <stop offset={0} stopColor="#907ebb" />
          <stop offset={1} stopColor="#926eb0" />
        </linearGradient>
        <path
          d="M985.562 197.524s33.889-40.536 41.338-86.593c0 0 5.265 54.443-23.451 86.593h-17.887z"
          fill="url(#Office_svg__B)"
        />
        <linearGradient
          id="Office_svg__C"
          gradientUnits="userSpaceOnUse"
          x1={939.153}
          y1={144.017}
          x2={1007.83}
          y2={144.017}
        >
          <stop offset={0} stopColor="#907ebb" />
          <stop offset={1} stopColor="#926eb0" />
        </linearGradient>
        <path
          d="M939.153 197.524s11.222-14.766 19.444-41.461c8.359-27.14 22.715-63.64 49.232-65.555l-61.301 107.016h-7.375z"
          fill="url(#Office_svg__C)"
        />
        <linearGradient
          id="Office_svg__D"
          gradientUnits="userSpaceOnUse"
          x1={985.251}
          y1={200.801}
          x2={985.251}
          y2={91.121}
        >
          <stop offset={0} stopColor="#b8acd4" />
          <stop offset={1} stopColor="#e0d1e7" />
        </linearGradient>
        <path
          d="M941.796 197.524s12.183-16.387 18.893-42.916c7.036-27.817 26.842-65.612 49.627-63.394s19.507 48.411 15.605 66.67c-3.9 18.259-22.473 42.916-22.473 42.916l-7.021-1.561s14.513-12.172 20.287-25.749c5.773-13.578 7.714-29.759-3.944-30.119-15.095-.468-44.435 38.546-52.081 54.152h-18.893z"
          fill="url(#Office_svg__D)"
        />
        <linearGradient
          id="Office_svg__E"
          gradientUnits="userSpaceOnUse"
          x1={939.153}
          y1={215.315}
          x2={939.153}
          y2={197.524}
        >
          <stop offset={0} stopColor="#b8acd4" />
          <stop offset={1} stopColor="#e0d1e7" />
        </linearGradient>
        <path
          d="M1003.449 197.524v4.431h-115.17s-6.711 3.996-10.457 13.359h-2.965s4.526-12.953 14.045-17.79h114.547z"
          fill="url(#Office_svg__E)"
        />
        <path
          d="M987.08 201.955l33.293 91.103h5.387s-16.207-68.684-26.818-91.103H987.08z"
          fill="#592046"
        />
        <linearGradient
          id="Office_svg__F"
          gradientUnits="userSpaceOnUse"
          x1={945.5}
          y1={247.507}
          x2={972.757}
          y2={247.507}
        >
          <stop offset={0} stopColor="#d74d7a" />
          <stop offset={1} stopColor="#891b53" />
        </linearGradient>
        <path
          d="M945.5 201.955l23.098 91.103h4.16s-1.248-53.253-16.221-91.103H945.5z"
          fill="url(#Office_svg__F)"
        />
        <linearGradient
          id="Office_svg__G"
          gradientUnits="userSpaceOnUse"
          x1={861.903}
          y1={287.111}
          x2={908.83}
          y2={205.832}
        >
          <stop offset={0} stopColor="#d74d7a" />
          <stop offset={1} stopColor="#891b53" />
        </linearGradient>
        <path
          d="M889.007 201.955s-12.693 51.654-14.15 92.636h4.785l22.473-92.636h-13.108z"
          fill="url(#Office_svg__G)"
        />
        <path
          d="M932.39 201.955s-12.691 51.654-14.148 92.636h4.785l22.473-92.636h-13.11z"
          fill="#592046"
        />
        <path
          fill="#45203b"
          d="M886.398 203.341l1.559 3.078h112.931l.168-4.464H888.279z"
        />
        <linearGradient
          id="Office_svg__H"
          gradientUnits="userSpaceOnUse"
          x1={1006.503}
          y1={148.174}
          x2={1006.503}
          y2={91.84}
        >
          <stop offset={0} stopColor="#d98c8b" />
          <stop offset={1} stopColor="#4d1e1e" />
        </linearGradient>
        <path
          d="M990.207 99.261s11.474 24.949 10.85 48.913c0 0 5.024-6.002 16.766-5.584 0 0 6.5-28.189 4.646-43.329-.001 0-11.69-16.697-32.262 0z"
          opacity={0.2}
          fill="url(#Office_svg__H)"
        />
        <linearGradient
          id="Office_svg__I"
          gradientUnits="userSpaceOnUse"
          x1={987.887}
          y1={156.064}
          x2={987.887}
          y2={106.189}
        >
          <stop offset={0} stopColor="#d98c8b" />
          <stop offset={1} stopColor="#4d1e1e" />
        </linearGradient>
        <path
          d="M983.673 106.189s8.678 15.299 14.904 43.85l-6.777 6.024s-6.836-25.537-14.604-40.729c.001.001 4.012-6.272 6.477-9.145z"
          opacity={0.2}
          fill="url(#Office_svg__I)"
        />
        <linearGradient
          id="Office_svg__J"
          gradientUnits="userSpaceOnUse"
          x1={890.804}
          y1={292.449}
          x2={890.804}
          y2={206.419}
        >
          <stop offset={0} stopColor="#cdecfc" />
          <stop offset={1} stopColor="#976aad" />
        </linearGradient>
        <path
          d="M882.564 232.453s3.529-17.832 8.836-17.486c5.307.348-11.236 77.482-11.236 77.482l21.281-86.03h-13.488s-4.607 21.737-5.393 26.034z"
          opacity={0.5}
          fill="url(#Office_svg__J)"
        />
        <linearGradient
          id="Office_svg__K"
          gradientUnits="userSpaceOnUse"
          x1={933.684}
          y1={294.591}
          x2={933.684}
          y2={206.419}
        >
          <stop offset={0} stopColor="#cdecfc" />
          <stop offset={1} stopColor="#976aad" />
        </linearGradient>
        <path
          d="M928.384 220.038s3.997-5.036 6.732-1.915c2.736 3.121-12.09 76.468-12.09 76.468l21.314-88.172h-13l-2.956 13.619z"
          opacity={0.5}
          fill="url(#Office_svg__K)"
        />
        <linearGradient
          id="Office_svg__L"
          gradientUnits="userSpaceOnUse"
          x1={958.203}
          y1={257.847}
          x2={958.203}
          y2={206.419}
        >
          <stop offset={0} stopColor="#cdecfc" />
          <stop offset={1} stopColor="#976aad" />
        </linearGradient>
        <path
          d="M948.027 211.926s2.977-3.072 7.98 0c5.005 3.073 12.828 38.171 13.768 45.921 0 0-5.615-37.538-11.564-51.428h-11.579l1.395 5.507z"
          opacity={0.5}
          fill="url(#Office_svg__L)"
        />
        <linearGradient
          id="Office_svg__M"
          gradientUnits="userSpaceOnUse"
          x1={1002.466}
          y1={255.513}
          x2={1002.466}
          y2={206.419}
        >
          <stop offset={0} stopColor="#cdecfc" />
          <stop offset={1} stopColor="#976aad" />
        </linearGradient>
        <path
          d="M991.835 214.967s4.906-3.069 9.221.348c4.314 3.418 15.164 40.198 15.164 40.198s-10.717-38.169-15.332-49.094h-12.176l3.123 8.548z"
          opacity={0.5}
          fill="url(#Office_svg__M)"
        />
      </g>
    </svg>
  );
}

export default SvgOffice;
