// ProductTitleTube
import React from "react";

function SvgProductTitleTube(props) {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57.698"
      height="10"
      viewBox="0 0 57.698 10"
    >
      <path 
        fill="none"
        stroke="#010101"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="10"
        d="M5 5h47.698"
        opacity="0.2"
      ></path>
    </svg>
  );
}

export default SvgProductTitleTube; 