import React from "react";

function SvgLess768S5() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="228.479"
            height="974.393"
            viewBox="0 0 228.479 974.393"
        >
            <path
                fill="none"
                stroke="#010101"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="15"
                d="M 7.501999999999971,966.8929999999999 L 7.499999999999972,863.7629999999999 C 7.499999999999972,847.2629999999999 20.99999999999997,833.7629999999999 37.49999999999997,833.7629999999999 L 190.97899999999998,833.761 C 207.47899999999998,833.761 220.97899999999998,820.261 220.97899999999998,803.761 L 220.97699999999998,715.226 L 152.612,207.226 L 152.614,7.5"
                opacity="0.3"
            />
            <path
                fill="none"
                className="tubefill s5"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 7.501999999999971,966.8929999999999 L 7.499999999999972,863.7629999999999 C 7.499999999999972,847.2629999999999 20.99999999999997,833.7629999999999 37.49999999999997,833.7629999999999 L 190.97899999999998,833.761 C 207.47899999999998,833.761 220.97899999999998,820.261 220.97899999999998,803.761 L 220.97699999999998,715.226 L 152.612,207.226 L 152.614,7.5"
            />
        </svg>
    );
}

export default SvgLess768S5;
