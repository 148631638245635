import * as React from "react";

function SvgSocialtube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={155.567}
      height={78.348}
      viewBox="0.001 -0.001 155.567 78.348"
      {...props}
    >
      <path
        opacity={0.3}
        fill="none"
        stroke="#010101"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M7.501 40.847c0 16.5 13.019 30 28.932 30h36.065c16.5 0 30-13.02 30-28.932l-.004-4.416c0-16.5 13.019-30 28.932-30l16.643.002"
      />
       <path
        className="tubefill servicepath"
        fill="none"
        stroke="#fff"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M7.501 40.847c0 16.5 13.019 30 28.932 30h36.065c16.5 0 30-13.02 30-28.932l-.004-4.416c0-16.5 13.019-30 28.932-30l16.643.002"
      />
    </svg>
  );
}

export default SvgSocialtube;
