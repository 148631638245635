import React from "react";
import Lottie from "react-lottie";
import * as loading from "../assets/json/loadingbarinintro";

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading.default,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const LoadingBarInIntro = () => {
    return (
        <div>
            <Lottie options={defaultOptions} height={120} width={120}/>
        </div>
    );
};

export default LoadingBarInIntro;
