import * as React from "react";

function SvgWebTube1024(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={144.598}
            height={117.856}
            {...props}
        >
            <path
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 137.098,7.5 L 137.098,25.684000000000005 C 137.098,42.184000000000005 124.07900000000001,55.684000000000005 108.167,55.684000000000005 L 37.5,55.879000000000005 C 21.0,55.879000000000005 7.5,68.897 7.5,84.811 L 7.522,110.356"
                opacity={0.3}
            />
            <path
                className="tubefill servicepath"
                fill="none"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 137.098,7.5 L 137.098,25.684000000000005 C 137.098,42.184000000000005 124.07900000000001,55.684000000000005 108.167,55.684000000000005 L 37.5,55.879000000000005 C 21.0,55.879000000000005 7.5,68.897 7.5,84.811 L 7.522,110.356"
            />
        </svg>
    );
}

export default SvgWebTube1024;
