import * as React from "react";

function SvgFb(props) {
  return (
    <svg width={10.721} height={23.229} {...props}>
      <path
        fill="#FFF"
        d="M2.432 23.229h4.677V11.516h3.264l.348-3.922H7.109V5.361c0-.925.187-1.289 1.08-1.289h2.531V0H7.482c-3.48 0-5.05 1.533-5.05 4.467v3.127H0v3.971h2.432v11.664z"
      />
    </svg>
  );
}

export default SvgFb;
