import * as React from "react";

function SvgPacmanHalf(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="113.77"
      height="115.839"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 113.77 115.839"
      xmlSpace="preserve"
    >
      <g>
        <linearGradient
          id="mouth_1_"
          x1="56.85"
          x2="113.77"
          y1="57.623"
          y2="57.623"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ba6c29"></stop>
          <stop offset="1" stopColor="#e98624"></stop>
        </linearGradient>
        <path
          fill="url(#mouth_1_)"
          fillRule="evenodd"
          d="M66.64 58.983l46.5 13.33v3.42l-55.86-17.29-.43-.13 55.95-18.801c.31.98.59 1.931.77 2.541.01.06.03.119.05.17l.08.289c.05.15.07.24.07.24L66.64 58.983z"
          clipRule="evenodd"
        ></path>
        <linearGradient
          id="top_shadow_1_"
          x1="62.938"
          x2="104.259"
          y1="69.811"
          y2="28.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#875422"></stop>
          <stop offset="1" stopColor="#723d19"></stop>
        </linearGradient>
        <path
          fill="url(#top_shadow_1_)"
          fillRule="evenodd"
          d="M113.77 42.753l-47.13 16.23-4.438-.229-5.229-.442-5.923-.388L107 34.464s3.92.209 4.45 1.27c.34.68.88 2.279 1.35 3.779.31.98.59 1.931.77 2.541.01.06.03.119.05.17.03.109.06.21.08.289.05.15.07.24.07.24z"
          clipRule="evenodd"
        ></path>
        <linearGradient
          id="poc_cir_1_"
          x1="56.57"
          x2="56.57"
          y1="115.839"
          y2="0.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f7992d"></stop>
          <stop offset="0.464" stopColor="#fac24e"></stop>
          <stop offset="1" stopColor="#fef277"></stop>
        </linearGradient>
        <path
          fill="url(#poc_cir_1_)"
          fillRule="evenodd"
          d="M113.14 75.733c-4.08 12.17-18.42 40.57-55.23 40.1C25.93 115.413 0 89.903 0 57.913 0 25.934 25.93-.326 57.91.004c31.03.33 48.73 23.279 53.8 36.14.48 1.22.85 2.359 1.1 3.369l-55.96 18.8 56.29 17.42z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FFE2A1"
          fillRule="evenodd"
          d="M56.851 58.312l56.289 17.422c0-.001-30.333-8.084-56.289-17.422"
          clipRule="evenodd"
        ></path>
        <path
          fill="#FFE2A1"
          fillRule="evenodd"
          d="M56.851 58.312L112.8 39.513s-39.347 12.47-55.949 18.799"
          clipRule="evenodd"
        ></path>
        <defs>
          <path
            id="SVGID_1_"
            d="M107.001 27.204C96.761 10.871 78.611.008 57.913.008 25.928.008.001 25.933.001 57.917c0 31.982 25.926 57.912 57.912 57.912 18.913 0 35.707-9.067 46.272-23.092L56.851 58.312l50.15-31.108z"
          ></path>
        </defs>
        <clipPath id="SVGID_2_">
          <use overflow="visible" xlinkHref="#SVGID_1_"></use>
        </clipPath>
        <linearGradient
          id="SVGID_3_"
          x1="-356.595"
          x2="-336.846"
          y1="1147.234"
          y2="1098.487"
          gradientTransform="matrix(.9719 .0716 .0716 .8178 337.276 -857.322)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f7ec28"></stop>
          <stop offset="0.285" stopColor="#f9f075" stopOpacity="0.943"></stop>
          <stop offset="0.942" stopColor="#fefdf1" stopOpacity="0.812"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0.8"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_3_)"
          d="M76.399 59.392l-8.211-3.25L89.546 1.709l8.211 3.25z"
          clipPath="url(#SVGID_2_)"
          opacity="0.6"
        ></path>
        <linearGradient
          id="SVGID_4_"
          x1="795.056"
          x2="770.928"
          y1="-5.668"
          y2="102.352"
          gradientTransform="matrix(.9835 .1686 -.1408 .9425 -730.486 -133.368)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f7ec28"></stop>
          <stop offset="0.285" stopColor="#f9f075" stopOpacity="0.715"></stop>
          <stop offset="0.942" stopColor="#fefdf1" stopOpacity="0.058"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </linearGradient>
        <path
          fill="url(#SVGID_4_)"
          d="M40.155 114.612l-60.704-23.881L22.814-19.703 83.518 4.178z"
          clipPath="url(#SVGID_2_)"
          opacity="0.6"
        ></path>
        <path
          fill="#FFF"
          fillRule="evenodd"
          d="M61.005 108.068c-28.156 0-50.979-22.824-50.979-50.979 0-21.265 13.022-39.479 31.524-47.125C20.438 16.198 5.027 35.718 5.027 58.851c0 28.153 22.823 50.979 50.979 50.979a50.789 50.789 0 0019.352-3.824 51.006 51.006 0 01-14.353 2.062z"
          clipRule="evenodd"
          opacity="0.6"
        ></path>
      </g>
    </svg>
  );
}

export default SvgPacmanHalf;
