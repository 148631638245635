import * as React from "react";

function SvgMediaTube1024(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={156.151}
            height={136.317}
            {...props}
        >
            <path
                opacity={0.3}
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 7.5,7.5 L 7.501000000000015,35.501999999999995 C 7.501000000000015,52.001999999999995 20.519000000000013,65.502 36.433000000000014,65.502 L 63.10100000000001,65.467 C 79.60100000000001,65.467 93.10100000000001,78.487 93.10100000000001,94.399 L 93.07600000000001,98.81700000000001 C 93.07600000000001,115.31700000000001 106.095,128.817 122.007,128.817 L 148.651,128.815"
            />
            <path
                className="tubefill servicepath"
                fill="none"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 7.5,7.5 L 7.501000000000015,35.501999999999995 C 7.501000000000015,52.001999999999995 20.519000000000013,65.502 36.433000000000014,65.502 L 63.10100000000001,65.467 C 79.60100000000001,65.467 93.10100000000001,78.487 93.10100000000001,94.399 L 93.07600000000001,98.81700000000001 C 93.07600000000001,115.31700000000001 106.095,128.817 122.007,128.817 L 148.651,128.815"
            />
        </svg>
    );
}

export default SvgMediaTube1024;
