import React, { Component, useEffect } from "react";
import Slider from "react-slick";
import api from "../../context/apiLink";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function SliderOurClients({ cD }) {
  const [clients, setClients] = React.useState({});
  const linkImg = `${api}/public/images/clients/`;

  useEffect(() => {
    setClients(cD);
  }, [cD]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div>
      <Slider {...settings}>
        {clients.length &&
          clients.map((e, key) => {
            return (
              <div className="client" key={key}>
                <img
                  src={linkImg + e.client_name + "/" + e.client_logo}
                  className={"client_logo"}
                  alt={e.client_link}
                  title={e.client_link}
                />
              </div>
            );
          })}
      </Slider>
    </div>
  );
}
