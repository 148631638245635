import React, {useEffect} from "react";
import SvgBaseTube from "../../assets/svg/SvgJs/Services/BaseTube";

import SvgBranding from "../../assets/svg/SvgJs/Services/Branding";
import SvgBrandingtube from "../../assets/svg/SvgJs/Services/V2/Brandingtube";
import SvgGraphic from "../../assets/svg/SvgJs/Services/Graphic";
import SvgGraphictube from "../../assets/svg/SvgJs/Services/V2/Graphictube";
import SvgMedia from "../../assets/svg/SvgJs/Services/Media";
import SvgMediatube from "../../assets/svg/SvgJs/Services/V2/Mediatube";
import SvgMiddtube from "../../assets/svg/SvgJs/Services/Middtube";
import SvgPrinting from "../../assets/svg/SvgJs/Services/Printing";
import SvgPrintingtube from "../../assets/svg/SvgJs/Services/V2/Printingtube";
import SvgSocial from "../../assets/svg/SvgJs/Services/Social";
import SvgSocialtube from "../../assets/svg/SvgJs/Services/V2/Socialtube";
import SvgWeb from "../../assets/svg/SvgJs/Services/Web";
import SvgWebtube from "../../assets/svg/SvgJs/Services/V2/Webtube";
import * as res from "../../helpers/responsive"

import SvgBrandingTube1024 from "../../assets/svg/SvgJs/Responsive/less_1024/services/BrandingTube";
import SvgGraphicTube1024 from "../../assets/svg/SvgJs/Responsive/less_1024/services/GraphicTube";
import SvgMediaTube1024 from "../../assets/svg/SvgJs/Responsive/less_1024/services/MediaTube";
import SvgPrintingTube1024 from "../../assets/svg/SvgJs/Responsive/less_1024/services/PrintingTube";
import SvgSocialTube1024 from "../../assets/svg/SvgJs/Responsive/less_1024/services/SocialTube";
import SvgWebTube1024 from "../../assets/svg/SvgJs/Responsive/less_1024/services/WebTube";
import {qsa} from "../../helpers/helpers";
import {IsDeskTop} from "../../hooks/resCheck";
import SvgLeftService from "../../assets/svg/SvgJs/Responsive/less_768/services/LeftService";
import SvgRightService from "../../assets/svg/SvgJs/Responsive/less_768/services/RightService";
import SvgLeftService480 from "../../assets/svg/SvgJs/Responsive/less_480/services/LeftService";
import SvgRightService480 from "../../assets/svg/SvgJs/Responsive/less_480/services/RightService";

function OurServicesCatSectionV2() {

    let categoriesPer = {}
    let topPer = {}
    if (res.DesktopCheck()) {
        categoriesPer = {
            "branding": 355,
            "graphic": 423,
            "web": 273,
            "social": 325,
            "media": 425,
            "printing": 235,
        }
        topPer = {
            "branding": 0.26,
            "graphic": 0.351,
            "web": 0.507,
            "social": 0.3255,
            "media": 0.4166,
            "printing": 0.5729,
        }
    } else if (res.TabletCheck()) {
        categoriesPer = {
            "branding": 285,
            "graphic": 320,
            "web": 240,
            "social": 290,
            "media": 312.5,
            "printing": 220,
        }
        topPer = {
            "branding": 0.26,
            "graphic": 0.351,
            "web": 0.507,
            "social": 0.3255,
            "media": 0.4166,
            "printing": 0.5729,
        }
        // let leftIcons = qsa(".service-path.left")
        // leftIcons.forEach(icon => {
        //     let rightTube = icon.querySelector(".tube.clip")
        //     rightTube.style.clip = `rect(0, ${rightTube.offsetWidth - 14}px, ${rightTube.offsetHeight}px, 0)`
        // })
    } else if (res.MobileCheck()) {
        categoriesPer = {
            "branding": 156,
            "graphic": 175,
            "web": 175,
            "social": 181,
            "media": 181,
            "printing": 185,
        }
        topPer = {
            "branding": 0.1753363740022805,
            "graphic": 0.4068072976054732,
            "web": 0.6827480045610034,
            "social": 0.1639338654503991,
            "media": 0.44557582668187,
            "printing": 0.7158152793614595,
        }
    } else if (res.LessMobileCheck()) {
        // categoriesPer = {
        //     "branding": 80,
        //     "graphic": 80,
        //     "web": 80,
        //     "social": 80,
        //     "media": 80,
        //     "printing": 80,
        // }
        // topPer = {
        //     "branding": 0.122,
        //     "graphic": 0.375,
        //     "web": 0.692,
        //     "social": 0.263,
        //     "media": 0.550,
        //     "printing": 0.818,
        // }
        categoriesPer = {
            "branding": 130,
            "graphic": 130,
            "web": 130,
            "social": 130,
            "media": 130,
            "printing": 130,
        }
        topPer = {
            "branding": 0.1753363740022805,
            "graphic": 0.4068072976054732,
            "web": 0.6827480045610034,
            "social": 0.1639338654503991,
            "media": 0.44557582668187,
            "printing": 0.7158152793614595,
        }
    }

    useEffect(() => {


    }, []);

    return (
        <div className="page sectionOurServicesCat">
            <div className="tube main-tube">
                <SvgMiddtube/>
            </div>

            <div className="services">
                <div className="tubes">
                    <div
                        data-per={topPer['branding']}
                        data-level={1}
                        data-type="branding"
                        className="service-path left branding"
                    >
                        <div className="icon">
                            <SvgBranding/>
                            <span className="ser-name">branding</span>
                        </div>
                        <div className="tube clip">
                            <res.Desktop>
                                <SvgBrandingtube/>
                            </res.Desktop>
                            <res.Tablet>
                                <SvgBrandingTube1024/>
                            </res.Tablet>
                            <res.Mobile>
                                <SvgLeftService/>
                            </res.Mobile>
                            <res.LessMobile>
                                <SvgLeftService480/>
                            </res.LessMobile>
                        </div>
                        <div className="tube-left">
                            <SvgBaseTube pre={categoriesPer["branding"]}/>
                        </div>
                    </div>
                    <div
                        data-per={topPer['graphic']}
                        data-level={2}
                        data-type="graphic"
                        className="service-path left graphic"
                    >
                        <div className="icon">
                            <SvgGraphic/>
                            <span className="ser-name">graphic</span>
                        </div>
                        <div className="tube clip">
                            <res.Desktop>
                                <SvgGraphictube/>
                            </res.Desktop>
                            <res.Tablet>
                                <SvgGraphicTube1024/>
                            </res.Tablet>
                            <res.Mobile>
                                <SvgLeftService/>
                            </res.Mobile>
                            <res.LessMobile>
                                <SvgLeftService480/>
                            </res.LessMobile>
                        </div>
                        <div className="tube-left">
                            <SvgBaseTube pre={categoriesPer['graphic']}/>
                        </div>
                    </div>
                    <div
                        data-per={topPer['web']}
                        data-level={3}
                        data-type="web"
                        className="service-path left web"
                    >
                        <div className="icon web">
                            <SvgWeb/>
                            <span className="ser-name">web services</span>
                        </div>
                        <div className="tube clip">
                            <res.Desktop>
                                <SvgWebtube/>
                            </res.Desktop>
                            <res.Tablet>
                                <SvgWebTube1024/>
                            </res.Tablet>
                            <res.Mobile>
                                <SvgLeftService/>
                            </res.Mobile>
                            <res.LessMobile>
                                <SvgLeftService480/>
                            </res.LessMobile>
                        </div>
                        <div className="tube-left">
                            <SvgBaseTube pre={categoriesPer['web']}/>
                        </div>
                    </div>
                    <div
                        data-per={topPer['printing']}
                        data-level={3}
                        data-type="printing"
                        className="service-path right printing"
                    >
                        <div className="icon">
                            <SvgPrinting/>
                            <span className="ser-name">printing</span>
                        </div>
                        <div className="tube clip">
                            <res.Desktop>
                                <SvgPrintingtube/>
                            </res.Desktop>
                            <res.Tablet>
                                <SvgPrintingTube1024/>
                            </res.Tablet>
                            <res.Mobile>
                                <SvgRightService/>
                            </res.Mobile>
                            <res.LessMobile>
                                <SvgRightService480/>
                            </res.LessMobile>
                        </div>
                        <div className="tube-left">
                            <SvgBaseTube pre={categoriesPer['printing']}/>
                        </div>
                    </div>
                    <div
                        data-per={topPer['media']}
                        data-level={2}
                        data-type="media"
                        className="service-path right media"
                    >
                        <div className="icon">
                            <SvgMedia/>
                            <span className="ser-name">media</span>
                        </div>
                        <div className="tube clip">
                            <res.Desktop>
                                <SvgMediatube/>
                            </res.Desktop>
                            <res.Tablet>
                                <SvgMediaTube1024/>
                            </res.Tablet>
                            <res.Mobile>
                                <SvgRightService/>
                            </res.Mobile>
                            <res.LessMobile>
                                <SvgRightService480/>
                            </res.LessMobile>
                        </div>
                        <div className="tube-left">
                            <SvgBaseTube pre={categoriesPer['media']}/>
                        </div>
                    </div>
                    <div
                        data-per={topPer['social']}
                        data-level={1}
                        data-type="social"
                        className="service-path right social"
                    >
                        <div className="icon">
                            <SvgSocial/>
                            <span className="ser-name">social media</span>
                        </div>
                        <div className="tube clip">
                            <res.Desktop>
                                <SvgSocialtube/>
                            </res.Desktop>
                            <res.Tablet>
                                <SvgSocialTube1024/>
                            </res.Tablet>
                            <res.Mobile>
                                <SvgRightService/>
                            </res.Mobile>
                            <res.LessMobile>
                                <SvgRightService480/>
                            </res.LessMobile>
                        </div>
                        <div className="tube-left">
                            <SvgBaseTube pre={categoriesPer['social']}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurServicesCatSectionV2;
