import React from "react";
import SvgBgStarsServices from "../../assets/svg/SvgJs/BgStarsServices";
import SvgMoon from "../../assets/svg/SvgJs/Moon";
import SvgRoket from "../../assets/svg/SvgJs/Roket";
import SvgS7 from "../../assets/svg/SvgJs/Tubes/S7";

function OurServicesSection({ sD }) {

  const bg = {background: "#27363b"}
  
  return (
    <div className="page sectionOurServices">
      <div className="title">
        <h3>Our Service</h3>
      </div>
      <div className="content">
        <div className="paragraph">{sD}</div>
      </div>
      <div className="tube">
        <SvgS7 />
        <div className="tubeh"></div>
        <div className="tubeh tubeh2"></div>
      </div>
      <div className="background">
        <SvgBgStarsServices />
      </div>
      <div className="roket" style={bg}>
        <SvgRoket />
      </div>
      <div className="moon" style={bg}>
        <SvgMoon />
      </div>
    </div>
  );
}

export default OurServicesSection;
