import * as React from "react";

function SvgBrandingTube1024(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={131.15}
            height={78.502}
            {...props}
        >
            <path
                opacity={0.3}
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 123.651,41.0 C 123.651,57.5 110.632,71.0 94.72,71.0 L 87.999,71.00200000000001 C 72.08699999999999,71.00200000000001 59.065999999999995,57.502 59.065999999999995,41.002 L 59.074,37.5 C 59.074,21.0 46.057,7.5 30.143,7.5 L 7.5,7.502"
            />
            <path
                className="tubefill servicepath"
                fill="none"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 123.651,41.0 C 123.651,57.5 110.632,71.0 94.72,71.0 L 87.999,71.00200000000001 C 72.08699999999999,71.00200000000001 59.065999999999995,57.502 59.065999999999995,41.002 L 59.074,37.5 C 59.074,21.0 46.057,7.5 30.143,7.5 L 7.5,7.502"
            />
        </svg>
    );
}

export default SvgBrandingTube1024;
