import * as React from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
function SvgBaseTube(props) {
  const size = useWindowSize();
  const [dd, setDD] = React.useState("M 293 7.5 h -110 h 0 Z");
  const [ww, setWW] = React.useState(0);

  React.useEffect(() => {
    let w = size.width / 2 - props.pre + 20;
    setDD(`M ${w - 7} 7.5h -${w - 14} 0 z`);
    setWW(w);
  }, [size.width]);

  return (
    <svg width={ww} height="15" x="0" y="0" viewBox={"0 0 " + ww + " 15"}>
      <path
        fill="none"
        stroke="#010101"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="15"
        d={dd}
        opacity="0.3"
      ></path>
      <path
        className="tubefill"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="15"
        d={dd}
      ></path>
    </svg>
  );
}

export default SvgBaseTube;
