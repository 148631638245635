import React, {useEffect} from "react";
import SvgProjectsTube from "../../assets/svg/SvgJs/Tubes/ProjectsTube";
import Navbar from "../../Components/Nav/Navbar";
import UpProjectLeft from "../../assets/svg/SvgJs/UpProjectLeft";
import UpProjectRight from "../../assets/svg/SvgJs/UpProjectRight";
import api from "../../context/apiLink";
import SvgProductTitleTube from "../../assets/svg/SvgJs/Tubes/ProductTitleTube";
import url from "../../helpers/url";
import {qs} from "../../helpers/helpers";
import {LessMobileCheck} from "../../helpers/responsive";

function ServiceProjectsSection({service, type}) {
    const [projects, setProjects] = React.useState({});
    const [currtype, setCurrType] = React.useState("");

    const linkImg = `${api}/public/images/`;

    useEffect(() => {
        setProjects(service);
        setCurrType(type);
        // console.log(service, type)
    }, [service, type]);

    return (
        <div className="sectionServiceProducts">
            <div className="up_project">
                <div className="img right">
                    <UpProjectRight/>
                </div>
                <div className="img left">
                    <UpProjectLeft/>
                </div>
                <div className="titles">
                    <h1 className="title">Nothing Here</h1>
                    <h6 className="subtitle">You Should back</h6>
                </div>
            </div>
            {projects && "length" in projects && projects.length === 1 ? (
                (() => {
                    document.title = `Creative Twinkles | ${projects[0]["title"]}`;
                    qs(".sectionServiceProducts .tube").classList.add("hidden");
                    return (
                        <>
                            <div className="content content_single_project">
                                <div className="top">
                                    <div className="title">
                                        <h3>Social Media</h3>
                                    </div>
                                    <div className="arrow">
                                        <svg
                                            width="98.402px"
                                            height="43.686px"
                                            viewBox="0 0 98.402 45.686"
                                        >
                                            <g fill="#000">
                                                <path
                                                    d="M29.66 12.913h52.019v21.675H29.66zm-11.178 0h7.467v21.675h-7.467zm-15.469 0h7.466v21.675H3.013zm9.799-.002h3.703v21.678h-3.703z"/>
                                                <path d="M80.493 45.243l20.922-21.844-21.18-21.143z"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="single_project">
                                <div className="content">
                                    <div className="project">
                                        <div className="presentation">
                                            {typeof projects[0].project_photos == "object" &&
                                            projects[0].project_photos.length &&
                                            projects[0].project_photos.map((e, key) => {
                                                let name = e.photo_link.split(".");
                                                let exten = name[name.length - 1];
                                                let videosExten = ["mp4", "flv", "mkv"];
                                                let isVid = videosExten.indexOf(exten) != -1;
                                                return (
                                                    <div
                                                        className={!isVid ? "image" : "video"}
                                                        key={key}
                                                    >
                                                        {!isVid ? (
                                                            <img
                                                                src={url(
                                                                    api,
                                                                    type,
                                                                    projects[0].title,
                                                                    e.photo_link
                                                                )}
                                                                alt={e.seo_title}
                                                            />
                                                        ) : (
                                                            <video
                                                                className="full"
                                                                height="100%"
                                                                width="100%"
                                                                loop
                                                                controls
                                                                alt={e.seo_title}
                                                                src={url(
                                                                    api,
                                                                    type,
                                                                    projects[0].title,
                                                                    e.photo_link
                                                                )}
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                })()
            ) : (
                <div className="content">
                    <div className="top">
                        <div className="title">
                            <h3>Social Media</h3>
                        </div>
                        <div className="arrow">
                            <svg
                                width="98.402px"
                                height="43.686px"
                                viewBox="0 0 98.402 45.686"
                            >
                                <g fill="#000">
                                    <path
                                        d="M29.66 12.913h52.019v21.675H29.66zm-11.178 0h7.467v21.675h-7.467zm-15.469 0h7.466v21.675H3.013zm9.799-.002h3.703v21.678h-3.703z"/>
                                    <path d="M80.493 45.243l20.922-21.844-21.18-21.143z"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    <div className="products">
                        {typeof projects == "object" &&
                        projects.length ?
                            projects.map((e, key) => {
                                return (
                                    <div className="product" key={key} data-index={key}>
                                        <div className="img-container">
                                            <a className="img-link">
                                                <img
                                                    src={
                                                        linkImg +
                                                        currtype +
                                                        "/" +
                                                        e.title +
                                                        "/" +
                                                        e.main_photo
                                                    }
                                                    alt={e.title}
                                                    className="img-product"
                                                />
                                            </a>
                                            <div className="details">
                                                <div className="info">
                                                    <a>{e.title}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }) : null}
                    </div>
                </div>
            )}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="tube">
                <SvgProjectsTube/>
            </div>
        </div>
    );
}

export default ServiceProjectsSection;
