import * as React from "react";

function Less768FooterLight(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1211.427"
            height="1109.387"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 1211.427 1109.387"
            xmlSpace="preserve"
            {...props}
        >
            <radialGradient
                id="less_768_footer_light_1_"
                cx="-1418.963"
                cy="-1613.352"
                r="33.374"
                gradientTransform="matrix(-1.7857 0 0 1.7857 -2320.276 2963.055)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#0F4A7D"></stop>
                <stop offset="0.351" stopColor="#10497C"></stop>
                <stop offset="0.527" stopColor="#134577"></stop>
                <stop offset="0.664" stopColor="#173E6E"></stop>
                <stop offset="0.781" stopColor="#193563"></stop>
                <stop offset="0.885" stopColor="#182954"></stop>
                <stop offset="0.979" stopColor="#151943"></stop>
                <stop offset="1" stopColor="#14143E"></stop>
            </radialGradient>
            <path
                fill="url(#less_768_footer_light_1_)"
                d="M218.967 93.532c-41.614 19.623-77.778 30.42-80.757 24.094-2.457-5.191 18.303-20.348 48.924-36.486a538.052 538.052 0 0121.037-10.488 520.536 520.536 0 0115.771-7.125c34.627-15.004 62.391-22.482 64.987-16.969 2.979 6.31-28.347 27.349-69.962 46.974z"
            ></path>
            <path
                fill="#321550"
                d="M39.017 706.744L149.527 706.744 145.042 702.625 90.648 698.506 88.287 698.746 49.013 702.625z"
            ></path>
            <path
                fill="#321550"
                d="M90.829 704.824L80.808 52.909 160.485 11.198 178.032 36.102 183.497 32.209 161.396 5.635 76.97 51.575 88.485 704.824z"
            ></path>
            <radialGradient
                id="less_768_footer_light_2_"
                cx="2458.568"
                cy="-1626.358"
                r="39.34"
                gradientTransform="translate(-4181.364 2963.055) scale(1.7857)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#0F4A7D"></stop>
                <stop offset="0.351" stopColor="#10497C"></stop>
                <stop offset="0.527" stopColor="#134577"></stop>
                <stop offset="0.664" stopColor="#173E6E"></stop>
                <stop offset="0.781" stopColor="#193563"></stop>
                <stop offset="0.885" stopColor="#182954"></stop>
                <stop offset="0.979" stopColor="#151943"></stop>
                <stop offset="1" stopColor="#14143E"></stop>
            </radialGradient>
            <path
                fill="url(#less_768_footer_light_2_)"
                d="M288.93 46.557l-82.663 38.99-68.242 32.186-8.814-69.287c-1.98-3.992 4.73-11.686 16.493-20.01 6.665-4.713 14.973-9.643 24.232-14.234C195.058 1.793 217.923-3.412 221.67 2.333l67.26 44.224z"
            ></path>
            <path
                fill="#7D2986"
                d="M258.463 50.303l-52.196 35.244-68.242 32.186-8.814-69.287c-1.98-3.992 4.73-11.686 16.493-20.01 0 0 15.142 47.82 17.261 49.938 2.118 2.119 95.498-28.071 95.498-28.071z"
                opacity="0.14"
            ></path>
            <linearGradient
                id="less_768_footer_light_3_"
                x1="3219.815"
                x2="3361.485"
                y1="81.082"
                y2="81.082"
                gradientTransform="translate(-3077.706)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="#FFF0D1"></stop>
                <stop offset="1" stopColor="#FEE2C9"></stop>
            </linearGradient>
            <path
                fill="url(#less_768_footer_light_3_)"
                d="M218.005 91.807c-39.014 18.398-72.92 28.52-75.711 22.59-2.304-4.867 17.159-19.076 45.866-34.207a506.73 506.73 0 0119.725-9.834 486.898 486.898 0 0114.784-6.678c32.465-14.066 58.494-21.078 60.926-15.908 2.794 5.916-26.576 25.639-65.59 44.037z"
            ></path>
            <path
                fill="#FFF"
                d="M216.046 67.686c0 7.023-5.701 12.723-12.723 12.723a12.638 12.638 0 01-8.699-3.443 317.313 317.313 0 0112.223-6.096 304.542 304.542 0 019.164-4.139c.026.312.035.635.035.955z"
            ></path>
            <linearGradient
                id="less_768_footer_light_4_"
                x1="0"
                x2="1211.427"
                y1="578.578"
                y2="578.578"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0" stopColor="silver"></stop>
                <stop offset="0.908" stopColor="#A9A9A9" stopOpacity="0.273"></stop>
                <stop offset="1" stopColor="#A7A7A7" stopOpacity="0.2"></stop>
            </linearGradient>
            <path
                fill="url(#less_768_footer_light_4_)"
                d="M283.595 47.77l927.833 381.299-4.229 678.666L0 1109.387s141.062-965.464 142.109-991.654S283.595 47.77 283.595 47.77z"
                opacity="0.6"
            ></path>
        </svg>
    );
}

export default Less768FooterLight;
