import * as React from "react";

function SvgBranding(props) {
  return (
    <svg width="130.88" height="166.17" viewBox="0 0 130.88 166.17" {...props}>
      <radialGradient
        id="branding_svg__a"
        cx={151.899}
        cy={203.751}
        r={69.425}
        gradientTransform="translate(-77.74 -126.616) scale(.9426)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.21} stopColor="#f7921e" />
        <stop offset={0.606} stopColor="#f7991f" stopOpacity={0.422} />
        <stop offset={0.725} stopColor="#f79d20" stopOpacity={0.25} />
        <stop offset={0.737} stopColor="#f89e20" stopOpacity={0.238} />
        <stop offset={0.971} stopColor="#fcb717" stopOpacity={0} />
      </radialGradient>
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__a)"
        cx={65.44}
        cy={65.439}
        r={65.44}
      />
      <linearGradient
        id="branding_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={70.342}
        y1={166.17}
        x2={70.342}
        y2={148.516}
      >
        <stop offset={0} stopColor="#898989" />
        <stop offset={0.983} stopColor="#5d3c82" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__b)"
        d="M64.969 149.551s0 16.24 1.814 16.609c1.814.367 7.761-9.514 8.933-17.645l-10.747 1.036z"
      />
      <linearGradient
        id="branding_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={57.64}
        y1={165.791}
        x2={57.64}
        y2={150.536}
      >
        <stop offset={0} stopColor="#898989" />
        <stop offset={0.983} stopColor="#5d3c82" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__c)"
        d="M51.71 150.536s6 14.158 8.269 15.205c2.268 1.047 3.591-14.693 3.591-14.693l-11.86-.512z"
      />
      <linearGradient
        id="branding_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={55.365}
        y1={152.71}
        x2={71.774}
        y2={152.71}
      >
        <stop offset={0.006} stopColor="#281742" />
        <stop offset={1} stopColor="#332d45" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__d)"
        d="M55.365 151.211s4.16 3.713 8.976 3.295c4.177-.363 7.433-3.625 7.433-3.625l-16.409.33z"
      />
      <radialGradient
        id="branding_svg__e"
        cx={150.085}
        cy={188.186}
        r={78.13}
        gradientTransform="matrix(.9426 0 0 1.0074 -77.74 -138.815)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="gold" />
        <stop offset={0.175} stopColor="#fed500" />
        <stop offset={0.32} stopColor="#fbcd0b" />
        <stop offset={0.456} stopColor="#f6bf16" />
        <stop offset={0.586} stopColor="#f1ad1d" />
        <stop offset={0.711} stopColor="#ea9522" />
        <stop offset={0.824} stopColor="#e37d25" />
        <stop offset={0.869} stopColor="#e27826" />
        <stop offset={0.934} stopColor="#dd6928" />
        <stop offset={1} stopColor="#d6532a" />
      </radialGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__e)"
        d="M110.82 71.318c-1.256-24.705-21.146-41.244-45.851-41.035-24.705-.209-44.594 16.33-45.851 41.035-1.256 24.706 19.68 43.967 23.239 48.364s3.769 11.514 3.769 11.514l18.843 7.328 18.843-7.328s.209-7.117 3.768-11.514c3.56-4.396 24.496-23.658 23.24-48.364z"
      />
      <path
        fill="#FBF49D"
        d="M65.433 31.957c11.597 0 22.358 3.867 30.299 10.892 8.195 7.248 12.834 17.122 13.415 28.555 1.067 20.988-14.813 38.351-20.792 44.888-.899.982-1.609 1.76-2.076 2.336-3.023 3.734-3.844 9.08-4.063 11.393l-17.246 6.707-17.247-6.707c-.219-2.313-1.04-7.658-4.063-11.393-.466-.576-1.177-1.354-2.076-2.336-5.979-6.537-21.859-23.9-20.792-44.888.581-11.433 5.22-21.308 13.414-28.556 7.94-7.023 18.701-10.893 30.3-10.891l.478.001.449-.001zm0-1.676c-.155 0-.308 0-.463.002-.154-.002-.31-.002-.463-.002-24.492-.001-44.14 16.487-45.388 41.037-1.256 24.706 19.68 43.967 23.239 48.364s3.769 11.514 3.769 11.514l18.843 7.328 18.843-7.328s.209-7.117 3.768-11.514c3.561-4.396 24.496-23.658 23.24-48.364-1.249-24.548-20.9-41.037-45.388-41.037z"
      />
      <linearGradient
        id="branding_svg__f"
        gradientUnits="userSpaceOnUse"
        x1={45.458}
        y1={138.656}
        x2={84.72}
        y2={138.656}
      >
        <stop offset={0.034} stopColor="#a5a4c1" />
        <stop offset={0.272} stopColor="#8369aa" />
        <stop offset={1} stopColor="#b9b9b9" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__f)"
        d="M45.458 126.577l2.204 22.695s12.143 5.582 32.521-.279l4.537-23.354s-16.088 5.033-39.262.938z"
      />
      <linearGradient
        id="branding_svg__g"
        gradientUnits="userSpaceOnUse"
        x1={43.195}
        y1={131.45}
        x2={87.685}
        y2={131.45}
      >
        <stop offset={0.034} stopColor="#e0cddc" />
        <stop offset={0.272} stopColor="#9680b7" />
        <stop offset={1} stopColor="#cfd0d0" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__g)"
        d="M46.074 132.61s-2.174 2.092-2.879 4.188l3.423 1.727s23.85 1.52 37.194-7.328c0 0 2.191-2.045 3.873-5.557l-2.547-1.363-.908 2c0-.001-19.313 11.044-38.156 6.333z"
      />
      <linearGradient
        id="branding_svg__h"
        gradientUnits="userSpaceOnUse"
        x1={43.195}
        y1={132.119}
        x2={87.685}
        y2={132.119}
      >
        <stop offset={0} stopColor="#542266" />
        <stop offset={1} stopColor="#90949b" />
      </linearGradient>
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__h)"
        d="M43.195 136.797s28.579 3.527 44.49-11.158c0 0-1.277 4.029-3.873 5.557 0 0-10.322 8.27-37.194 7.328l-3.423-1.727z"
      />
      <linearGradient
        id="branding_svg__i"
        gradientUnits="userSpaceOnUse"
        x1={45.458}
        y1={143.585}
        x2={84.72}
        y2={143.585}
      >
        <stop offset={0.034} stopColor="#e0cddc" />
        <stop offset={0.272} stopColor="#9680b7" />
        <stop offset={1} stopColor="#cfd0d0" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__i)"
        d="M47.169 144.198l-1.711 2.018 2.096 1.943s20.018 2.381 33.79-5.145l3.376-3.549-2.529-.811c0 .001-13.559 7.561-35.022 5.544z"
      />
      <linearGradient
        id="branding_svg__j"
        gradientUnits="userSpaceOnUse"
        x1={45.458}
        y1={144.018}
        x2={84.72}
        y2={144.018}
      >
        <stop offset={0} stopColor="#542266" />
        <stop offset={1} stopColor="#90949b" />
      </linearGradient>
      <path
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__j)"
        d="M45.458 146.215s21.327 3.195 39.262-6.75l-3.376 3.549s-11.636 7.346-33.79 5.145l-2.096-1.944z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#E3DFF0"
        d="M56.387 146.661s5.913-1.422 9.053-.84c0 0-4.001 1.498-9.053.84zm0-9.676s4.213-1.473 7.183-.803c0 0-3.517 1.463-7.183.803z"
      />
      <linearGradient
        id="branding_svg__k"
        gradientUnits="userSpaceOnUse"
        x1={85.138}
        y1={119.533}
        x2={108.478}
        y2={119.533}
      >
        <stop offset={0} stopColor="#898989" />
        <stop offset={0.983} stopColor="#5d3c82" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__k)"
        d="M85.138 124.276s11.026-.826 20.865-12.969l2.475 2.668s-8.146 13.566-23.34 13.783v-3.482z"
      />
      <linearGradient
        id="branding_svg__l"
        gradientUnits="userSpaceOnUse"
        x1={110.874}
        y1={114.586}
        x2={110.874}
        y2={88.905}
      >
        <stop offset={0.006} stopColor="#401742" />
        <stop offset={1} stopColor="#7d2e46" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__l)"
        d="M105.326 112.424s-2.582-3.072-2.791-6.352c0 0 1.376-3.42 5.005-5.652 0 0 3.718-10.678 4.347-11.516 0 0 1.465.07 1.953 1.676.488 1.604-.279 7.746-1.256 10.084l1.408-1.43s3.407 1.324 4.175 4.117v1.604s.907.488 1.048 2.305c0 0-1.467 1.953-3.77 2.371 0 0-1.326 3.838-5.723 4.955l-4.396-2.162z"
      />
      <linearGradient
        id="branding_svg__m"
        gradientUnits="userSpaceOnUse"
        x1={106.704}
        y1={114.291}
        x2={106.704}
        y2={99.512}
      >
        <stop offset={0.006} stopColor="#401742" />
        <stop offset={1} stopColor="#a43b46" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__m)"
        d="M102.535 106.073s2.074-5.373 7.447-6.561c0 0 .946 0 .893 2.234 0 0-1.451 2.721-3.684 4.326 0 0 2.094.838 2.791 2.932.698 2.094-.861 5.287-.861 5.287l-3.795-1.867s-2.912-3.072-2.791-6.351z"
      />
      <linearGradient
        id="branding_svg__n"
        gradientUnits="userSpaceOnUse"
        x1={37.648}
        y1={148.516}
        x2={37.648}
        y2={126.577}
      >
        <stop offset={0} stopColor="#898989" />
        <stop offset={0.983} stopColor="#5d3c82" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__n)"
        d="M45.458 126.577s-11.37 3.352-15.138 10.006c-3.769 6.652 6.595 11.934 6.595 11.934l2.303-4.498s-5.094-2.053-3.838-6.861c1.257-4.809 10.421-7.059 10.421-7.059l-.343-3.522z"
      />
      <radialGradient
        id="branding_svg__o"
        cx={811.364}
        cy={233.125}
        r={23.142}
        gradientTransform="matrix(-.9426 0 0 .9426 807.254 -126.616)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#f7901e" />
        <stop offset={0.241} stopColor="#f68d20" />
        <stop offset={0.441} stopColor="#f38626" />
        <stop offset={0.629} stopColor="#ef792f" />
        <stop offset={0.809} stopColor="#ea6639" />
        <stop offset={0.982} stopColor="#e54942" />
        <stop offset={1} stopColor="#e44542" />
      </radialGradient>
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__o)"
        cx={42.322}
        cy={87.448}
        r={15.375}
      />
      <linearGradient
        id="branding_svg__p"
        gradientUnits="userSpaceOnUse"
        x1={44.976}
        y1={159.251}
        x2={44.976}
        y2={139.067}
      >
        <stop offset={0.006} stopColor="#401742" />
        <stop offset={1} stopColor="#7d2e46" />
      </linearGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__p)"
        d="M38.59 143.458s1.703-1.063 4.412-1.527c2.709-.463 5.805-2.863 5.805-2.863s1.238.754 1.393 2.195c.155 1.443-3.026 3.609-4.415 3.533 0 0 6.118 3.932 8.13 7.15 0 0-.31.941-2.167 1.152l-4.024-2.412s2.631 3.551 3.405 5.873c0 0 .078.953-3.483 1.676l-3.792-2.527 1.658 2.527s-.884 1.406-2.122.91c-1.239-.496-6.27-5.654-7.353-10.699-.001 0 .618-3.229 2.553-4.988z"
      />
      <radialGradient
        id="branding_svg__q"
        cx={127.924}
        cy={220.335}
        r={21.263}
        gradientTransform="translate(-77.74 -126.616) scale(.9426)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#fff" />
        <stop offset={0.24} stopColor="#fbf9fa" />
        <stop offset={0.439} stopColor="#f3edef" />
        <stop offset={0.626} stopColor="#e8dce0" />
        <stop offset={0.805} stopColor="#dac5cd" />
        <stop offset={0.977} stopColor="#caa9b7" />
        <stop offset={1} stopColor="#c7a5b4" />
      </radialGradient>
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__q)"
        cx={42.322}
        cy={86.61}
        r={14.125}
      />
      <defs>
        <circle id="branding_svg__r" cx={45.351} cy={88.413} r={8.715} />
      </defs>
      <clipPath id="branding_svg__s">
        <use xlinkHref="#branding_svg__r" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#branding_svg__s)"
        fill="#A35925"
        d="M36.636 79.697h17.43v17.432h-17.43z"
      />
      <radialGradient
        id="branding_svg__t"
        cx={130.721}
        cy={229.138}
        r={6.469}
        gradientTransform="translate(-77.74 -126.616) scale(.9426)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef4723" />
        <stop offset={0.256} stopColor="#ea4623" />
        <stop offset={0.397} stopColor="#dd4624" />
        <stop offset={0.511} stopColor="#cc4526" />
        <stop offset={0.61} stopColor="#b54327" />
        <stop offset={0.699} stopColor="#993f27" />
        <stop offset={0.781} stopColor="#783826" />
        <stop offset={0.859} stopColor="#562e22" />
        <stop offset={0.929} stopColor="#34211a" />
        <stop offset={0.997} stopColor="#040403" />
        <stop offset={1} stopColor="#010101" />
      </radialGradient>
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__t)"
        cx={45.351}
        cy={88.413}
        r={5.795}
      />
      <radialGradient
        id="branding_svg__u"
        cx={130.729}
        cy={229.203}
        r={6.88}
        gradientTransform="translate(-77.74 -126.616) scale(.9426)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#39190d" />
        <stop offset={0.289} stopColor="#38190e" />
        <stop offset={0.509} stopColor="#361813" />
        <stop offset={0.708} stopColor="#311619" />
        <stop offset={0.892} stopColor="#2b1220" />
        <stop offset={1} stopColor="#261024" />
      </radialGradient>
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__u)"
        cx={45.35}
        cy={88.413}
        r={6.162}
      />
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        cx={36.635}
        cy={84.315}
        r={2.137}
      />
      <radialGradient
        id="branding_svg__v"
        cx={764.869}
        cy={233.125}
        r={23.142}
        gradientTransform="matrix(-.9426 0 0 .9426 807.254 -126.616)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#f7901e" />
        <stop offset={0.241} stopColor="#f68d20" />
        <stop offset={0.441} stopColor="#f38626" />
        <stop offset={0.629} stopColor="#ef792f" />
        <stop offset={0.809} stopColor="#ea6639" />
        <stop offset={0.982} stopColor="#e54942" />
        <stop offset={1} stopColor="#e44542" />
      </radialGradient>
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__v)"
        cx={86.148}
        cy={87.448}
        r={15.375}
      />
      <radialGradient
        id="branding_svg__w"
        cx={767.79}
        cy={219.891}
        r={21.263}
        gradientTransform="matrix(-.9426 0 0 .9426 807.254 -126.616)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#fff" />
        <stop offset={0.24} stopColor="#fbf9fa" />
        <stop offset={0.439} stopColor="#f3edef" />
        <stop offset={0.626} stopColor="#e8dce0" />
        <stop offset={0.805} stopColor="#dac5cd" />
        <stop offset={0.977} stopColor="#caa9b7" />
        <stop offset={1} stopColor="#c7a5b4" />
      </radialGradient>
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__w)"
        cx={86.148}
        cy={86.61}
        r={14.125}
      />
      <defs>
        <circle id="branding_svg__x" cx={83.12} cy={88.413} r={8.715} />
      </defs>
      <clipPath id="branding_svg__y">
        <use xlinkHref="#branding_svg__x" overflow="visible" />
      </clipPath>
      <path
        clipPath="url(#branding_svg__y)"
        fill="#A35925"
        d="M74.405 79.697h17.431v17.432H74.405z"
      />
      <radialGradient
        id="branding_svg__z"
        cx={768.366}
        cy={229.138}
        r={6.469}
        gradientTransform="matrix(-.9426 0 0 .9426 807.254 -126.616)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#ef4723" />
        <stop offset={0.256} stopColor="#ea4623" />
        <stop offset={0.397} stopColor="#dd4624" />
        <stop offset={0.511} stopColor="#cc4526" />
        <stop offset={0.61} stopColor="#b54327" />
        <stop offset={0.699} stopColor="#993f27" />
        <stop offset={0.781} stopColor="#783826" />
        <stop offset={0.859} stopColor="#562e22" />
        <stop offset={0.929} stopColor="#34211a" />
        <stop offset={0.997} stopColor="#040403" />
        <stop offset={1} stopColor="#010101" />
      </radialGradient>
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__z)"
        cx={83.12}
        cy={88.413}
        r={5.796}
      />
      <radialGradient
        id="branding_svg__A"
        cx={768.375}
        cy={229.215}
        r={6.96}
        gradientTransform="matrix(-.9426 0 0 .9426 807.254 -126.616)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#39190d" />
        <stop offset={0.289} stopColor="#38190e" />
        <stop offset={0.509} stopColor="#361813" />
        <stop offset={0.708} stopColor="#311619" />
        <stop offset={0.892} stopColor="#2b1220" />
        <stop offset={1} stopColor="#261024" />
      </radialGradient>
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__A)"
        cx={83.12}
        cy={88.413}
        r={6.235}
      />
      <circle
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        cx={91.835}
        cy={84.315}
        r={2.137}
      />
      <radialGradient
        id="branding_svg__B"
        cx={151.164}
        cy={239.062}
        r={5.607}
        gradientTransform="translate(-77.74 -126.616) scale(.9426)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#f47a20" />
        <stop offset={0.26} stopColor="#f37721" />
        <stop offset={0.476} stopColor="#f06f24" />
        <stop offset={0.679} stopColor="#eb6127" />
        <stop offset={0.873} stopColor="#e64a2b" />
        <stop offset={1} stopColor="#e2332e" />
      </radialGradient>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__B)"
        d="M59.212 98.327s.837 1.564 4.501 1.426c3.664-.141 6.176-2.623 6.176-2.623s-2.407 5.264-5.967 5.244c-3.333-.018-4.71-4.047-4.71-4.047z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#F8951D"
        d="M87.544 58.207s-.27 1.457-.326 3.551c0 0 4.744.963 7.145 3.615l.754-1.104c0 .001-2.444-3.968-7.573-6.062zm-44.366 0s.27 1.457.327 3.551c0 0-4.744.963-7.146 3.615l-.754-1.104c0 .001 2.444-3.968 7.573-6.062z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFCA06"
        d="M33.753 100.215s3.403 3.293 8.569 3.555c5.165.262 9.674-4.373 9.674-4.373s-4.001 3.426-9.674 3.426c-4.701 0-8.569-2.608-8.569-2.608zm44.659.52s3.68 2.963 8.807 2.965c6.072.004 9.029-4.664 9.029-4.664s-4.412 3.787-9.029 3.748c-4.863-.039-8.807-2.049-8.807-2.049zm-17.126.633s1.241 1.625 3.265 1.455 3.695-3.07 3.695-3.07-1.135 1.703-3.695 1.994c-2.56.291-3.265-.379-3.265-.379z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        d="M109.31 79.697s4.172-15.328-4.17-25.168c0 0 0 10.844 4.17 25.168z"
      />
      <linearGradient
        id="branding_svg__C"
        gradientUnits="userSpaceOnUse"
        x1={44.822}
        y1={126.577}
        x2={44.822}
        y2={110.284}
      >
        <stop offset={0.006} stopColor="#fec049" />
        <stop offset={0.111} stopColor="#ca9b48" />
        <stop offset={0.353} stopColor="#775f3a" />
        <stop offset={0.572} stopColor="#453a2a" />
        <stop offset={0.76} stopColor="#252019" />
        <stop offset={0.909} stopColor="#0d0c09" />
        <stop offset={1} stopColor="#010101" />
      </linearGradient>
      <path
        style={{ mixBlendMode: "screen" }}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#branding_svg__C)"
        d="M46.793 125.639s-.119-5.656-9.146-14.332c0 0 6.498-2.303 11.676 0 0 0 2.47 4.689 2.673 15.27l-5.203-.938z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFF"
        d="M41.477 49.781s.594-5.645 7.499-6.918c0 0 1 2.79-1.008 5.148-2.087 2.452-6.491 1.77-6.491 1.77z"
      />
    </svg>
  );
}

export default SvgBranding;
