import styled from "styled-components";

export const Banner = styled.div`
  background: ${(props) => props.theme.background};
  height: 100%;
  width: 100%;
  position: absolute;
  // z-index: -1;
`;
export const Video = styled.div`
  height: 100%;
  width: 100%;
  video {
    object-fit: cover;
  }
  video.full {
    width:100%;
    height:100%;
  }
`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: block;
  width: 100%;
`;

