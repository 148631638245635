import * as React from "react";

function SvgLampshadesN(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1415.242"
      height="729.942"
      viewBox="0 0 1415.242 729.942"
    >
      <g>
        <linearGradient
          id="Lampshadow_1_"
          x1="-344.733"
          x2="-344.733"
          y1="-1842.652"
          y2="-1920.463"
          gradientTransform="scale(-1 1) rotate(-60.436 833.681 5.147)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#9f3040"></stop>
          <stop offset="1" stopColor="#e05b2b"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_1_)"
          d="M1415.121 69.052l-45.706 63.285s-3.608 2.858-5.521 1.184c-3.292-2.882-.894-6.586-.894-6.586l46.451-61.553s2.366-2.082 4.476-.297c1.735 1.469 1.194 3.967 1.194 3.967z"
        ></path>
        <linearGradient
          id="Lampshadow_2_"
          x1="-345.907"
          x2="-345.907"
          y1="-1843.051"
          y2="-1920.256"
          gradientTransform="scale(-1 1) rotate(-60.436 833.681 5.147)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f4774d"></stop>
          <stop offset="1" stopColor="#f8983a"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_2_)"
          d="M1414.814 69.968l-45.809 62.93s-2.396 2.271-4.549.92c-2.154-1.352-.238-4.736-.238-4.736l47.133-62.457s1.638-1.524 3.128-.292c1.494 1.232.335 3.635.335 3.635z"
        ></path>
        <linearGradient
          id="Lampshadow_3_"
          x1="-1575.832"
          x2="-1575.832"
          y1="24.946"
          y2="11.131"
          gradientTransform="matrix(-1 0 0 1 -202.644 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f4774d"></stop>
          <stop offset="1" stopColor="#f8983a"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_3_)"
          d="M1355.109 18.201l33.59-7.069s3.271-.051 2.43 5.905l-34.093 7.909-1.927-6.745z"
        ></path>
        <linearGradient
          id="Lampshadow_4_"
          x1="-1573.604"
          x2="-1573.604"
          y1="18.201"
          y2="11.131"
          gradientTransform="matrix(-1 0 0 1 -202.644 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#9f3040"></stop>
          <stop offset="1" stopColor="#e05b2b"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_4_)"
          d="M1388.699 11.131h-4.307l-31.17 5.992 2.725 1.078z"
        ></path>
        <linearGradient
          id="Lampshadow_5_"
          x1="-1504.145"
          x2="-1457.539"
          y1="-346.066"
          y2="-346.066"
          gradientTransform="scale(-1 1) rotate(-9.41 872.545 -1045.307)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#63203c"></stop>
          <stop offset="1" stopColor="#7d3c34"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_5_)"
          d="M1345.303 58.468s13.402-7.392 12.661-34.318l-.682-4.138S1339.99-3.348 1315.919.62l29.384 57.848z"
        ></path>
        <linearGradient
          id="Lampshadow_6_"
          x1="-1500.526"
          x2="-1451.685"
          y1="-344.734"
          y2="-344.734"
          gradientTransform="scale(-1 1) rotate(-9.41 872.545 -1045.307)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f6d8bf"></stop>
          <stop offset="1" stopColor="#f1bfbd"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_6_)"
          d="M1308.971 25.035c1.016 4.312 2.726 8.852 5.133 13.332 8.504 15.805 22.48 24.802 31.199 20.102 2.936-1.58 4.902-4.514 5.884-8.321 1.018-3.825 1.018-8.553.022-13.659 0 0-.006-.037-.029-.117a34.651 34.651 0 00-.393-1.843c-1.009-4.276-2.719-8.75-5.097-13.176-2.095-3.881-4.516-7.357-7.113-10.313-3.097-3.538-6.439-6.343-9.747-8.234-5.193-2.978-10.32-3.727-14.338-1.555-6.258 3.368-8.117 12.864-5.521 23.784z"
        ></path>
        <path
          fill="#FFF"
          d="M1330.034 21.369c-1.204 7.268 3.705 14.141 10.972 15.345 3.561.591 7.029-.29 9.781-2.186-1.009-4.276-2.719-8.75-5.097-13.176-2.095-3.881-4.516-7.357-7.113-10.313a13.33 13.33 0 00-8.543 10.33z"
        ></path>
        <linearGradient
          id="Lampshadow_7_"
          x1="-1489.711"
          x2="-1489.711"
          y1="-319.151"
          y2="-339.787"
          gradientTransform="scale(-1 1) rotate(-9.41 872.545 -1045.307)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#010101"></stop>
          <stop offset="1" stopColor="#b39d75"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_7_)"
          style={{ mixBlendMode: "screen", opacity: "0.8" }}
          d="M1350.174 38.234l-4.053 18.073s-5.543 3.198-14.469-1.386l11.955-16.296s5.361.448 6.567-.391z"
          opacity="0.8"
        ></path>
        <linearGradient
          id="Lampshadow_8_"
          x1="-1481.644"
          x2="-1481.644"
          y1="-320.115"
          y2="-338.94"
          gradientTransform="scale(-1 1) rotate(-9.41 872.545 -1045.307)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#010101"></stop>
          <stop offset="1" stopColor="#b39d75"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_8_)"
          style={{ mixBlendMode: "screen", opacity: "0.8" }}
          d="M1342.25 38.505s-3.024-.345-6.213-1.756l-12.021 13.097 5.967 4.98 12.267-16.321z"
          opacity="0.8"
        ></path>
        <linearGradient
          id="Lampshadow_9_"
          x1="-1498.173"
          x2="-1451.685"
          y1="-357.242"
          y2="-357.242"
          gradientTransform="scale(-1 1) rotate(-9.41 872.545 -1045.307)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f7aab3"></stop>
          <stop offset="1" stopColor="#cb8190"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_9_)"
          d="M1308.971 25.035s5.013-15.084 21.035-11.385c15.283 3.528 20.698 21.077 21.174 22.721a34.651 34.651 0 00-.393-1.843c-1.009-4.276-2.719-8.75-5.097-13.176-2.095-3.881-4.516-7.357-7.113-10.313-3.097-3.538-6.439-6.343-9.747-8.234-5.193-2.978-10.32-3.727-14.338-1.555-6.258 3.369-8.117 12.865-5.521 23.785z"
          opacity="0.4"
        ></path>
        <linearGradient
          id="Lampshadow_10_"
          x1="-1596.811"
          x2="-1596.811"
          y1="82.087"
          y2="4.278"
          gradientTransform="matrix(-1 0 0 1 -202.644 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#9f3040"></stop>
          <stop offset="1" stopColor="#e05b2b"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_10_)"
          d="M1380.896 5.404l32.5 70.98s.706 4.546-1.693 5.385c-4.133 1.44-6.17-2.472-6.17-2.472L1374.91 8.524s-.645-3.085 1.949-4.039c2.133-.784 4.037.919 4.037.919z"
        ></path>
        <linearGradient
          id="Lampshadow_11_"
          x1="-1597.983"
          x2="-1597.983"
          y1="81.689"
          y2="4.485"
          gradientTransform="matrix(-1 0 0 1 -202.644 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f4774d"></stop>
          <stop offset="1" stopColor="#f8983a"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_11_)"
          d="M1381.546 6.123l32.136 70.89s.795 3.208-1.442 4.414c-2.239 1.205-4.237-2.129-4.237-2.129l-31.074-71.813s-.518-2.178 1.289-2.865c1.807-.69 3.328 1.503 3.328 1.503z"
        ></path>
        <linearGradient
          id="Lampshadow_12_"
          x1="-1572.692"
          x2="-1572.692"
          y1="137.046"
          y2="126.573"
          gradientTransform="matrix(-1 0 0 1 -202.644 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#f4774d"></stop>
          <stop offset="1" stopColor="#f8983a"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_12_)"
          d="M1390.518 137.046l-1.325-10.473h-37.702l-1.912 10.473z"
        ></path>
        <linearGradient
          id="Lampshadow_13_"
          x1="-1544.956"
          x2="-1544.956"
          y1="137.045"
          y2="126.572"
          gradientTransform="matrix(-1 0 0 1 -202.644 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#9f3040"></stop>
          <stop offset="1" stopColor="#e05b2b"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_13_)"
          d="M1351.491 126.573h-17.044l-1.314 10.473h16.446z"
        ></path>
        <linearGradient
          id="Lampshadow_14_"
          x1="21.461"
          x2="1428.035"
          y1="453.133"
          y2="248.387"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.102" stopColor="#ea9e24" stopOpacity="0"></stop>
          <stop offset="0.229" stopColor="#f0ab20" stopOpacity="0.172"></stop>
          <stop offset="0.411" stopColor="#f8b91a" stopOpacity="0.416"></stop>
          <stop offset="0.608" stopColor="#fdc213" stopOpacity="0.681"></stop>
          <stop offset="0.844" stopColor="#ffc50f"></stop>
          <stop offset="1" stopColor="#ffc50f"></stop>
        </linearGradient>
        <path
          fill="url(#Lampshadow_14_)"
          style={{ mixBlendMode: "soft-light" }}
          className="lightAbout"
          d="M1336.869 9.581c11.041 9.007 12.71 27.766 12.71 27.766l-327.306 692.596-1005.736-4.19L0 33.767S1329.661 9.572 1336.869 9.581z"
          opacity="0.6"
        ></path>
      </g>
    </svg>
  );
}

export default SvgLampshadesN;
