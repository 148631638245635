import React, {useEffect, useRef} from "react";
import ReactDOM from "react-dom";
import Coin from "./Coin";
import SvgGhost1 from "../../assets/svg/SvgJs/Ghost1";
import SvgGhost2 from "../../assets/svg/SvgJs/Ghost2";
import SvgGhost3 from "../../assets/svg/SvgJs/Ghost3";
import SvgGhost4 from "../../assets/svg/SvgJs/Ghost4";
import SvgGhost5 from "../../assets/svg/SvgJs/Ghost5";
import SvgGhost6 from "../../assets/svg/SvgJs/Ghost6";
import Pacman from "../../assets/svg/SvgJs/Pacman2/Pacman";
import useWindowSize from "../../hooks/useWindowSize";
import SvgS4N from "../../assets/svg/SvgJs/NewTubes/S4N";
import Icon from "../../assets/svg/SvgJs/NewTubes/Icon";

function SetupSection({callB}) {
    let steps = useRef();
    const size = useWindowSize();

    const CreateCoins = () => {
        let coins = [];
        for (let i = 0; i < 9; i++) {
            coins.push(
                <div key={i} className="coin">
                    <Coin/>
                </div>
            );
        }
        return <div className="coins">{coins}</div>;
    };

    useEffect(() => {
        let step = steps.current.querySelectorAll(".step");

        for (let i = 0; i < step.length - 1; i++) {
            let elm = step[i];
            let cos = document.createElement("div");
            cos.classList.add("coins-f");
            ReactDOM.render(<CreateCoins/>, cos);
            elm.append(cos);
        }
    }, []);

    return (
        <div className="page sectionSetup">
            <div className="content">
                <div className="title">
                    <h3>Set up</h3>
                </div>
                <div className="steps" ref={steps}>
                    <div className="step step-1">
                        <h3 className="title">1</h3>
                        <p className="text">Business & strategic Market planning</p>
                    </div>
                    <div className="step step-2">
                        <h3 className="title">2</h3>
                        <p className="text">Creative team</p>
                        <div className="ghost ghost-1">
                            <SvgGhost1/>
                        </div>
                    </div>
                    <div className="step step-3">
                        <h3 className="title">3</h3>
                        <p className="text">Web design Solutions</p>
                        <div className="ghost ghost-2">
                            <SvgGhost2/>
                        </div>
                    </div>
                    <div className="step step-4">
                        <h3 className="title">4</h3>
                        <p className="text">Graphic design team</p>
                        <div className="ghost ghost-3">
                            <SvgGhost3/>
                        </div>
                    </div>
                    <div className="step step-5">
                        <h3 className="title">5</h3>
                        <p className="text">Development team</p>
                        <div className="ghost ghost-4">
                            <SvgGhost4/>
                        </div>
                    </div>
                    <div className="step step-6">
                        <h3 className="title">6</h3>
                        <p className="text">Multimedia / new media team</p>
                        <div className="ghost ghost-5">
                            <SvgGhost5/>
                        </div>
                    </div>
                    <div className="step step-7">
                        <h3 className="title">7</h3>
                        <p className="text">Social media team</p>
                        <div className="ghost ghost-6">
                            <SvgGhost6/>
                        </div>
                    </div>
                </div>
                <div className="tube">
                    <SvgS4N callb={callB}/>
                </div>
                <div className="shapes">
                    <div className="pocman">
                        <Pacman/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SetupSection;
