import * as React from "react";

function SvgBe(props) {
  return (
    <svg width={25.44} height={16.197} {...props}>
      <path
        d="M9.351 15.585a4.336 4.336 0 002.905-2.036c.468-.744.702-1.583.702-2.52 0-.907-.205-1.679-.614-2.314-.411-.636-1.019-1.102-1.825-1.395.53-.271.933-.57 1.206-.9.487-.584.731-1.361.731-2.325 0-.937-.242-1.741-.726-2.413C10.927.591 9.562.029 7.636 0H0v15.8h7.12c.801 0 1.547-.071 2.231-.215zm-6.2-12.841h3.437c.757 0 1.379.082 1.868.248.565.234.85.721.85 1.457 0 .664-.217 1.127-.644 1.388-.428.261-.985.392-1.671.392h-3.84V2.744zm0 10.313V8.844H7.04c.679.008 1.203.096 1.579.268.671.307 1.005.871 1.005 1.693 0 .972-.346 1.629-1.037 1.973-.381.186-.916.279-1.6.279H3.151zm12.484 1.703c1.188.958 2.561 1.438 4.114 1.438 1.885 0 3.352-.568 4.395-1.705.676-.715 1.053-1.42 1.135-2.111H22.16a2.593 2.593 0 01-.629.803c-.436.358-1.001.537-1.697.537-.656 0-1.217-.147-1.684-.439-.766-.473-1.174-1.294-1.221-2.467h8.508c.016-1.008-.018-1.781-.1-2.318-.143-.915-.453-1.722-.932-2.415-.529-.787-1.203-1.364-2.02-1.728-.813-.365-1.729-.548-2.746-.548-1.712 0-3.105.54-4.178 1.62-1.073 1.079-1.609 2.631-1.609 4.655.001 2.158.594 3.719 1.783 4.678zm2.181-7.768c.433-.445 1.04-.668 1.823-.668.721 0 1.326.211 1.813.63.488.42.758 1.036.815 1.846h-5.265c.112-.76.384-1.362.814-1.808zM16.007.908h6.866v1.595h-6.866z"
        fill="#FFF"
      />
    </svg>
  );
}

export default SvgBe;
