import React, {useEffect} from "react";
import SvgDinghy from "../../assets/svg/SvgJs/Dinghy2";
import SvgGun from "../../assets/svg/SvgJs/Gun";
import SvgStars from "../../assets/svg/SvgJs/Stars";
import SvgS9 from "../../assets/svg/SvgJs/Tubes/S9";
import SliderOurClients from "./SliderOurClients";

function OurClientsSection({cD}) {
    useEffect(() => {
        const mr = 16.5;
        const width = 14.5;
        if (cD.length) {
            const weproud = document.querySelector(".box_under");
            const sum = mr * cD.length + width * (cD.length - 2);
            weproud.style.width = (sum > 300 ? sum : 300) + "px";
        }
    }, [cD]);

    return (
        <div
            className="page sectionOurClients"
            data-menu="change"
            data-menu-color="#252525"
        >
            <div className="title">
                <h3>Our Clients</h3>
            </div>
            <div className="content"></div>

            <div className="background">
                <SvgStars/>
            </div>
            <div className="slider">
                <SliderOurClients cD={cD}/>
                <div className="box_under">
                    <div className="weproud"></div>
                    <span className="top">We Are</span>
                    <span className="middle">PROUD</span>
                    <p className="paragraph">
                        We are proud to have these clients in our group, we have served them with our tools as much as
                        we could, and it will be our pleasure if you become one of them.
                    </p>
                </div>
            </div>
            <div className="gun">
                <SvgGun/>
            </div>
            <div className="dinghy">
                <SvgDinghy/>
            </div>
            <div className="tube">
                <SvgS9/>
                <div className="tubeh"></div>
            </div>
        </div>
    );
}

export default OurClientsSection;
