import React from "react";
import SvgLampshadesN from "../../assets/svg/SvgJs/Lampshades_n";
import SvgS2N from "../../assets/svg/SvgJs/NewTubes/S2N";
import SvgRobot from "../../assets/svg/SvgJs/Robot";

import * as res from '../../helpers/responsive'
import SvgLess1024S2 from "../../assets/svg/SvgJs/Responsive/less_1024/Less1024S2";
import SvgLess768S2 from "../../assets/svg/SvgJs/Responsive/less_768/Less768S2";
import SvgMiddtubeRespon from "../../assets/svg/SvgJs/Responsive/less_480/Middtube";

function AboutSection({sD}) {
    return (
        <div className="page sectionAbout">
            <div className="content">
                <div className="page_title">
                    <h1>about us</h1>
                </div>
                <div className="paragraph">{sD}</div>
            </div>
            <div className="tube">
                <res.Desktop>
                    <SvgS2N/>
                </res.Desktop>
                <res.Tablet>
                    <SvgLess1024S2/>
                </res.Tablet>
                <res.Mobile>
                    <SvgLess768S2/>
                </res.Mobile>
                <res.LessMobile>
                    <SvgMiddtubeRespon heightOfTube={"100vh"} tubeClass={"s2"}/>
                </res.LessMobile>
            </div>
            <div className="lampshades">
                <SvgLampshadesN/>
            </div>
            <div className="robot">
                <SvgRobot/>
            </div>
            <res.LessMobile>
                <div className="tubeh"/>
            </res.LessMobile>
        </div>
    );
}

export default AboutSection;
