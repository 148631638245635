import React from "react";
import {landingPageLink, otherSiteLink} from "../../context/otherSite";
import useWindowSize from "../../hooks/useWindowSize";
import {qs} from "../../helpers/helpers";

export default function Menu() {
    const menu = React.useRef(null);
    const size = useWindowSize();

    React.useEffect(() => {
        let sections = [
            "SectionLogo",
            "sectionAbout",
            "sectionVisionMission",
            "setupOurStudio",
            "sectionOurSudio",
            "sectionOurTeam",
            "sectionOurServices",
            "services-all",
            "sectionOurClients",
            "sectionFooter",
        ];
        let items = menu.current.querySelectorAll(".item");
        items.forEach((item, key) => {
            item.addEventListener("click", () => {
                window.locomotiveScroll.scrollTo("." + sections[key]);
                window.closeMenu();
            });
        });

        if(size.height < 870){
            qs(".menu").classList.add("le-height")
        }

    },[]);



    return (
        <div className="menu" ref={menu}>
            <div className="elms">
                <ul>
                    <li className="item">
                        <span className="click">Home</span>
                    </li>
                    <li className="item">
                        <span className="click">About Us</span>
                    </li>
                    <li className="item">
                        <span className="click">Vision & Mission</span>
                    </li>
                    <li className="item">
                        <span className="click">Setup</span>
                    </li>
                    <li className="item">
                        <span className="click">Studio</span>
                    </li>
                    <li className="item">
                        <span className="click">Team</span>
                    </li>
                    <li className="item">
                        <span className="click">Services</span>
                    </li>
                    <li className="item">
                        <span className="click">Projects</span>
                    </li>
                    <li className="item">
                        <span className="click">Clients</span>
                    </li>
                    <li className="item">
                        <span className="click">Contact</span>
                    </li>
                </ul>
                <p className="other-site-in-menu"><a href={otherSiteLink} target="_blank">
                    if you face any issue in website loading or in website animation, please check the other version of
                    website.
                </a></p>
                <a target="_blank" className="hire-us" href={landingPageLink}>Hire US</a>
            </div>
        </div>
    );
}
