import * as React from "react";

function SvgGraphic(props) {
  return (
    <svg
      width="166.938"
      height="146.008"
      viewBox="0 0 166.938 146.008"
      {...props}
    >
      <linearGradient
        id="graphic_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={-20.57}
        y1={53.26}
        x2={-20.57}
        y2={0.015}
        gradientTransform="rotate(-4.211 284.765 -656.675)"
      >
        <stop offset={0} stopColor="#2c3077" />
        <stop offset={1} stopColor="#6b3393" />
      </linearGradient>
      <path
        fill="url(#graphic_svg__a)"
        d="M41.301 73.097S12.616 60.568 8.61 22.114c0 0 30.332 1.389 43.31 46.291l-10.619 4.692z"
      />
      <path
        fill="#C0DEF5"
        d="M10.609 24.3l33.773 44.624-7.724-.279c.001 0-24.79-13.537-26.049-44.345z"
      />
      <radialGradient
        id="graphic_svg__b"
        cx={357.615}
        cy={-431.644}
        r={19.738}
        gradientTransform="matrix(-1.2163 .2222 .2222 1.2163 549.917 493.87)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#568ecb" />
        <stop offset={1} stopColor="#6354a4" />
      </radialGradient>
      <path
        fill="url(#graphic_svg__b)"
        d="M41.592 76.246S35.36 47.972 4.001 47.959c0 0 1.834 27.825 33.939 29.131l3.652-.844z"
      />
      <path
        fill="#C0DEF5"
        d="M4.001 47.959l37.591 28.287-4.79-.135S9.601 75.157 4.001 47.959z"
      />
      <g>
        <linearGradient
          id="graphic_svg__c"
          gradientUnits="userSpaceOnUse"
          x1={88.055}
          y1={107.619}
          x2={88.055}
          y2={10.315}
        >
          <stop offset={0} stopColor="#2c3077" />
          <stop offset={1} stopColor="#492e82" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__c)"
          d="M149.445 98.139s0 9.48-9.006 9.48H32.713c-3.555-.359-6.049-4.74-6.049-7.707 0-1.146.607-14.146 1.356-29.64 1.186-24.498 2.711-55.255 2.711-55.255s.559-4.229 7.31-4.703l52.383.551 50.015.52s4.863.238 6.76 5.216l2.246 81.538z"
        />
        <linearGradient
          id="graphic_svg__d"
          gradientUnits="userSpaceOnUse"
          x1={88.033}
          y1={98.762}
          x2={88.033}
          y2={15.347}
        >
          <stop offset={0} stopColor="#fdfeff" />
          <stop offset={1} stopColor="#d8cae3" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__d)"
          d="M31.364 96.713s76.011 4.268 112.155.484c0 0 3.36-41.252-1.223-79.651 0 0-56.729-3.95-106.189-1.264.001 0-4.899 35.554-4.743 80.431z"
        />
        <linearGradient
          id="graphic_svg__e"
          gradientUnits="userSpaceOnUse"
          x1={23.481}
          y1={90.551}
          x2={23.481}
          y2={82.335}
        >
          <stop offset={0} stopColor="#2c3077" />
          <stop offset={1} stopColor="#492e82" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__e)"
          d="M27.446 82.335h-7.93v8.216h7.546z"
        />
        <path
          fill="#3C2D83"
          d="M130.023 98.795l3.955 8.824h6.461s7.881.443 9.006-9.48V81.681l-5.113.655-.813 14.861-13.496 1.598z"
        />
        <linearGradient
          id="graphic_svg__f"
          gradientUnits="userSpaceOnUse"
          x1={63.377}
          y1={122.707}
          x2={63.377}
          y2={86.005}
        >
          <stop offset={0} stopColor="#2c3077" />
          <stop offset={1} stopColor="#492e82" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__f)"
          d="M66.829 122.707c-18.485.001-32.59-2.604-39.488-4.198-9.167-2.12-14.102-3.963-15.765-5.952-4.608-.744-8.105-1.924-9.979-3.729-1.058-1.02-1.596-2.225-1.597-3.581-.005-10.741 19.492-18.9 20.322-19.241l.361.876c-.196.081-19.741 8.256-19.735 18.365 0 1.104.428 2.053 1.307 2.897 1.654 1.594 4.708 2.675 8.751 3.385a2.095 2.095 0 01-.05-.222c-.229-1.326.727-2.625 3.011-4.089 2.904-1.862 6.69-2.255 10.386-1.078 3.417 1.089 6.342 3.428 8.335 6.597 7.061-.048 14.692-.326 22.246-.602 15.815-.575 30.753-1.119 39.488.467 3.27.596 4.667 1.255 4.676 2.205.006.784-.772 1.741-6.6 2.9-15.269 3.038-56.434 5.175-59.28-1.853a16.746 16.746 0 00-1.065-2.171c-7.102.02-13.616-.18-18.896-.879 2.169 1.491 6.896 3.068 14.295 4.779 11.044 2.554 40.665 7.71 77.123.472 18.863-3.745 21.06-6.71 21.128-7.81.066-1.078-1.83-1.956-2.501-2.179l.301-.9c.134.045 3.273 1.112 3.146 3.14-.185 2.952-7.344 5.791-21.891 8.678a195.553 195.553 0 01-38.029 3.723zm-33.591-9.031c.317.585.604 1.192.86 1.822 1.652 4.086 21.478 4.838 40.209 3.469 15.854-1.158 23.601-3.168 23.841-4.16-.006-.007-.233-.605-3.896-1.271-8.634-1.571-23.523-1.025-39.284-.453-7.36.269-14.805.539-21.73.593zm-21.122-1.965c5.261.805 12.003 1.054 19.442 1.03-1.859-2.726-4.473-4.737-7.492-5.697-3.376-1.076-6.96-.711-9.588.973-1.869 1.198-2.74 2.25-2.587 3.129.032.186.107.375.225.565z"
        />
        <linearGradient
          id="graphic_svg__g"
          gradientUnits="userSpaceOnUse"
          x1={61.167}
          y1={70.367}
          x2={61.167}
          y2={10.315}
        >
          <stop offset={0} stopColor="#b8acd4" />
          <stop offset={1} stopColor="#e0d1e7" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__g)"
          d="M94.313 11.65l-3.081 2.77s-45.113-.36-55.939.929c0 0-4.703 32.843-4.703 53.352l-2.569 1.667v-.095c1.186-24.498 2.711-55.255 2.711-55.255s.559-4.229 7.31-4.703l52.383.551 3.888.784z"
        />
        <linearGradient
          id="graphic_svg__h"
          gradientUnits="userSpaceOnUse"
          x1={87.512}
          y1={99.549}
          x2={87.512}
          y2={96.713}
        >
          <stop offset={0} stopColor="#6c5ba7" />
          <stop offset={1} stopColor="#568ecb" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__h)"
          d="M31.364 96.713l-1.046 1.062s72.531 4.015 114.389-.033l-1.188-.544c.001-.001-52.007 2.958-112.155-.485z"
        />
        <linearGradient
          id="graphic_svg__i"
          gradientUnits="userSpaceOnUse"
          x1={84.569}
          y1={106.055}
          x2={84.569}
          y2={98.761}
        >
          <stop offset={0} stopColor="#b8acd4" />
          <stop offset={1} stopColor="#e0d1e7" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__i)"
          d="M57.497 98.761l9.798 7.294h44.346l-6.737-6.361c-.001-.001-39.647-.39-47.407-.933z"
        />
        <linearGradient
          id="graphic_svg__j"
          gradientUnits="userSpaceOnUse"
          x1={23.481}
          y1={82.335}
          x2={23.481}
          y2={81.025}
        >
          <stop offset={0} stopColor="#6c5ba7" />
          <stop offset={1} stopColor="#568ecb" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__j)"
          d="M19.516 82.335l5.864-1.31h2.066v1.31z"
        />
        <path
          fill="#9376B5"
          d="M38.75 25.008s-2.12 12.482-2.111 22.306h33.5s-.158-16.459 0-22.306H38.75z"
        />
        <path fill="#90D3CF" d="M49.326 36.516h-5.481l.3-5.598h5.396z" />
        <path fill="#899ACE" d="M56.335 36.516h-5.481l.192-5.598h5.396z" />
        <path fill="#6672B6" d="M63.344 36.516h-5.48l.084-5.598h5.396z" />
        <path fill="#ED3393" d="M49.029 44.203h-5.596l.3-5.596h5.512z" />
        <path fill="#962582" d="M56.188 44.203h-5.597l.192-5.596h5.512z" />
        <path fill="#190E45" d="M63.344 44.203h-5.596l.085-5.596h5.511z" />
        <linearGradient
          id="graphic_svg__k"
          gradientUnits="userSpaceOnUse"
          x1={54.151}
          y1={28.867}
          x2={54.151}
          y2={25.008}
        >
          <stop offset={0} stopColor="#b8acd4" />
          <stop offset={1} stopColor="#e0d1e7" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__k)"
          d="M38.164 28.867h31.919l.056-3.859H38.75z"
        />
        <path fill="#FFF" d="M38.93 28.285l.355-2.646h2.608l-.276 2.646z" />
        <linearGradient
          id="graphic_svg__l"
          gradientUnits="userSpaceOnUse"
          x1={59.87}
          y1={58.967}
          x2={59.87}
          y2={15.356}
        >
          <stop offset={0} stopColor="#b8acd4" />
          <stop offset={1} stopColor="#e0d1e7" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__l)"
          style={{ mixBlendMode: "screen" }}
          d="M32.839 58.967l54.06-43.535s-25.882-.449-50.792.851c.001-.001-2.175 29.108-3.268 42.684z"
        />
        <linearGradient
          id="graphic_svg__m"
          gradientUnits="userSpaceOnUse"
          x1={90.811}
          y1={72.842}
          x2={90.811}
          y2={63.402}
        >
          <stop offset={0} stopColor="#eee8f4" />
          <stop offset={1} stopColor="#cabadb" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__m)"
          d="M85.64 71.146l.889-7.744h8.867l.584 7.428-2.918 2.012h-4.42z"
        />
        <linearGradient
          id="graphic_svg__n"
          gradientUnits="userSpaceOnUse"
          x1={90.893}
          y1={63.402}
          x2={90.893}
          y2={36.07}
        >
          <stop offset={0} stopColor="#b998c7" />
          <stop offset={1} stopColor="#7860a9" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__n)"
          d="M90.811 36.07c-13.509 0-12.953 14.564-8.172 20.012 3.383 3.855 2.001 5.337 2.335 6.087.333.751 1.556 1.233 1.556 1.233h8.56s1.223-.482 1.557-1.233c.334-.75-1.097-2.273 2.335-6.087 5.002-5.559 5.585-20.012-8.171-20.012z"
        />
        <linearGradient
          id="graphic_svg__o"
          gradientUnits="userSpaceOnUse"
          x1={90.852}
          y1={73.654}
          x2={90.852}
          y2={72.842}
        >
          <stop offset={0} stopColor="#2c3077" />
          <stop offset={1} stopColor="#492e82" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__o)"
          d="M88.642 72.842l1.167.812h2.084l1.169-.812z"
        />
        <path
          fill="#FFF"
          d="M81.721 44.377h2.668s.501-3.47 2.057-5.249l-2.363-.612s-2.639 2.106-2.362 5.861zm15.371 9.424l1.5.808s2.919-3.163 2.835-7.671h-2.126s.291 3.887-2.209 6.863z"
        />
        <linearGradient
          id="graphic_svg__p"
          gradientUnits="userSpaceOnUse"
          x1={84.974}
          y1={64.739}
          x2={96.647}
          y2={64.739}
        >
          <stop offset={0} stopColor="#8d8bc3" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__p)"
          d="M84.974 62.169s8.265 1.11 11.673 0c0 0-.366.937-1.25 1.233l.307 3.906s-3.877-2.366-9.326-2.57l.152-1.336c-.001 0-1.239-.631-1.556-1.233z"
        />
        <linearGradient
          id="graphic_svg__q"
          gradientUnits="userSpaceOnUse"
          x1={29.177}
          y1={33.717}
          x2={29.177}
          y2={28.867}
        >
          <stop offset={0} stopColor="#2c3077" />
          <stop offset={1} stopColor="#492e82" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__q)"
          d="M30.048 28.867h-1.741v4.574l1.501.276z"
        />
        <linearGradient
          id="graphic_svg__r"
          gradientUnits="userSpaceOnUse"
          x1={28.816}
          y1={40.812}
          x2={28.816}
          y2={36.516}
        >
          <stop offset={0} stopColor="#2c3077" />
          <stop offset={1} stopColor="#492e82" />
        </linearGradient>
        <path
          fill="url(#graphic_svg__r)"
          d="M29.67 36.516l-1.363.382-.346 3.444 1.498.47z"
        />
        <path
          fill="#ED3393"
          d="M152.539 14.17l8.296-5.837 3.517 1.981-9.237 8.502z"
        />
        <path
          fill="#5452A3"
          d="M36.596 3.984L45.011 0l2.179 4.069-8.618 2.124z"
        />
        <path
          fill="#6672B6"
          d="M121.574 121.761l11.9-.298 2.644 4.021-13.581-.529z"
        />
        <g>
          <linearGradient
            id="graphic_svg__s"
            gradientUnits="userSpaceOnUse"
            x1={115.345}
            y1={50.069}
            x2={126.433}
            y2={50.069}
          >
            <stop offset={0} stopColor="#d06075" />
            <stop offset={1} stopColor="#f48080" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__s)"
            d="M118.038 39.752s-3.19 11.026-2.626 15.549c0 0 .424 4.803 5.511 5.086l5.51-13.794-8.395-6.841z"
          />
          <linearGradient
            id="graphic_svg__t"
            gradientUnits="userSpaceOnUse"
            x1={93.643}
            y1={53.322}
            x2={93.643}
            y2={49.602}
          >
            <stop offset={0} stopColor="#2c3077" />
            <stop offset={1} stopColor="#492e82" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__t)"
            d="M94.36 49.602s-1.979 1.442-1.854 2.078c.115.595 2.278 1.643 2.278 1.643l-.424-3.721z"
          />
          <linearGradient
            id="graphic_svg__u"
            gradientUnits="userSpaceOnUse"
            x1={129.534}
            y1={144.836}
            x2={129.534}
            y2={24.893}
          >
            <stop offset={0.016} stopColor="#fbc2aa" />
            <stop offset={1} stopColor="#f59496" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__u)"
            d="M162.173 108.561l-2.145 36.275s-29.505-39.52-38.399-46.584a83.246 83.246 0 00-2.192-1.684c-7.348-5.505-10.638-6.149-11.79-6.794-1.276-.7-3.538-4.239-3.82-5.369-.281-1.13-.281-17.52-1.22-21.193-.95-3.673-5.279-7.437-5.653-8.759-.383-1.324 1.222-3.584 2.826-3.867 1.594-.282 9.891 2.171 12.434 10.558 0 0-1.04 1.876-1.604 4.138-.565 2.26.282 5.934.282 5.934s3.481.668 4.996.283c1.503-.373 4.33-8.659 4.137-15.068-.18-6.408-6.397-18.648-6.397-18.648l-4.622-10.83s5.368-2.542 8.295-1.978c0 0 4.613 7.438 7.349 9.044 0 0 6.081 1.027 9.562 2.068 0 0 4.184.564 7.676 3.481 0 0 3.854.281 5.65 1.694 1.785 1.413 9.608 11.303 10.072 13.948l.011.158c.237 3.152-5.833 21.499-17.758 33.559 0 0 2.849 2.746 7.009 6.522v.011c4.269 3.888 9.933 8.872 15.301 13.101z"
          />
          <linearGradient
            id="graphic_svg__v"
            gradientUnits="userSpaceOnUse"
            x1={124.552}
            y1={57.369}
            x2={124.552}
            y2={26.342}
          >
            <stop offset={0} stopColor="#2c3077" />
            <stop offset={1} stopColor="#492e82" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__v)"
            d="M149.4 44.901l-.022.011a4.657 4.657 0 01-1.604.498s-17.509 2.78-30.338 5.131v.012c-6.76 1.221-12.23 2.34-13.134 2.859-.826.476-1.402 1.041-1.786 1.594-.859 1.209-.859 2.363-.859 2.363s-3.809-.373-6.872-4.047c0 0-1.459-1.503-.804-3.392 0 0 1.222-3.187 4.635-5.335h.012c.768-.497 1.65-.926 2.656-1.255 0 0 .012.216.068.51.112.61.451 1.56 1.344 1.56.668 0 8.998-3.075 18.719-7.008l.012-.012c9.505-3.865 20.334-8.568 26.618-12.049 0 0 5.946.136 7.076 6.781.971 5.708-2.34 10.084-5.721 11.779z"
          />
          <path
            opacity={0.86}
            fill="#9A7BB3"
            d="M117.436 50.541v.012c-6.76 1.221-12.23 2.34-13.134 2.859-.826.476-1.402 1.041-1.786 1.594l-4.25 1.051c-2.17-4.543.35-11.461.35-11.461h.012l2.725-.745c.112.61.451 1.56 1.344 1.56.668 0 8.998-3.075 18.719-7.008-4.625 4.938-3.98 12.138-3.98 12.138z"
          />
          <linearGradient
            id="graphic_svg__w"
            gradientUnits="userSpaceOnUse"
            x1={108.865}
            y1={46.593}
            x2={108.865}
            y2={24.845}
          >
            <stop offset={0.016} stopColor="#f69893" />
            <stop offset={1} stopColor="#f59496" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__w)"
            d="M98.615 44.591s1.255 4.068 8.602.607c0 0 9.891-8.735 11.899-17.478l-1.82-2.75s-5.439-.872-8.289 1.978c0 0-1.154 6.663-3.977 11.267-.945 1.544-6.415 4.793-6.415 4.793v1.583z"
          />
          <linearGradient
            id="graphic_svg__x"
            gradientUnits="userSpaceOnUse"
            x1={104.586}
            y1={83.846}
            x2={104.586}
            y2={49.79}
          >
            <stop offset={0.016} stopColor="#f69893" />
            <stop offset={1} stopColor="#f59496" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__x)"
            d="M96.95 54.453s5.339 6.83 5.651 8.759c.313 1.93 1.161 20.634 1.161 20.634s-.351-7.359 7.128-12.627c0 0-.336-2.547 0-5.217.336-2.668 1.083-3.755 1.318-4.861.236-1.108-3.07-10.221-11.313-11.352 0 0-1.434.471-2.502 1.507-1.066 1.038-1.443 3.157-1.443 3.157z"
          />
          <linearGradient
            id="graphic_svg__y"
            gradientUnits="userSpaceOnUse"
            x1={154.482}
            y1={145.799}
            x2={154.482}
            y2={98.158}
          >
            <stop offset={0} stopColor="#6c5ba7" />
            <stop offset={1} stopColor="#568ecb" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__y)"
            d="M163.541 139.813c-.994 2.431-2.592 4.357-4.219 5.985l-9.153-13.772s-3.933 2.192-5.052 1.966c-2.928-.564-2.928-6.917-2.928-6.917s-.803-7.584 1.175-15.226c.013-.034.013-.057.013-.057 1.379-5.346 4.137-10.727 9.482-13.541 0 0 3.596-1.197 6.218 4.951l7.481 5.087c.001.001 1.957 19.408-3.017 31.524z"
          />
          <linearGradient
            id="graphic_svg__z"
            gradientUnits="userSpaceOnUse"
            x1={152.785}
            y1={146.008}
            x2={152.785}
            y2={103.203}
          >
            <stop offset={0} stopColor="#8d8bc3" />
            <stop offset={1} stopColor="#fff" />
          </linearGradient>
          <path
            opacity={0.5}
            fill="url(#graphic_svg__z)"
            d="M163.541 139.792v.021c-.994 2.431-2.272 4.566-3.898 6.194l-9.474-13.981s-3.933 2.192-5.052 1.966c-2.928-.564-2.928-6.917-2.928-6.917s-.803-7.584 1.175-15.226c-.146.949-2.103 13.35.589 17.486 2.803 4.306 7.538-23.172 15.124-26.133 0 0-7.688 12.659-7.02 21.42.655 8.76 11.484 15.17 11.484 15.17z"
          />
          <linearGradient
            id="graphic_svg__A"
            gradientUnits="userSpaceOnUse"
            x1={132.636}
            y1={96.568}
            x2={132.636}
            y2={49.129}
          >
            <stop offset={0.016} stopColor="#f7987e" />
            <stop offset={1} stopColor="#f4888f" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__A)"
            d="M139.86 88.927s2.849 2.746 7.009 6.522v.011s-9.98-2.43-18.459-1.446c-6.193.723-8.296 1.944-8.974 2.555-7.348-5.505-10.638-6.149-11.79-6.794l9.555-5.929s9.231-20.445 10.554-30.852l-2.327-3.865 7.029.938s4.646 1.322 12.66 1.23c0 0 4.104 2.149 7.664 1.696l4.826 2.216.011.158c.237 3.154-5.833 21.5-17.758 33.56z"
          />
          <linearGradient
            id="graphic_svg__B"
            gradientUnits="userSpaceOnUse"
            x1={110.891}
            y1={70.469}
            x2={118.291}
            y2={70.469}
          >
            <stop offset={0} stopColor="#d06075" />
            <stop offset={1} stopColor="#f48080" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__B)"
            d="M110.891 71.219s2.41 2.261 4.455 2.543c0 0 1.801-2.182 2.945-6.586 0 0-1.09 3.055-2.455 3.762 0 0-2.261 1.034-4.945.281z"
          />
          <linearGradient
            id="graphic_svg__C"
            gradientUnits="userSpaceOnUse"
            x1={96.95}
            y1={55.253}
            x2={104.572}
            y2={55.253}
          >
            <stop offset={0} stopColor="#b8acd4" />
            <stop offset={1} stopColor="#e0d1e7" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__C)"
            d="M96.95 54.453s4.325 5.507 5.604 5.51c1.713.002 2.686-2.59 1.483-6.005-.876-2.487-3.415-3.415-3.415-3.415s-2.212.377-3.672 3.91z"
          />
          <linearGradient
            id="graphic_svg__D"
            gradientUnits="userSpaceOnUse"
            x1={99.24}
            y1={55.184}
            x2={99.24}
            y2={50.543}
          >
            <stop offset={0} stopColor="#b8acd4" />
            <stop offset={1} stopColor="#e0d1e7" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__D)"
            d="M97.524 55.184s1.358-2.895 4.007-4.179l-.908-.462s-2.178.589-3.673 3.91l.574.731z"
          />
          <linearGradient
            id="graphic_svg__E"
            gradientUnits="userSpaceOnUse"
            x1={102.998}
            y1={60.168}
            x2={102.998}
            y2={51.942}
          >
            <stop offset={0} stopColor="#d06075" />
            <stop offset={1} stopColor="#f48080" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__E)"
            d="M102.735 51.942s2.23 1.852 1.735 5.431c-.495 3.578-2.684 2.427-3.574 1.557 0 0 .935 1.086 1.924 1.228.988.142 2.26-1.113 2.278-3.55.017-2.438-2.363-4.666-2.363-4.666z"
          />
          <linearGradient
            id="graphic_svg__F"
            gradientUnits="userSpaceOnUse"
            x1={149.809}
            y1={45.173}
            x2={149.809}
            y2={26.342}
          >
            <stop offset={0} stopColor="#b8acd4" />
            <stop offset={1} stopColor="#e0d1e7" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__F)"
            d="M149.4 44.901l-.022.011-.645.261s-4.407-3.345-4.407-10.308c0-6.139 3.718-8.523 3.718-8.523s5.946.136 7.076 6.781c.972 5.707-2.339 10.083-5.72 11.778z"
          />
          <linearGradient
            id="graphic_svg__G"
            gradientUnits="userSpaceOnUse"
            x1={152.277}
            y1={36.263}
            x2={152.277}
            y2={31.793}
          >
            <stop offset={0} stopColor="#fdfeff" />
            <stop offset={1} stopColor="#d8cae3" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__G)"
            d="M152.332 36.197l-.007.004-.151.062s-1.348-.874-1.348-2.527c0-1.456 1.184-1.942 1.184-1.942s1.412.032 1.679 1.609a2.626 2.626 0 01-1.357 2.794z"
          />
          <path
            opacity={0.86}
            fill="#937AB8"
            d="M151.783 35.409l-.002.001-.098.04s-.691-.37-.691-1.422c0-.929.588-1.423.588-1.423s.896.021 1.067 1.025a1.67 1.67 0 01-.864 1.779z"
          />
          <linearGradient
            id="graphic_svg__H"
            gradientUnits="userSpaceOnUse"
            x1={112.325}
            y1={40.458}
            x2={112.325}
            y2={31.373}
          >
            <stop offset={0} stopColor="#d06075" />
            <stop offset={1} stopColor="#f48080" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__H)"
            d="M113.76 38.048l-2.869-6.675.862 9.085s1.037-1.179 2.007-2.41z"
          />
          <linearGradient
            id="graphic_svg__I"
            gradientUnits="userSpaceOnUse"
            x1={122.595}
            y1={44.94}
            x2={122.595}
            y2={34.028}
          >
            <stop offset={0} stopColor="#b8acd4" />
            <stop offset={1} stopColor="#e0d1e7" />
          </linearGradient>
          <path
            fill="url(#graphic_svg__I)"
            d="M110.043 43.902l25.103-9.874-.937 2.058-24.166 8.853z"
          />
          <path
            fill="#FFF"
            d="M135.952 35.849l1.077-2.767 5.067-2.548-1.251 3.948zm16.841 1.877s-.314 1.638-1.66 3.086l.967.969s1.672-1.313 2.115-3.409l-1.422-.646zm5.187 66.407c-.84-4.736-5.131-5.594-5.173-5.604l.103-.557c.048.01 4.719.944 5.627 6.063l-.557.098zm-1.99 2.877c-2.377-4.314-7.055-5.556-7.104-5.568l.14-.547c.202.051 4.966 1.317 7.459 5.843l-.495.272zm-1.689 3.232c-3.59-3.021-8.761-3.984-8.811-3.994l.1-.557c.218.04 5.367.997 9.073 4.119l-.362.432zm-1.683 5.286c-4.259-3.131-9.01-4.379-9.056-4.392l.139-.548c.05.013 4.902 1.287 9.251 4.483l-.334.457zm-1.237 4.853c-5.951-2.838-8.984-2.454-9.016-2.447l-.082-.56c.127-.017 3.214-.425 9.343 2.498l-.245.509zm-3.917 5.116c-3.141 0-5.366-.586-5.488-.618l.146-.546c.038.012 3.901 1.024 8.558.383l.076.559a23.953 23.953 0 01-3.292.222zm-4.382 6.529c-.063 0-.104-.003-.116-.003l.025-.564c.032-.006 3.05.095 7.029-2.362l.298.479c-3.748 2.316-6.629 2.45-7.236 2.45z"
          />
          <path
            fill="#FFF"
            d="M150.451 132.028l-.563-.005c.001-.203.301-20.321 8.989-29.02l.398.398c-8.525 8.537-8.821 28.427-8.824 28.627z"
          />
          <path
            fill="#E2D197"
            d="M109.007 26.948s3.862 6.282 6.413 8.771c3.696 3.607 8.161.197 8.161.197l.812-1.447s-4.434-4.83-7.097-9.498c0 0-5.075-.95-8.289 1.977z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgGraphic;
