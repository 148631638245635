import React from "react";
import SvgPicPicture from "../../assets/svg/SvgJs/PicPicture";
import SvgLampshades2 from "../../assets/svg/SvgJs/Lampshades2";
import SvgS6N from "../../assets/svg/SvgJs/NewTubes/S6N";
import useWindowSize from "../../hooks/useWindowSize";
import * as res from '../../helpers/responsive'
import SvgLess768S6 from "../../assets/svg/SvgJs/Responsive/less_768/Less768S6";
import SvgMiddtubeRespon from "../../assets/svg/SvgJs/Responsive/less_480/Middtube";

function OurTeamSection({sD}) {
    const size = useWindowSize();

    React.useEffect(() => {
        if (size.width < 1024) return;

        // console.log(size.width);

        // let q = 0.628131 * size.width;
        let q = size.width / 2 + 199;

        // console.log(q);

        let tubeh = document.querySelector(".sectionOurTeam .tubeh");
        let tubeh2 = document.querySelector(".sectionOurTeam .tubeh2");

        tubeh.style.left = "-" + (size.width - 1024) / 2 + "px";
        tubeh.style.clip = `rect(70px, ${
            270 + (size.width - 1024) / 2 + "px"
        }, 212px, 240px)`;
        tubeh2.style.left = "-" + (size.width - 1024) / 2 + "px";
        // tubeh2.style.clip = `rect(331px, ${
        //   770 + (size.width - 1024) / 2 + "px"
        // }, 652px, 1150px)`;
        tubeh2.style.clip = `rect(331px, ${q + 20 + "px"}
    , 652px, ${q}px)`;
    }, [size.width]);

    return (
        <div className="page sectionOurTeam">
            <div className="cont">
                <div className="title">
                    <h3>Our Team</h3>
                </div>
                <div className="content">
                    <div className="paragraph">{sD}</div>
                </div>
                <div className="tube">
                    <res.Desktop>
                        <SvgS6N/>
                    </res.Desktop>
                    <res.Tablet>
                        <SvgS6N/>
                    </res.Tablet>
                    <res.Mobile>
                        <SvgLess768S6/>
                    </res.Mobile>
                    <res.LessMobile>
                        <SvgMiddtubeRespon heightOfTube={"100vh"} tubeClass={"s6"}/>
                    </res.LessMobile>
                </div>
                <div className="image">
                    <SvgPicPicture/>
                </div>
                <div className="light">
                    <SvgLampshades2/>
                </div>
                <div className="tubeh"></div>
                <div className="tubeh2 tubeh"></div>
            </div>
        </div>
    );
}

export default OurTeamSection;
