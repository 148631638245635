import * as React from "react";

function SvgMushrooms(props) {
  return (
    <svg
      width="159.804"
      height="209.68"
      viewBox="0 0 159.804 209.68"
      {...props}
    >
      <radialGradient
        id="Mushrooms_svg__a"
        cx={40.585}
        cy={-172.435}
        r={8.339}
        gradientTransform="matrix(8.4627 0 0 .8075 -272.902 342.187)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#8e55a2" />
        <stop offset={0.09} stopColor="#7c4d89" />
        <stop offset={0.274} stopColor="#593e61" />
        <stop offset={0.451} stopColor="#3d2f42" />
        <stop offset={0.616} stopColor="#28212b" />
        <stop offset={0.767} stopColor="#171318" />
        <stop offset={0.9} stopColor="#070708" />
        <stop offset={1} stopColor="#010101" />
      </radialGradient>
      <ellipse
        cx={70.558}
        cy={202.945}
        rx={70.558}
        ry={6.734}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__a)"
      />
      <linearGradient
        id="Mushrooms_svg__b"
        gradientUnits="userSpaceOnUse"
        x1={85.129}
        y1={118.337}
        x2={98.385}
        y2={102.085}
      >
        <stop offset={0} stopColor="#743f98" />
        <stop offset={0.336} stopColor="#6f4099" />
        <stop offset={0.826} stopColor="#5f439a" />
        <stop offset={0.994} stopColor="#57449b" />
      </linearGradient>
      <path
        d="M80.402 113.641s13.514 1.064 26.583-1.807l2.08-2.996-18.193-4.934-13.043 3.086 2.573 6.651z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__b)"
      />
      <linearGradient
        id="Mushrooms_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={89.466}
        y1={202.94}
        x2={89.466}
        y2={93.902}
      >
        <stop offset={0.006} stopColor="#de5f3a" />
        <stop offset={0.231} stopColor="#db5d3c" />
        <stop offset={0.405} stopColor="#d35740" />
        <stop offset={0.563} stopColor="#c64e47" />
        <stop offset={0.711} stopColor="#b4424e" />
        <stop offset={0.852} stopColor="#9f3354" />
        <stop offset={0.986} stopColor="#881f59" />
        <stop offset={1} stopColor="#851c5a" />
      </linearGradient>
      <path
        d="M91.877 138.354c-8.963 23.045 21.246 40.25 21.246 52.813 0 1.381-.431 2.67-1.11 3.852-2.722 4.73-9.503 7.732-9.503 7.732l-33.359.189s-5.401-.799-6.562-9.482c-.37-2.76-.581-5.383-.469-8.143.009 0 .009 0 0-.008.32-8.443 3.562-18.096 13.713-36.881 4.15-7.682 5.831-15.854 6.171-23.477v-.01c.341-7.852-.739-15.111-1.98-20.633-1.438-6.352-3.11-10.404-3.11-10.404h3.609s.322 4.379 1.582 9.701c1.39 5.912 3.931 12.984 8.462 16.445 6.041 4.621 14.094-8.023 18.334-15.914 1.781-3.332 2.891-5.813 2.891-5.813h5.052s-2.361 2.09-5.873 6.402c-4.981 6.102-12.264 16.645-18.455 32.008-.21.535-.429 1.072-.639 1.623z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__c)"
      />
      <linearGradient
        id="Mushrooms_svg__d"
        gradientUnits="userSpaceOnUse"
        x1={62.088}
        y1={173.972}
        x2={96.918}
        y2={173.972}
      >
        <stop offset={0} stopColor="#ca538e" />
        <stop offset={1} stopColor="#8e7190" />
      </linearGradient>
      <path
        d="M62.088 185.406s4.018-9.887 12.487-15.068c8.471-5.18 22.343-4.813 22.343-4.813l-1.422-2.988h-9.173s-14.572 4.674-21.375 14.908l-2.86 7.961z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__d)"
      />
      <linearGradient
        id="Mushrooms_svg__e"
        gradientUnits="userSpaceOnUse"
        x1={80.022}
        y1={120.168}
        x2={110.971}
        y2={120.168}
      >
        <stop offset={0} stopColor="#ca538e" />
        <stop offset={1} stopColor="#8e7190" />
      </linearGradient>
      <path
        d="M110.971 104.725c-4.981 6.102-12.264 16.645-18.455 32.008-6.421-3.631-10.271-11.301-10.513-11.783v-.01c.341-7.852-.739-15.111-1.98-20.633l2.081-.703c1.39 5.912 3.931 12.984 8.462 16.445 6.041 4.621 14.094-8.023 18.334-15.914l2.071.59z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__e)"
      />
      <linearGradient
        id="Mushrooms_svg__f"
        gradientUnits="userSpaceOnUse"
        x1={96.559}
        y1={113.639}
        x2={96.559}
        y2={80.386}
      >
        <stop offset={0} stopColor="#81449a" />
        <stop offset={1} stopColor="#928bc3" />
      </linearGradient>
      <path
        d="M159.804 88.473s-2.061 3.309-6.122 7.299c-4.351 4.281-11.012 9.332-19.905 11.873-17.184 4.912-26.788 4.191-26.788 4.191-.839-1.869-2.86-.609-2.86-.609l-19.346.18c-4.33-1.721-4.381 2.232-4.381 2.232s-53.882-4.012-46.37-11.984c30.177-38.301 125.772-13.182 125.772-13.182z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__f)"
      />
      <radialGradient
        id="Mushrooms_svg__g"
        cx={-419.799}
        cy={-298.3}
        r={56.356}
        gradientTransform="translate(720.56 491.876) scale(1.5364)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#f6bad5" />
        <stop offset={0.221} stopColor="#f3b8d4" />
        <stop offset={0.328} stopColor="#ebb5d3" />
        <stop offset={0.412} stopColor="#dfb0d2" />
        <stop offset={0.483} stopColor="#ceaad0" />
        <stop offset={0.546} stopColor="#b9a2cd" />
        <stop offset={0.584} stopColor="#a99ccc" />
        <stop offset={0.675} stopColor="#a39bcc" />
        <stop offset={0.807} stopColor="#9198cc" />
        <stop offset={0.963} stopColor="#7394cc" />
        <stop offset={0.986} stopColor="#6e93cc" />
      </radialGradient>
      <path
        d="M159.804 88.473s-10.983 20.912-51.303 12.412c-8.804-1.852-16.306-3.102-23.076-3.861h-.021a88.066 88.066 0 00-2.631-.281c-.078-.018-.159-.018-.229-.018a5.643 5.643 0 00-.591-.063c-.021 0-.029-.01-.05-.01-1.66-.16-3.289-.279-4.881-.381-.58-.029-1.16-.059-1.73-.09-2.229-.111-4.391-.16-6.533-.168l-1.26-4.613-3.34 4.613a118.98 118.98 0 00-4.912.178l2.841-10.572c-4.121 4.803-12.783 9.934-15.655 12.104-6.771 1.061-8.992 2.201-12.393 3.92.451-.488 14.894-16.193 25.967-37.029C73.312 39.6 71.901 8.52 82.112 1.52c4.411-3.031 11.163-1.393 18.995 3.719 6.653 4.342 14.094 11.203 21.527 19.826 14.184 16.433 28.366 39.289 37.17 63.408z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__g)"
      />
      <linearGradient
        id="Mushrooms_svg__h"
        gradientUnits="userSpaceOnUse"
        x1={80.522}
        y1={77.383}
        x2={158.088}
        y2={77.383}
      >
        <stop offset={0} stopColor="#fff" />
        <stop offset={1} stopColor="#1573b4" />
      </linearGradient>
      <path
        d="M80.521 93.902s26.269 3.186 43.073 6.092c16.805 2.906 33.356-8.012 34.494-11.521 0 0-2.067-13.061-14.616-34.188 0 0 8.228 16.41 3.115 28.525-4.084 9.68-14.399 14.199-29.744 13.18-18.533-1.23-25.569-2.242-36.322-2.088z"
        opacity={0.4}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__h)"
      />
      <path
        d="M91.961 67.002c-1.857 2.691-3.578 5.453-5.443 8.145-1.155 1.668-1.795 3.283-2.326 5.248-.852 3.15 1.346 7.119 3.821 9.107 3.077 2.471 7.444 3.217 11.261 3.576 3.457.328 7.264 1.432 10.295-.854 3.499-2.641 5.644-7.053 6.581-11.324 1.133-5.172.666-10.811-3.52-14.588-2.881-2.598-7.375-4.99-11.307-5.17-1.827-.084-3.502.307-5.188 1-1.211.498-2.425 1.211-3.283 2.203-1.057 1.221-1.581 2.841-.891 2.657zm9.145-16.029c-.731.682-.937 1.404-.62 2.172 1.611-.395 4.681.334 4.687-2.172.001-1.145-.513-1.41-1.528-1.527-1.215-.143-1.605.33-2.54 1.02v.507zm21.529-25.909l-.09.15c-1.331 1.99-3.982 2.039-6.083 1.57-1.72-.391-3.591-.551-5.273-1.07-.668-.199-1.248-.561-1.938-.711a5.864 5.864 0 01-1.881-.738c-1.852-1.09-3.55-1.922-4.952-3.604-1.231-1.49-2.22-3.33-2.602-5.221-.369-1.879-.33-3.84-.039-5.73.12-.832.24-1.672.39-2.502.18-1.049.261-1.24.939-1.971 6.654 4.343 14.096 11.204 21.529 19.827zM75.079 91.656c.339-1.184 1.05-2.414 2.199-2.988.585-.291 1.18-.199 1.79-.291.615-.092 1.143-.246 1.773-.264 2.242-.068 4.029 1.129 5.103 3.029.581 1.027 1.022 1.781 1.01 2.982-.005.627.029 1.311-.245 1.832-.145.273-.521.799-.815.955-.465.248-1.443.043-1.972.049-.426.008-.621-.096-1.011-.195-.345-.084-.663-.029-1.009-.113-.572-.139-1.148-.361-1.712-.504-.288-.072-.424-.201-.81-.197-.42.008-.867-.053-1.281-.006-.666.076-1.343.568-2.014.523-.57-.037-.878-.326-1.257-.715-.227-.23-.34-.291-.506-.563-.167-.27-.134-.504-.248-.762-.249-.576-.339-1.037-.202-1.707.039-.193.181-.566.248-.814.081-.313.317-.798.959-.251z"
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#babfe1"
      />
      <linearGradient
        id="Mushrooms_svg__i"
        gradientUnits="userSpaceOnUse"
        x1={54.262}
        y1={106.266}
        x2={54.262}
        y2={87.012}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#e2d5e9" />
      </linearGradient>
      <path
        d="M60.801 105.668s-10.411-3.904-14.366-7.949c0 0 11.002-7.016 15.653-12.1l-6.342 12.354c.001 0 1.792 5.32 5.055 7.695z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__i)"
      />
      <linearGradient
        id="Mushrooms_svg__j"
        gradientUnits="userSpaceOnUse"
        x1={65.828}
        y1={101.161}
        x2={65.828}
        y2={92.061}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#e2d5e9" />
      </linearGradient>
      <path
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__j)"
        d="M66.549 100.879l-2.39-4.865 3.339-4.612-.758 4.739z"
      />
      <linearGradient
        id="Mushrooms_svg__k"
        gradientUnits="userSpaceOnUse"
        x1={78.665}
        y1={187.807}
        x2={78.665}
        y2={156.569}
      >
        <stop offset={0} stopColor="#851c5a" />
        <stop offset={0.36} stopColor="#a63c5e" />
        <stop offset={0.774} stopColor="#cc5b62" />
        <stop offset={0.994} stopColor="#de6863" />
      </linearGradient>
      <path
        d="M96.918 165.525s0-9.559-6.426-8.926c-5.395.529-27.923 21.098-29.565 23.752s1.162 7.455 1.162 7.455v-2.4s2.8-7.203 8.694-13.266c5.895-6.066 25.143-8.088 25.143-8.088l.992 1.473z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__k)"
      />
      <linearGradient
        id="Mushrooms_svg__l"
        gradientUnits="userSpaceOnUse"
        x1={93.511}
        y1={194.023}
        x2={93.511}
        y2={167.543}
      >
        <stop offset={0.006} stopColor="#9e473c" />
        <stop offset={0.159} stopColor="#954340" />
        <stop offset={0.437} stopColor="#7e3749" />
        <stop offset={0.804} stopColor="#5e2552" />
        <stop offset={1} stopColor="#4b1a56" />
      </linearGradient>
      <path
        d="M106.63 192.086c-.671.57-1.682 1.002-2.943 1.311-7.36 1.832-22.943-.568-23.805-4.451-.689-3.109-.399-10.512 1.921-15.744 1.08-2.42 2.589-4.381 4.642-5.229 9.483-3.924 24.765 20.213 20.185 24.113z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__l)"
      />
      <linearGradient
        id="Mushrooms_svg__m"
        gradientUnits="userSpaceOnUse"
        x1={91.699}
        y1={194.023}
        x2={91.699}
        y2={171.273}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#c4a0a5" />
      </linearGradient>
      <path
        d="M103.688 193.396c-7.36 1.832-22.943-.568-23.805-4.451-.689-3.109-.399-10.512 1.921-15.744 6.481-4.121 11.213-1.438 17.284 6.143 6.061 7.582 4.6 14.052 4.6 14.052z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__m)"
      />
      <path
        d="M68.759 96.012s23.22.309 44.7 5.795c21.482 5.484 42.879-3.127 46.345-13.334 0 0-10.054 17.195-41.136 12.406-27.694-4.268-33.484-4.809-49.909-4.867zm-34.727 5.642s3.218-4.045 12.403-6.01l13.976-8.275-13.976 10.35s-8.977 1.297-12.403 3.935z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#babbde"
      />
      <linearGradient
        id="Mushrooms_svg__n"
        gradientUnits="userSpaceOnUse"
        x1={33.313}
        y1={104.705}
        x2={153.682}
        y2={104.705}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M153.682 95.771c-4.351 4.281-11.012 9.332-19.905 11.873-17.184 4.912-26.788 4.191-26.788 4.191-.839-1.869-2.86-.609-2.86-.609l-19.346.18c-4.33-1.721-4.381 2.232-4.381 2.232s-53.882-4.012-46.37-11.984c0 0 8.305 4.945 26.379 7.215 18.085 2.271 62.043-1.584 67.734-5.354l25.537-7.744z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__n)"
      />
      <linearGradient
        id="Mushrooms_svg__o"
        gradientUnits="userSpaceOnUse"
        x1={129.143}
        y1={76.311}
        x2={129.143}
        y2={34.043}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M144.819 68.656c-.391 1.859-2.81 4.029-6.13 5.291-5.273 2-12.813 1.699-18.125-5.813-6.883-9.732-10.034-36.318-3.721-37.129.15-.02.309-.029.48-.01 8.231.3 28.487 32.95 27.496 37.661z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__o)"
      />
      <linearGradient
        id="Mushrooms_svg__p"
        gradientUnits="userSpaceOnUse"
        x1={57.653}
        y1={88.332}
        x2={57.653}
        y2={69.504}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#d7cde6" />
      </linearGradient>
      <path
        d="M62.948 80.389c-2.911 4.141-8.192 6.922-11.073 7.311-.87.121-1.52.02-1.811-.33-1.269-1.51 6.512-18.645 12.024-19.213 1.36-.141 2.341.719 2.86 2.139.922 2.472.412 6.651-2 10.093z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__p)"
      />
      <linearGradient
        id="Mushrooms_svg__q"
        gradientUnits="userSpaceOnUse"
        x1={78.124}
        y1={66.077}
        x2={78.124}
        y2={31.452}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#fbd6e0" />
      </linearGradient>
      <path
        d="M87.964 46.529c.3 10.422-8.96 18.906-15.042 18.455-1.751-.131-3.241-1-4.163-2.771-2.139-4.109 3.282-25.615 7.455-31.377 1.389-1.922 3.108-2.311 4.81-1.471 3.399 1.651 6.691 8.213 6.94 17.164z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__q)"
      />
      <path
        d="M89.464 20.402c-.039 3.561-3.361 4.451-5.941 3.48a5.409 5.409 0 01-.69-.32c-2.029-1.098-.969-5.93 1.38-7.602 1.462-1.029 3.151-.559 4.201.871.651.87 1.063 2.101 1.05 3.571z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#ccc9e0"
      />
      <path
        d="M148.081 79.828v.01c-.66.33-1.391.539-2.131.6-2.301.18-4.031-4.461-2.961-7.131 1.071-2.682 4.741-2.412 6.822.881 1.571 2.468.281 4.63-1.73 5.64z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#acc8df"
      />
      <linearGradient
        id="Mushrooms_svg__r"
        gradientUnits="userSpaceOnUse"
        x1={114.282}
        y1={52.472}
        x2={144.855}
        y2={52.472}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M144.819 68.656c-.391 1.859-2.81 4.029-6.13 5.291 1.599-1.811 3.009-3.951 2.91-5.813-.22-4.182-7.772-17.484-14.785-27.146s-12.532-7.16-12.532-7.16c.529-1.432 1.62-2.354 3.041-2.832 8.231.299 28.487 32.949 27.496 37.66z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__r)"
      />
      <linearGradient
        id="Mushrooms_svg__s"
        gradientUnits="userSpaceOnUse"
        x1={142.989}
        y1={75.656}
        x2={150.511}
        y2={75.656}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M148.081 79.828c1.74-1.959 1.16-4.221-.981-5.881-2.15-1.658-4.111-.641-4.111-.641 1.071-2.682 4.741-2.412 6.822.881 1.571 2.469.281 4.631-1.73 5.641z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__s)"
      />
      <linearGradient
        id="Mushrooms_svg__t"
        gradientUnits="userSpaceOnUse"
        x1={74.65}
        y1={64.984}
        x2={74.65}
        y2={28.946}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M81.023 29.365s-3.16-.248-6.953 10.863c-3.791 11.123-3.681 22.086-1.149 24.756-1.751-.131-3.241-1-4.163-2.771-2.139-4.109 3.282-25.615 7.455-31.377 1.39-1.922 3.109-2.311 4.81-1.471z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__t)"
      />
      <linearGradient
        id="Mushrooms_svg__u"
        gradientUnits="userSpaceOnUse"
        x1={57.438}
        y1={87.748}
        x2={57.438}
        y2={68.142}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M64.948 70.295c-1.188-.311-4.621-.789-7.292 2.912-3.01 4.152-5.8 11.412-5.781 14.492-.87.121-1.52.02-1.811-.33-1.269-1.51 6.512-18.645 12.024-19.213 1.36-.14 2.341.719 2.86 2.139z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__u)"
      />
      <linearGradient
        id="Mushrooms_svg__v"
        gradientUnits="userSpaceOnUse"
        x1={85.086}
        y1={23.883}
        x2={85.086}
        y2={15.407}
      >
        <stop offset={0} stopColor="#7a56a3" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        d="M88.414 16.832c-1.62-.359-3.781.4-4.741 2.051-1.09 1.891-.468 4.133-.15 5a5.409 5.409 0 01-.69-.32c-2.029-1.098-.969-5.93 1.38-7.602 1.462-1.029 3.151-.559 4.201.871z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__v)"
      />
      <linearGradient
        id="Mushrooms_svg__w"
        gradientUnits="userSpaceOnUse"
        x1={58.688}
        y1={41.332}
        x2={111.472}
        y2={41.332}
      >
        <stop offset={0} stopColor="#c6e6dc" />
        <stop offset={0.191} stopColor="#96a3ae" />
        <stop offset={0.409} stopColor="#726f8a" />
        <stop offset={0.606} stopColor="#5a4e73" />
        <stop offset={0.777} stopColor="#4a3664" />
        <stop offset={0.914} stopColor="#41275c" />
        <stop offset={1} stopColor="#3e2259" />
      </linearGradient>
      <path
        d="M83.702 1.643c-9.571 3.148-12.411 33.354-16.203 45.328-3.73 11.771-8.662 19.824-8.812 20.064.22-.182 2.765-2.025 5.904-.586 3.685 1.691 1.128 14.111 4.167 14.908 5.057 1.328 15.666-16.041 13-17.752-1.8-1.154-11.04 7.762-14.259-.762-1.61-4.264 4.042-28.467 6.572-31.857 2.521-3.391 5.344-4.957 9.224-2.041 4.678 3.516 6.379 18.494 7.391 19.627 1.011 1.139 10.541-5.682 13.704-14.535 3.16-8.844 3.16-14.654 7.082-17.686 0 .001-17.664-18.035-27.77-14.708zm8.244 20.39c-.951 2.66-8.531 5.313-10.922 3.041-2.392-2.281-.129-9.951.729-11.564 1.251-2.309 3.373-2.279 6.224-1.709 2.85.57 4.919 7.582 3.969 10.232z"
        opacity={0.5}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__w)"
      />
      <linearGradient
        id="Mushrooms_svg__x"
        gradientUnits="userSpaceOnUse"
        x1={75.832}
        y1={147.138}
        x2={90.686}
        y2={147.138}
      >
        <stop offset={0} stopColor="#ca538e" />
        <stop offset={1} stopColor="#8e7190" />
      </linearGradient>
      <path
        d="M75.832 148.426l1.997-3.104s9.663-.629 12.857 6.379v-3.281l-5.942-5.088-5.687-.758-1.229 1.682-1.996 4.17z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__x)"
      />
      <linearGradient
        id="Mushrooms_svg__y"
        gradientUnits="userSpaceOnUse"
        x1={83.143}
        y1={151.701}
        x2={83.143}
        y2={138.83}
      >
        <stop offset={0} stopColor="#851c5a" />
        <stop offset={0.36} stopColor="#a63c5e" />
        <stop offset={0.774} stopColor="#cc5b62" />
        <stop offset={0.994} stopColor="#de6863" />
      </linearGradient>
      <path
        d="M74.575 150.771s1.257-5.811 1.257-10.471c0-4.803 14.914 3.412 15.671 6.695.757 3.285-.817 4.705-.817 4.705l-.699-2.178s-4.356-6.293-12.161-4.818l-3.251 6.067z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__y)"
      />
      <linearGradient
        id="Mushrooms_svg__z"
        gradientUnits="userSpaceOnUse"
        x1={65.426}
        y1={166.43}
        x2={95.262}
        y2={166.43}
      >
        <stop offset={0} stopColor="#f49f57" />
        <stop offset={0.031} stopColor="#f49c58" />
        <stop offset={0.232} stopColor="#f38d58" />
        <stop offset={0.448} stopColor="#f38158" />
        <stop offset={0.687} stopColor="#f47a57" />
        <stop offset={0.994} stopColor="#f47857" />
      </linearGradient>
      <path
        d="M65.426 175.648s5.92-5.682 11.074-9.217c11.056-7.59 13.741-5.947 15.825-6.328 2.085-.379 2.938-1.102 2.938-1.102s-2.02-3.066-5.794-1.172c-3.778 1.896-16.544 9.2-24.043 17.819z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__z)"
      />
      <linearGradient
        id="Mushrooms_svg__A"
        gradientUnits="userSpaceOnUse"
        x1={75.832}
        y1={142.975}
        x2={90.686}
        y2={142.975}
      >
        <stop offset={0} stopColor="#f49f57" />
        <stop offset={0.031} stopColor="#f49c58" />
        <stop offset={0.232} stopColor="#f38d58" />
        <stop offset={0.448} stopColor="#f38158" />
        <stop offset={0.687} stopColor="#f47a57" />
        <stop offset={0.994} stopColor="#f47857" />
      </linearGradient>
      <path
        d="M75.832 139.777s.531 2.24 3.67 2.439c5.621.357 9.356 1.996 11.184 4.922 0 0-.637-2.48-5.375-5.18-4.418-2.519-7.872-4.358-9.479-2.181z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__A)"
      />
      <path
        d="M75.439 21.734c-1.861-.611 2.67-15.705 5.653-16.385 2.98-.682 1.691 5.756.652 9.709-1.037 3.95-5.135 7.061-6.305 6.676zm-9.53 37.08c-3.055-.891-4.741 5.703-3.859 6.328.882.623 3.053-.631 3.837-2.332.859-1.867 1.429-3.583.022-3.996z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fffef9"
      />
      <linearGradient
        id="Mushrooms_svg__B"
        gradientUnits="userSpaceOnUse"
        x1={87.051}
        y1={202.94}
        x2={87.051}
        y2={172.162}
      >
        <stop offset={0.006} stopColor="#c18bbe" />
        <stop offset={1} stopColor="#ea9dbd" />
      </linearGradient>
      <path
        d="M112.012 195.018c-2.722 4.73-9.503 7.732-9.503 7.732l-33.359.189s-5.401-.799-6.562-9.482c-.37-2.76-.581-5.383-.469-8.143.009 0 .009 0 0-.008.369-.881 3.32-7.824 8.642-13.145-.341.52-6.342 9.512-5.333 17.244 1.031 7.953 18.646 8.301 31.49 8.381 8.572.054 13.053-1.638 15.094-2.768z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__B)"
      />
      <linearGradient
        id="Mushrooms_svg__C"
        gradientUnits="userSpaceOnUse"
        x1={70.48}
        y1={177.891}
        x2={70.48}
        y2={144.705}
      >
        <stop offset={0.006} stopColor="#c18bbe" />
        <stop offset={1} stopColor="#ea9dbd" />
      </linearGradient>
      <path
        d="M77.826 144.705s-9.509 20.135-9.067 22.836c.444 2.703 9.067-2.791 9.067-2.791s-10.909 8.277-14.693 13.141c0 0 2.277-8.969 6.587-17.527 4.308-8.557 8.106-15.659 8.106-15.659z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__C)"
      />
      <radialGradient
        id="Mushrooms_svg__D"
        cx={-435.054}
        cy={-213.959}
        r={17.005}
        gradientTransform="translate(720.56 491.876) scale(1.5364)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.006} stopColor="#f6bad5" />
        <stop offset={0.221} stopColor="#f3b8d4" />
        <stop offset={0.328} stopColor="#ebb5d3" />
        <stop offset={0.412} stopColor="#dfb0d2" />
        <stop offset={0.483} stopColor="#ceaad0" />
        <stop offset={0.546} stopColor="#b9a2cd" />
        <stop offset={0.584} stopColor="#a99ccc" />
        <stop offset={0.675} stopColor="#a39bcc" />
        <stop offset={0.807} stopColor="#9198cc" />
        <stop offset={0.963} stopColor="#7394cc" />
        <stop offset={0.986} stopColor="#6e93cc" />
      </radialGradient>
      <path
        d="M77.98 172.994c-.617-.18-9.212-2.645-19.282 1.264-10.428 4.055-13.783 6.908-19.298 9.291.292-.502 4.93-8.512 7.492-19.084 3.082-12.764 8.318-11.467 11.806-10.801.243.049.471.113.714.227 2.515 1.039 5.433 4.994 9.875 10.102 2.384 2.741 5.222 5.839 8.693 9.001z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__D)"
      />
      <linearGradient
        id="Mushrooms_svg__E"
        gradientUnits="userSpaceOnUse"
        x1={58.688}
        y1={189.141}
        x2={58.688}
        y2={171.992}
      >
        <stop offset={0} stopColor="#81449a" />
        <stop offset={1} stopColor="#8f78b7" />
      </linearGradient>
      <path
        d="M39.376 183.564c-.425 5.432 7.251 6.367 17.647 5.037 10.395-1.33 20.992-15.592 20.992-15.592s-4.956-2.189-16.88-.146c-11.926 2.043-21.573 8.344-21.759 10.701z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__E)"
      />
      <linearGradient
        id="Mushrooms_svg__F"
        gradientUnits="userSpaceOnUse"
        x1={64.853}
        y1={202.945}
        x2={64.853}
        y2={180.154}
      >
        <stop offset={0.006} stopColor="#de5f3a" />
        <stop offset={0.231} stopColor="#db5d3c" />
        <stop offset={0.405} stopColor="#d35740" />
        <stop offset={0.563} stopColor="#c64e47" />
        <stop offset={0.711} stopColor="#b4424e" />
        <stop offset={0.852} stopColor="#9f3354" />
        <stop offset={0.986} stopColor="#881f59" />
        <stop offset={1} stopColor="#851c5a" />
      </linearGradient>
      <path
        d="M76.294 202.945H55.455s-.259-1.979-.681-5.465c-.34-2.756-.812-6.486-1.363-10.93 0 0 1.833-5.578 6.341-6.309 4.509-.729 5.238 3.324 5.238 3.324s.891 2.156 2.368 5.174c2.077 4.2 5.32 10.12 8.936 14.206z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__F)"
      />
      <path
        d="M69.288 163.992c-.325 1.188-.729 1.98-1.896 2.564-2.92 1.459-6.227-1.166-8.06-3.342-2.497-2.936-1.477-6.227.081-9.324 2.514 1.04 5.432 4.995 9.875 10.102zm-21 4.772c-.261.553-.441 1.047-.742 1.586-.384.686-.617 1.443-.884 2.184-.116.32-.363.805-.198 1.152.162.334.546.287.856.336.899.148 1.619.086 2.504-.113.735-.164 1.01-.219 1.305-.934.466-1.125.765-3.055-.243-3.984-.434-.398-.925-.773-1.531-.85-.425-.055-1.388.27-1.375.775l.308-.152zm5.223 3.224c.191-.672.566-1.416 1.229-.77.501.484.636.926.615 1.617-.007.271-.007.412-.304.502-.206.064-.56.074-.771.041a1.096 1.096 0 01-.923-1.084l.154-.306z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#babfe1"
      />
      <linearGradient
        id="Mushrooms_svg__G"
        gradientUnits="userSpaceOnUse"
        x1={53.412}
        y1={188.817}
        x2={67.358}
        y2={188.817}
      >
        <stop offset={0} stopColor="#ca538e" />
        <stop offset={1} stopColor="#8e7190" />
      </linearGradient>
      <path
        d="M67.358 188.74l-12.583 8.74c-.34-2.756-.812-6.486-1.363-10.93 0 0 1.833-5.578 6.341-6.309 4.509-.729 5.238 3.324 5.238 3.324s.89 2.158 2.367 5.175z"
        opacity={0.71}
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__G)"
      />
      <linearGradient
        id="Mushrooms_svg__H"
        gradientUnits="userSpaceOnUse"
        x1={57.753}
        y1={136.923}
        x2={57.753}
        y2={96.445}
      >
        <stop offset={0} stopColor="#81449a" />
        <stop offset={1} stopColor="#8f78b7" />
      </linearGradient>
      <path
        d="M34.316 101.34s11.457 31.984 20.951 35.393c9.495 3.408 25.922-40.043 25.922-40.043l-3.225-.244s-9.667 37.523-20.528 38.947c-10.216 1.34-21.033-34.912-21.033-34.912l-2.087.859z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__H)"
      />
      <linearGradient
        id="Mushrooms_svg__I"
        gradientUnits="userSpaceOnUse"
        x1={109.794}
        y1={118.792}
        x2={109.794}
        y2={95.99}
      >
        <stop offset={0} stopColor="#81449a" />
        <stop offset={1} stopColor="#8f78b7" />
      </linearGradient>
      <path
        d="M71.745 95.99s17.404 22.154 41.686 22.787c24.282.633 34.412-19.465 34.412-19.465l-2.883 1.168s-9.145 16.398-32.408 16.715c-23.262.314-38.745-21.047-38.745-21.047l-2.062-.158z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#Mushrooms_svg__I)"
      />
      <path
        d="M51.562 156.568c-1.911.559-3.811 6.201-3.216 7.389.592 1.188 2.753-1.455 3.216-3.693.462-2.243 0-3.696 0-3.696z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#fffef9"
      />
    </svg>
  );
}

export default SvgMushrooms;
