import React, {Component} from "react";
import SvgManOnMoon from "../../assets/svg/SvgJs/ManOnMoon";
import SvgS3N from "../../assets/svg/SvgJs/NewTubes/S3N";
import SvgS3NN from "../../assets/svg/SvgJs/NewTubes/S3NN";
import SvgTelescope from "../../assets/svg/SvgJs/Telescope";
import * as res from '../../helpers/responsive'
import SvgLess1024S3 from "../../assets/svg/SvgJs/Responsive/less_1024/Less1024S3";
import SvgLess768S3 from "../../assets/svg/SvgJs/Responsive/less_768/Less768S3";
import SvgMiddtubeRespon from "../../assets/svg/SvgJs/Responsive/less_480/Middtube";

function VisionMissionSection({sVD, sMD}) {
    return (
        <section className="page vi_mi sectionVisionMission" data-menu="change" data-menu-color="#252525">
            <div className="content">
                <div className="vision">
                    <h1 className="title">Vision</h1>
                    <p className="paragraph">{sVD}</p>
                </div>
                <div className="mission">
                    <h1 className="title">Mission</h1>
                    <p className="paragraph">{sMD}</p>
                </div>
                <div className="telescope">
                    <SvgTelescope/>
                </div>
                <div className="manonthemoon">
                    <SvgManOnMoon/>
                </div>
            </div>
            <div className="tube">
                <res.Desktop>
                    <SvgS3NN/>
                </res.Desktop>
                <res.Tablet>
                    <SvgLess1024S3/>
                </res.Tablet>
                <res.Mobile>
                    <SvgLess768S3/>
                </res.Mobile>
                <res.LessMobile>
                    <SvgMiddtubeRespon heightOfTube={"100vh"} tubeClass={"s3"} colorFill={"#3f3f3f"}/>
                </res.LessMobile>
            </div>
        </section>
    );
}

export default VisionMissionSection;

