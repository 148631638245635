import * as React from "react";

function SvgS3NN(props) {
    return (
        <svg width={1275.6} height={1349.6} viewBox="0 0 1275.6 1349.6" {...props}>
            <path
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M7.5 7.5h171.6c16.5 0 30 13.5 30 30l-.6 358.6v34.5c0 16.5 13.5 30 30 30l863.6-.2c16.5 0 30 13.5 30 30v630.5c0 16.5-13.5 30-30 30h-34.5l-58.6-.6c-16.5 0-30 13.5-30 30v171.6"
                opacity={0.3}
            />
            <path
                fill="none"
                stroke="#3f3f3f"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="tubefill s3"
                strokeMiterlimit={10}
                d="M7.5 7.5h171.6c16.5 0 30 13.5 30 30l-.6 358.6v34.5c0 16.5 13.5 30 30 30l863.6-.2c16.5 0 30 13.5 30 30v630.5c0 16.5-13.5 30-30 30h-34.5l-58.6-.6c-16.5 0-30 13.5-30 30v171.6"
            />
        </svg>
    );
}

export default SvgS3NN;
