import * as React from "react";

function SvgMoon(props) {
  return (
    <svg width="98.926" hieght="98.927" viewBox="0 0 98.926 98.927" {...props}>
      <g>
        <radialGradient
          id="Moon_svg__a"
          cx={339.398}
          cy={871.153}
          r={62.747}
          gradientTransform="translate(-218.084 -637.268) scale(.7883)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#7953a2" />
          <stop offset={1} stopColor="#010101" />
        </radialGradient>
        <circle
          cx={49.463}
          cy={49.463}
          r={49.463}
          fillRule="evenodd"
          clipRule="evenodd"
          style={{ mixBlendMode: "screen" }}
          fill="url(#Moon_svg__a)"
        />
        <radialGradient
          id="Moon_svg__b"
          cx={343.553}
          cy={874.726}
          r={52.882}
          gradientTransform="translate(-218.084 -637.268) scale(.7883)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#7953a2" />
          <stop offset={1} stopColor="#010101" />
        </radialGradient>
        <circle
          cx={52.739}
          cy={52.28}
          r={41.687}
          fillRule="evenodd"
          style={{ mixBlendMode: "screen" }}
          clipRule="evenodd"
          fill="url(#Moon_svg__b)"
        />
        <radialGradient
          id="Moon_svg__c"
          cx={338.837}
          cy={870.237}
          r={50.464}
          gradientTransform="translate(-218.084 -637.268) scale(.7883)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#7953a2" />
          <stop offset={1} stopColor="#010101" />
        </radialGradient>
        <circle
          cx={49.021}
          cy={48.741}
          r={39.781}
          style={{ mixBlendMode: "screen" }}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__c)"
        />
        <linearGradient
          id="Moon_svg__d"
          gradientUnits="userSpaceOnUse"
          x1={74.818}
          y1={36.732}
          x2={78.01}
          y2={16.58}
        >
          <stop offset={0} stopColor="#81449a" />
          <stop offset={1} stopColor="#8888c2" />
        </linearGradient>
        <path
          d="M68.325 15.179s4.917 1.362 8.19 1.163c0 0 5.862 3.983 9.136 14.538 0 0-2.816 3.338-2.217 7.217 0 0-5.014-13.625-15.109-22.918z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__d)"
        />
        <linearGradient
          id="Moon_svg__e"
          gradientUnits="userSpaceOnUse"
          x1={-320.986}
          y1={49.551}
          x2={-319.88}
          y2={42.563}
          gradientTransform="scale(-1) rotate(9.928 588.527 1411.22)"
        >
          <stop offset={0} stopColor="#8888c2" />
          <stop offset={1} stopColor="#81449a" />
        </linearGradient>
        <path
          d="M73.717 93.947s-2.006-.607-3.493-.425c0 0-2.001-1.501-2.033-4.103 0 0 1.536-1.896 2.093-3.356 0 0 .615 4.202 3.433 7.884z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__e)"
        />
        <radialGradient
          id="Moon_svg__f"
          cx={320.041}
          cy={849.974}
          r={71.44}
          gradientTransform="translate(-218.084 -637.268) scale(.7883)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.006} stopColor="#f6bad5" />
          <stop offset={0.221} stopColor="#f3b8d4" />
          <stop offset={0.328} stopColor="#ebb5d3" />
          <stop offset={0.412} stopColor="#dfb0d2" />
          <stop offset={0.483} stopColor="#ceaad0" />
          <stop offset={0.546} stopColor="#b9a2cd" />
          <stop offset={0.584} stopColor="#a99ccc" />
          <stop offset={0.637} stopColor="#a397c9" />
          <stop offset={0.714} stopColor="#9489c1" />
          <stop offset={0.805} stopColor="#7d74b6" />
          <stop offset={0.907} stopColor="#5e5ba8" />
          <stop offset={0.916} stopColor="#5a59a7" />
          <stop offset={0.986} stopColor="#6e93cc" />
        </radialGradient>
        <path
          d="M84.225 45.755c0 3.588-.504 7.064-1.459 10.344-1.892 6.599-5.557 12.438-10.452 16.996a37.297 37.297 0 01-12.179 7.584 37.217 37.217 0 01-15.246 2.363h-.009a39.336 39.336 0 01-2.9-.259 37.107 37.107 0 01-19.077-8.403 36.609 36.609 0 01-5.407-5.59 36.733 36.733 0 01-3.272-4.895 67.436 67.436 0 01-.669-1.246c0-.017-.008-.023-.017-.04a29.417 29.417 0 01-.646-1.355h-.008a35.813 35.813 0 01-1.671-4.414 33.868 33.868 0 01-.875-3.343c-.008-.007-.008-.023-.008-.032-.165-.796-.308-1.6-.417-2.411a36.793 36.793 0 01-.245-8.372 31.67 31.67 0 01.206-1.924 36.579 36.579 0 012.988-10.429 37.334 37.334 0 017.985-11.351c6.725-6.543 15.909-10.571 26.03-10.571a32.888 32.888 0 012.428.079c2.145.143 4.241.458 6.267.946 1.183.283 2.342.615 3.478 1.009a36.025 36.025 0 013.924 1.608c.095.047.19.087.284.134a37.404 37.404 0 019.768 6.905c.41.394.803.805 1.198 1.223.26.283.52.575.772.866a36.769 36.769 0 015.155 7.6 36.54 36.54 0 012.98 7.978 37.282 37.282 0 011.094 9z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__f)"
        />
        <linearGradient
          id="Moon_svg__g"
          gradientUnits="userSpaceOnUse"
          x1={10.958}
          y1={29.057}
          x2={26.587}
          y2={29.057}
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.051} stopColor="#edeaf5" />
          <stop offset={0.233} stopColor="#c3b5d9" />
          <stop offset={0.411} stopColor="#a58ec3" />
          <stop offset={0.581} stopColor="#9175b4" />
          <stop offset={0.74} stopColor="#8464ab" />
          <stop offset={0.884} stopColor="#7c59a5" />
          <stop offset={1} stopColor="#7a56a3" />
        </linearGradient>
        <path
          d="M10.958 40.241s6.661 3.475 12.628-1.251c5.966-4.725 1.194-22.349 1.194-22.349l-13.822 23.6z"
          opacity={0.5}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__g)"
        />
        <linearGradient
          id="Moon_svg__h"
          gradientUnits="userSpaceOnUse"
          x1={54.257}
          y1={54.92}
          x2={81.396}
          y2={54.92}
        >
          <stop offset={0} stopColor="#7a56a3" />
          <stop offset={0.116} stopColor="#7c59a5" />
          <stop offset={0.261} stopColor="#8464ab" />
          <stop offset={0.419} stopColor="#9175b4" />
          <stop offset={0.589} stopColor="#a58ec3" />
          <stop offset={0.767} stopColor="#c3b5d9" />
          <stop offset={0.949} stopColor="#edeaf5" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <path
          d="M54.257 67.087s3.014 3.623 10.389 4.021c7.376.398 14.743-8.065 16.437-15.732 1.693-7.668-3.784-15.418-9.161-16.621-5.378-1.204-17.665 28.332-17.665 28.332z"
          opacity={0.89}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__h)"
        />
        <radialGradient
          id="Moon_svg__i"
          cx={301.498}
          cy={834.2}
          r={62.358}
          gradientTransform="translate(-218.084 -637.268) scale(.7883)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fcfadb" />
          <stop offset={1} stopColor="#010101" />
        </radialGradient>
        <path
          d="M24.732 74.923a37.453 37.453 0 01-9.361-11.771 36.947 36.947 0 01-3.207-9.114c-.006-.006-.006-.02-.006-.032a37.78 37.78 0 01-.796-7.709 37.194 37.194 0 015.824-20.037 37.458 37.458 0 015.487-6.737c6.73-6.543 15.91-10.572 26.035-10.572a37.401 37.401 0 019.784 1.301 37.432 37.432 0 00-11.613-1.844c-10.125.001-19.304 4.03-26.035 10.573a37.381 37.381 0 00-5.487 6.737 37.188 37.188 0 00-5.824 20.037c0 2.643.278 5.222.796 7.709a36.926 36.926 0 003.213 9.146 37.468 37.468 0 009.361 11.771 37.203 37.203 0 0014.221 7.428 37.276 37.276 0 01-12.392-6.886z"
          style={{ mixBlendMode: "screen" }}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__i)"
        />
        <linearGradient
          id="Moon_svg__j"
          gradientUnits="userSpaceOnUse"
          x1={32.546}
          y1={86.581}
          x2={35.328}
          y2={69.021}
        >
          <stop offset={0} stopColor="#6d2c91" />
          <stop offset={1} stopColor="#8888c2" />
        </linearGradient>
        <path
          d="M16.374 67.3s2.366 3.856 1.769 10.129c0 0 5.187 9.065 19.825 9.812 0 0 7.367-5.433 14.42-4.548-.001-.001-12.178-17.359-36.014-15.393z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__j)"
        />
        <linearGradient
          id="Moon_svg__k"
          gradientUnits="userSpaceOnUse"
          x1={21.462}
          y1={31.565}
          x2={9.141}
          y2={20.855}
        >
          <stop offset={0} stopColor="#b191c3" />
          <stop offset={1} stopColor="#81449a" />
        </linearGradient>
        <path
          d="M10.958 40.241s-2.454-6.772-4.656-9.162c0 0-1.693-10.754 9.26-14.438 0 0 5.899 1.125 9.218 0 0 0 1.263 7.269-3.741 15.035-4.267 6.624-10.081 8.565-10.081 8.565z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__k)"
        />
        <linearGradient
          id="Moon_svg__l"
          gradientUnits="userSpaceOnUse"
          x1={83.057}
          y1={32.932}
          x2={60.073}
          y2={19.662}
          gradientTransform="rotate(7.412 -141.794 -12.967)"
        >
          <stop offset={0} stopColor="#583a97" />
          <stop offset={1} stopColor="#e2c1dc" />
        </linearGradient>
        <path
          d="M78.059 45.513s-2.609-9.18-11.475-9.731c-9.402-.584-17.729 18.178-14.828 27.142 2.902 8.963 13.366 7.672 18.158 4.414 5.821-3.957 8.676-12.7 8.145-21.825z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__l)"
        />
        <linearGradient
          id="Moon_svg__m"
          gradientUnits="userSpaceOnUse"
          x1={38.179}
          y1={18.819}
          x2={55.828}
          y2={18.819}
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={0.051} stopColor="#edeaf5" />
          <stop offset={0.233} stopColor="#c3b5d9" />
          <stop offset={0.411} stopColor="#a58ec3" />
          <stop offset={0.581} stopColor="#9175b4" />
          <stop offset={0.74} stopColor="#8464ab" />
          <stop offset={0.884} stopColor="#7c59a5" />
          <stop offset={1} stopColor="#7a56a3" />
        </linearGradient>
        <path
          d="M38.179 18.555s3.182 5.384 9.347 4.794c6.731-.643 8.531-6.342 8.279-8.6-.25-2.257-17.626 3.806-17.626 3.806z"
          opacity={0.5}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__m)"
        />
        <linearGradient
          id="Moon_svg__n"
          gradientUnits="userSpaceOnUse"
          x1={73.66}
          y1={42.511}
          x2={73.66}
          y2={11.367}
          gradientTransform="rotate(7.412 -141.794 -12.967)"
        >
          <stop offset={0} stopColor="#5f5eaa" />
          <stop offset={1} stopColor="#846fb2" />
        </linearGradient>
        <path
          d="M68.033 38.99c-8.129-.043-20.17 21.585-9.687 28.856 9.961 6.908 19.164-8.087 20.244-13.209s.388-15.586-10.557-15.647z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__n)"
        />
        <linearGradient
          id="Moon_svg__o"
          gradientUnits="userSpaceOnUse"
          x1={57.521}
          y1={65.619}
          x2={73.958}
          y2={45.467}
        >
          <stop offset={0} stopColor="#8062aa" />
          <stop offset={0.241} stopColor="#7d60a9" />
          <stop offset={0.466} stopColor="#755ca7" />
          <stop offset={0.685} stopColor="#6755a4" />
          <stop offset={0.899} stopColor="#534da0" />
          <stop offset={0.994} stopColor="#47489e" />
        </linearGradient>
        <path
          d="M64.25 67.628a15.292 15.292 0 01-1.121-.142c-5.044-.867-6.936-4.376-7.361-7.914-.269-2.247.055-4.502.535-6.11.955-3.185 6.07-10.649 11.928-11.698 2.081-.378 4.257.063 6.377 1.806 1.789 1.481 2.681 3.429 2.885 5.588.789 8.057-7.748 18.958-13.243 18.47z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__o)"
        />
        <radialGradient
          id="Moon_svg__p"
          cx={-1157.419}
          cy={110.587}
          r={17.767}
          gradientTransform="matrix(-.2604 -.7441 .7441 -.2604 -344.666 -816.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#c9a7cf" />
          <stop offset={1} stopColor="#81449a" />
        </radialGradient>
        <path
          d="M39.612 8.663s-3.733 4.793-1.892 9.104c1.954 4.57 12.903 4.377 16.44.885 3.538-3.492.715-10.154-2.329-11.322-3.466-1.332-8.043-1.081-12.219 1.333z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__p)"
        />
        <linearGradient
          id="Moon_svg__q"
          gradientUnits="userSpaceOnUse"
          x1={-294.002}
          y1={124.715}
          x2={-294.002}
          y2={108.281}
          gradientTransform="rotate(-109.287 -160.863 -56.592)"
        >
          <stop offset={0} stopColor="#aa89bf" />
          <stop offset={1} stopColor="#c4b2d7" />
        </linearGradient>
        <path
          d="M38.915 14.936c1.906 3.843 14.957 4.393 15.9-2.274.895-6.333-8.356-7.118-11.027-6.412-2.671.704-7.44 3.512-4.873 8.686z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__q)"
        />
        <linearGradient
          id="Moon_svg__r"
          gradientUnits="userSpaceOnUse"
          x1={46.536}
          y1={16.708}
          x2={46.536}
          y2={7.235}
        >
          <stop offset={0} stopColor="#846fb2" />
          <stop offset={0.286} stopColor="#826db1" />
          <stop offset={0.552} stopColor="#7a68ae" />
          <stop offset={0.809} stopColor="#6c5faa" />
          <stop offset={1} stopColor="#5e56a5" />
        </linearGradient>
        <path
          d="M52.958 11.02c.023.213.023.418 0 .616a3.227 3.227 0 01-.087.621c-1.064 4.281-9.664 5.677-11.627 3.297-.545-.662-.875-1.317-1.033-1.939-.67-2.626 1.672-4.729 3.224-5.542 1.916-1.01 9.098-2.044 9.523 2.947z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__r)"
        />
        <linearGradient
          id="Moon_svg__s"
          gradientUnits="userSpaceOnUse"
          x1={11.164}
          y1={31.413}
          x2={11.164}
          y2={16.642}
        >
          <stop offset={0} stopColor="#dea4ca" />
          <stop offset={1} stopColor="#c47cb4" />
        </linearGradient>
        <path
          d="M6.302 31.079c.18 2.443 12.462-9.137 9.26-14.438 0 .001-10.256.946-9.26 14.438z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__s)"
        />
        <linearGradient
          id="Moon_svg__t"
          gradientUnits="userSpaceOnUse"
          x1={28.087}
          y1={87.865}
          x2={28.087}
          y2={75.513}
        >
          <stop offset={0} stopColor="#a885bd" />
          <stop offset={1} stopColor="#b178b4" />
        </linearGradient>
        <path
          d="M18.143 77.429c-.763-4.363 21.418-1.349 19.825 9.812-.35 2.44-18.559-2.567-19.825-9.812z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__t)"
        />
        <linearGradient
          id="Moon_svg__u"
          gradientUnits="userSpaceOnUse"
          x1={27.698}
          y1={86.949}
          x2={27.698}
          y2={77.501}
        >
          <stop offset={0} stopColor="#515ca9" />
          <stop offset={1} stopColor="#6155a4" />
        </linearGradient>
        <path
          d="M35.973 86.919c-.37.299-12.542-1.498-16.728-8.893-.276-.495 1.025-.654 3.02-.418.756.087 1.608.236 2.515.449 2.885.67 6.283 1.979 8.585 4.06.717.646 1.269 1.229 1.679 1.751.591.757.906 1.372 1.048 1.844.197.671.055 1.072-.119 1.207z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__u)"
        />
        <linearGradient
          id="Moon_svg__v"
          gradientUnits="userSpaceOnUse"
          x1={29.912}
          y1={83.868}
          x2={29.912}
          y2={78.058}
        >
          <stop offset={0} stopColor="#5f5eaa" />
          <stop offset={1} stopColor="#846fb2" />
        </linearGradient>
        <path
          d="M35.043 83.868c-4.825-.852-8.293-3.706-10.264-5.811 2.885.67 6.283 1.979 8.585 4.06.718.647 1.269 1.23 1.679 1.751z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__v)"
        />
        <linearGradient
          id="Moon_svg__w"
          gradientUnits="userSpaceOnUse"
          x1={10.904}
          y1={28.639}
          x2={10.904}
          y2={18.06}
        >
          <stop offset={0} stopColor="#846fb2" />
          <stop offset={1} stopColor="#5f5eaa" />
        </linearGradient>
        <path
          d="M14.229 19.995c-.204.655-.55 1.53-1.071 2.694-.931 2.073-2.772 4.232-4.113 5.283-.684.542-1.241.797-1.467.6-.466-.402-.388-3.379 1.037-6.144 1.425-2.772 5.283-4.74 5.678-4.311.246.27.289.764-.064 1.878z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__w)"
        />
        <linearGradient
          id="Moon_svg__x"
          gradientUnits="userSpaceOnUse"
          x1={11.202}
          y1={23.709}
          x2={12.235}
          y2={24.362}
        >
          <stop offset={0} stopColor="#8581bd" />
          <stop offset={1} stopColor="#aa89bf" />
        </linearGradient>
        <path
          d="M14.229 19.995c-.204.655-.55 1.53-1.071 2.694-.931 2.073-2.772 4.232-4.113 5.283 1.565-4.26 4.338-7.159 5.184-7.977z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__x)"
        />
        <linearGradient
          id="Moon_svg__y"
          gradientUnits="userSpaceOnUse"
          x1={15.297}
          y1={56.109}
          x2={21.736}
          y2={60.18}
        >
          <stop offset={0} stopColor="#8581bd" />
          <stop offset={0.489} stopColor="#b58fc2" />
          <stop offset={1} stopColor="#f0cbe0" />
        </linearGradient>
        <path
          d="M18.142 62.047c-.387-.236-.765-.662-1.104-1.206 0 0 0-.009-.016-.023-1.238-1.994-1.955-5.463-.647-6.172 1.617-.884 4.005-.426 5.085 2.443a1.816 1.816 0 01.11.315c.008.017.017.032.017.047a.77.77 0 00.063.198c.032.103.063.205.087.315.67 2.734-1.79 5.18-3.595 4.083z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__y)"
        />
        <radialGradient
          id="Moon_svg__z"
          cx={300.209}
          cy={881.385}
          r={3.928}
          gradientTransform="translate(-218.084 -637.268) scale(.7883)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#1573b4" />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
        <path
          d="M21.461 57.089c-.607-1.222-2.972-2.641-4.422-1.316-1.521 1.388-.142 4.729-.016 5.045-1.238-1.994-1.955-5.463-.647-6.172 1.617-.884 4.005-.426 5.085 2.443z"
          opacity={0.4}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__z)"
        />
        <linearGradient
          id="Moon_svg__A"
          gradientUnits="userSpaceOnUse"
          x1={33.081}
          y1={4.516}
          x2={36.049}
          y2={6.392}
          gradientTransform="rotate(4.824 -187.054 -42.135)"
        >
          <stop offset={0} stopColor="#8581bd" />
          <stop offset={0.489} stopColor="#b58fc2" />
          <stop offset={1} stopColor="#f0cbe0" />
        </linearGradient>
        <path
          d="M29.456 25.701c-.168-.123-.326-.333-.46-.597 0 0 0-.003-.006-.012-.492-.964-.688-2.585-.058-2.859.777-.344 1.856-.041 2.241 1.318a.994.994 0 01.038.151c.004.007.007.015.006.021.004.037.012.07.021.093.01.049.021.097.027.149.202 1.282-1.023 2.311-1.809 1.736z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__A)"
        />
        <radialGradient
          id="Moon_svg__B"
          cx={387.268}
          cy={793.459}
          r={1.81}
          gradientTransform="rotate(4.831 7310.302 -2945.49) scale(.7883)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#1573b4" />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
        <path
          d="M31.173 23.552c-.231-.584-1.263-1.328-1.981-.775-.753.578-.248 2.167-.202 2.316-.492-.964-.688-2.585-.058-2.859.776-.344 1.856-.042 2.241 1.318z"
          opacity={0.4}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__B)"
        />
        <linearGradient
          id="Moon_svg__C"
          gradientUnits="userSpaceOnUse"
          x1={59.694}
          y1={75.682}
          x2={53.099}
          y2={75.543}
        >
          <stop offset={0} stopColor="#7c69af" />
          <stop offset={1} stopColor="#574da0" />
        </linearGradient>
        <path
          d="M57.424 77.837h-.012a.842.842 0 01-.248.088c-.012.012-.025.024-.037.024-.062.013-.123.024-.161.049-.073.024-.16.05-.259.074-1.25.334-2.425-.123-3.08-.865-.533-.594-.718-1.349-.334-2.018.186-.32.531-.643.977-.927l.013-.013c1.62-1.051 4.466-1.707 5.071-.644.174.284.285.606.322.94.185 1.19-.421 2.563-2.252 3.292z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__C)"
        />
        <radialGradient
          id="Moon_svg__D"
          cx={323.522}
          cy={883.469}
          r={8.844}
          gradientTransform="translate(-218.084 -637.268) scale(.7883)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fffce9" />
          <stop offset={0.137} stopColor="#bbb7ac" />
          <stop offset={0.369} stopColor="#6e6b66" />
          <stop offset={0.58} stopColor="#403e3b" />
          <stop offset={0.762} stopColor="#222220" />
          <stop offset={0.908} stopColor="#0c0c0c" />
          <stop offset={1} stopColor="#010101" />
        </radialGradient>
        <path
          d="M26.638 57.271s2.246 3.582 8.405 3.209c6.159-.373 7.279-3.387 7.652-5.839.374-2.452-.654-5.468-.654-5.468s-13.613 2.572-15.403 8.098z"
          opacity={0.5}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__D)"
        />
        <radialGradient
          id="Moon_svg__E"
          cx={348.176}
          cy={903.721}
          r={3.49}
          gradientTransform="translate(-218.084 -637.268) scale(.7883)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#1573b4" />
          <stop offset={1} stopColor="#fff" />
        </radialGradient>
        <path
          d="M59.676 74.547c-.508-.433-1.56-.964-3.278-.42-2.636.828-2.771 3.08-2.771 3.08-.533-.594-.718-1.349-.334-2.018.186-.32.531-.643.977-.927l.013-.013c1.62-1.051 4.466-1.707 5.071-.644.174.286.285.608.322.942z"
          opacity={0.4}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__E)"
        />
        <linearGradient
          id="Moon_svg__F"
          gradientUnits="userSpaceOnUse"
          x1={24.224}
          y1={43.988}
          x2={42.14}
          y2={55.314}
        >
          <stop offset={0} stopColor="#8581bd" />
          <stop offset={0.127} stopColor="#8b82be" />
          <stop offset={0.31} stopColor="#9b87bf" />
          <stop offset={0.527} stopColor="#b58fc2" />
          <stop offset={0.528} stopColor="#b58fc2" />
          <stop offset={1} stopColor="#f0cbe0" />
        </linearGradient>
        <path
          d="M42.343 52.643l-.009.134c.017 9.538-14.275 8.373-16.514 3.296a13.76 13.76 0 01-.765-2.303v-.008c-1.261-5.108-.474-11.95 1.805-13.52 2.396-1.647 6.999-1.687 10.351 0 3.776 1.9 5.266 7.583 5.132 12.401z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__F)"
        />
        <linearGradient
          id="Moon_svg__G"
          gradientUnits="userSpaceOnUse"
          x1={24.456}
          y1={46.377}
          x2={42.351}
          y2={46.377}
        >
          <stop offset={0} stopColor="#1573b4" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <path
          d="M42.343 52.643l-.009.134s.214-5.967-2.672-9.152c-2.892-3.187-8.662-4.084-11.966-.497-3.216 3.5-2.672 10.311-2.64 10.634-1.261-5.108-.474-11.95 1.805-13.52 2.396-1.647 6.999-1.687 10.351 0 3.775 1.9 5.265 7.583 5.131 12.401z"
          opacity={0.4}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__G)"
        />
        <linearGradient
          id="Moon_svg__H"
          gradientUnits="userSpaceOnUse"
          x1={61.369}
          y1={15.797}
          x2={70.167}
          y2={21.359}
        >
          <stop offset={0} stopColor="#7165ad" />
          <stop offset={0.489} stopColor="#8f75b5" />
          <stop offset={1} stopColor="#cab0d5" />
        </linearGradient>
        <path
          d="M69.137 22.616c-.812.434-2.734-.766-4.493-2.263-2.136-1.837-4.013-4.132-3.279-4.462 1.001-.448 4.683-.149 6.377 1.246.726.598 1.419 1.521 1.837 2.459.528 1.222.575 2.468-.442 3.02z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__H)"
        />
        <linearGradient
          id="Moon_svg__I"
          gradientUnits="userSpaceOnUse"
          x1={61.203}
          y1={18.025}
          x2={69.579}
          y2={18.025}
        >
          <stop offset={0} stopColor="#1573b4" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <path
          d="M69.579 19.597c-.008-.008-.016-.023-.023-.031-2.662-4.02-6.769-3.846-7.068-2.75s2.157 3.538 2.157 3.538c-2.136-1.837-4.013-4.132-3.279-4.462 1.001-.448 4.683-.149 6.377 1.246.724.597 1.418 1.52 1.836 2.459z"
          opacity={0.4}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__I)"
        />
        <path
          d="M8.949 30.072s6.078-3.05 6.717-9.074c0 0-3.066 6.199-6.717 9.074zm45.308 29.863s.599-12.077 9.333-19.194c0-.001-10.476 5.011-9.333 19.194z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#dabddb"
        />
        <path
          d="M39.272 15.469s1.638 2.486 7.236 2.296c5.598-.19 7.436-2.746 7.749-3.432 0 0-1.39 4.287-7.719 4.137-6.678-.157-7.266-3.001-7.266-3.001zM27.698 76.322s8.49 3.425 10.208 8.356c.001.001-.189-5.659-10.208-8.356z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#e3aacd"
        />
        <linearGradient
          id="Moon_svg__J"
          gradientUnits="userSpaceOnUse"
          x1={55.671}
          y1={50.605}
          x2={77.493}
          y2={50.605}
        >
          <stop offset={0} stopColor="#7a56a3" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <path
          d="M77.493 49.157v.016s-2.883-4.979-10.885-2.796c-7.174 1.955-10.272 11.185-10.84 13.195-.269-2.247.055-4.502.535-6.11.955-3.185 6.07-10.649 11.928-11.698 2.081-.378 4.257.063 6.377 1.806 1.789 1.481 2.681 3.428 2.885 5.587z"
          opacity={0.71}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__J)"
        />
        <radialGradient
          id="Moon_svg__K"
          cx={521.332}
          cy={811.752}
          r={17.032}
          gradientTransform="matrix(.7758 .1401 -.1401 .7758 -214.7 -618.82)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.006} stopColor="#f6bad5" />
          <stop offset={0.221} stopColor="#f3b8d4" />
          <stop offset={0.328} stopColor="#ebb5d3" />
          <stop offset={0.412} stopColor="#dfb0d2" />
          <stop offset={0.483} stopColor="#ceaad0" />
          <stop offset={0.546} stopColor="#b9a2cd" />
          <stop offset={0.584} stopColor="#a99ccc" />
          <stop offset={0.637} stopColor="#a397c9" />
          <stop offset={0.714} stopColor="#9489c1" />
          <stop offset={0.805} stopColor="#7d74b6" />
          <stop offset={0.907} stopColor="#5e5ba8" />
          <stop offset={0.916} stopColor="#5a59a7" />
          <stop offset={0.986} stopColor="#6e93cc" />
        </radialGradient>
        <path
          d="M87.209 89.144a8.881 8.881 0 01-3.953 5.91 8.9 8.9 0 01-3.18 1.264 8.914 8.914 0 01-3.678-.092h-.002a8.83 8.83 0 01-4.789-2.964 9.087 9.087 0 01-1.697-3.148c.002-.004 0-.007-.002-.011a7.255 7.255 0 01-.094-.345h-.002a8.428 8.428 0 01-.205-1.107 7.7 7.7 0 01-.063-.821c-.002-.001-.001-.005-.001-.005a8.874 8.874 0 01.432-3.002 8.662 8.662 0 011.143-2.322c.238-.344.499-.67.781-.978.471-.51 1-.96 1.574-1.348a8.875 8.875 0 016.555-1.378c.093.017.185.036.274.054a8.929 8.929 0 013.348 1.483l.061.043a8.954 8.954 0 013.262 4.609c.195.644.318 1.313.359 1.999.047.707.008 1.43-.123 2.159z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__K)"
        />
        <linearGradient
          id="Moon_svg__L"
          gradientUnits="userSpaceOnUse"
          x1={-66.258}
          y1={219.514}
          x2={-75.21}
          y2={214.345}
          gradientTransform="rotate(-64.056 -100.332 28.593)"
        >
          <stop offset={0} stopColor="#583a97" />
          <stop offset={1} stopColor="#e2c1dc" />
        </linearGradient>
        <path
          d="M80.853 77.968s-3.534-.094-4.854 3.426c-1.401 3.732 4.083 9.456 7.578 9.405 3.494-.051 4.376-4.449 3.851-6.798-.639-2.852-3.325-5.105-6.575-6.033z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__L)"
        />
        <linearGradient
          id="Moon_svg__M"
          gradientUnits="userSpaceOnUse"
          x1={-69.882}
          y1={222.957}
          x2={-69.882}
          y2={211.273}
          gradientTransform="rotate(-64.056 -100.332 28.593)"
        >
          <stop offset={0} stopColor="#5f5eaa" />
          <stop offset={1} stopColor="#846fb2" />
        </linearGradient>
        <path
          d="M77.302 81.21c-1.05 3.284 4.96 10.869 8.831 7.538 3.677-3.166-.381-8.772-2.03-9.852s-5.387-2.11-6.801 2.314z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__M)"
        />
        <linearGradient
          id="Moon_svg__N"
          gradientUnits="userSpaceOnUse"
          x1={-99.223}
          y1={229.39}
          x2={-99.223}
          y2={219.714}
          gradientTransform="rotate(-71.464 -106.183 27.79)"
        >
          <stop offset={0.006} stopColor="#47489e" />
          <stop offset={1} stopColor="#5e4c9f" />
        </linearGradient>
        <path
          d="M86.808 86.331c-.06.152-.127.299-.191.436-.945 1.933-2.409 2.26-3.697 1.987a5.185 5.185 0 01-2.063-.983c-.989-.785-2.942-3.791-2.563-6.294.134-.89.564-1.716 1.442-2.354.744-.539 1.536-.655 2.315-.469 2.912.691 5.627 5.514 4.757 7.677z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__N)"
        />
        <linearGradient
          id="Moon_svg__O"
          gradientUnits="userSpaceOnUse"
          x1={-99.793}
          y1={229.344}
          x2={-99.793}
          y2={219.772}
          gradientTransform="rotate(-71.464 -106.183 27.79)"
        >
          <stop offset={0} stopColor="#5c5ca9" />
          <stop offset={1} stopColor="#745fa9" />
        </linearGradient>
        <path
          d="M86.617 86.767c-1.548 3.158-4.478 2.023-5.76 1.004-.989-.785-2.942-3.791-2.563-6.294a3.558 3.558 0 011.099-1.265c3.26-2.376 7.513 3.505 7.224 6.555z"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__O)"
        />
        <linearGradient
          id="Moon_svg__P"
          gradientUnits="userSpaceOnUse"
          x1={-103.895}
          y1={223.092}
          x2={-94.565}
          y2={223.092}
          gradientTransform="rotate(-71.464 -106.183 27.79)"
        >
          <stop offset={0} stopColor="#7a56a3" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <path
          d="M82.052 78.653l.006.003s-2.104.543-2.361 4.056c-.23 3.148 2.593 5.561 3.223 6.042a5.185 5.185 0 01-2.063-.983c-.989-.785-2.942-3.791-2.563-6.294.134-.89.564-1.716 1.442-2.354.745-.54 1.537-.656 2.316-.47z"
          opacity={0.71}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__P)"
        />
        <linearGradient
          id="Moon_svg__Q"
          gradientUnits="userSpaceOnUse"
          x1={40.094}
          y1={10.425}
          x2={52.976}
          y2={10.425}
        >
          <stop offset={0} stopColor="#7a56a3" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <path
          d="M52.958 11.02c.023.213.023.418 0 .616-1.498-2.199-4.257-2.688-7.733-1.941-3.484.75-5.014 3.92-5.014 3.92-.67-2.626 1.672-4.729 3.224-5.542 1.916-1.01 9.098-2.044 9.523 2.947z"
          opacity={0.71}
          fillRule="evenodd"
          clipRule="evenodd"
          fill="url(#Moon_svg__Q)"
        />
      </g>
    </svg>
  );
}

export default SvgMoon;
