import * as React from "react";

function SvgMediatube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={246.15}
      height={108.314}
      viewBox="0.001 28.002 246.15 108.314"
      {...props}
    >
      <path
        opacity={0.3}
        fill="none"
        stroke="#010101"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M7.501 35.502c0 16.5 13.02 30 28.933 30l66.668-.035c16.5 0 30 13.02 30 28.932l-.025 4.418c0 16.5 13.02 30 28.932 30l76.644-.002"
      />
       <path
        className="tubefill servicepath"
        fill="none"
        stroke="#fff"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M7.501 35.502c0 16.5 13.02 30 28.933 30l66.668-.035c16.5 0 30 13.02 30 28.932l-.025 4.418c0 16.5 13.02 30 28.932 30l76.644-.002"
      />
    </svg>
  );
}

export default SvgMediatube;
