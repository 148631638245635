import * as React from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import {qs} from "../../../../helpers/helpers";
import mobileCheck from "../../../../hooks/mobileCheck";

export let totalLength = 0;
export let start = false;

function SvgS4N(props) {
    const size = useWindowSize();
    const defaultAddressBarHeight = 50;
    const isMob = mobileCheck();
    const pathRef = React.useRef();
    const [tubeW, setTubeW] = React.useState(15);
    const [tubeWidth, setTubeWidth] = React.useState(
        `M 4227.272 771.099 L 4227.272 750.828 L 4227.272 730.996 C 4227.272 714.496 4240.772 700.996 4257.272 700.996 L 4346.14 700.998 L 4385.699 701.016 C 4402.199 701.016 4415.699 687.516 4415.699 671.016 L 4415.699 651.953 L 4415.711 427.105 C 4415.711 410.605 4402.209 397.108 4385.707 397.11 L 4214.83 397.256 L 4077.803 396.973 L 3765.584 397.256 L 3398.559 396.973 L 3070.639 397.256 L 2703.611 396.973 L 2486.516 397.256 L 2119.488 396.973 L 1888.582 397.256 L 1521.554 396.973 L 1292.022 397.256 L 924.995 396.973 L 737.967 397.256 L 370.941 396.973 L 80.14 397.109 L 37.501 397.111 C 21 397.111 7.501 384.611 7.501 368.111 L 7.5 232.953 L 7.562 153.084 L 0 153.084 L 571.205 153.087 C 587.707 153.087 601.205 139.587 601.205 123.087 L 601.207 7.928`
    );


    React.useEffect(() => {
        pathRef.current.style.strokeOfsset =
            Math.ceil(pathRef.current.getTotalLength()) + "px";
        totalLength = Math.ceil(pathRef.current.getTotalLength());
        // gsap.registerPlugin(ScrollTrigger);
        // let setup = document.querySelector(".sectionSetup");
        // let ani = document.querySelector(".setupOurStudio .ani");
        // let setupWidth = setup == null ? 5000 : setup.offsetWidth;
        // // console.log(setupTubeLength);
        // gsap
        //   .timeline({
        //     defaults: { duration: 100 },
        //     scrollTrigger: {
        //       trigger: ".setupOurStudio",
        //       scrub: true,
        //       triggerHook: 0,
        //       start: "top top",
        //       id: "setup",
        //       ease: "none",
        //       pin: true,
        //       anticipatePin: 0,
        //       end: setupWidth,
        //     },
        //   })
        //   // .fromTo(".tubefill.s4", { drawSVG: "0%" }, { drawSVG: "-100%" }, 0)
        //   .fromTo(
        //     ".tubefill.s4",
        //     {
        //       strokeDasharray: setupTubeLength + "px",
        //       strokeDashoffset: setupTubeLength * -1 + "px",
        //     },
        //     {
        //       strokeDashoffset:
        //         (setupTubeLength - setupTubeLength * 0.95) * -1 + "px",
        //     },
        //     0
        //   )

        //   .to(
        //     ".sectionSetup .shapes",
        //     {
        //       left: "-270px",
        //     },
        //     0
        //   )
        //   .to(
        //     ".setupOurStudio .ani",
        //     {
        //       x: (ani.offsetWidth - size.width) * -1 + "px",
        //     },
        //     4
        //   )
        //   .add(window.tl, 0);

        props.callb();
    }, [tubeWidth]);

    React.useEffect(() => {
        start = true;
        props.callb();
    }, [totalLength]);

    React.useEffect(() => {
            if (size.width < 100) {
                start = true;
                return;
            }
            let l71 = 0;
            let w = 4227.272 - (size.width - 1002) / 2;
            // let ww = (size.width - 1002) / 2
            let ww = [4240.772 - (size.width - 1002) / 2, 4257.272 - (size.width - 1002) / 2];
            if (size.width >= 768 && size.width < 1024) {
                l71 = 100;
                // w = 4227.272
                w = 4212.472 - (size.width - 768);
                // ww = 12.2 + (size.width - 768)
                ww = [4240.772 - (12.2 + (size.width - 768)), 4257.272 - (12.2 + (size.width - 768))]
            } else if (size.width < 768 && size.width >= 480) {
                l71 = 300;
                w = 4212.472 - ((size.width - 480) / 2) + 33.5;
                // ww = 12.2 + ((size.width - 480) / 2
                ww = [4240.772 + 33.5 - (12.2 + ((size.width - 480) / 2)), 4257.272 + 33.5 - (12.2 + ((size.width - 480) / 2))]
            } else if (size.width < 480) {
                l71 = 470;
                w = 4212.472 - ((size.width - 480) / 2) + 33.5;
                // ww = 12.2 + ((size.width - 480) / 2
                ww = [4240.772 + 33.5 - (12.2 + ((size.width - 480) / 2)), 4257.272 + 33.5 - (12.2 + ((size.width - 480) / 2))];

                let dWidth = (size.width - 320) / 2;
                qs(".sectionSetup .content").style.left = dWidth + "px";
                qs(".sectionSetup").style.width = 4515 + dWidth + "px"

            } else {
                l71 = 0;
                w = 4227.272 - (size.width - 1002) / 2 +1;
                // ww = (size.width - 1002) / 2
                ww = [4240.772 - (size.width - 1002) / 2, 4257.272 - (size.width - 1002) / 2]
            }

            if (size.width < 480) {
                setTubeW(14)
            }

            let h = size.height > 768 ? size.height - 768 : 0;
            h = isMob ? h + defaultAddressBarHeight : h;

            let lowH = 0;
            let topOfTube = 0;
            if (size.height < 768) {
                let box = qs(".box_under_setup");
                let title = qs(".sectionSetup .content > .title");
                let content = qs(".sectionSetup .content");
                let svg = qs(".sectionSetup .tube > svg");

                let size_diff = 768 - size.height;

                svg.setAttribute("viewBox", `0 ${svg.viewBox.baseVal.y.toFixed(3)} ${svg.viewBox.baseVal.width.toFixed(3)} ${svg.viewBox.baseVal.height + size_diff}`);
                svg.style.height = svg.clientHeight + size_diff;

                if (size_diff <= 80) {
                    topOfTube = size_diff;
                    content.style.transform = `translateY(-${topOfTube}px)`
                } else {
                    topOfTube = 80;
                    let new_size_diff = size_diff - 80;
                    new_size_diff = Math.min(new_size_diff, 118);
                    lowH = Math.min(new_size_diff, 118);
                    content.style.transform = `translateY(-${Math.min(size_diff, 200)}px)`;
                    title.style.transform = `translateY(${new_size_diff}px)`;
                    box.style.transform = `translateY(${new_size_diff}px)`
                }
            }

            setTubeWidth(
                `M ${w} ${
                    771.099 + h + 10
                } L ${w} 750.828 L ${w} 730.996 C ${w} 714.496 ${
                    ww[0]
                } 700.996 ${
                    ww[1]
                } 700.996 L ${size.width < 480 ? 4386.14 : 4346.14} 700.998 L 4385.699 701.016 C 4402.199 701.016 4415.699 687.516 4415.699 671.016 L 4415.699 651.953 L 4415.711 427.105 C 4415.711 410.605 4402.209 397.108 4385.707 397.11 L 4214.83 397.256 L 4077.803 396.973 L 3765.584 397.256 L 3398.559 396.973 L 3070.639 397.256 L 2703.611 396.973 L 2486.516 397.256 L 2119.488 396.973 L 1888.582 397.256 L 1521.554 396.973 L 1292.022 397.256 L 924.995 396.973 L 737.967 397.256 L 370.941 396.973 L 80.14 397.109 L 37.501 397.111 C 21 397.111 7.501 384.611 7.501 368.111 L 7.5 ${232.953 + lowH} L 7.562 ${153.084 + lowH} L 0 ${153.084 + lowH} L ${571.205 - l71} ${153.087 + lowH} C ${587.707 - l71} ${153.087 + lowH} ${601.205 - l71} ${139.587 + lowH} ${601.205 - l71} ${123.087 + lowH} L ${601.207 - l71} ${7.928 + lowH + topOfTube}`
            );
        }
        , [size.width, size.height]);

    return (
        <svg
            width={4423.211}
            height={isMob ? size.height + defaultAddressBarHeight + 10 : size.height + 10}
            viewBox={"0 0.428 4423.211 " + (isMob ? size.height + defaultAddressBarHeight + 10 : size.height + 10)}
        >
            <path
                fill="none"
                stroke="#3AAFA9"
                strokeWidth={16}
                strokeMiterlimit={10}
                d="M80.14 397.109h289.086H80.14zm659 0h185.086H739.14zm551 0h231.411-231.411zm599 0h231.411-231.411zm598.001 0h216.043-216.043zm584 0h326.373-326.373zm695 0h311.661-311.661z"
            />
            {/*<path fill="#3AAFA9" className={"box_under_setup"}*/}
            {/*      d="M -4 113.302 L 87.228 113.302 L 87.228 229.969 L -4 229.969 L -4 113.302 Z"/>*/}
            {/*<path fill="#3AAFA9" d="M 0 113.302 L 91.228 113.302 L 91.228 229.969 L 0 229.969 L 0 113.302 Z"/>*/}
            {/*<path fill="#3AAFA9" d="M 0 243.302 L 91.228 243.302 L 91.228 359.969 L 0 359.969 L 0 243.302 Z"/>*/}
            <path
                fill="none"
                stroke="#000"
                strokeWidth={tubeW}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d={tubeWidth}
                opacity={0.3}
            />
            {/*<path*/}
            {/*    fill="none"*/}
            {/*    stroke="#000"*/}
            {/*    strokeWidth={tubeW}*/}
            {/*    strokeLinecap="round"*/}
            {/*    strokeLinejoin="round"*/}
            {/*    strokeMiterlimit={10}*/}
            {/*    d="M 4228.945 721.801 L 4228.945 662.741 C 4228.945 646.241 4242.445 632.741 4258.945 632.741 L 4325.697 632.761 C 4342.197 632.761 4355.697 619.261 4355.697 602.761 L 4355.711 427.106 C 4355.711 410.606 4342.209 397.109 4325.707 397.111 L 4214.83 397.257 L 4077.802 396.974 L 3765.584 397.257 L 3398.558 396.974 L 3070.639 397.257 L 2703.61 396.974 L 2486.515 397.257 L 2119.487 396.974 L 1888.581 397.257 L 1521.553 396.974 L 1292.021 397.257 L 924.994 396.974 L 737.966 397.257 L 370.94 396.974 L 80.14 397.109 L 37.502 397.111 C 21.001 397.111 7.502 384.611 7.502 368.111 L 6.312 291.47 L 6.373 211.601 L 92.378 211.601 L 570.016 211.604 C 586.518 211.604 600.016 198.104 600.016 181.604 L 600.018 66.445"*/}
            {/*    opacity={0.3}*/}
            {/*/>*/}
            <path
                ref={pathRef}
                fill="none"
                stroke="#fff"
                strokeWidth={tubeW}
                className="tubefill s4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d={tubeWidth}
            />
            {/*<path fill="#3AAFA9" d="M0 113.302h91.228v116.667H0z"/>*/}
            <path fill="#3AAFA9" className={"box_under_setup"}
                  d="M -4 113.302 L 87.228 113.302 L 87.228 229.969 L -4 229.969 L -4 113.302 Z"/>
            <g fill="none" stroke="#3AAFA9" strokeWidth="16" strokeMiterlimit="10">
                <path
                    d="M80.14 397.109h289.086H80.14zM739.14 397.109h185.086H739.14zM1290.14 397.109h231.411-231.411zM1889.14 397.109h231.411-231.411zM2487.141 397.109h216.043-216.043zM3071.141 397.109h326.373-326.373zM3766.141 397.109h311.661-311.661z"/>
            </g>
        </svg>
    );
}

export default SvgS4N;
