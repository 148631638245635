import React, {useEffect, useMemo, useRef, useState} from "react";
//Custom Hook
import useWindowSize from "../../hooks/useWindowSize";
import isTouchDevice from "../../hooks/isTouchDevice";
import axios from "axios";
import api from "../../context/apiLink";

import {Banner, Canvas, Video,} from "../../styles/homeStyles";
import {qs} from "../../helpers/helpers";

const BackgroundBanner = ({onCursor}) => {

    const apiLink = api;
    const videoAllow = useMemo(() => {
        return ["mp4", "flv", "mkv"];
    }, [])

    const size = useWindowSize();
    const isTouch = isTouchDevice();
    let canvas = useRef(null);

    let [sliderData, setSliderData] = useState("");

    const getSliderData = () => {
        axios.get(`${apiLink}/api/slider`).then(({data}) => {
            var ext = data[0].slider_link.substr(
                data[0].slider_link.lastIndexOf(".") + 1
            );
            if (videoAllow.includes(ext)) {
                setSliderData(
                    `${apiLink}/public/images/sliders/${data[0].title}/${data[0].slider_link}`
                );
            }
        });
    };

    useEffect(() => {
        getSliderData();
    }, []);

    useEffect(() => {
        if (!isTouch) return;
        let dragItem = qs(".drag")

        if (dragItem) {
            let box1 = qs(".SectionLogo")
            let container = dragItem
            let active = false;
            let currentX;
            let currentY;
            let initialX;
            let initialY;
            let xOffset = dragItem.getBoundingClientRect().x + (dragItem.scrollWidth / 2);
            let yOffset = dragItem.getBoundingClientRect().y + (dragItem.scrollHeight / 2);

            let renderingElement = canvas.current;
            let drawingElement = renderingElement.cloneNode();
            let SectionLogo = document.querySelector(".SectionLogo");
            let drawingCtx = drawingElement.getContext("2d");
            let renderingCtx = renderingElement.getContext("2d");
            let lastX;
            let lastY;
            let moving = false;

            container.addEventListener("touchstart", dragStart, false);
            container.addEventListener("touchend", dragEnd, false);
            container.addEventListener("touchmove", drag, false);

            SectionLogo.addEventListener("touchstart", (e) => {
                if (!(e.target === dragItem || e.target === dragItem.children[0])) {
                    if (window.locomotiveScroll) {
                        window.locomotiveScroll.start()
                    }
                }
            }, false);

            function dragStart(e) { //when the drag starts
                if (e.type === "touchstart") { //if its a touchscreen
                    initialX = e.touches[0].clientX - xOffset; //set initial x-cordinate to where it was before drag started
                    initialY = e.touches[0].clientY - yOffset; //set initial y-cordinate to where it was before drag started
                } else { //if its not a touchscreen (mouse)
                    initialX = e.clientX - xOffset; //set initial x-cordinate to where it was before drag started
                    initialY = e.clientY - yOffset; //set initial y-cordinate to where it was before drag started
                }
                moving = true;
                lastX = e.touches[0].clientX - SectionLogo.offsetLeft;
                lastY = e.touches[0].clientY - SectionLogo.offsetTop;
                if (e.target === dragItem || e.target == dragItem.children[0]) { //if user is dragging circle
                    active = true; //the drag is active
                    if (window.locomotiveScroll) {
                        window.locomotiveScroll.stop()
                    }
                } else {
                    if (window.locomotiveScroll) {
                        window.locomotiveScroll.start()
                    }
                }
            }

            function dragEnd(e) { //when the drag ends
                const box1Size = box1.getBoundingClientRect(); //the size of box1
                const elementSize = dragItem.getBoundingClientRect(); //the size of the circle
                moving = false;
                // console.log(e.touches[0].clientX, e.touches[0].clientY)
                // if (e.touches[0].clientX && e.touches[0].clientY) {
                //     lastX = e.touches[0].clientX - SectionLogo.offsetLeft;
                //     lastY = e.touches[0].clientY - SectionLogo.offsetTop;
                // }
                if (elementSize.left >= box1Size.left && elementSize.right <= box1Size.right && elementSize.top >= box1Size.top && elementSize.bottom <= box1Size.bottom) { //if the circle is in box1
                    initialX = currentX; //set the initial x-cordinate to where it is now
                    initialY = currentY; //set the initial y-cordinate to where it is now
                } else { //if the circle is in neither box1 nor box2
                    currentX = 0;
                    currentY = 0;
                    initialX = 0;
                    initialY = 0;
                    xOffset = 0;
                    yOffset = 0;
                    setTranslate(0, 0, dragItem);
                }
                active = false; //the drag is no longer active
            }

            function drag(e) { //when the circle is being dragged
                if (active) { //if the drag is active
                    e.preventDefault(); //the user cant do anything else but drag
                    // let color = random_rgba()
                    // dragItem.style.borderColor = color
                    // dragItem.querySelector("span").style.color = color
                    if (e.type === "touchmove") { //if its a touchscreen
                        currentX = e.touches[0].clientX - initialX; //set current x-cordinate to where it is now
                        currentY = e.touches[0].clientY - initialY; //set current y-cordinate to where it is now
                    } else { //if its not a touchscreen (mouse)
                        currentX = e.clientX - initialX; //set current x-cordinate to where it is now
                        currentY = e.clientY - initialY; //set current y-cordinate to where it is now
                    }

                    //Im not sure what this does but it dosnt work without it
                    xOffset = currentX;
                    yOffset = currentY;
                    setTranslate(currentX, currentY, dragItem);
                    if (moving) {
                        drawingCtx.globalCompositeOperation = "source-over";
                        renderingCtx.globalCompositeOperation = "destination-out";
                        let currentXX = e.touches[0].clientX - SectionLogo.offsetLeft;
                        let currentYY = e.touches[0].clientY - SectionLogo.offsetTop;
                        drawingCtx.lineJoin = "round";
                        drawingCtx.moveTo(lastX, lastY);
                        drawingCtx.lineTo(currentXX, currentYY);
                        drawingCtx.closePath();
                        drawingCtx.lineWidth = 120;
                        drawingCtx.stroke();
                        lastX = currentXX;
                        lastY = currentYY;
                        renderingCtx.drawImage(drawingElement, 0, 0);
                    }
                }
            }

            function setTranslate(xPos, yPos, el) { //Im not sure what this does but it dosnt work without it
                el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
            }

            // console.log(isTouch)
        }
    }, [isTouch]);

    useEffect(() => {
        let renderingElement = canvas.current;
        let renderingCtx = renderingElement.getContext("2d");

        renderingCtx.globalCompositeOperation = "source-over";

        let x = size.width / 2,
            y = size.height / 2,
            innerRadius = 5,
            outerRadius = size.width / 2,
            radius = 60;

        let grd = renderingCtx.createRadialGradient(
            x,
            y,
            innerRadius,
            x,
            y,
            outerRadius
        );

        renderingCtx.arc(x, y, radius, 0, 2 * Math.PI);

        grd.addColorStop(0, "#6C6D70");
        grd.addColorStop(1, "#595A5C");

        renderingCtx.fillStyle = grd;
        renderingCtx.fillRect(0, 0, size.width, size.height);
    }, [size.width, size.height]);
    useEffect(() => {

        let renderingElement = canvas.current;
        let drawingElement = renderingElement.cloneNode();
        let SectionLogo = document.querySelector(".SectionLogo");
        let drawingCtx = drawingElement.getContext("2d");
        let renderingCtx = renderingElement.getContext("2d");
        let lastX;
        let lastY;
        let moving = false;

        renderingCtx.globalCompositeOperation = "source-over";
        let x = size.width / 2,
            y = size.height / 2,
            innerRadius = 5,
            outerRadius = size.width / 2,
            radius = 60;

        let grd = renderingCtx.createRadialGradient(
            x,
            y,
            innerRadius,
            x,
            y,
            outerRadius
        );

        renderingCtx.arc(x, y, radius, 0, 2 * Math.PI);

        grd.addColorStop(0, "#6C6D70");
        grd.addColorStop(1, "#595A5C");

        renderingCtx.fillStyle = grd;
        renderingCtx.fillRect(0, 0, size.width, size.height);

        canvas.current.addEventListener("contextmenu", (event) =>
            event.preventDefault()
        );

        SectionLogo.addEventListener("mouseover", (ev) => {
            moving = true;
            lastX = ev.pageX - SectionLogo.offsetLeft;
            lastY = ev.pageY - SectionLogo.offsetTop;
        });

        SectionLogo.addEventListener("click", (ev) => {
            moving = true;
            lastX = ev.pageX - SectionLogo.offsetLeft;
            lastY = ev.pageY - SectionLogo.offsetTop;
        });

        SectionLogo.addEventListener("mouseup", (ev) => {
            moving = false;
            lastX = ev.pageX - SectionLogo.offsetLeft;
            lastY = ev.pageY - SectionLogo.offsetTop;
        });

        SectionLogo.addEventListener("mousemove", (ev) => {
            if (moving) {
                drawingCtx.globalCompositeOperation = "source-over";
                renderingCtx.globalCompositeOperation = "destination-out";
                let currentX = ev.pageX - SectionLogo.offsetLeft;
                let currentY = ev.pageY - SectionLogo.offsetTop;
                drawingCtx.lineJoin = "round";
                drawingCtx.moveTo(lastX, lastY);
                drawingCtx.lineTo(currentX, currentY);
                drawingCtx.closePath();
                drawingCtx.lineWidth = 120;
                drawingCtx.stroke();
                lastX = currentX;
                lastY = currentY;
                renderingCtx.drawImage(drawingElement, 0, 0);
            }
        });
    }, []);

    const container = {
        initial: {y: 800},
        animate: {
            y: 0,
            transition: {
                staggerChildren: 0.2,
            },
        },
    };
    const item = {
        initial: {y: 800},
        animate: {
            y: 0,
            transition: {
                duration: 1,
                ease: [0.6, 0.05, -0.01, 0.9],
            },
        },
    };

    // const media = useMemo(() => {
    //     return require("../../assets/images/banner.png");
    // }, [])
    // const mediaVideo = useMemo(() => {
    //     return require("../../assets/videos/video.mp4");
    // }, [])

    return (
        <Banner>
            {isTouch ? <div className="drag"><span>Drag Me</span></div> :
                <div className="drag"><span>Move Mouse</span></div>}
            {sliderData !== "" && <Video>
                <video
                    className="full"
                    muted
                    height="100%"
                    width="100%"
                    loop
                    autoPlay
                    src={sliderData}
                />
            </Video>}
            {/* <ImageB>
        <img height="100%" width="100%" src={media} />
      </ImageB> */}
            <Canvas height={size.height} width={size.width} ref={canvas}/>
        </Banner>
    );
};

export default BackgroundBanner;
