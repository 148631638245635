import React from "react";

function SvgProjectsTube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="77.967"
      height="904.423"
      viewBox="0 0 77.967 904.423"
      {...props}
    >
      <path
        fill="none"
        stroke="#010101"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="15"
        d="M7.5 7.536l32.967-.034c16.5 0 30 13.018 30 28.932l-.002 860.489"
        opacity="0.3"
        className="products_tube_path"
      ></path>
      <path
        fill="none"
        stroke="#3f3f3f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="15"
        d="M7.5 7.536l32.967-.034c16.5 0 30 13.018 30 28.932l-.002 860.489"
        className="products_tube_path tubefillprojects"
      ></path>
    </svg>
  );
}

export default SvgProjectsTube;
