import * as React from "react";

function SvgBrandingtube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={175.149}
      height={78.504}
      viewBox="0 54.686 175.149 78.504"
      {...props}
    >
      <path
        opacity={0.3}
        fill="none"
        stroke="#010101"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M167.649 95.686c0 16.5-13.019 30-28.933 30l-46.719.004c-15.914 0-28.932-13.5-28.932-30l.009.428-.001-3.932c0-16.5-13.019-30-28.932-30L7.5 62.188"
      />
      <path
        className="tubefill servicepath"
        fill="none"
        stroke="#fff"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M167.649 95.686c0 16.5-13.019 30-28.933 30l-46.719.004c-15.914 0-28.932-13.5-28.932-30l.009.428-.001-3.932c0-16.5-13.019-30-28.932-30L7.5 62.188"
      />
    </svg>
  );
}

export default SvgBrandingtube;
