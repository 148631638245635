import * as React from "react";

function SvgGhost6(props) {
  return (
    <svg viewBox="0 0 107 109.332" {...props}>
      <path
        d="M93.666 27.334V13.667H107V0H93.334v13.667H80v13.667H27V13.667H13.666V0H0v13.667h13.332v13.667h13.336v13.668H0v40.997h13.332v13.667h13.336v13.666h53.664V95.666h13.334V81.999H107V41.002H80.332V27.334h13.334zM26.668 54.667h13.334v13.667H13.666V54.667h13.002zm66.666 0v13.667H67.002V54.667h26.332z"
        fill="#cb2f2b"
      />
      <defs>
        <path
          id="Ghost6_svg__a"
          d="M93.666 27.334V13.667H107V0H93.334v13.667H80v13.667H27V13.667H13.666V0H0v13.667h13.332v13.667h13.336v13.668H0v40.997h13.332v13.667h13.336v13.666h53.664V95.666h13.334V81.999H107V41.002H80.332V27.334h13.334zM26.668 54.667h13.334v13.667H13.666V54.667h13.002zm66.666 0v13.667H67.002V54.667h26.332z"
        />
      </defs>
      <clipPath id="Ghost6_svg__b">
        <use xlinkHref="#Ghost6_svg__a" overflow="visible" />
      </clipPath>
      <linearGradient
        id="Ghost6_svg__c"
        gradientUnits="userSpaceOnUse"
        x1={-758.875}
        y1={352.273}
        x2={-698.873}
        y2={315.272}
        gradientTransform="matrix(.915 .0903 .0903 1.0065 660.792 -203.655)"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0} />
        <stop offset={1} stopColor="#c7d1d7" stopOpacity={0.5} />
      </linearGradient>
      <path
        opacity={0.4}
        clipPath="url(#Ghost6_svg__b)"
        fill="url(#Ghost6_svg__c)"
        d="M90.414 114.123l-53.73 33.098-79.038-128.424 53.731-33.098z"
      />
    </svg>
  );
}

export default SvgGhost6;
