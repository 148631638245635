import React from "react";
import SvgPacmanFull from "./PacmanFull";
import SvgPacmanHalf from "./PacmanHalf";
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
function Pacman() {
  React.useEffect(() => {
    gsap.registerPlugin(MorphSVGPlugin);

    let tl = gsap.timeline();

    tl.to(
      "#mouth_full",
      {
        morphSVG:
          "M108.468 30.672l-1.696 8.481L66.64 58.981l47.13 13.941-1.767 5.349-11.128-2.802-43.543-17.318 46.19-29.248z",
        duration: 0.2,
      },
      0
    )
      .yoyo(true)
      .repeat(-1);
    tl.to(
      "#top_light_shdow_full",
      {
        // morphSVG: "M56.851 58.312L112.8 39.513s-39.347 12.47-55.949 18.799",
        morphSVG:
          "M56.851,58.312l59.052,0.107C115.903,58.419,86.378,56.973,56.851,58.312",
        duration: 0.2,
      },
      0
    )
      .yoyo(true)
      .repeat(-1);
    tl.to(
      "#down_light_shdow_full",
      {
        // morphSVG:
        //   "M56.851 58.312l56.289 17.422c0-.001-30.333-8.084-56.289-17.422",
        morphSVG:
          "M56.851,58.312l59.052,0.107C115.903,58.419,92.786,59.502,56.851,58.312",
        duration: 0.2,
      },
      0
    )
      .yoyo(true)
      .repeat(-1);
    tl.to(
      "#top_shadow_full",
      {
        morphSVG:
          // "M113.77 42.753l-47.13 16.23-4.438-.229-5.229-.442-5.923-.388L107 34.464s3.92.209 4.45 1.27c.34.68.88 2.279 1.35 3.779.31.98.59 1.931.77 2.541.01.06.03.119.05.17.03.109.06.21.08.289.05.15.07.24.07.24z",
          "M 115.903 57.341 L 66.64 58.983 l -4.438 -0.23 l -5.229 -0.441 l -5.923 -0.388 l 58.083 -8.872 c 0 0 5.58 -0.12 6.11 0.941 c 0.109 0.73 0.197 1.354 0.427 3.895 c 0.162 0.998 0.206 2.078 0.233 2.578 c 0.011 0.06 -0.036 0.191 -0.016 0.242 c 0.029 0.109 -0.005 0.351 0.016 0.43 C 115.954 57.288 115.903 57.341 115.903 57.341 Z",
        duration: 0.2,
      },
      0
    )
      .yoyo(true)
      .repeat(-1);
    tl.to(
      "#poc_cir_full",
      {
        morphSVG:
          // "M113.14 75.733c-4.08 12.17-18.42 40.57-55.23 40.1C25.93 115.413 0 89.903 0 57.913 0 25.934 25.93-.326 57.91.004c31.03.33 48.73 23.279 53.8 36.14.48 1.22.85 2.359 1.1 3.369l-55.96 18.8 56.29 17.42z",
          "M 115.903 58.419 c 0 29.25 -21.183 57.883 -57.992 57.414 C 25.93 115.414 0 89.903 0 57.913 C 0 25.934 25.93 -0.326 57.91 0.004 c 31.029 0.33 54.89 21.666 57.898 54.573 C 115.905 55.623 115.89 57.38 115.903 58.419 L 56.85 58.313 L 115.903 58.419 Z",
        duration: 0.3,
      },
      0
    )
      .yoyo(true)
      .repeat(-1);

    tl.to(
      "#topshadow",
      {
        morphSVG:
          "M76.64,58.778      l-9.688-0.859l22.594-56.21l8.211,3.25L76.64,58.778z",
        duration: 0.3,
      },
      0
    )
      .yoyo(true)
      .repeat(-1);
    tl.to(
      "#topshadow_mask",
      {
        morphSVG:
          "M107.192,26.969C96.953,10.636,78.802-0.227,58.104-0.227c-31.983,0-57.912,25.925-57.912,57.909       c0,31.983,25.926,57.913,57.912,57.913c18.913,0,35.707-9.066,46.271-23.092L68.078,57.919l9.645-0.148L107.192,26.969z",
        duration: 0.3,
      },
      0
    )
      .yoyo(true)
      .repeat(-1);
  }, []);

  return (
    <div className="pacmanall">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="113.77"
        height="115.839"
        version="1.1"
        viewBox="0 0 113.77 115.839"
      >
        <g>
          <g>
            <linearGradient
              id="mouth_2_"
              x1="51.045"
              x2="107"
              y1="59.078"
              y2="59.078"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#ba6c29"></stop>
              <stop offset="1" stopColor="#e98624"></stop>
            </linearGradient>
            <path
              fill="url(#mouth_2_)"
              id="mouth_full"
              fillRule="evenodd"
              d="M107 27.196l-1.694 8.481-39.25 23.501 36.759 26.994 1.369 6.557-11.128-2.801L51.045 57.91l51.011-32.483z"
              clipRule="evenodd"
            ></path>
            <linearGradient
              id="top_shadow_2_"
              x1="59.053"
              x2="102.388"
              y1="65.918"
              y2="22.583"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#875422"></stop>
              <stop offset="1" stopColor="#723d19"></stop>
            </linearGradient>
            <path
              fill="url(#top_shadow_2_)"
              id="top_shadow_full"
              fillRule="evenodd"
              d="M107 27.196l-1.694 8.481-39.25 23.501-15.011-1.268 51.011-32.483z"
              clipRule="evenodd"
            ></path>
            <linearGradient
              id="poc_cir_2_"
              x1="53.5"
              x2="53.5"
              y1="115.82"
              y2="0"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#f7992d"></stop>
              <stop offset="0.464" stopColor="#fac24e"></stop>
              <stop offset="1" stopColor="#fef277"></stop>
            </linearGradient>
            <path
              fill="url(#poc_cir_2_)"
              fillRule="evenodd"
              id="poc_cir_full"
              d="M107.001,27.196c-0.412-0.658-0.837-1.308-1.274-1.947
              C95.293,10.005,77.775,0,57.911,0C25.926,0,0,25.925,0,57.909c0,31.981,25.926,57.911,57.911,57.911
              c18.913,0,35.707-9.066,46.272-23.092L56.85,58.303L107.001,27.196z"
              clipRule="evenodd"
            ></path>
            <path
              fill="#FFE2A1"
              fillRule="evenodd"
              d="M56.85 58.303l47.334 34.426S76.974 74.092 56.85 58.303"
              id="down_light_shdow_full"
              clipRule="evenodd"
            ></path>
            <path
              fill="#FFE2A1"
              fillRule="evenodd"
              d="M56.85 58.303L107 27.196S69.895 49.053 56.85 58.303"
              id="top_light_shdow_full"
              clipRule="evenodd"
            ></path>
            <defs>
              <path
                id="topshadow_mask"
                d="M107 27.196C96.76 10.863 78.609 0 57.912 0 25.926 0 0 25.925 0 57.908 0 89.89 25.926 115.82 57.912 115.82c18.912 0 35.707-9.066 46.271-23.092L56.85 58.303 107 27.196z"
              ></path>
            </defs>
            <clipPath id="SVGID_2_">
              <use overflow="visible" xlinkHref="#topshadow_mask"></use>
            </clipPath>
            <linearGradient
              id="SVGID_3_"
              x1="-339.769"
              x2="-320.021"
              y1="1104.388"
              y2="1055.64"
              gradientTransform="matrix(.9719 .0716 .0716 .8178 323.99 -823.497)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#f7ec28"></stop>
              <stop
                offset="0.285"
                stopColor="#f9f075"
                stopOpacity="0.943"
              ></stop>
              <stop
                offset="0.942"
                stopColor="#fefdf1"
                stopOpacity="0.812"
              ></stop>
              <stop offset="1" stopColor="#fff" stopOpacity="0.8"></stop>
            </linearGradient>
            <path
              id="topshadow"
              fill="url(#SVGID_3_)"
              d="M76.398 59.383l-8.21-3.25L89.545 1.7l8.211 3.25z"
              clipPath="url(#SVGID_2_)"
              opacity="0.6"
            ></path>
            <linearGradient
              id="SVGID_4_"
              x1="775.787"
              x2="751.659"
              y1="-39.266"
              y2="68.754"
              gradientTransform="matrix(.9835 .1686 -.1408 .9425 -716.267 -98.463)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#f7ec28"></stop>
              <stop
                offset="0.285"
                stopColor="#f9f075"
                stopOpacity="0.715"
              ></stop>
              <stop
                offset="0.942"
                stopColor="#fefdf1"
                stopOpacity="0.058"
              ></stop>
              <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
            </linearGradient>
            <path
              fill="url(#SVGID_4_)"
              d="M40.154 114.604L-20.55 90.723 22.813-19.711l60.704 23.88z"
              clipPath="url(#SVGID_2_)"
              opacity="0.6"
            ></path>
            <path
              fill="#FFF"
              fillRule="evenodd"
              d="M61.004 108.061c-28.156 0-50.979-22.824-50.979-50.979 0-21.265 13.022-39.479 31.524-47.125C20.438 16.19 5.026 35.71 5.026 58.842c0 28.154 22.823 50.979 50.979 50.979a50.793 50.793 0 0019.352-3.824 51.007 51.007 0 01-14.353 2.064z"
              clipRule="evenodd"
              opacity="0.6"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default Pacman;
