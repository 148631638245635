import * as React from "react";

function SvgGraphictube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={236.149}
      height={108.315}
      viewBox="0 18.185 236.149 108.315"
      {...props}
    >
      <path
        opacity={0.3}
        fill="none"
        stroke="#010101"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M228.649 25.685c0 16.5-13.02 30-28.933 30l-46.667.194c-16.5 0-30 13.02-30 28.932l.025 4.189c0 16.5-13.019 30-28.932 30L7.5 118.998"
      />
      <path
        className="tubefill servicepath"
        fill="none"
        stroke="#fff"
        strokeWidth={15}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        d="M228.649 25.685c0 16.5-13.02 30-28.933 30l-46.667.194c-16.5 0-30 13.02-30 28.932l.025 4.189c0 16.5-13.019 30-28.932 30L7.5 118.998"
      />
    </svg>
  );
}

export default SvgGraphictube;
