import React from "react";

function SvgS9(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.002"
      height="786.151"
      viewBox="231 1 15.002 786.151"
      {...props}
    >
      <path
        fill="none"
        stroke="#000"
        strokeWidth="15"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M238.5 8.5l.002 771.151"
        opacity=".3"
      />
      <path
        className="tubefill s9"
        fill="none"
        stroke="#3f3f3f"
        strokeWidth="15"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="M238.5 8.5l.002 771.151"
      />
    </svg>
  );
}

export default SvgS9;
