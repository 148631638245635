import React from "react";
import SvgS5N from "../../assets/svg/SvgJs/NewTubes/S5N";
import SvgOffice from "../../assets/svg/SvgJs/Office";
import SvgSpotLight from "../../assets/svg/SvgJs/SpotLight";
import SvgSpotLightN from "../../assets/svg/SvgJs/SpotLight_n";
import * as res from '../../helpers/responsive'
import SvgLess768S5 from "../../assets/svg/SvgJs/Responsive/less_768/Less768S5";
import SvgMiddtubeRespon from "../../assets/svg/SvgJs/Responsive/less_480/Middtube";

function OurStudioSection({sD}) {
    return (
        <div className="page sectionOurSudio">
            <div className="cont">
                <div className="content">
                    <div className="title">
                        <h3>Our Studio</h3>
                    </div>
                    <div className="paragraph">{sD}</div>
                </div>
                <div className="tube">
                    <res.Desktop>
                        <SvgS5N/>
                    </res.Desktop>
                    <res.Tablet>
                        <SvgS5N/>
                    </res.Tablet>
                    <res.Mobile>
                        <SvgLess768S5/>
                    </res.Mobile>
                    <res.LessMobile>
                        <SvgMiddtubeRespon heightOfTube={"100vh"} tubeClass={"s5"}/>
                    </res.LessMobile>

                </div>
                <div className="tubeh"></div>
                <div className="officeandlight">
                    <SvgSpotLightN className="light"/>
                    <SvgOffice className="office"/>
                </div>
            </div>
        </div>
    );
}

export default OurStudioSection;
