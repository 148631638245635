import React from "react";
import axios from "axios";
import SvgBe from "../../assets/svg/SvgJs/Be";
import SvgFb from "../../assets/svg/SvgJs/Fb";
import SvgIg from "../../assets/svg/SvgJs/Ig";
import SvgIn from "../../assets/svg/SvgJs/In";
import SvgMushrooms from "../../assets/svg/SvgJs/Mushrooms";
import SvgOfficeFooter from "../../assets/svg/SvgJs/OfficeFooter";
import SvgSpotFooter from "../../assets/svg/SvgJs/SpotFooter";
import SvgSpotFooterN from "../../assets/svg/SvgJs/SpotFooter_n";
import SvgS10 from "../../assets/svg/SvgJs/Tubes/S10";
import SvgYt from "../../assets/svg/SvgJs/Yt";
import ContactLoading from "../../hooks/ContactLoading";
import api from "../../context/apiLink";
import * as res from '../../helpers/responsive'
import Less768FooterLight from "../../assets/svg/SvgJs/Responsive/less_768/Less768FooterLight";
import mobileCheck from "../../hooks/mobileCheck";
import {qs, qsa} from "../../helpers/helpers";

function FooterSection() {

    const apiLink = api;
    const submiteRef = React.useRef(null);
    const formRef = React.useRef(null);
    let [loading, setLoading] = React.useState(false);
    let [footerData, setFooterData] = React.useState({});
    let [contactResult, setContactResult] = React.useState("Submit");

    const submitForm = (e) => {
        e.preventDefault();
        setLoading(true);
        submiteRef.current.setAttribute("disabled", "true");
        const formData = new FormData(formRef.current);
        axios.post(`${apiLink}/api/contact`, formData).then(({data}) => {
            setLoading(false);
            submiteRef.current.removeAttribute("disabled");
            if (data.success != null) {
                setContactResult("Success");
                setTimeout(() => {
                    formRef.current.reset();
                    let form = formRef.current;
                    let c = form.children;
                    for (let e of c) {
                        if (e.classList.contains("active")) {
                            e.classList.remove("active");
                            e.querySelector("label").classList.remove("active");
                        }
                    }
                    setContactResult("Submit");
                }, 3000);
            } else if (data.error != null) {
                setContactResult("Fill fields");
            }
        });
    };

    const getFooterData = () => {
        axios.get(`${apiLink}/api/footer`).then(({data}) => {
            setFooterData(data);
        });
    };

    React.useEffect(() => {
        getFooterData();

        function setInputFilter(textbox, inputFilter) {
            [
                "input",
                "keydown",
                "keyup",
                "mousedown",
                "mouseup",
                "select",
                "contextmenu",
                "drop",
            ].forEach(function (event) {
                textbox.addEventListener(event, function () {
                    if (inputFilter(this.value)) {
                        this.oldValue = this.value;
                        this.oldSelectionStart = this.selectionStart;
                        this.oldSelectionEnd = this.selectionEnd;
                    } else if (this.hasOwnProperty("oldValue")) {
                        this.value = this.oldValue;
                        this.setSelectionRange(
                            this.oldSelectionStart,
                            this.oldSelectionEnd
                        );
                    } else {
                        this.value = "";
                    }
                });
            });
        }

        setInputFilter(document.getElementById("phone"), function (value) {
            return /^\d*\.?\d*$/.test(value);
        });

        let ourClient = qs(".sectionOurClients")
        const cls = "client_logo"
        let dragItem = qs(".drag")

        ourClient.addEventListener("touchstart", (e) => {
            let el = e.target
            if (el && el.nodeType === Node.ELEMENT_NODE) {
                if (el.classList.contains(cls)) {
                    window.locomotiveScroll && window.locomotiveScroll.stop()
                } else if ((e.target === dragItem || e.target === dragItem.children[0])) {
                    window.locomotiveScroll && window.locomotiveScroll.stop()
                } else {
                    if (window.locomotiveScroll) {
                        window.locomotiveScroll.scroll.stop && window.locomotiveScroll.start()
                    }
                }
            }
        }, false);
    }, []);

    let resultButton = () => {
        if (contactResult == "Success") {
            return {background: "#2ecc71"};
        } else if (contactResult == "Fill fields") {
            return {background: "#c0392b"};
        } else {
            return {background: "#303030"};
        }
    };

    React.useEffect(() => {
        let formGroups = document.querySelectorAll(".form-group.input");

        formGroups.forEach((group) => {
            group.querySelector("input").addEventListener("focus", (e) => {
                //
                // if (mobileCheck()) {
                //     qsa(".sectionFooter > *").forEach(c => {
                //       c.style.marginLeft = "12px"
                //     })
                // }

                e.preventDefault()
                let label = group.querySelector("label"),
                    input = group.querySelector("input");
                label.classList.add("active");
                input.parentNode.classList.add("active");
                input.focus();
            });

            let input = group.querySelector("input");
            if (input != null) {
                input.addEventListener("focusout", () => {
                    if (input.value == "") {
                        let label = input.parentNode.querySelector("label");
                        label.classList.remove("active");
                        input.parentNode.classList.remove("active");
                    }
                });
            }
        });
    }, []);

    return (
        <div className="page sectionFooter">
            <div className="content">
                <div className="title">
                    <h3>Contact Us</h3>
                </div>
                <div className="middle">
                    <div className="left">
                        <div className="email">
                            <h2 className="email_title">Email</h2>
                            <h6 className="email_content">
                                <a href={"mailto:" + footerData.site_email_contact}>
                                    {footerData.site_email_contact}
                                </a>
                            </h6>
                        </div>
                        <div className="office office1">
                            <h2 className="office_country">EGYPT office</h2>
                            <h6 className="office_address">
                                <a
                                    target="_blank"
                                    href={
                                        "https://maps.google.com/?q=" +
                                        footerData.site_full_location
                                    }
                                >
                                    {footerData.site_full_location}
                                </a>
                            </h6>
                            <div className="numbers_contact">
                                <h6 className="fax">
                                    <a href={"tel:" + footerData.site_fax_phone}>
                                        TEL./FAX: {footerData.site_fax_phone}
                                    </a>
                                </h6>
                                <h6 className="phone1">
                                    <a href={"tel:" + footerData.site_mobile_phone}>
                                        CEL. {footerData.site_mobile_phone}
                                    </a>
                                </h6>
                                <h6 className="phone2">
                                    <a href={"tel:" + footerData.site_mobile_phone_2}>
                                        CEL. {footerData.site_mobile_phone_2}
                                    </a>
                                </h6>
                            </div>
                        </div>
                        <div className="office office2">
                            <h2 className="office_country">CANADA office</h2>
                            <h6 className="office_address">
                                <a
                                    target="_blank"
                                    href={
                                        "https://maps.google.com/?q=" +
                                        footerData.site_full_location_2
                                    }
                                >
                                    {footerData.site_full_location_2}
                                </a>
                            </h6>
                            <div className="numbers_contact">
                                <h6 className="phone1">
                                    <a href={"tel:" + footerData.site_mobile_phone_3}>
                                        CEL. {footerData.site_mobile_phone_3}
                                    </a>
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <form
                            action="#"
                            id="contactusform"
                            ref={formRef}
                            autoComplete="off"
                        >
                            <div className="form-group input">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                />
                            </div>
                            <div className="form-group input">
                                <label htmlFor="phone" className="form-label">
                                    phone Number
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phone"
                                    autoComplete="off"
                                    name="phone"
                                />
                            </div>
                            <div className="form-group input required">
                                <label htmlFor="email" className="form-label">
                                    email address
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    autoComplete="off"
                                />
                            </div>
                            <div className="form-group input required">
                                <label htmlFor="message" className="form-label">
                                    message
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="message"
                                    name="message"
                                />
                            </div>
                            <div className="form-group submit">
                                <button
                                    ref={submiteRef}
                                    onClick={submitForm}
                                    type="submit"
                                    style={resultButton()}
                                    className="form-control submit"
                                >
                                    {loading ? <ContactLoading/> : contactResult}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="social">
                    <ul className="icons">
                        <li className="icon fb">
                            <a target="_blank" href={footerData.site_fb}>
                                <SvgFb/>
                            </a>
                        </li>
                        <li className="icon ig">
                            <a target="_blank" href={footerData.site_ig}>
                                <SvgIg/>
                            </a>
                        </li>
                        <li className="icon in">
                            <a target="_blank" href={footerData.site_in}>
                                <SvgIn/>
                            </a>
                        </li>
                        <li className="icon yt">
                            <a target="_blank" href={footerData.site_yt}>
                                <SvgYt/>
                            </a>
                        </li>
                        <li className="icon be">
                            <a target="_blank" href={footerData.site_be}>
                                <SvgBe/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="mushroom">
                <SvgMushrooms/>
            </div>
            <div className="office">
                <SvgOfficeFooter/>
            </div>
            <div className="light">
                <res.Desktop>
                    <SvgSpotFooterN/>
                </res.Desktop>
                <res.Tablet>
                    <SvgSpotFooterN/>
                </res.Tablet>
                <res.Mobile>
                    <Less768FooterLight/>
                </res.Mobile>
            </div>
            <div className="tube">
                <SvgS10/>
            </div>
        </div>
    );
}

export default FooterSection;
