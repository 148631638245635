import React, {useEffect} from "react";
import SvgProductTitleTube from "../../assets/svg/SvgJs/Tubes/ProductTitleTube";
import SvgProjectTube from "../../assets/svg/SvgJs/Tubes/ProjectTube";
import api from "../../context/apiLink";
import useWindowSize from "../../hooks/useWindowSize";
import {qs, qsa} from "../../helpers/helpers";

import url from '../../helpers/url'

function ProjectSection({project, type, changeHeight}) {
    // const presentation = useMemo(() => {
    //     return require("../../assets/images/project_presentation.png")
    // }, []);

    const size = useWindowSize();
    const [currProject, setCurrProject] = React.useState({});

    function compare(a, b) {
        if (parseInt(a.position) < parseInt(b.position)) {
            return -1;
        }
        if (parseInt(a.position) > parseInt(b.position)) {
            return 1;
        }
        return 0;
    }

    function sort(project) {
        if (Object.keys(project).length !== 0)
            return project.project_photos.sort(compare)
    }

    useEffect(() => {
        if (window.isProjectOpen) {
            sort(project);
            setCurrProject({})
            setCurrProject(project);
        }
    }, [project, sort]);

    useEffect(() => {
        let products = qsa(
            ".sectionServiceProducts .products > *"
        );
        let service = qs(".sectionServiceProducts");
        products.forEach((product) => {
            product.addEventListener("click", (e) => {
                let productIndex = product.getAttribute("data-index");
                if (window.projectsAll[type][productIndex]['title'] === window.currentProjectTitle) {
                    let serviceTop = service.getBoundingClientRect().top + window.locomotiveScroll.scroll.instance.delta.y + (service.offsetHeight < window.innerHeight ? window.innerHeight : service.offsetHeight);
                    window.locomotiveScroll.scrollTo(serviceTop, 1)
                }
            })
        })
    }, [type])

    useEffect(() => {
        if (!(Object.keys(currProject).length === 0 && currProject.constructor === Object)) {

            // console.log(currProject)

            let loadingBar = qs(".projectLoadingBar");
            loadingBar.classList.add("hidden");
            loadingBar.style.removeProperty("background");
            loadingBar.querySelector(".bar").style.width = "0vw";

            let preloader = require("preloader");
            let sectionServiceProduct = qs(
                ".sectionServiceProduct"
            );

            window.projectLoading = true

            let service = qs(".sectionServiceProducts");
            let len = currProject["project_photos"].length;
            let servicesAll = qs(".services-all");
            let presentation = qs(".sectionServiceProduct .presentation");
            sectionServiceProduct.style.top = (service.offsetHeight < window.innerHeight ? window.innerHeight : service.offsetHeight) + "px";
            let arrowBack = qs(".sectionServiceProducts .content .top .arrow");

            arrowBack.addEventListener("click", () => {
                if (window.ev) window.ev.disable();
                if (window.serviceAllHeight) {
                    servicesAll.style.height = window.serviceAllHeight + "px";
                    if (window.locomotiveScroll) {
                        window.locomotiveScroll.update();
                        setCurrProject({})
                        window.isProjectOpen = false
                    }
                }
                presentation.innerHTML = "";
            });

            if (window.serviceAllHeight) {
                servicesAll.style.height = window.serviceAllHeight + "px";
                if (window.locomotiveScroll) {
                    window.locomotiveScroll.update();
                    window.locomotiveScroll.scrollTo(service, 1, () => {
                        setTimeout(() => {
                            presentation.innerHTML = "";
                        }, 500)
                    })
                }
            } else {
                presentation.innerHTML = "";
            }

            loadingBar.classList.contains("hidden") && loadingBar.classList.remove("hidden");

            let loader = preloader({
                xhrImages: false,
            });
            let oldProgress = 0;
            loader.on("progress", function (progress) {
                oldProgress = Math.max(progress, oldProgress);
                loadingBar.classList.contains("hidden") && loadingBar.classList.remove("hidden");
                loadingBar.querySelector(".bar").style.width = (oldProgress.toFixed(3) * 100) + "vw";
                // loadingBar.style.background = randomColor()
            });
            loader.on("complete", function () {
                for (let i = 0; i < len; i++) {
                    let vid = false;
                    let imag = url(
                        api,
                        type,
                        currProject.title,
                        currProject["project_photos"][i]["photo_link"]
                    );
                    let result = loader.get(imag);
                    if (result.tagName.toLowerCase() === 'video') {
                        vid = true;
                        result.setAttribute('controls', true);
                        result.classList.add("full");
                        result.style.width = "100%";
                        result.style.height = "100%";
                        result.setAttribute("title", currProject["project_photos"][i]['seo_title'])
                    } else {
                        result.setAttribute("title", currProject["project_photos"][i]['seo_title'])
                    }
                    let div = document.createElement("div");
                    div.setAttribute('data-position', currProject["project_photos"][i]['position'])
                    div.append(result);
                    div.classList.add(vid ? "video" : "image");
                    presentation.append(div)
                }

                let presentationHeight = presentation.offsetHeight;
                let serviceAllHeight = servicesAll.offsetHeight;
                window.serviceAllHeight = serviceAllHeight;
                servicesAll.style.cssText = `height: ${presentationHeight + serviceAllHeight + "px !important"}`;

                if (window.locomotiveScroll) {
                    window.locomotiveScroll.update();
                    let serviceTop = service.getBoundingClientRect().top + window.locomotiveScroll.scroll.instance.delta.y + (service.offsetHeight < window.innerHeight ? window.innerHeight : service.offsetHeight);
                    window.locomotiveScroll.scrollTo(serviceTop, 1, () => {
                        loadingBar.classList.add("hidden");
                        loadingBar.querySelector(".bar").style.width = "0vw"
                        window.currentProjectTitle = currProject['title']
                        window.projectLoading = false
                    })
                }
                window.projectLoading = false
                setTimeout(() => {
                    // loadingBar.classList.add("hidden");
                    // loadingBar.querySelector(".bar").style.width = "0vw"
                }, 3000)
            });
            for (let i = 0; i < len; i++) {
                let imag = url(
                    api,
                    type,
                    currProject.title,
                    currProject["project_photos"][i]["photo_link"]
                );
                loader.add(imag)
            }
            loader.load();
        }
    }, [currProject]);

    return (
        <div className="sectionServiceProduct projectsection">
            <div className="content">
                <div className="project">
                    <div className="details">
                        <div className="title_tube">
                            <SvgProductTitleTube/>
                        </div>
                        <div className="title">
                            <h3>{currProject.title}</h3>
                        </div>
                        <div className="description">{currProject.description} </div>
                    </div>
                    <div className="presentation"></div>
                </div>
            </div>
            <div className="tube">
                <SvgProjectTube/>
            </div>
        </div>
    );
}

export default ProjectSection;
