export default function swipedetect(el, callback) {
    var touchsurface = el,
        swipedir,
        dist,
        startX,
        startY,
        distX,
        distY,
        threshold = 150, //required min distance traveled to be considered swipe
        restraint = 100, // maximum distance allowed at the same time in perpendicular direction
        allowedTime = 300, // maximum time allowed to travel that distance
        elapsedTime,
        startTime,
        handleswipe = callback || function (swipedir) {
        };

    touchsurface.addEventListener(
        "touchstart",
        function (e) {
            var touchobj = e.changedTouches[0];
            swipedir = "none";
            dist = 0;
            startX = touchobj.pageX;
            startY = touchobj.pageY;
            startTime = new Date().getTime(); // record time when finger first makes contact with surface
            // e.preventDefault()
        },
        false
    );

    touchsurface.addEventListener(
        "touchmove",
        function (e) {
            // e.preventDefault() // prevent scrolling when inside DIV
        },
        false
    );

    touchsurface.addEventListener(
        "touchend",
        function (e) {
            var touchobj = e.changedTouches[0];
            distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
            distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
            elapsedTime = new Date().getTime() - startTime; // get time elapsed
            if (elapsedTime <= allowedTime) {
                // first condition for awipe met
                if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
                    // 2nd condition for horizontal swipe met
                    swipedir = distX < 0 ? "left" : "right"; // if dist traveled is negative, it indicates left swipe
                } else if (
                    Math.abs(distY) >= threshold &&
                    Math.abs(distX) <= restraint
                ) {
                    // 2nd condition for vertical swipe met
                    swipedir = distY < 0 ? "up" : "down"; // if dist traveled is negative, it indicates up swipe
                }
            }
            handleswipe(swipedir);
            // e.preventDefault()
        },
        false
    );
}

(function (d) {
    var ce = function (e, n) {
            var a = document.createEvent("CustomEvent");
            a.initCustomEvent(n, true, true, e.target);
            e.target.dispatchEvent(a);
            a = null;
            return false;
        },
        nm = true,
        sp = {
            x: 0,
            y: 0,
        },
        ep = {
            x: 0,
            y: 0,
        },
        touch = {
            touchstart: function (e) {
                sp = {
                    x: e.touches[0].pageX,
                    y: e.touches[0].pageY,
                };
            },
            touchmove: function (e) {
                nm = false;
                ep = {
                    x: e.touches[0].pageX,
                    y: e.touches[0].pageY,
                };
            },
            touchend: function (e) {
                if (nm) {
                    ce(e, "fc");
                } else {
                    var x = ep.x - sp.x,
                        xr = Math.abs(x),
                        y = ep.y - sp.y,
                        yr = Math.abs(y);
                    if (Math.max(xr, yr) > 20) {
                        ce(e, xr > yr ? (x < 0 ? "swl" : "swr") : y < 0 ? "swu" : "swd");
                    }
                }
                nm = true;
            },
            touchcancel: function (e) {
                nm = false;
            },
        };
    for (var a in touch) {
        d.addEventListener(a, touch[a], false);
    }
})(document);
