import React from "react";
import BackgroundBanner from "./../../Components/LogoSection/BackgroundBanner";
import SvgS1 from "../../assets/svg/SvgJs/Tubes/S1";
import SvgLogo from "../../assets/svg/SvgJs/Logo";
import SvgArrow from "../../assets/svg/SvgJs/Arrow";
import SvgLogoLoadingN from "../../assets/svg/SvgJs/LogoLoading_N";
import ScrollDownIcon from "../../assets/svg/SvgJs/ScrollDownIcon";
import "../../css/mouse.css";

import * as res from '../../helpers/responsive'
import SvgLess1024S1 from "../../assets/svg/SvgJs/Responsive/less_1024/Less1024S1";
import SvgLess768S1 from "../../assets/svg/SvgJs/Responsive/less_768/Less768S1";
import SvgMiddtubeRespon from "../../assets/svg/SvgJs/Responsive/less_480/Middtube";

const LogoSection = () => {
    return (
        <div className="page SectionLogo">
            <BackgroundBanner/>
            <div className="logo">
                <SvgLogoLoadingN/>
            </div>
            <div className="scroll-down">
                {/*<ScrollDownIcon/>*/}
                {/*<span className="arrowContainer"><i className="arrow down"></i></span>*/}
                {/*<span className={"scroll_down_word"}>Scroll Down</span>*/}
                <div className="mouse"></div>
                <span>Scroll</span>
            </div>
            <div className="tube">
                <div className="arrow">
                    <SvgArrow/>
                    <span>Scroll Down</span>
                </div>
                <res.Desktop>
                    <SvgS1/>
                </res.Desktop>
                <res.Tablet>
                    <SvgLess1024S1/>
                </res.Tablet>
                <res.Mobile>
                    <SvgLess768S1/>
                </res.Mobile>
                <res.LessMobile>
                    <SvgMiddtubeRespon tubeClass="s1" heightOfTube={250}/>
                </res.LessMobile>
            </div>
        </div>
    );
};

export default LogoSection;
