import * as React from "react";

function SvgLess768S1(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={116.666}
            height={453.006}
            {...props}
        >
            <path
                opacity={0.3}
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 109.166,445.506 L 109.166,37.5 C 109.166,21.0 95.666,7.5 79.166,7.5 L 7.5,7.5"
            />
            <path
                className="tubefill s1"
                fill="none"
                stroke="#fff"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d="M 109.166,445.506 L 109.166,37.5 C 109.166,21.0 95.666,7.5 79.166,7.5 L 7.5,7.5"
            />
        </svg>
    );
}

export default SvgLess768S1;
