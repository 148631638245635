import * as React from "react";

function SvgYt(props) {
  return (
    <svg width={30.756} height={21.669} {...props}>
      <path
        fill="#FFF"
        d="M30.114 3.384A3.864 3.864 0 0027.395.647C24.997 0 15.378 0 15.378 0S5.761 0 3.362.646a3.867 3.867 0 00-2.72 2.737C0 5.798 0 10.834 0 10.834s0 5.037.643 7.451a3.865 3.865 0 002.72 2.737c2.398.646 12.016.646 12.016.646s9.619 0 12.017-.646a3.867 3.867 0 002.719-2.737c.643-2.414.643-7.451.643-7.451s-.002-5.036-.644-7.45zM12.232 15.407V6.262l8.04 4.572-8.04 4.573z"
      />
    </svg>
  );
}

export default SvgYt;
