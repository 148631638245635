import * as React from "react";

function SvgGhost1(props) {
  return (
    <svg viewBox="0 0 107 97.215" {...props}>
      <linearGradient
        id="Ghost1_svg__a"
        gradientUnits="userSpaceOnUse"
        x1={8.375}
        y1={105.59}
        x2={98.625}
        y2={15.339}
      >
        <stop offset={0} stopColor="#2d445e" />
        <stop offset={0.575} stopColor="#3d5a7b" />
      </linearGradient>
      <path
        d="M95.141 24.305V12.152H83.286V0H59.277v12.152H47.721V0H23.713v12.152H11.857v12.152H0v72.908h12.15V85.063h11.563v12.151h12.151V85.063h11.558v12.151h12.152V85.063h11.56v12.151h12.152V85.063h11.56v12.151H107V24.305H95.141zm-.295 24.304H82.989v12.15H71.431V36.457h23.416v12.152zM12.15 36.457h23.418v12.152H23.713v12.15H12.15V36.457z"
        fill="url(#Ghost1_svg__a)"
      />
    </svg>
  );
}

export default SvgGhost1;
