import React from 'react'
import {useMediaQuery} from 'react-responsive'

export const Desktop = ({children}) => {
    const isDesktop = useMediaQuery({minWidth: 1024})
    return isDesktop ? children : null
}
export const DesktopCheck = () => {
    return useMediaQuery({minWidth: 1024})
}
export const Tablet = ({children}) => {
    const isTablet = useMediaQuery({minWidth: 768, maxWidth: 1023})
    return isTablet ? children : null
}
export const TabletCheck = () => {
    return useMediaQuery({minWidth: 768, maxWidth: 1023})
}
export const Mobile = ({children}) => {
    const isMobile = useMediaQuery({minWidth: 480, maxWidth: 767})
    return isMobile ? children : null
}
export const MobileCheck = () => {
    return useMediaQuery({minWidth: 480, maxWidth: 767})
}

export const LessMobile = ({children}) => {
    const isLessMobile = useMediaQuery({maxWidth: 479})
    return isLessMobile ? children : null
}
export const LessMobileCheck = () => {
    return useMediaQuery({maxWidth: 479})
}

export const NoSite = ({children}) => {
    const isLessMobile = useMediaQuery({maxWidth: 319})
    return isLessMobile ? children : null
}
export const NoSiteCheck = () => {
    return useMediaQuery({maxWidth: 319})
}

export const SiteAva = ({children}) => {
    const isLessMobile = useMediaQuery({minWidth: 320})
    return isLessMobile ? children : null
}
export const SiteAvaCheck = () => {
    return useMediaQuery({minWidth: 320})
}




