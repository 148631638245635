import {useState, useEffect} from "react";

export default function isTouchDevice() {

    function touchEnabled() {
        return ('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0);
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isTouch, setIsTouch] = useState(touchEnabled);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        function handleTouch() {
            setIsTouch(touchEnabled());
        }

        window.addEventListener("resize", handleTouch);
        return () => window.removeEventListener("resize", handleTouch);
    }, []);

    return isTouch

}
