import React, {useEffect, useState} from "react";
import {gsap, Power0} from "gsap";
import {CSSRulePlugin} from "gsap/CSSRulePlugin";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import DrawSVGPlugin from "gsap/DrawSVGPlugin";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

import locomotiveScroll from "locomotive-scroll";
import axios from "axios";

import "./App.css";
import "./css/video-js.min.css";
import "./css/forest.css";

import WebFont from "webfontloader";

import getRandomQuote from "./data/getRandomQuote";

import LogoSction from "./Sections/LogoSection/LogoSection";
import Layout from "./Components/layout";
import Navbar from "./Components/Nav/Navbar";
import AboutSection from "./Sections/AboutSection/AboutSection";
import VisionMissionSection from "./Sections/VisionMissionSection/VisionMissionSection";
import SetupSection from "./Sections/SetupSection/SetupSection";
import OurStudioSection from "./Sections/OurStudioSection/OurStudioSection";
import OurTeamSection from "./Sections/OurTeamSection/OurTeamSection";
import OurServicesSection from "./Sections/OurServicesSection/OurServicesSection";
import OurServicesCatSection from "./Sections/OurServicesCatSection/OurServicesCatSection";
import OurClientsSection from "./Sections/OurClientsSection/OurClientsSection";
import FooterSection from "./Sections/FooterSection/FooterSection";
import ServiceProjectsSection from "./Sections/ServiceProjectsSection/ServiceProjectsSection";
import ProjectSection from "./Sections/ProjectSection/ProjectSection";

import useWindowSize from "./hooks/useWindowSize";
import api from "./context/apiLink";
import {totalLength} from "./assets/svg/SvgJs/NewTubes/S4N";
import Menu from "./Components/Menu/Menu";

import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import {qs, qsa} from "./helpers/helpers";
import {LessMobileCheck, MobileCheck, TabletCheck,} from "./helpers/responsive";
import mobileCheck from "./hooks/mobileCheck";
import printConsole from "./hooks/printConsole";
import LoadingBarLoading from "./hooks/LoadingBarLoading";
import Loading from "./Sections/Loading";
import {isProd} from "./hooks/isDev";
import url from "./helpers/url";

import swipedetect from "./hooks/touchDir";
import OurServicesCatSectionV2 from "./Sections/OurServicesCatSection/OurServicesCatSectionV2";

if (isProd()) {
    ReactGA.initialize("G-9577QRMJ9K");
    ReactGA.pageview(window.location.pathname + window.location.search);
    // const advancedMatching = {em: "mina.sameh.lameh@gmail.com"};
    // const options = {
    //     autoConfig: true,
    //     debug: false,
    // };
    // ReactPixel.init("1093607401051802", advancedMatching, options);
    // ReactPixel.pageView();
}

function App() {

    useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('1093607401051802') // facebookPixelId
                ReactPixel.pageView()
            })
    }, [])

    const size = useWindowSize();
    const checkSite = false;
    let [myWidth, setMyWidth] = useState(0);
    let [myHeight, setMyHeight] = useState(0);

    const [preventAnimation, setPreventAnimation] = useState(0);

    useEffect(() => {
        printConsole();

        if (checkSite) return;
        if (mobileCheck()) {
            qs(".menu").classList.add("mobile");
        }

        if (size.height <= 720) {
            qs(".menu").classList.add("mobile-height");
        }

        if (window.location.pathname != "/") {
            window.history.pushState({}, "", `/`);
        }

        if (isProd()) {
            // const advancedMatching = {em: "mina.sameh.lameh@gmail.com"};
            // const options = {
            //     autoConfig: true,
            //     debug: false,
            // };
            // ReactPixel.init("1093607401051802", advancedMatching, options);
            // ReactPixel.pageView();
        }

        setQuote(getRandomQuote());

        let navbar = qs(".navbar");
        let sidebarIcon = qs(".sidebar-icon");
        let menu = qs(".menu");
        let ham = qs(".ham");
        sidebarIcon.addEventListener("click", (e) => {
            if (!navbar.classList.contains("open")) {
                // qs(".other-site") && qs(".other-site").classList.add("hidden")
                gsap.to(".other-site", 0.3, {
                    x: "10px", opacity: 0, onComplete: () => {
                        ham.classList.add("w");
                        ham.classList.add("active");
                        menu.classList.add("open");
                        navbar.classList.add("open");
                        gsap
                            .timeline()
                            .staggerFromTo(
                                ".menu .item span",
                                1,
                                {opacity: 0, y: -10},
                                {opacity: 1, y: 0},
                                0.1,
                                0
                            );
                        navbar.children[0].classList.add("default");
                        window.menu = true;
                    }
                }, 0);

            } else {
                window.closeMenu();
            }
        });
    }, []);

    useEffect(() => {
        if (myWidth === 0) {
            setMyWidth(size.width);
            setMyHeight(size.height);
        }
    }, [size.width]);

    function setWindowSize() {
        let changeWidth = () => {
            if (typeof window.innerWidth == "number") {
                setMyWidth(window.innerWidth);
                setMyHeight(window.innerHeight);
            } else {
                if (
                    document.documentElement &&
                    (document.documentElement.clientWidth ||
                        document.documentElement.clientHeight)
                ) {
                    setMyWidth(document.documentElement.clientWidth);
                    setMyHeight(document.documentElement.clientHeight);
                } else {
                    if (
                        document.body &&
                        (document.body.clientWidth || document.body.clientHeight)
                    ) {
                        setMyWidth(document.body.clientWidth);
                        setMyHeight(document.body.clientHeight);
                    }
                }
            }
        };
        if (!myWidth && window.innerWidth !== myWidth) {
            changeWidth();
        } else {
            if (window.innerWidth !== myWidth) window.location.reload();
        }
    }

    window.addEventListener("resize", setWindowSize);

    WebFont.load({
        custom: {
            families: [
                "Special Elite",
                "FuturaFuturis",
                "UniversLight",
                "OSAKA",
                "Montserrat",
                "Antonio",
            ],
        },
    });

    gsap.config({nullTargetWarn: false});

    window.closeMenu = () => {
        let navbar = qs(".navbar");
        let menu = qs(".menu");
        let ham = qs(".ham");
        ham.classList.remove("active");
        menu.classList.remove("open");
        ham.classList.remove("w");
        navbar.classList.remove("open");
        navbar.children[0].classList.remove("default");
        window.menu = false;
        // gsap.to(".other-site", 0.3, {x: "0px", autoAlpha: 1});
        setTimeout(() => {
            gsap.to(".other-site", 0.3, {x: "0px", autoAlpha: 1}, 0);
        }, 500)
    };

    document.addEventListener("keydown", (e) => {
        if (e.keyCode == 9 || e.which == 9) e.preventDefault();

        if (e.keyCode == 33 || e.which == 33) e.preventDefault();

        if (e.keyCode == 34 || e.which == 34) e.preventDefault();

        if (e.keyCode == 35 || e.which == 35) e.preventDefault();

        if (e.keyCode == 36 || e.which == 36) e.preventDefault();

        if (e.keyCode == 27 || e.which == 27) {
            if (window.menu) {
                window.closeMenu();
            }
        }
    });

    let [sections, setSections] = useState({});
    const apiLink = api;

    let [scrollA, setScroll] = React.useState({});

    let [currentService, setCurrentService] = useState({});
    let [currentServiceType, setCurrentServiceType] = useState("");
    let [currentProject, setCurrentProject] = useState({});

    let [quote, setQuote] = useState({});

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => {
        updateState({});
    }, []);
    const [setupTubeLength, setSetupTubeLength] = useState(5889.32568359375);

    const main = ".main";

    const getSectionsData = () => {
        axios.get(`${apiLink}/api/sections`).then(({data}) => {
            setSections(data);
        });
    };

    useEffect(() => {
        if (checkSite) return;
        getSectionsData();
    }, []);

    /* End Get Sectios data */

    /* Get Clients */
    let [clients, setClients] = useState({});
    const getClientsData = () => {
        axios.get(`${apiLink}/api/clients`).then(({data}) => {
            setClients(data);
        });
    };

    const visitApi = () => {
        axios.get(`${apiLink}/api/visit`).then(({data}) => {
            // console.log(data)
        });
    };

    useEffect(() => {
        if (checkSite) return;
        getClientsData();
        visitApi()
    }, []);

    let [projects, setProjects] = useState({});
    useEffect(() => {
        if (checkSite) return;

        async function getProjectsData() {
            const res = await axios.get(`${apiLink}/api/services`);
            setProjects(res.data);
            return res.data;
        }

        getProjectsData().then((a) => {
            setProjects(a);
        });
    }, []);

    React.useLayoutEffect(() => {
        Object.keys(projects).forEach((category) => {
            if (projects[category].length > 0) {
                projects[category].forEach((project) => {
                    let img = new Image();
                    img.src = `${apiLink}/public/images/${category}/${project.title}/${project.main_photo}`;
                });
            }
        });
        window.projectsAll = projects;
    }, [projects]);

    React.useEffect(() => {
        if (Object.keys(projects).length === 0) return;
        Object.keys(projects).forEach((a) => {
            if (projects[a].length == 1) {
                let pro = projects[a][0];
                let preloader = require("preloader");
                let loader = preloader({
                    xhrImages: false,
                });
                let len = pro["project_photos"].length;
                for (let i = 0; i < len; i++) {
                    let imag = url(
                        api,
                        a,
                        pro.title,
                        pro["project_photos"][i]["photo_link"]
                    );
                    loader.add(imag);
                }
                loader.load();
            }
        });
    }, [projects]);

    let scroll;
    useEffect(() => {
        if (checkSite) return;

        let arrow = qs(".SectionLogo .tube .arrow");
        let arrowStyle = window.getComputedStyle(arrow);


        gsap
            .fromTo(
                arrow,
                1,
                {left: parseInt(arrowStyle["left"])},
                {left: parseInt(arrowStyle["left"]) - 20},
                0
            )
            .yoyo(true)
            .repeat(-1);

        let titles = qsa(".page .title");
        titles.forEach((title) => {
            let x = title.querySelector("h3")?.cloneNode(true);
            if (x != undefined) {
                x.classList.add("fill-text");
                title.appendChild(x);
            }
        });

        gsap.registerPlugin(
            ScrollTrigger,
            DrawSVGPlugin,
            ScrollToPlugin,
            CSSRulePlugin
        );
        scroll = new locomotiveScroll({
            el: qs(main),
            smooth: true,
            smartphone: {
                smooth: true,
            },
            smoothMobile: 1,
            multiplier: 0.4,
            firefoxMultiplier: 5,
            touchMultiplier: 5,
            // smoothMobile: true,
        });
        window.locomotiveScroll = scroll;

        (!process.env.NODE_ENV || process.env.NODE_ENV !== "development") && scroll.stop();

        scroll.on("scroll", ScrollTrigger.update);
        ScrollTrigger.scrollerProxy(main, {
            scrollTop(value) {
                return arguments.length
                    ? scroll.scrollTo(value, 0, 0)
                    : scroll.scroll.instance.scroll.y;
            },
            getBoundingClientRect() {
                return {
                    top: 0,
                    left: 0,
                    width: window.innerWidth,
                    height: window.innerHeight,
                };
            },
            pinType: qs(main).style.transform ? "transform" : "fixed",
        });

        ScrollTrigger.defaults({
            markers: false,
            scroller: main,
            ease: "ease",
        });

        scroll.on("scroll", (e) => {
            window.y = e.delta.y
        });

        const lights = [
            "lightFooter",
            "lightAbout",
            "lightOurStudio",
        ];

        lights.forEach((light) => {
            let ttt1 = gsap.timeline({repeatDelay: 2, repeat: -1});
            ttt1.fromTo(`.${light}`, 0.1, {autoAlpha: 0}, {autoAlpha: 1}, 0);
            ttt1.fromTo(`.${light}`, 0.1, {autoAlpha: 0}, {autoAlpha: 1}, 0.2);
        });

        // let ttt1 = gsap.timeline({repeatDelay: 2, repeat: -1});
        let ttt1 = gsap.timeline({repeatDelay: 2, repeat: -1});
        ttt1.fromTo(".lightOurTeam", 0.1, {autoAlpha: 0}, {autoAlpha: 0.5}, 0);
        ttt1.fromTo(".lightOurTeam", 0.1, {autoAlpha: 0}, {autoAlpha: 0.5}, 0.2);

        function code(e) {
            e = e || window.event;
            return e.keyCode || e.which;
        }

        let oldScroll;
        let servicesAll = qs(".services-all");

        window.addEventListener("wheel", (e) => {
            if (window.lc) {
                if (e.deltaY > 0) {
                    scroll.start();
                } else {
                    if (servicesAll.getBoundingClientRect().top > 20) {
                        scroll.stop();
                    }
                }
            }
        });

        swipedetect(document, (dir) => {
            if (dir === "up") {
                if (window.lc) {
                    scroll.start();
                }
            }
        });

        document.body.addEventListener(
            "swu",
            () => {
                if (window.lc) {
                    scroll.start();
                }
            },
            false
        );

        document.body.addEventListener(
            "swd",
            () => {
                if (window.lc) {
                    if (servicesAll.getBoundingClientRect().top > 20) {
                        scroll.stop();
                    }
                }
            },
            false
        );

        window.addEventListener("keydown", (e) => {
            if (window.lc) {
                if (code(e) === 40) {
                    scroll.start();
                } else if (code(e) === 38) {
                    if (servicesAll.getBoundingClientRect().top > 20) {
                        scroll.stop();
                    }
                }
            }
        });

        scroll.on("scroll", (args) => {
            let scrollY = args.scroll.y;
            if (!oldScroll) oldScroll = scrollY;
            let pages = qsa(".main > .page, .pin-spacer-setup, .services-all");

            let servicesAll = qs(".services-all");
            let items = qsa(".menu .item");

            pages.forEach((page, key) => {
                if (
                    page.offsetTop <= scrollY + 68 &&
                    page.offsetTop + page.offsetHeight > scrollY + 68 &&
                    !window.lc
                ) {
                    items.forEach((item) => {
                        item.classList.remove("active");
                    });
                    items[key].classList.add("active");

                    if (!window.menu) {
                        if (page.hasAttribute("data-menu")) {
                            let lines = qsa(".ham .line");
                            lines.forEach((line) => {
                                line.style.opacity = "1";
                                line.style.stroke = page.getAttribute("data-menu-color");
                            });
                            qs(".other-site a").style.color = page.getAttribute("data-menu-color");
                            // let bars = qsa(
                            //   ".navbar .sidebar-icon .bar"
                            // );
                            // bars.forEach((bar) => {
                            //   bar.style.opacity = "1";
                            //   bar.style.background = page.getAttribute("data-menu-color");
                            // });
                        } else {
                            // let bars = qsa(
                            //   ".navbar .sidebar-icon .bar"
                            // );
                            // bars.forEach((bar) => {
                            //   bar.style.opacity = "1";
                            //   bar.style.background = "#fff";
                            // });
                            let lines = qsa(".ham .line");
                            lines.forEach((line) => {
                                line.style.opacity = "1";
                                line.style.stroke = "#fff";
                            });
                            qs(".other-site a").style.color = "#fff";
                        }
                    }
                }
            });
        });

        return () => {
            if (scroll) {
                scroll.destroy()
            }
        }
    }, []);

    let leftShapes = -270;
    if (TabletCheck()) {
        leftShapes = -314;
    } else if (MobileCheck()) {
        leftShapes = -365;
    } else if (LessMobileCheck()) {
        leftShapes = -450;
    }

    useEffect(() => {
        if (checkSite) return;

        window.locomotiveScroll.update();
        setSetupTubeLength(totalLength);
        var keys = {37: 1, 38: 1, 39: 1, 40: 1};

        function preventDefault(e) {
            e.preventDefault();
        }

        function preventDefaultForScrollKeys(e) {
            if (keys[e.keyCode]) {
                preventDefault(e);
                return false;
            }
        }

        let supportsPassive = false;
        try {
            window.addEventListener(
                "test",
                null,
                Object.defineProperty({}, "passive", {
                    get: function () {
                        supportsPassive = true;
                    },
                })
            );
        } catch (e) {
        }

        var wheelOpt = supportsPassive ? {passive: false} : false;
        var wheelEvent =
            "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

        function disableScroll() {
            window.addEventListener("DOMMouseScroll", preventDefault, false);
            window.addEventListener(wheelEvent, preventDefault, wheelOpt);
            window.addEventListener("touchmove", preventDefault, wheelOpt);
            window.addEventListener("keydown", preventDefaultForScrollKeys, false);
            scroll.stop();
        }

        function enableScroll() {
            window.removeEventListener("DOMMouseScroll", preventDefault, false);
            window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
            window.removeEventListener("touchmove", preventDefault, wheelOpt);
            window.removeEventListener("keydown", preventDefaultForScrollKeys, false);
            scroll.start();
        }

        var tl = gsap.timeline();
        window.tl = tl;
        if (size.width <= 479) {
            gsap
                .timeline({
                    defaults: {duration: 1},
                    scrollTrigger: {
                        trigger: ".SectionLogo",
                        scrub: true,
                        triggerHook: 0,
                        start: "top top",
                        end: "bottom+=80 top",
                        ease: "none",
                    },
                })
                .fromTo(".tubefill.s1", {drawSVG: "5%"}, {drawSVG: "100%"}, 0);
            gsap
                .timeline({
                    defaults: {duration: 1},
                    scrollTrigger: {
                        trigger: ".sectionAbout",
                        scrub: "20",
                        triggerHook: 0,
                        start: "top-=20 top",
                        end: "bottom-=50 top",
                        ease: "none",
                    },
                })
                .fromTo(".tubefill.s2", {drawSVG: "0%"}, {drawSVG: "100%"}, 0);
        } else {
            gsap
                .timeline({
                    defaults: {duration: 1},
                    scrollTrigger: {
                        trigger: ".SectionLogo",
                        scrub: true,
                        triggerHook: 0,
                        start: "top top",
                        end: "bottom+=80 top",
                        ease: "none",
                    },
                })
                .fromTo(
                    ".tubefill.s1",
                    {
                        strokeDasharray: qs(".tubefill.s1").getTotalLength() + "px",
                        strokeDashoffset: qs(".tubefill.s1").getTotalLength() + 10 + "px",
                    },
                    {
                        strokeDashoffset: qs(".tubefill.s1").getTotalLength() * 2 + "px",
                    },
                    0
                );
        }

        if (size.width >= 768 && size.width < 1024) {
            gsap
                .timeline({
                    defaults: {duration: 1},
                    scrollTrigger: {
                        trigger: ".sectionAbout",
                        scrub: "20",
                        triggerHook: 0,
                        start: "top-=20 top",
                        end: "bottom-=50 top",
                        ease: "none",
                    },
                })
                .fromTo(".tubefill.s2", {drawSVG: "0%"}, {drawSVG: "100%"}, 0);
        }

        gsap
            .timeline({
                defaults: {duration: 1},
                scrollTrigger: {
                    trigger: ".sectionVisionMission",
                    scrub: "20",
                    triggerHook: 0,
                    start: "top-=20 top",
                    end: "bottom+=50 top",
                    ease: "none",
                },
            })
            .fromTo(".tubefill.s3", {drawSVG: "0%"}, {drawSVG: "100%"}, 0);

        let setup = qs(".sectionSetup");
        let ani = qs(".setupOurStudio .ani");
        let setupWidth = setup == null ? 5000 : setup.offsetWidth;
        gsap
            .timeline({
                defaults: {duration: 100},
                scrollTrigger: {
                    trigger: ".setupOurStudio",
                    scrub: true,
                    triggerHook: 0,
                    start: "top top",
                    id: "setup",
                    ease: "none",
                    pin: true,
                    anticipatePin: 0,
                    end: setupWidth,
                },
            })
            .fromTo(
                ".tubefill.s4",
                {
                    strokeDasharray: setupTubeLength + "px",
                    strokeDashoffset: setupTubeLength * -1 + "px",
                },
                {
                    strokeDashoffset:
                        (setupTubeLength - setupTubeLength * 0.95) * -1 + "px",
                },
                0
            )

            .to(
                ".sectionSetup .shapes",
                {
                    left: `${leftShapes}px`,
                },
                8.2
            )
            .to(
                ".setupOurStudio .ani",
                {
                    x:
                        size.width < 480
                            ? (ani.offsetWidth - (size.width - (size.width - 320) / 2)) * -1 +
                            "px"
                            : (ani.offsetWidth - size.width) * -1 + "px",
                },
                4
            );

        if (size.width <= 479) {
            gsap
                .timeline({
                    defaults: {duration: 1.5},
                    scrollTrigger: {
                        trigger: ".sectionOurSudio",
                        scrub: true,
                        triggerHook: 0,
                        id: "studio",
                        start: "top-=20 bottom",
                        end: "bottom-=50 top",
                        ease: "none",
                    },
                })
                .fromTo(
                    ".tubefill.s4",
                    {
                        strokeDashoffset:
                            (setupTubeLength - setupTubeLength * 0.95) * -1 + "px",
                    },
                    {
                        strokeDashoffset: "10px",
                    },
                    0
                )
                .fromTo(".tubefill.s5", {drawSVG: "0%"}, {drawSVG: "100%"}, 1.1)
                .fromTo(
                    ".sectionOurSudio .title .fill-text",
                    0.2,
                    {
                        height: "0%",
                    },
                    {
                        height: "100%",
                    },
                    1.3
                );
        } else {
            gsap
                .timeline({
                    defaults: {duration: 1.5},
                    scrollTrigger: {
                        trigger: ".sectionOurSudio",
                        scrub: true,
                        triggerHook: 0,
                        id: "studio",
                        start: "top-=20 bottom",
                        end: "bottom-=50 top",
                        ease: "none",
                    },
                })
                .fromTo(
                    ".tubefill.s4",
                    {
                        strokeDashoffset:
                            (setupTubeLength - setupTubeLength * 0.95) * -1 + "px",
                    },
                    {
                        strokeDashoffset: "10px",
                    },
                    0
                )
                .fromTo(
                    ".tubefill.s5",
                    {
                        strokeDasharray: qs(".tubefill.s5").getTotalLength() + 5 + "px",
                        strokeDashoffset: qs(".tubefill.s5").getTotalLength() + "px",
                    },
                    {
                        strokeDashoffset: qs(".tubefill.s5").getTotalLength() * 2 + "px",
                    },
                    1.1
                )
                .fromTo(
                    ".sectionOurSudio .title .fill-text",
                    0.2,
                    {
                        height: "0%",
                    },
                    {
                        height: "100%",
                    },
                    1.3
                );
        }

        if (size.width <= 479) {
            gsap
                .timeline({
                    defaults: {duration: 3},
                    scrollTrigger: {
                        trigger: ".sectionOurTeam",
                        scrub: true,
                        triggerHook: 0,
                        start: "top-=80 top",
                        end: "bottom-=50 top",
                        ease: "none",
                    },
                })
                .fromTo(".tubefill.s6", {drawSVG: "0%"}, {drawSVG: "100%"}, 0)
                .fromTo(
                    ".sectionOurTeam .title .fill-text",
                    0.15,
                    {
                        height: "0%",
                    },
                    {
                        height: "100%",
                    },
                    0.35
                );
        } else {
            gsap
                .timeline({
                    defaults: {duration: 3},
                    scrollTrigger: {
                        trigger: ".sectionOurTeam",
                        scrub: true,
                        triggerHook: 0,
                        start: "top-=80 top",
                        end: "bottom-=50 top",
                        ease: "none",
                    },
                })
                .fromTo(".tubefill.s6", {drawSVG: "0%"}, {drawSVG: "100%"}, 0)
                .fromTo(
                    ".sectionOurTeam .title .fill-text",
                    0.15,
                    {
                        height: "0%",
                    },
                    {
                        height: "100%",
                    },
                    0.1
                );
        }

        gsap
            .timeline({
                defaults: {duration: 3},
                scrollTrigger: {
                    trigger: ".sectionOurServices",
                    scrub: true,
                    triggerHook: 0,
                    start: "top-=100 top",
                    end: "bottom-=50 top",
                    ease: "ease",
                },
            })
            .fromTo(".tubefill.s7", {drawSVG: "0%"}, {drawSVG: "100%"}, 0)
            .fromTo(
                ".sectionOurServices .title .fill-text",
                0.2,
                {
                    height: "0%",
                },
                {
                    height: "100%",
                },
                0.1
            );
        let servicesAnimation = gsap
            .timeline({
                defaults: {duration: 1},
                scrollTrigger: {
                    trigger: ".sectionOurServicesCat",
                    scrub: true,
                    triggerHook: 0,
                    onUpdate: (e) => {
                        window.ev = e;
                        // if (window.lc) {
                        //     // e.disable();
                        // } else {
                        //     // e.enable();
                        // }
                    },
                    start: "top-=120 top",
                    end: "bottom-=50 top",
                    ease: "none",
                },
            })
            .fromTo(".tubefill.s8", {drawSVG: "0%"}, {drawSVG: "100%"}, 0);
        window.servicesAnimation = servicesAnimation;
        gsap
            .timeline({
                defaults: {duration: 3},
                scrollTrigger: {
                    trigger: ".sectionOurClients",
                    scrub: true,

                    triggerHook: 0,
                    start: "top-=120 top",
                    end: "bottom-=50 top",
                    ease: "none",
                },
            })
            .fromTo(".tubefill.s9", {drawSVG: "0%"}, {drawSVG: "100%"}, 0)
            .fromTo(
                ".sectionOurClients .title .fill-text",
                0.2,
                {
                    height: "0%",
                },
                {
                    height: "100%",
                },
                0.35
            );
        gsap
            .timeline({
                defaults: {duration: 3},
                scrollTrigger: {
                    trigger: ".sectionFooter",
                    scrub: true,
                    id: true,
                    triggerHook: 0,
                    start: `top-=100 top`,
                    end: "bottom bottom",
                    ease: "none",
                },
            })
            .fromTo(".tubefill.s10", {drawSVG: "0%"}, {drawSVG: "100%"}, 0)
            .fromTo(
                ".sectionFooter .title .fill-text",
                0.5,
                {
                    height: "0%",
                },
                {
                    height: "100%",
                },
                2.5
            );

        let icons = qsa(".service-path .icon");

        let tubes = qsa(".tubefill.servicepath");
        let tubesLeft = qsa(".tube-left path.tubefill");

        let mainTubePath = qs(".tube.main-tube path.tubefill.s8");

        tubes.forEach((e) => {
            gsap.set(e, {drawSVG: "0%"}, 0);
        });
        tubesLeft.forEach((e) => {
            gsap.set(e, {drawSVG: "0%"}, 0);
        });

        let servicesCat = qs(".sectionOurServicesCat");
        let topoficons = [105, 250, 363, 417, 285, 200];
        let c = [
            0.13671875,
            0.32552083333333333333333333333333,
            0.47265625,
            0.54296875,
            0.37109375,
            0.26041666666666666666666666666667,
        ];

        icons.forEach((e, kke) => {
            let products;
            e.addEventListener(
                "click",
                (a) => {
                    if (window.ani) return false;
                    window.lc = true;

                    if (window.locomotiveScroll) {
                        if (typeof window.locomotiveScroll.stop != "undefined")
                            window.locomotiveScroll.stop();
                    }

                    qs(".menu") && qs(".menu").classList.add("hidden");
                    qs(".navbar") && qs(".navbar").classList.add("hidden");

                    let serivceAnd = qs(".serivceAnd");
                    serivceAnd.style.display = "block";

                    let lines = qsa(".ham .line");
                    qs(".other-site").style.opacity = "0";
                    lines.forEach((line) => {
                        line.style.opacity = "0";
                    });

                    let type = e.parentNode.querySelector(".ser-name").textContent;
                    let str = type.replace(/\s+/g, "-").toLowerCase();

                    let dataType = e.parentNode.getAttribute("data-type");
                    window.currentType = dataType
                    // console.log(window.projectsAll, dataType, window.projectsAll[dataType])
                    window.history.pushState({}, "", `/${str}`);
                    setCurrentService(window.projectsAll[dataType]);
                    setCurrentServiceType(dataType);

                    let tube = e.parentNode.querySelector(".tube");
                    let tubeLeft = e.parentNode.querySelector(".tube-left path.tubefill");
                    let tubePath = e.parentNode.querySelector(".tube svg path.tubefill");

                    let level = e.parentNode.getAttribute("data-level");

                    let projects = qs(".sectionServiceProducts");
                    let project = qs(".sectionServiceProduct");
                    let projectsTitle = projects.querySelector(".content .title");
                    let ProjectArrow = projects.querySelector(".arrow");
                    let projectstube = projects.querySelector(".tube");

                    let servicesAll = qs(".services-all");
                    let per =
                        e.parentNode.getAttribute("data-per") == null
                            ? 0.5
                            : e.parentNode.getAttribute("data-per");

                    let perOrg =
                        e.parentNode.getAttribute("data-per") == null
                            ? 0.5
                            : e.parentNode.getAttribute("data-per");

                    if (size.height > 768) {
                        let pper = per * 768;
                        let aa = size.height * c[kke] - topoficons[kke];
                        per = ((pper + aa) / size.height) * 100 + "%";

                        let cca = {
                            branding: 0.13671875,
                            graphic: 0.32552083333333333333333333333333,
                            web: 0.47265625,
                            printing: 0.54296875,
                            media: 0.37109375,
                            social: 0.26041666666666666666666666666667,
                        };

                        let ca = {
                            branding: 55,
                            graphic: 105,
                            web: 205,
                            printing: 204,
                            media: 128,
                            social: 15,
                        };
                        projectstube.style.top =
                            servicesAll.offsetHeight * cca[dataType] + ca[dataType] + "px";
                    } else {
                        per = per * 100 + "%";
                    }

                    e.parentNode.classList.add("hoverd");
                    e.parentNode.classList.add("clicked");

                    let logo = qs(".SectionLogo");
                    let about = qs(".sectionAbout");
                    let upProject = qs(".up_project");
                    let vi_mi = qs(".sectionVisionMission");
                    let setup = qs(".setupOurStudio");
                    let ourTeam = qs(".sectionOurTeam");
                    let ourServices = qs(".sectionOurServices");
                    let sectionOurSudio = qs(".sectionOurSudio");
                    let ourClients = qs(".sectionOurClients");
                    let footer = qs(".sectionFooter");
                    let main = qs(".main");

                    servicesAll.classList.add("project-open");

                    project.classList.remove("right", "left");

                    let dir;
                    if (e.parentNode.classList.contains("right")) {
                        projects.style.right = "2px";
                        projects.style.left = "auto";
                        projectstube.className = `tube level${level} left`;
                        dir = "r";
                        project.classList.add("right");
                    } else if (e.parentNode.classList.contains("left")) {
                        projects.style.left = "-100vw";
                        projects.style.right = "auto";
                        projectstube.className = `tube level${level} right`;
                        dir = "l";
                        project.classList.add("left");
                    }

                    if (window.projectsAll) {
                        window.projectsAll[dataType].length === 1 &&
                        projectstube.classList.add("hidden");
                    }

                    projectsTitle.querySelector("h3").textContent = type;

                    projects.setAttribute("data-level", level);
                    projects.setAttribute("data-service", type);
                    projects.setAttribute("data-dir", dir == "r" ? "right" : "left");
                    let tubeProjects = qs(".sectionServiceProducts .tube");

                    let paths = qsa(".products_tube_path");
                    let h;
                    h = projects.offsetHeight;

                    if (h <= size.height) {
                        h = size.height;
                    }

                    h -= tubeProjects.offsetTop;

                    paths[0].parentElement.setAttribute(
                        "viewBox",
                        `0 0 77.967 ${h + 30}`
                    );
                    paths[0].parentElement.style.height = h + 30;
                    for (let patha of paths) {
                        patha.style.d = `path('M7.5 7.536l32.967-.034c16.5 0 30 13.018 30 28.932l-.002 ${h}')`;
                    }

                    gsap
                        .timeline({
                            defaults: {duration: 1},
                            scrollTrigger: {
                                trigger: ".sectionServiceProducts",
                                scrub: 0,
                                triggerHook: 0,
                                start: `top+=${tubeProjects.offsetTop} top`,
                                end: "bottom bottom",
                                ease: "none",
                            },
                        })
                        .fromTo(
                            ".products_tube_path.tubefillprojects",
                            {drawSVG: "0%"},
                            {drawSVG: "100% live"},
                            0
                        );

                    window.ani = true;

                    disableScroll();
                    let prevProgress;
                    let goToProjects = (callback = null) => {
                        window.midTube = mainTubePath;
                        let pp = `${
                            parseFloat(window.servicesAnimation.progress()) * 100
                        }%`;
                        prevProgress = pp;
                        let timeIn = 0.5
                        // if (size.width < 480) timeIn = 0.25
                        gsap.fromTo(
                            mainTubePath,
                            timeIn,
                            {drawSVG: pp},
                            {
                                drawSVG: per,
                                onComplete: () => {
                                    window.per = per;
                                    gsap.set(tube, {className: "tube"}, 0);
                                    gsap.to(
                                        tubePath,
                                        timeIn,
                                        {
                                            drawSVG: "100%",
                                            onComplete: () => {

                                                if (callback != null) {
                                                    callback()
                                                }

                                                gsap.fromTo(
                                                    tubeLeft,
                                                    timeIn,
                                                    {drawSVG: "0%"},
                                                    {drawSVG: "-100%"},
                                                    0
                                                );
                                                ourTeam.style.visibility = "hidden";
                                                gsap.to(
                                                    ".services-all .ani",
                                                    0.5,
                                                    {
                                                        xPercent: dir == "r" ? -50 : 50,
                                                        onComplete: () => {
                                                            // size.width < 480
                                                            //     ? (servicesAll.style.cssText =
                                                            //     (projects.offsetHeight > size.height
                                                            //         ? `height: ${projects.offsetHeight}`
                                                            //         : `height: ${size.height}`) +
                                                            //     "px !important")
                                                            //     : (servicesAll.style.height =
                                                            //     (projects.offsetHeight > size.height
                                                            //         ? projects.offsetHeight
                                                            //         : size.height) + "px");

                                                            servicesAll.style.height =
                                                                (projects.offsetHeight > size.height
                                                                    ? projects.offsetHeight
                                                                    : size.height) + "px"

                                                            upProject.style.display = "block";
                                                            logo.style.visibility = "hidden";
                                                            vi_mi.style.visibility = "hidden";
                                                            about.style.visibility = "hidden";
                                                            setup.style.visibility = "hidden";
                                                            ourTeam.style.visibility = "hidden";
                                                            ourServices.style.visibility = "hidden";
                                                            gsap.set(ourServices, {left: "100%"}, 0);
                                                            sectionOurSudio.style.visibility = "hidden";
                                                            ourClients.style.display = "none";
                                                            footer.style.display = "none";
                                                            window.toProduct =
                                                                main.offsetHeight - servicesAll.offsetHeight;
                                                            scroll.update();
                                                            setTimeout(() => {
                                                                window.ani = false;
                                                                scroll.update();
                                                            }, 1000);
                                                            setTimeout(() => {
                                                                enableScroll();
                                                            }, 500);
                                                        },
                                                    },
                                                    0
                                                );
                                            },
                                        },
                                        0
                                    );
                                },
                            },
                            0
                        );
                    };

                    // if (size.width < 480) {
                    //     if (["branding"].indexOf(dataType) > -1) {
                    //         scroll.scrollTo(servicesCat, 2, () => {
                    //             goToProjects();
                    //         });
                    //
                    //     } else if (["printing"].indexOf(dataType) > -1) {
                    //         let scroltt =
                    //             servicesCat.getBoundingClientRect().top +
                    //             window.locomotiveScroll.scroll.instance.delta.y +
                    //             (servicesCat.offsetHeight - size.height)
                    //         scroll.scrollTo(scroltt, 2, () => {
                    //             goToProjects();
                    //         });
                    //     } else {
                    //         let scroltt =
                    //             servicesCat.getBoundingClientRect().top +
                    //             window.locomotiveScroll.scroll.instance.delta.y;
                    //         scroll.scrollTo(scroltt, (servicesCat.offsetHeight * perOrg) - (size.height / 2), () => {
                    //             goToProjects();
                    //         });
                    //     }
                    // } else {
                    //     window.locomotiveScroll.stop();
                    //     if (Math.abs(servicesCat.getBoundingClientRect().top) < 5) {
                    //         goToProjects();
                    //     } else {
                    //         // let serivcesTop = servicesAll.offsetTop;
                    //         // if (serivcesTop !== 0) {
                    //         //     scroll.scroll.stopScrolling();
                    //         //     setTimeout(() => {
                    //         //         main.style.cssText = `transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -${serivcesTop}, 0, 1); transition: all 0.2s ease;`;
                    //         //         setTimeout(() => {
                    //         //             console.log("run");
                    //         //             goToProjects(() => {
                    //         //                 scroll.setScroll(0, serivcesTop);
                    //         //             });
                    //         //         }, 500)
                    //         //     }, 500)
                    //         // } else {
                    //         //     scroll.scrollTo(servicesCat, 2, () => {
                    //         //         goToProjects();
                    //         //     });
                    //         // }
                    //         // gsap.to(main, 1, {
                    //         //     force3D: true,
                    //         //     transform: `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -${serivcesTop}, 0, 1)`,
                    //         //     onComplete: () => {
                    //         //         // scroll.setScroll(0, serivcesTop);
                    //         //         goToProjects();
                    //         //     }
                    //         // }, 0)
                    //
                    //         scroll.scrollTo(servicesCat, 1, () => {
                    //             let x = setInterval(() => {
                    //                 if (Math.abs(servicesAll.offsetTop - Math.ceil(window.y)) <= 5) {
                    //                     scroll.scroll.stopScrolling();
                    //                     window.pp = `${
                    //                         parseFloat(window.servicesAnimation.progress()) * 100
                    //                     }%`;
                    //                     goToProjects();
                    //                     clearInterval(x);
                    //                 }
                    //             }, 50)
                    //             // scroll.on("scroll", (e) => {
                    //             //     if (Math.abs(servicesAll.offsetTop - Math.ceil(e.delta.y)) <= 3) {
                    //             //         window.goingToCategory = false
                    //             //         scroll.scroll.stopScrolling();
                    //             //         goToProjects();
                    //             //     }
                    //             // });
                    //             // if (Math.abs(servicesAll.offsetTop - Math.ceil(e.delta.y)) <= 3) {
                    //             //
                    //             // }
                    //
                    //         });
                    //     }
                    // }

                    window.locomotiveScroll.stop();
                    if (Math.abs(servicesCat.getBoundingClientRect().top) < 5) {
                        goToProjects();
                    } else {
                        // let serivcesTop = servicesAll.offsetTop;
                        // if (serivcesTop !== 0) {
                        //     scroll.scroll.stopScrolling();
                        //     setTimeout(() => {
                        //         main.style.cssText = `transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -${serivcesTop}, 0, 1); transition: all 0.2s ease;`;
                        //         setTimeout(() => {
                        //             console.log("run");
                        //             goToProjects(() => {
                        //                 scroll.setScroll(0, serivcesTop);
                        //             });
                        //         }, 500)
                        //     }, 500)
                        // } else {
                        //     scroll.scrollTo(servicesCat, 2, () => {
                        //         goToProjects();
                        //     });
                        // }
                        // gsap.to(main, 1, {
                        //     force3D: true,
                        //     transform: `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -${serivcesTop}, 0, 1)`,
                        //     onComplete: () => {
                        //         // scroll.setScroll(0, serivcesTop);
                        //         goToProjects();
                        //     }
                        // }, 0)

                        scroll.scrollTo(servicesCat, 1, () => {
                            let x = setInterval(() => {
                                if (Math.abs(servicesAll.offsetTop - Math.ceil(window.y)) <= 5) {
                                    scroll.scroll.stopScrolling();
                                    window.pp = `${
                                        parseFloat(window.servicesAnimation.progress()) * 100
                                    }%`;
                                    goToProjects();
                                    clearInterval(x);
                                }
                            }, 50)
                            // scroll.on("scroll", (e) => {
                            //     if (Math.abs(servicesAll.offsetTop - Math.ceil(e.delta.y)) <= 3) {
                            //         window.goingToCategory = false
                            //         scroll.scroll.stopScrolling();
                            //         goToProjects();
                            //     }
                            // });
                            // if (Math.abs(servicesAll.offsetTop - Math.ceil(e.delta.y)) <= 3) {
                            //
                            // }

                        });
                    }

                    products = qsa(".sectionServiceProducts .products > *");
                    products.forEach((product) => {

                        product.addEventListener("click", (e) => {
                            if (window.projectLoading) return;
                            window.projectLoading = true;
                            let productIndex = product.getAttribute("data-index");

                            window.project = true;

                            let heightOfProductPre = 0;
                            let sectionServiceProduct = qs(".sectionServiceProduct");
                            let servicesAll = qs(".services-all");
                            let service = qs(".sectionServiceProducts");

                            let serviceTop =
                                service.getBoundingClientRect().top +
                                scroll.scroll.instance.delta.y +
                                (service.offsetHeight < window.innerHeight
                                    ? window.innerHeight
                                    : service.offsetHeight);

                            window.isProjectOpen = true;
                            // console.log(window.currentType)
                            setCurrentProject(window.projectsAll[window.currentType][productIndex]);
                            document.title =
                                "Creative Twinkles" +
                                ` | ${window.projectsAll[window.currentType][productIndex]["title"]}`;
                            window.history.pushState(
                                {},
                                document.title +
                                ` | ${window.projectsAll[window.currentType][productIndex]["title"]}`,

                                `/${window.currentType}/${window.projectsAll[window.currentType][productIndex]["project_link"]}`
                            );

                            let paths = qsa(".product_tube_path");
                            let myPath = qs(".tubefillproject");
                            let windowHeight = size.height;
                            paths[0].parentElement.setAttribute(
                                "viewBox",
                                `0 0 15.002 ${windowHeight + 5}`
                            );
                            paths[0].parentElement.style.height = windowHeight + 5;
                            for (let path of paths) {
                                path.style.d = `path('M 7.502 7.5 L 7.5 ${windowHeight + 3}')`;
                            }
                            paths[1].style.strokeDasharray = paths[1].getTotalLength();
                            paths[1].style.strokeDashoffset = paths[1].getTotalLength();

                            myPath = qs(".tubefillproject");
                            var length = myPath.getTotalLength();
                            myPath.style.strokeDasharray = length + "px";
                            myPath.style.strokeDashoffset = length + "px";

                            let proHei = sectionServiceProduct.offsetHeight;

                            scroll.on("scroll", (e) => {
                                fixedTube =
                                    sectionServiceProduct.getBoundingClientRect().top > 0
                                        ? 0
                                        : sectionServiceProduct.getBoundingClientRect().top;
                                gsap.set(
                                    ".sectionServiceProduct .tube",
                                    {top: fixedTube * -1 - 5},
                                    0
                                );

                                if (fixedTube <= 0) {
                                    proHei = qs(".sectionServiceProduct").offsetHeight;
                                    let nsba = (fixedTube * -1) / proHei;
                                    paths[1].style.strokeDashoffset =
                                        paths[1].getTotalLength() -
                                        nsba * paths[1].getTotalLength();
                                }
                            });
                        });
                    });

                    ProjectArrow.addEventListener("click", () => {

                        if (window.projectLoading) return;

                        // let pp = `${
                        //     parseFloat(window.servicesAnimation.progress()) * 100
                        // }%`;

                        if (window.ev) {
                            window.ev.disable();
                        }

                        window.locomotiveScroll && scroll.stop();
                        qs(".menu").classList.remove("hidden");
                        qs(".navbar").classList.remove("hidden");

                        let videos = qsa(
                            ".sectionServiceProduct .content .project .presentation video"
                        );

                        let presentation = qs(
                            ".sectionServiceProduct .content .project .presentation"
                        );

                        presentation.innerHeight = "";

                        if (videos.length) {
                            videos.forEach((video) => {
                                video.pause();
                                video.currentTime = 0;
                            });
                        }

                        window.history.pushState({}, "", "/");
                        window.ani = true;

                        document.title = "Creative Twinkles";

                        let mainbars = qsa("main > .navbar .sidebar-icon .bar");
                        let serivceAnd = qs(".serivceAnd");
                        mainbars.forEach((bar) => {
                            bar.style.opacity = "1";
                        });

                        servicesAll.style.height = size.height + "px";
                        servicesAll.classList.remove("project-open");

                        logo.style.visibility = "visible";
                        setup.style.visibility = "visible";
                        vi_mi.style.visibility = "visible";
                        ourServices.style.visibility = "visible";
                        about.style.visibility = "visible";
                        ourTeam.style.visibility = "visible";
                        sectionOurSudio.style.visibility = "visible";
                        footer.style.display = "block";

                        let per = document
                            .querySelector(".service-path.clicked")
                            .getAttribute("data-per");

                        if (size.height > 768) {
                            let pper = per * 768;
                            let aa = size.height * c[kke] - topoficons[kke];
                            per = ((pper + aa) / size.height) * 100 + "%";
                        } else {
                            per = per * 100 + "%";
                        }

                        gsap.set(
                            mainTubePath,
                            {
                                drawSVG: per,
                            },
                            0
                        );
                        disableScroll();

                        function backToProjects() {
                            let timeOut = 0.5
                            if (size.width < 768) timeOut = 0.25;
                            gsap
                                .timeline({
                                    defaults: {duration: 1},
                                })
                                .to(
                                    ".services-all .ani",
                                    0.5,
                                    {
                                        ease: Power0.easeNone,
                                        xPercent: 0,
                                        onUpdate: () => {
                                            window.ev.disable();
                                            gsap.set(
                                                mainTubePath,
                                                {
                                                    drawSVG: per,
                                                },
                                                0
                                            );
                                        },
                                        onComplete: () => {
                                            // if (size.width < 480) {
                                            //     servicesAll.style.removeProperty("height");
                                            // }
                                            ourClients.style.display = "block";
                                            serivceAnd.style.display = "none";
                                            let tubeLeft = qs(
                                                ".tubes .clicked .tube-left path.tubefill"
                                            );
                                            gsap.fromTo(
                                                tubeLeft,
                                                timeOut,
                                                {
                                                    onComplete: () => {
                                                        icons.forEach((icon) => {
                                                            icon.parentNode.classList.remove("clicked");
                                                            icon.parentNode.classList.remove("hoverd");
                                                        });
                                                    },
                                                    drawSVG: "-100%",
                                                },
                                                {
                                                    drawSVG: "0%",
                                                    onComplete: () => {
                                                        upProject.style.display = "none";
                                                        gsap.to(
                                                            tubePath,
                                                            timeOut,
                                                            {
                                                                drawSVG: "0%",
                                                                onComplete: () => {
                                                                    gsap.to(
                                                                        mainTubePath,
                                                                        timeOut,
                                                                        {
                                                                            drawSVG: window.pp,
                                                                            onComplete: () => {
                                                                                qs(".other-site").style.opacity = "1";
                                                                                setTimeout(() => {
                                                                                    enableScroll();
                                                                                }, 500);
                                                                                window.ani = false;
                                                                                window.lc = false;
                                                                                scroll.update();
                                                                                if (window.ev) {
                                                                                    window.ev.enable();
                                                                                }
                                                                            },
                                                                        },
                                                                        0
                                                                    );
                                                                    gsap.set(tube, {className: "tube clip"}, 0);
                                                                },
                                                            },
                                                            0
                                                        );
                                                    },
                                                },
                                                0
                                            );
                                        },
                                    },
                                    0
                                );
                            gsap
                                .timeline({
                                    defaults: {duration: 1},
                                })
                                .to(
                                    ourServices,
                                    0.5,
                                    {
                                        ease: Power0.easeNone,
                                        left: "0%",
                                    },
                                    0
                                );
                        }

                        // let serivcesTop = servicesAll.offsetTop;
                        // if (serivcesTop !== 0) {
                        //     scroll.scroll.stopScrolling();
                        //     setTimeout(() => {
                        //         main.style.cssText = `transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, -${serivcesTop}, 0, 1); transition: all 0.2s ease;`;
                        //         setTimeout(() => {
                        //             console.log("run");
                        //             backToProjects(() => {
                        //                 scroll.setScroll(0, serivcesTop);
                        //             });
                        //         }, 500)
                        //     }, 500)
                        // } else {
                        //     scroll.scrollTo(servicesCat, 2, () => {
                        //         backToProjects();
                        //     });
                        // }


                        scroll.scrollTo(servicesCat, 2, () => {
                            let x = setInterval(() => {
                                if (Math.abs(servicesAll.offsetTop - Math.ceil(window.y)) <= 5) {
                                    scroll.scroll.stopScrolling();
                                    let mmTube = mainTubePath,
                                        mmTubeStyle = window.getComputedStyle(mmTube),
                                        strokD = parseFloat(mmTubeStyle["strokeDasharray"].split(",")[0].replace("px", "")),
                                        totD = mainTubePath.getTotalLength();
                                    window.pp = `${
                                        parseFloat(window.servicesAnimation.progress()) * 100
                                    }%`;
                                    // console.log(window.pp, strokD / totD)
                                    window.pp = `${(strokD / totD) * 100}%`
                                    window.ev.disable();
                                    backToProjects();
                                    clearInterval(x);
                                }
                            }, 50)
                        });
                    });
                },
                true
            );
        });

        ScrollTrigger.addEventListener("refresh", () =>
            window.locomotiveScroll.update()
        );

        ScrollTrigger.refresh();
        let fixedTube = 0;


    }, [totalLength]);

    React.useEffect(() => {
        if (checkSite) return;
        let sectionServiceProduct = qs(".sectionServiceProduct");
        let servicesAll = qs(".services-all");
        let service = qs(".sectionServiceProducts");
        sectionServiceProduct.style.top =
            (service.offsetHeight < size.height
                ? size.height
                : service.offsetHeight) + "px";
        sectionServiceProduct.style.display = "block";
        servicesAll.style.height =
            (service.offsetHeight < size.height
                ? size.height
                : service.offsetHeight) + "px";
        window.locomotiveScroll.update();
    }, []);

    return (
        <div>
            {/*<NoSite>*/}
            {/*    <div className="container-no-site">*/}
            {/*        <h1>Can't browse this site :"</h1>*/}
            {/*    </div>*/}
            {/*</NoSite>*/}
            {/*<SiteAva>*/}

            {(!process.env.NODE_ENV || process.env.NODE_ENV !== "development") && (
                <Loading quote={quote}/>
            )}
            <Layout>
                <div className="projectLoadingBar hidden">
                    <div className="bar"></div>
                    <div className="loading_circle">
                        <LoadingBarLoading/>
                    </div>
                </div>
                <Menu/>
                <Navbar/>
                <div className="main">
                    <LogoSction/>
                    <AboutSection sD={sections.section_about}/>
                    <VisionMissionSection
                        sVD={sections.section_vision}
                        sMD={sections.section_mission}
                    />
                    <div className="setupOurStudio">
                        <div className="ani">
                            <SetupSection callB={forceUpdate}/>
                        </div>
                    </div>
                    <OurStudioSection sD={sections.section_ourstudio}/>
                    <OurTeamSection sD={sections.section_ourteam}/>
                    <OurServicesSection sD={sections.section_ourservices}/>
                    <div className="services-all">
                        <div className="ani">
                            <OurServicesCatSectionV2/>
                            <div className="serivceAnd">
                                <ServiceProjectsSection
                                    service={currentService}
                                    type={currentServiceType}
                                />
                                <ProjectSection
                                    project={currentProject}
                                    type={currentServiceType}
                                />
                            </div>
                        </div>
                    </div>
                    <OurClientsSection cD={clients}/>
                    <FooterSection/>
                </div>
            </Layout>
            {/*</SiteAva>*/}
        </div>
    );
}

export default App;
