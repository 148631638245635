import * as React from "react";
import useWindowSize from "../../../../../hooks/useWindowSize";
// import {qs} from "../../../../../helpers/helpers";
// import {gsap} from "gsap";
// import DrawSVGPlugin from "gsap/DrawSVGPlugin";
// import {ScrollTrigger} from "gsap/ScrollTrigger";

function SvgMiddtubeRespon({heightOfTube, tubeClass, colorFill}) {

    const size = useWindowSize();
    const [height, setHeight] = React.useState(782.7752);
    const [tube, setTube] = React.useState("M7.501 7.5L7.5 775.252");
    const [color, setColor] = React.useState("white")

    React.useEffect(() => {
        if (size.width > 479) return;

        if (heightOfTube == "100%") {
            heightOfTube = 778
        } else if (heightOfTube == "100vh") {
            heightOfTube = 911 + 10
            // heightOfTube = size.height + 10
        }

        if (colorFill) {
            setColor(colorFill)
        } else {
        }

        setHeight(heightOfTube + 10);
        setTube(`M7.501 7.5L7.5 ${heightOfTube}`);
    }, [size.height, heightOfTube, colorFill]);

    React.useEffect(() => {
        // if (tubeClass == "s2") {
        //     gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);
        //     gsap
        //         .timeline({
        //             defaults: {duration: 1},
        //             scrollTrigger: {
        //                 trigger: ".sectionAbout",
        //                 scrub: "20",
        //                 triggerHook: 0,
        //                 start: "top-=20 top",
        //                 end: "bottom-=50 top",
        //                 ease: "none",
        //             },
        //         })
        //         .fromTo(".tubefill.s2", {drawSVG: "5%"}, {drawSVG: "100%"}, 0)
        //         .add(window.tl, 0);
        // }
    }, [tubeClass])

    return (
        <svg
            width="15.001"
            height={height}
            viewBox={"0 0 15.001 " + height}
        >
            <path
                opacity={0.3}
                fill="none"
                stroke="#010101"
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d={tube}
            />
            <path
                className={"tubefill " + tubeClass}
                fill="none"
                stroke={color}
                strokeWidth={15}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                d={tube}
            />
        </svg>
    );
}

export default SvgMiddtubeRespon;
